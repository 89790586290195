import { RefObject, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import DefaultThumbnail from 'assets/defaultThumbnail.png';
import { UniversityCatalogProps, UniversityCatalogItemProps } from 'types';
import { CustomButton } from 'components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ListItem from '@material-ui/core/ListItem';

interface Props {
  catalog: UniversityCatalogProps;
  addItem?: (item: UniversityCatalogItemProps) => void;
  listRef: RefObject<HTMLDivElement>;
  isEnableCatalogList?: boolean;
}

const UniversityCatalog = ({ catalog, addItem, listRef, isEnableCatalogList }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [catalogItem, setCatalogItem] = useState<UniversityCatalogItemProps | undefined>();

  const handleExpandClick = () => {
    catalog.catalogItems && catalog.catalogItems.length > 0 && setExpanded(!expanded);
  };

  const onAddItem = () => {
    catalogItem && addItem !== undefined && addItem(catalogItem);
    setCatalogItem(undefined);
  };

  return (
    <div
      className={`${classes.root} ${isEnableCatalogList && isEnableCatalogList ? classes.gradedOut : ''}`}
      ref={listRef}
      id={`item-${catalog.catalogId}`}
    >
      <div className={classes.flex} onClick={handleExpandClick}>
        <img src={catalog.thumbnail ? catalog.thumbnail : DefaultThumbnail} className={classes.thumbnail} />
        <div className={classes.catalog}>{catalog?.itemName}</div>
      </div>
      {expanded && (
        <div>
          <div onClick={() => setIsOpen(!isOpen)} className={isOpen ? classes.open : classes.close}>
            <div className={classes.selectTop}>
              <div className={classes.selectText}>
                {catalogItem
                  ? catalogItem?.itemVariantName || catalogItem?.descriptorTags?.type || 'No Name Provided'
                  : 'Select Item'}
              </div>
              <KeyboardArrowDown />
            </div>
            {isOpen && (
              <div className={classes.list}>
                {catalog.catalogItems?.map((item, index) => (
                  <ListItem
                    className={classes.item}
                    button
                    key={index}
                    onClick={() => {
                      setIsOpen(false);
                      setCatalogItem(item);
                    }}
                  >
                    {item?.itemVariantName || item.descriptorTags?.type || 'No Name Provided'}
                  </ListItem>
                ))}
              </div>
            )}
          </div>
          <CustomButton
            title="Add Item"
            width="230px"
            solid={false}
            height="48px"
            borderRadius="34px"
            mTop="0px"
            enable={!!catalogItem}
            buttonClicked={onAddItem}
            textColor={Colors.cyprus}
            border={catalogItem ? `1px solid ${Colors.frenchPass}` : 'none'}
          />
        </div>
      )}
    </div>
  );
};

export default UniversityCatalog;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'auto',
      marginBottom: '30px',
    },
    flex: {
      display: 'flex',
      cursor: 'pointer',
      transition: 'transform 0.4s',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
    list: {
      margin: '10px 0px',
    },
    thumbnail: {
      width: '40px',
      height: '40px',
      marginRight: '20px',
    },
    catalog: {
      fontSize: '16px',
      color: Colors.cyprus1,
      lineHeight: '21px',
    },
    selectText: {
      fontSize: '17px',
      color: Colors.cyprus1,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '180px',
    },
    selectTop: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      marginTop: '13px',
    },
    close: {
      border: `1px solid ${Colors.mystic}`,
      width: '230px',
      height: '48px',
      boxSizing: 'border-box',
      cursor: 'pointer',
      marginBottom: '10px',
      borderRadius: '6px',
      marginTop: '20px',
      transition: 'transform 0.4s',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
    open: {
      marginBottom: '10px',
      border: `1px solid ${Colors.mystic}`,
      width: '230px',
      borderRadius: '6px',
      marginTop: '20px',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
      color: Colors.cyprus1,
    },
    gradedOut: {
      opacity: '0.5' /* Adjust the opacity value as needed */,
      pointerEvents: 'none' /* Disables pointer events */,
    },
  }),
);
