import { ReactNode } from 'react';
import styled from 'styled-components';
import logo_header from 'assets/logo-header.svg';
import Colors from 'theme';

interface Props {
  children?: ReactNode;
}

const Card = ({ children }: Props): JSX.Element => {
  return (
    <Container>
      <Logo src={logo_header}></Logo>
      {children}
    </Container>
  );
};

const Container = styled.div`
  border-radius: 8px;
  background-color: white;
  min-width: 300px;
  min-height: 300px;
  width: 530px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0px 0px;
  @media (max-width: 900px) {
    width: 430px;
  }
  @media (max-width: 700px) {
    padding: 30px 0;
  }
  @media (max-width: 650px) {
    width: 100%;
    border-radius: 0px;
    justify-content: flex-start;
    padding-top: 77px;
    margin: 0px;
    overflow: auto;
    height: calc(100vh - 60px);
  }
  @media (max-height: 900px) {
    padding: 30px 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.icon_grey_light};
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar {
    width: 16px;
  }
`;
const Logo = styled.img`
  width: 168px;
  height: 38px;
  margin: 31px auto 0px auto;
  display: block;
  @media (max-width: 170px) {
    width: 99vw;
  }
  @media (max-width: 550px) {
    margin-top: 0;
  }
`;

export default Card;
