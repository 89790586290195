import useAxios, { ServerRoutes } from 'api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GET } from 'utils';
import RoomStyleTemplate from './RoomStyleTemplate';
import { Style } from 'types';

const RoomStyles = () => {
  const [styles, setStyles] = useState<Style[] | null>(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const room_config_id = searchParams.get('room_config_id');

  const {
    callApi: getStyles,
    loading: stylesLoading,
    error: stylesError,
  } = useAxios(`${ServerRoutes.stylesApi}/${room_config_id}`, GET, false);

  console.log({ room_config_id, stylesError, stylesLoading });
  useEffect(() => {
    fetchStyles();
  }, []);

  const fetchStyles = async () => {
    await getStyles({}, async (styleResp) => {
      if (styleResp) {
        setStyles(styleResp?.data);
      }
    });
  };

  return (
    <>
      <RoomStyleTemplate styles={styles || []} fetchStyles={fetchStyles} />
    </>
  );
};
export default RoomStyles;
