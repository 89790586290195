import { FormControl, InputLabel, Select, MenuItem, createStyles, makeStyles, Typography } from '@material-ui/core';

import Colors from 'theme';
import { Category, SubCategory, UniversityCatalogProps } from 'types';

interface Props {
  options: Category[] | SubCategory[] | UniversityCatalogProps[] | undefined;
  onChange: (value: string) => void;
  width?: string;
  label?: string;
  errorMsg?: string;
  value?: string;
  isdisabled?: boolean;
}

interface StyleProps {
  width?: string;
}

const CustomSelectCategory = ({ options, onChange, width, label, errorMsg, isdisabled = false, value = '' }: Props) => {
  const classes = useStyles({ width });

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          className={classes.select}
          onChange={(e) => onChange(e.target.value as string)}
          disabled={isdisabled}
        >
          {options?.map((option, index: number) => {
            if ('id' in option) {
              return (
                <MenuItem key={index} value={option.id}>
                  {option.title}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={index} value={option.catalogId}>
                  {option.itemName}
                </MenuItem>
              );
            }
          })}
        </Select>
      </FormControl>

      {errorMsg && (
        <Typography variant="h4" className={classes.Label}>
          {errorMsg}
        </Typography>
      )}
    </div>
  );
};

export default CustomSelectCategory;

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      width: (props: StyleProps) => props.width || '200px !important',
      [theme.breakpoints.down('sm')]: {
        width: '200px !important',
      },
    },
    formControl: {
      minWidth: '200px',
      margin: '20px 0px',

      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    Label: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      color: Colors.caution,
      marginTop: 0,
      height: 'fit-content',
      marginBottom: '6px',
      transtion: 'all 0.8s',
    },
  }),
);
