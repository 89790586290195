import {
  Button,
  Card,
  Collapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import useStyles from 'styles/ProductStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Combination, Geometry, Material, Media } from 'types';

const geometryInit = {
  id: '',
  url: '',
};
const materialsInit = {
  id: '',
  name: '',
  materialName: '',
  materialUrl: '',
};

const mediasInit = {
  id: '',
  position: 0,
  url: '',
};

type CombinationCardProps = {
  combination: Combination;
  i: number;
  catalogId: string;
  handleCombinationMaterialFormChange: (
    catalogId: string,
    combinationId: string,
    materialId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => void;
  handleCombinationGeometryFormChange: (
    catalogId: string,
    combinationId: string,
    geometryId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => void;
  handleCombinationMediasFormChange: (
    catalogId: string,
    combinationId: string,
    mediasId: string,
    fieldToUpdate: string,
    updatedValue: string | number,
  ) => void;
  addNewMaterial: (catalogId: string, combinationId: string, material: Material) => void;
  addNewGeometries: (catalogId: string, combinationId: string, geometry: Geometry) => void;
  addNewMedias: (catalogId: string, combinationId: string, medias: Media) => void;
  removeAttribute: (catalogId: string, combinationId: string, attributeId: string, propertyName: string) => void;
  updateCombination: (catalogId: string, combinationId: string, fieldToUpdate: 'active', active: boolean) => void;
};
const CombinationCard = ({
  combination,
  i,
  catalogId,
  handleCombinationMaterialFormChange,
  handleCombinationGeometryFormChange,
  handleCombinationMediasFormChange,
  addNewMaterial,
  addNewGeometries,
  addNewMedias,
  removeAttribute,
  updateCombination,
}: CombinationCardProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [geometry, setGeometry] = useState(geometryInit);
  const [material, setMaterial] = useState(materialsInit);
  const [medias, setMedias] = useState(mediasInit);

  const handleMaterialChange = (fieldName: string, value: string, materialId: string): void => {
    if (materialId) {
      handleCombinationMaterialFormChange(catalogId, combination?.id, materialId, fieldName, value);
    }
  };

  const handleGeometryChange = (fieldName: string, value: string, geometryId: string): void => {
    if (geometryId) {
      handleCombinationGeometryFormChange(catalogId, combination?.id, geometryId, fieldName, value);
    }
  };

  const handleMediasChange = (fieldName: string, value: string | number, mediaId: string): void => {
    console.log({ value });
    if (mediaId) {
      handleCombinationMediasFormChange(catalogId, combination?.id, mediaId, fieldName, value);
    }
  };

  const handleChangeMaterialFields = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setMaterial({
      ...material,
      [name]: value,
    });
  };

  const handleAddClick = () => {
    const { name, materialName, materialUrl } = material;
    if (name && materialName && materialUrl) {
      addNewMaterial(catalogId, combination?.id, material);
      setMaterial({ ...materialsInit });
    }
  };

  const handleChangeGeometryField = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setGeometry({
      ...geometry,
      [name]: value,
    });
  };

  const handleGeometryClick = () => {
    const { url } = geometry;
    if (url) {
      addNewGeometries(catalogId, combination?.id, geometry);
      setGeometry({ ...geometryInit });
    }
  };

  const handleChangeMediasField = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (typeof name === 'string') {
      setMedias({
        ...medias,
        [name]: value,
      });
    }
  };

  const handleMediasClick = () => {
    const { url } = medias;
    if (url) {
      addNewMedias(catalogId, combination?.id, medias);
      setMedias({ ...mediasInit });
    }
  };

  const handleSwitchChange = (event: any) => {
    const isActive = event.target.checked;
    event.stopPropagation();
    console.log(isActive);
    updateCombination(catalogId, combination?.id, 'active', isActive);
  };

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const isAddDisabled = !(material.name && material.materialName && material.materialUrl);
  const isGeometryAddDisabled = !geometry?.url;
  const isMediasAddDisabled = !(medias?.position && medias?.url);

  return (
    <Card style={i % 2 == 0 ? { backgroundColor: '#E5E5E5' } : {}}>
      <div className={classes.buttons}>
        <div className={classes.distributeHorizontally}>
          <Typography gutterBottom variant="overline" component="div">
            <span style={{ fontWeight: 'bold' }}>SKU: </span>
            {combination.vendorSku}
          </Typography>
          <Typography gutterBottom variant="overline" component="div">
            <span style={{ fontWeight: 'bold' }}>Variant: </span>
            {combination.variantName}
          </Typography>
          <Typography gutterBottom variant="overline" component="div">
            <span style={{ fontWeight: 'bold' }}>Shopify Variant ID: </span>
            {combination.shopifyVariantId}
          </Typography>
          <div>
            <FormControlLabel
              control={<Switch checked={combination.active} onChange={(e) => handleSwitchChange(e)} />}
              label="Active"
            />
            {open ? <ExpandLess onClick={handleExpandClick} /> : <ExpandMore onClick={handleExpandClick} />}
          </div>
        </div>
      </div>
      <Collapse key={combination.vendorSku} in={open} timeout="auto" unmountOnExit>
        <div className={classes.buttons}>
          <div className={classes.materialWrapper}>
            <div style={{ margin: '2%' }}>
              <Typography variant="button" style={{ fontWeight: 'lighter' }}>
                Materials
              </Typography>
              {combination.materials?.map((material: Material) => (
                <div key={material.id} className={classes.buttons}>
                  <div className={classes.distributeHorizontally}>
                    <TextField
                      onChange={(e) => handleMaterialChange('name', e.target.value, material?.id)}
                      label="Name"
                      variant="standard"
                      defaultValue={material.name}
                      className={classes.textField}
                    />
                    <TextField
                      onChange={(e) => handleMaterialChange('materialName', e.target.value, material?.id)}
                      label="Material Name"
                      variant="standard"
                      defaultValue={material.materialName}
                      className={classes.textField}
                    />
                    <TextField
                      onChange={(e) => handleMaterialChange('materialUrl', e.target.value, material?.id)}
                      label="Material URL"
                      variant="standard"
                      defaultValue={material.materialUrl}
                      className={classes.textField}
                    />
                    <span
                      className={classes.textField}
                      onClick={() => removeAttribute(catalogId, combination?.id, material?.id, 'materials')}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}
              <div className={classes.buttons}>
                <div className={classes.distributeHorizontally}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={material.name}
                    onChange={(e) => handleChangeMaterialFields(e)}
                    className={classes.textField}
                  />
                  <TextField
                    label="Material Name"
                    variant="outlined"
                    name="materialName"
                    value={material.materialName}
                    onChange={(e) => handleChangeMaterialFields(e)}
                    className={classes.textField}
                  />
                  <TextField
                    label="Material URL"
                    variant="outlined"
                    name="materialUrl"
                    value={material.materialUrl}
                    onChange={(e) => handleChangeMaterialFields(e)}
                    className={classes.textField}
                  />
                  <Button
                    className={`${classes.textField}${classes.buttonStyle}`}
                    onClick={handleAddClick}
                    disabled={isAddDisabled}
                    variant="contained"
                    color="primary"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.geometriesWrapper}>
            <div style={{ margin: '2%' }}>
              <Typography variant="button" style={{ fontWeight: 'lighter' }}>
                Geometries
              </Typography>
              {combination.geometries?.map((geometry: Geometry) => (
                <div key={geometry.id} className={classes.buttons}>
                  <div className={classes.distributeHorizontally}>
                    <TextField
                      onChange={(e) => handleGeometryChange('url', e.target.value, geometry?.id)}
                      label="URL"
                      variant="standard"
                      defaultValue={geometry.url}
                      className={classes.textField}
                    />
                    <span
                      className={classes.textField}
                      onClick={() => removeAttribute(catalogId, combination?.id, geometry?.id, 'geometries')}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              ))}
              <div className={classes.buttons}>
                <div className={classes.distributeHorizontally}>
                  <TextField
                    name="url"
                    value={geometry.url}
                    onChange={(e) => handleChangeGeometryField(e)}
                    label="URL"
                    variant="outlined"
                    className={classes.textField}
                  />
                  <Button
                    className={`${classes.textField}${classes.buttonStyle}`}
                    onClick={handleGeometryClick}
                    disabled={isGeometryAddDisabled}
                    variant="contained"
                    color="primary"
                  >
                    <AddIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.buttons}>
          <div className={classes.mediasWrapper}>
            <div style={{ margin: '2%' }}>
              <Typography variant="button" style={{ fontWeight: 'lighter' }}>
                Medias
              </Typography>
              {combination.medias?.map((media: Media) => (
                <div key={media.id} className={classes.distributeHorizontally100}>
                  <TextField
                    onChange={(e) => handleMediasChange('url', e.target.value, media?.id)}
                    label="URL"
                    variant="standard"
                    defaultValue={media.url}
                    style={{ width: '90%' }}
                  />
                  <FormControl>
                    <InputLabel id="position">Position</InputLabel>
                    <Select
                      labelId="position"
                      value={media.position}
                      onChange={(e) => {
                        console.log({ e });
                        handleMediasChange('position', e.target.value as number, media?.id);
                      }}
                      autoWidth
                    >
                      {[...Array(10)].map((_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <span onClick={() => removeAttribute(catalogId, combination?.id, media?.id, 'medias')}>
                    <DeleteIcon />
                  </span>
                </div>
              ))}
              <div className={classes.distributeHorizontally100}>
                <TextField
                  name="url"
                  value={medias.url}
                  onChange={(e) => handleChangeMediasField(e)}
                  label="URL"
                  variant="standard"
                  style={{ width: '90%' }}
                />
                <FormControl>
                  <InputLabel id="position">Position</InputLabel>
                  <Select
                    name="position"
                    onChange={(e) => handleChangeMediasField(e)}
                    labelId="position"
                    value={medias?.position}
                    autoWidth
                  >
                    {[...Array(10)].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  className={`${classes.textField}${classes.buttonStyle}`}
                  onClick={handleMediasClick}
                  disabled={isMediasAddDisabled}
                  variant="contained"
                  color="primary"
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </Card>
  );
};
export default CombinationCard;
