import { useEffect, useRef } from 'react';
import { Engine, Scene, Vector3, PhotoDome, ArcRotateCamera, PointerEventTypes } from '@roomie-engineering/room-viewer';

interface Props {
  url?: string;
}

// TODO jm: this is duplicated between admin/student frontends temporarily.
const PhotoViewerContainer = ({ url }: Props): JSX.Element => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    // setup babylon
    const canvas = ref.current;
    const engine = new Engine(ref.current, true);
    const scene = new Scene(engine);

    function loadPhoto() {
      engine.runRenderLoop(function () {
        scene.render();
      });

      const getUrl = () => {
        if (url) {
          if (url.includes('app.roomie.com')) {
            return `${window.location.origin}${getPath(url || '')}`;
          }
          return url;
        }
        return `${window.location.origin}/models/rooms/room-standard-umasslowell-riverview-type1-00.jpg`;
      };

      const photoParam = getUrl();

      const camera = new ArcRotateCamera('Camera', -Math.PI / 2, Math.PI / 2, 5, Vector3.Zero(), scene);
      camera.angularSensibilityX = -3500;
      camera.angularSensibilityY = -3500;
      camera.attachControl(canvas, true);
      camera.inputs.attached.mousewheel.detachControl();

      let zoomLevel = 2;

      const dome = new PhotoDome(
        'PhotoDome',
        '' + photoParam,
        {
          resolution: 32,
          size: 1000,
          useDirectMapping: false,
        },
        scene,
      );

      scene.registerAfterRender(function () {
        dome.fovMultiplier = zoomLevel;
      });

      scene.onPointerObservable.add(function (e: any) {
        if (dome === undefined) {
          return;
        }
        const e2: WheelEvent = e.event as WheelEvent;
        zoomLevel += e2.deltaY * 0.0005;
        if (zoomLevel < 1) {
          zoomLevel = 1;
        }
        if (zoomLevel > 2) {
          zoomLevel = 2;
        }
      }, PointerEventTypes.POINTERWHEEL);
    }

    loadPhoto();

    const resizeEventListener = () => {
      if (engine) {
        engine.resize();
      }
    };

    // the canvas/window resize event handler
    window.addEventListener('resize', resizeEventListener);

    return function cleanUp() {
      window.removeEventListener('resize', resizeEventListener);
    };
  }, [url]);

  return (
    <>
      <canvas id="photoViewer" ref={ref}></canvas>
    </>
  );
};

function getPath(url: string): string {
  const path = url.split('models');
  return `/models${path[1]}`;
}

export default PhotoViewerContainer;
