const Routes = {
  DEFAULT: '/',
  STUDENTS_ASSIGNMENTS: '/students_assignments',
  TERMS_AND_POLICY: '/terms_and_policy',
  PHOTO_VIEWER: '/photo_viewer',
  TEMPLATE: '/template',
  UPLOAD_CATALOG: '/upload_catalog',
  UPLOAD_ROOM: '/upload_room',
  ROOM_LINKS: '/room_links',
  TEMPLATE_LINKS: '/template_links',
  ROOM_VIEWER: '/room_viewer',
  ROOM_ASSIGNMENTS: '/room_assignments',
  ROOM_SETUP: '/room_setup',
  UNIVERSITY_FURNITURE: '/university_furniture',
  CATALOGS_UNIVERSITY_ASSOCIATION: '/catalogs_university_association',
  SEARCH_CATALOG: '/search_catalog',
  ROOM_STYLES: '/room_styles',
  PRODUCTS: '/products',
  CREATE_STYLE: '/create_style',
  STYLES_DASHBOARD: '/styles_dashboard',
  CHECKLIST: '/checklist',
};

export default Routes;
