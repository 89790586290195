import { ReactNode, useState } from 'react';
import { LiveChatComponent, LiveChatIcon, Snackbar } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

interface Props {
  children: ReactNode;
}

const LiveChatContainer = ({ children }: Props) => {
  const classes = useStyles();
  const [showLiveChat, setShowLiveChat] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

  return (
    <>
      <Snackbar setIsOpen={setIsError} isOpen={isError} severity={'error'} message={message} />
      {children}
      {showLiveChat ? (
        <LiveChatComponent
          setOpen={setShowLiveChat}
          open={showLiveChat}
          setError={setIsError}
          setMessage={setMessage}
        />
      ) : (
        <LiveChatIcon onClick={() => setShowLiveChat(true)} className={classes.liveChatIcon} />
      )}
    </>
  );
};

export default LiveChatContainer;

const useStyles = makeStyles(() => ({
  liveChatIcon: {
    position: 'absolute',
    right: '30px',
    zIndex: 10,
    bottom: '20px',
    transition: 'all 0.5s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
    height: '50px',
    width: '50px',
    cursor: 'pointer',
    filter: Colors.liveChatShadow,
  },
}));
