import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Colors from 'theme';

const useStyles = makeStyles(() => ({
  overlay: {
    height: (props: Props) => (props.coverPage ? '100%' : 'auto'),
    zIndex: 1000000,
    position: (props: Props) => (props.coverPage ? 'fixed' : 'relative'),
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: (props: Props) => (props.coverPage ? Colors.overlay : 'none'),
  },
  progress: {
    color: (props: Props) => props.color || Colors.rating_count_blue,
  },
}));

interface Props {
  isLoading: boolean;
  coverPage?: boolean;
  color?: string;
}

const CircularProgressWithOverlay = ({ isLoading, coverPage = true, color }: Props): JSX.Element => {
  const classes = useStyles({ coverPage, isLoading, color });
  return (
    <>
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
    </>
  );
};

export default CircularProgressWithOverlay;
