import { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'context/authContext';
import axios, { AxiosError, AxiosResponse } from 'axios';
import config from 'config';
import { ServerErrors } from 'utils/errors';
import { BuildingsAttributes, LayoutItem, Section, SectionResponse, Catalog } from 'types';
import { DELETE, GET, PUT } from 'utils';
import { getAuthTokens, saveAuthTokens, saveError } from 'context/localStorage';

export const ServerRoutes = {
  googleSignIn: 'google_authentication',
  adminLoadLayout: 'admin/roomconfig/load',
  adminSaveLayout: 'admin/roomconfig/save',
  forgotPassword: 'forgot_password',
  verifyOtp: 'verify_forgot_password_otp',
  updatePassword: 'update_password',
  adminRoomConfigs: 'admin/roomconfigs',
  adminRoomsByConfig: 'admin/roomsbyconfig',
  adminUploadCatalog: 'admin/roomconfig/upload_catalog',
  adminUploadCatalogItem: 'admin/roomconfig/upload_catalog_item',
  searchRoom: 'admin/roomconfig/search_room_number',
  adminSearchCatalog: 'admin/roomconfig/search_catalog',
  adminUniversities: 'admin/roomconfig/universities',
  loadRooms: 'admin/roomconfig/rooms_by_config_and_building',
  adminUniversityBuildings: 'admin/roomconfig/university_buildings',
  adminUploadRoom: 'admin/roomconfig/upload_building_room',
  configsByBuilding: 'admin/roomconfig/room_configs_by_building',
  adminUniversityCatalogs: 'admin/universitycatalog/get_catalogs',
  adminSaveUniversityCatalogs: 'admin/universitycatalog/save',
  adminLoadUniversityCatalogs: 'admin/roomconfig/university_catalog',
  adminRoomConfig: 'admin/room_config_by_university_and_building',
  adminEditConfig: 'admin/roomconfig/edit_room_config',
  addTemplate: 'admin/roomconfig/add_template',
  saveCatalog: 'admin/save_catalog',
  saveCatalogItem: 'admin/save_catalog_item',
  addRoom: 'admin/roomconfig/add_room',
  moveRoom: 'admin/roomconfig/move_room',
  updateBuildingName: 'admin/update_building_name',
  getBuildingsAttributes: 'admin/get_buildings_attributes_years',
  saveBuildingsAttributes: 'admin/save_buildings_attributes_years',
  updateConfigName: 'admin/roomconfig/update_config_name',
  adminUpdateStatus: 'admin/roomconfig/update_status',
  addBuilding: 'admin/add_building',
  removeStudent: 'admin/remove_student',
  getUniversityLinks: 'admin/get_buildings_info',
  getTemplateLinks: 'admin/get_template_links',
  guideViewData: 'admin/get_guide_view_data',
  studentsAssigments: 'admin/studentassignment',
  refreshToken: 'refresh_token',
  logout: 'logout',
  getAdminInfo: 'admin/get_admin_info',
  loadCategories: 'loadcategories',
  room_styles: 'template',
  stylesApi: 'admin/styles',
  deleteStyle: 'admin/styles',
  productCatalog: 'product-catalogs/csv',
  productCatalogs: 'product-catalogs',
  syncProductCatalog: 'product-catalogs/sync',
  removeItem: 'removeitem',
  styleItemsCatalogs: 'product-catalogs/style-catalogs',
  getAppliedStyles: 'admin/roomconfig/getAppliedStyle',
  removeStyleByConfigId: 'admin/room-config/styles',
  createAssociation: 'admin/room-config/styles',
  createChecklist: 'checklist/sections',
};

export const ShoppingServiceRoute = {
  getStyleCatalog: 'product-catalogs/university-catalog',
};

export interface Params {
  roomie_university_building_room_id?: string;
  roomie_user_room_layout_id?: string;
  roomie_catalog_id?: string;
  roomie_catalog_item_id?: string;
  roomie_parent_user_room_item_id?: string | null;
  anchor?: string | null;
  position_x?: number;
  position_y?: number;
  position_z?: number;
  rotation_x?: number;
  rotation_y?: number;
  rotation_z?: number;
  height?: number;
  catalog_id?: string;
  room_config_id?: string;
  user_room_id?: string;
  roomie_user_room_item_id?: string;
  token?: string;
  university_id?: string;
  roomie_room_config_id?: string;
  catalogId?: string | null;
  catalogName?: string;
  universityId?: string;
  catalogIds?: string[];
  productCatalogIds?: string;
  templateItems?: LayoutItem[];
  id?: string;
  emails?: string[];
  email?: string;
  otp?: string;
  otpId?: string;
  newPassword?: string;
  userRoomId?: string;
  building_id?: string | null;
  roomie_building_room_id?: string;
  roomie_user_room_id?: string;
  primary_roomie_user_room_id?: string;
  configId?: string;
  panoUrl?: string;
  roomConfigName?: string;
  friendlyName?: string;
  roomDescriptionBlurb?: string;
  status?: string;
  buildingName?: string;
  buildingId?: string;
  roomNumber?: string;
  isSecondary?: boolean;
  roomId?: string;
  buildingAttributes?: BuildingsAttributes[];
  type?: string;
  subType?: string;
  surfaceType?: string;
  itemName?: string;
  itemDescription?: string;
  itemVariantName?: string;
  itemVariantDescription?: string;
  itemVariantDefault?: boolean;
  catalogItemId?: string | null;
  thumbnail?: string;
  category?: string;
  subCategory?: string;
  modelUrl?: string;
  styleId?: string;
  query?: string;
  appliedStylePayload?: any;
  templateStyleId?: string;
  templateStyleItems?: any;
  filteredLayout?: any;
  sections?: SectionResponse[] | Section[];
  catalogs?: Catalog[];
}

interface Response {
  response: AxiosResponse | undefined;
  error: AxiosError | undefined;
  loading: boolean;
  callApi: (params: Params | FormData, onCompleted?: (res: AxiosResponse) => void, postParams?: boolean) => void;
}

const useAxios = (
  route: string,
  method: string,
  toCallOnMount = true,
  contentType = 'application/json',
  params?: Params,
  service?: 'server' | 'shopping',
): Response => {
  console.log({ contentType });

  const authContext = useContext(AuthContext);
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);

  const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    if (!base64Url) return;
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload).exp;
  };

  const isPublicRoute = () =>
    route === ServerRoutes.forgotPassword || route === ServerRoutes.verifyOtp || route === ServerRoutes.updatePassword;

  const logout = async () => {
    const tokens = getAuthTokens();
    await axios.post(
      `${config.REACT_APP_API_BASE_URL}/${ServerRoutes.logout}`,
      {},
      {
        headers: {
          'roomie-session-key': tokens.roomieAccessToken ?? '',
        },
      },
    );
  };

  const getRefreshToken = async () => {
    const tokens = getAuthTokens();
    return await axios.post(
      `${config.REACT_APP_API_BASE_URL}/${ServerRoutes.refreshToken}`,
      {},
      {
        headers: {
          'roomie-session-key': tokens.roomieRefreshToken ?? '',
        },
      },
    );
  };

  const callGet = async (onCompleted?: (res: AxiosResponse) => void, params?: Params | FormData) => {
    setLoading(true);
    let noToken = false;
    let isTokenValid = true;
    let res,
      exp = 0;
    const tokens = getAuthTokens();
    if (tokens.roomieAccessToken) exp = parseJwt(tokens.roomieAccessToken);
    else noToken = true;
    const seconds = new Date().getTime() / 1000;
    if (seconds - exp >= 0) {
      isTokenValid = false;
      if (tokens.roomieRefreshToken) res = await getRefreshToken();
      if (res && res?.data && res?.data?.code === 0)
        await saveAuthTokens({
          roomieAccessToken: res?.data?.roomieAccessToken,
          roomieRefreshToken: res?.data?.roomieRefreshToken,
        });
      else setLoading(false);
    }
    if ((res && res?.data && res?.data?.code === 0 && !isTokenValid) || isTokenValid) {
      await axios
        .get(`${service === 'shopping' ? config.REACT_APP_SHOPPING_URL : config.REACT_APP_API_BASE_URL}/${route}`, {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'roomie-session-key': !isTokenValid ? res?.data?.roomieAccessToken : tokens.roomieAccessToken ?? '',
          },
          params: params,
          data: {},
        })
        .then((res) => {
          onInvalidSession(res);
          onCompleted && onCompleted(res);
          setResponse(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (res) onInvalidSession(res);
    else if (noToken || !isTokenValid) {
      saveError('error');
      authContext?.logout();
    }
  };

  const callDelete = async (onCompleted?: (res: AxiosResponse) => void, params?: Params | FormData) => {
    setLoading(true);
    let noToken = false;
    let isTokenValid = true;
    let res,
      exp = 0;
    const tokens = getAuthTokens();
    if (tokens.roomieAccessToken) exp = parseJwt(tokens.roomieAccessToken);
    else noToken = true;
    const seconds = new Date().getTime() / 1000;
    if (seconds - exp >= 0) {
      isTokenValid = false;
      if (tokens.roomieRefreshToken) res = await getRefreshToken();
      if (res && res?.data && res?.data?.code === 0)
        await saveAuthTokens({
          roomieAccessToken: res?.data?.roomieAccessToken,
          roomieRefreshToken: res?.data?.roomieRefreshToken,
        });
      else setLoading(false);
    }
    if ((res && res?.data && res?.data?.code === 0 && !isTokenValid) || isTokenValid) {
      await axios
        .delete(`${service === 'shopping' ? config.REACT_APP_SHOPPING_URL : config.REACT_APP_API_BASE_URL}/${route}`, {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'roomie-session-key': !isTokenValid ? res?.data?.roomieAccessToken : tokens.roomieAccessToken ?? '',
          },
          params: params,
          data: {},
        })
        .then((res) => {
          onInvalidSession(res);
          onCompleted && onCompleted(res);
          setResponse(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (res) onInvalidSession(res);
    else if (noToken || !isTokenValid) {
      saveError('error');
      authContext?.logout();
    }
  };

  const callPost = async (args: Params | FormData, onCompleted?: (res: AxiosResponse) => void, postParams = false) => {
    setLoading(true);
    let isTokenValid = true;
    let noToken = false;
    let res,
      exp = 0;
    const tokens = getAuthTokens();
    if (tokens.roomieAccessToken) exp = parseJwt(tokens.roomieAccessToken);
    else noToken = true;
    const seconds = new Date().getTime() / 1000;
    if (seconds - exp >= 0 && !isPublicRoute()) {
      isTokenValid = false;
      if (tokens.roomieRefreshToken) res = await getRefreshToken();
      if (res && res?.data && res?.data?.code === 0)
        await saveAuthTokens({
          roomieAccessToken: res?.data?.roomieAccessToken,
          roomieRefreshToken: res?.data?.roomieRefreshToken,
        });
      else setLoading(false);
    }
    if ((res && res?.data && res?.data?.code === 0 && !isTokenValid) || isTokenValid) {
      const endpoint = `${
        service === 'shopping' ? config.REACT_APP_SHOPPING_URL : config.REACT_APP_API_BASE_URL
      }/${route}`;
      await axios
        .post(endpoint, args, {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'roomie-session-key': !isTokenValid ? res?.data?.roomieAccessToken : tokens.roomieAccessToken ?? '',
          },
          params: postParams ? args : null,
        })
        .then((res) => {
          onInvalidSession(res);
          setResponse(res);
          onCompleted && onCompleted(res);
        })
        .catch((err) => {
          console.log({ err });
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (res) onInvalidSession(res);
    else if (noToken || !isTokenValid) {
      saveError('error');
      authContext?.logout();
    }
  };

  const callPut = async (args: Params | FormData, onCompleted?: (res: AxiosResponse) => void, postParams = false) => {
    setLoading(true);
    let isTokenValid = true;
    let noToken = false;
    let res,
      exp = 0;
    const tokens = getAuthTokens();
    if (tokens.roomieAccessToken) exp = parseJwt(tokens.roomieAccessToken);
    else noToken = true;
    const seconds = new Date().getTime() / 1000;
    if (seconds - exp >= 0 && !isPublicRoute()) {
      isTokenValid = false;
      if (tokens.roomieRefreshToken) res = await getRefreshToken();
      if (res && res?.data && res?.data?.code === 0)
        await saveAuthTokens({
          roomieAccessToken: res?.data?.roomieAccessToken,
          roomieRefreshToken: res?.data?.roomieRefreshToken,
        });
      else setLoading(false);
    }
    if ((res && res?.data && res?.data?.code === 0 && !isTokenValid) || isTokenValid) {
      const endpoint = `${
        service === 'shopping' ? config.REACT_APP_SHOPPING_URL : config.REACT_APP_API_BASE_URL
      }/${route}`;
      await axios
        .put(endpoint, args, {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
            'roomie-session-key': !isTokenValid ? res?.data?.roomieAccessToken : tokens.roomieAccessToken ?? '',
          },
          params: postParams ? args : null,
        })
        .then((res) => {
          console.log({ res });
          onInvalidSession(res);
          setResponse(res);
          onCompleted && onCompleted(res);
        })
        .catch((err) => {
          console.log({ err });
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (res) onInvalidSession(res);
    else if (noToken || !isTokenValid) {
      saveError('error');
      authContext?.logout();
    }
  };

  const onInvalidSession = async (err: AxiosResponse) => {
    if (
      err?.data?.reason == ServerErrors.INVALID_SESSION ||
      err?.data?.reason == ServerErrors.EXPIRED_ACCESS_TOKEN ||
      err?.data?.reason == ServerErrors.REFRESH_TOKEN_EXPIRED ||
      err?.data?.reason == ServerErrors.INVALID_REFRESH_TOKEN
    ) {
      saveError('error');
      await logout();
      authContext?.logout();
    }
  };

  const callApi = async (
    params?: Params | FormData,
    onCompleted?: (res: AxiosResponse) => void,
    postParams = false,
  ) => {
    if (method === GET) await callGet(onCompleted, params);
    else if (method === DELETE) await callDelete(onCompleted, params);
    else if (method === PUT && params) await callPut(params, onCompleted, postParams);
    else if (params) await callPost(params, onCompleted, postParams);
  };

  useEffect(() => {
    const networkCall = async () => {
      if (toCallOnMount) {
        await callApi(params);
      }
    };
    networkCall();
  }, [toCallOnMount]);

  return { response, error, loading, callApi };
};

export default useAxios;
