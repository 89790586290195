import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import { MinimizeIcon, OnlineStatusIcon, PopChatIcon } from 'components';
import clsx from 'clsx';
import { TextStyles } from 'styles/defaultStyles';
import Close from '@material-ui/icons/Close';

interface Props {
  name: string;
  online: boolean;
  open: boolean;
  minimize: () => void;
  popChat: () => void;
  closeChat: () => void;
}

const TitleBar = (props: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.Container}>
      <div className={classes.IconAndNameContainer}>
        <OnlineStatusIcon
          fill={props.online ? Colors.button_bg_active : Colors.caution}
          className={classes.StatusIcon}
        />
        <div className={clsx(TextStyles().Icon_Text, classes.Name)}>{props.name}</div>
      </div>
      <div className={classes.Icons}>
        {props.open ? (
          <MinimizeIcon className={clsx(classes.MinimizeIcon, classes.hover)} onClick={() => props.minimize()} />
        ) : (
          <PopChatIcon className={clsx(classes.PopChatIcon, classes.hover)} onClick={() => props.popChat()} />
        )}
        <Close className={clsx(classes.hover, classes.CloseIcon)} onClick={() => props.closeChat()} />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    margin: '0px 20px',
  },
  IconAndNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '10px',
    alignItems: 'center',
  },
  Icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '30px',
    alignItems: 'center',
  },
  StatusIcon: {
    width: 8,
    height: 8,
  },
  CloseIcon: {
    color: Colors.button_bg_active,
  },
  Name: {
    color: Colors.message_title_black,
    fontSize: '12px',
    lineHeight: '12px',
  },
  MinimizeIcon: {
    width: 14,
    height: 19,
  },
  PopChatIcon: {
    width: 13,
    height: 13,
  },
  hover: {
    transition: 'all 0.5s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.1)',
    },
  },
}));

export default TitleBar;
