import { ChangeEvent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Colors from 'theme';
import Search from '@material-ui/icons/Search';

const InputFieldWithLabel = ({
  type = 'text',
  isError = false,
  errorMsg = '',
  required = true,
  autoFocus = false,
  disabled = false,
  value = '',
  labelText = '',
  placeholder = '',
  showLabel = true,
  onFieldClick,
  onChange,
  searchIcon = false,
  ...props
}: Props): JSX.Element => {
  const classes = useStyles({ type, isError, value, ...props });

  let fieldValue = '';

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    fieldValue = event.target.value;
    props.setFieldValue && props.setFieldValue(fieldValue);
  };

  return (
    <div className={classes.TextFieldContainer} onClick={onFieldClick}>
      {showLabel && (
        <Typography variant="h4">
          <span className={classes.Label}>{labelText}</span>
          <span className={classes.optionalText}>{props.optionalText}</span>
        </Typography>
      )}

      <OutlinedInput
        type={type}
        startAdornment={searchIcon ? <Search className={classes.search} /> : null}
        disabled={disabled}
        placeholder={placeholder}
        inputProps={{ className: classes.TextField }}
        classes={{ notchedOutline: classes.TextFieldNotched, root: classes.root }}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (onChange) onChange(event);
          else handleChange(event);
        }}
        onKeyDown={props.onKeyDown}
        required={required}
        autoFocus={autoFocus}
        value={value}
        onWheel={(e: any) => type === 'number' && e.target.blur()}
      />

      {isError && errorMsg && (
        <Typography variant="h4" className={classes.Label}>
          {errorMsg}
        </Typography>
      )}
    </div>
  );
};
interface Props {
  labelText?: string;
  showLabel?: boolean;
  optionalText?: string;
  searchIcon?: boolean;
  setFieldValue?: (item: string) => void;
  type?: string;
  width?: string;
  height?: string;
  onFieldClick?: () => void;
  placeholder?: string;
  isError?: boolean;
  errorMsg?: string;
  boxShadow?: string;
  disabled?: boolean;
  labelFontSize?: string;
  required?: boolean;
  borderRadius?: string;
  autoFocus?: boolean;
  value: string | number;
  mTop?: string;
  italicPlaceholder?: boolean;
  mleft?: string;
  padding?: string;
  background?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    TextFieldContainer: {
      marginTop: ({ ...props }: Props) => (props.mTop ? props.mTop : '21px'),
      '@media (max-width: 350px)': {
        width: '80vw',
      },
    },
    search: {
      color: Colors.disabled_text,
      marginLeft: '12px',
    },
    TextField: {
      padding: ({ ...props }: Props) => (props.padding ? props.padding : '10px 20px'),
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      color: Colors.plain_black,
      zIndex: 1,
      boxShadow: ({ ...props }: Props) => (props.boxShadow ? props.boxShadow : '0 0 0 21px white inset'),
      height: '40%',
      '&::placeholder': {
        color: Colors.greyShady,
        opacity: 1,
        fontStyle: ({ ...props }: Props) => (props.italicPlaceholder ? 'italic' : 'normal'),
      },
      background: ({ ...props }: Props) => (props.background ? props.background : 'none'),
      '&:focus': {
        boxShadow: ({ ...props }: Props) =>
          props.boxShadow
            ? props.boxShadow
            : props.background
            ? 'none'
            : props.isError
            ? '0 0 0 21px ' + Colors.input_field_focus_bg + ' inset'
            : '0 0 0 21px ' + Colors.dropdown_border + ' inset',
      },
      '&:focus ~ $TextFieldNotched': {
        background: ({ ...props }: Props) =>
          props.background ? 'none' : props.isError ? Colors.input_field_focus_bg : Colors.dropdown_border,
        borderColor: ({ ...props }: Props) => (!props.isError ? Colors.hilighted_text : Colors.caution),
      },
      '@media (max-width: 350px)': {
        width: 'inherit',
      },
    },
    TextFieldNotched: {
      height: 'calc(100% - 2px)',
      boxShadow: ({ ...props }: Props) => (props.boxShadow ? props.boxShadow : '0px 1px 2px rgba(0, 0, 0, 0.08)'),
      top: '-2.5px',
      background: ({ ...props }: Props) => (!props.isError ? 'none' : Colors.input_field_focus_bg),
      borderColor: ({ ...props }: Props) => (!props.isError ? Colors.input_field_border : Colors.caution),
      transition: 'background 0.8s',
    },
    Label: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: ({ ...props }: Props) => props.labelFontSize || '16px',
      lineHeight: '16px',
      color: ({ ...props }: Props) => (!props.isError ? Colors.secondary_text : Colors.caution),
      marginTop: ({ ...props }: Props) => (props.isError ? 11 : 0),
      height: 'fit-content',
      marginBottom: '6px',
      transtion: 'all 0.8s',
    },
    root: {
      width: ({ ...props }: Props) => (props.width ? props.width : '311px'),
      height: ({ ...props }: Props) => (props.height ? props.height : '46px'),
      marginLeft: ({ ...props }: Props) => (props.mleft ? props.mleft : ''),
      padding: '2px 2px',
      borderRadius: ({ ...props }: Props) => (props.borderRadius ? props.borderRadius : '4px'),
      '@media (max-width: 350px)': {
        width: 'inherit',
      },
    },
    optionalText: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '14px',
      color: Colors.greyShady,
      marginTop: '11px',
      height: 'fit-content',
      marginBottom: '6px',
      transtion: 'all 0.8s',
    },
  }),
);

export default InputFieldWithLabel;
