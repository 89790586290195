import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputFieldWithLabel } from 'components/fields';
import { useEffect, useState } from 'react';
import ButtonsContainer from './buttonsContainer';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (building: string) => void;
  universityName: string;
}

const AddBuildingModal = ({ isOpen, setIsOpen, onSubmit, universityName }: Props) => {
  const classes = useStyles();
  const [buildingToAdd, setBuildingToAdd] = useState('');

  useEffect(() => {
    !isOpen && setBuildingToAdd('');
  }, [isOpen]);

  const isBuilding = () => buildingToAdd.split(' ').join('');

  const handleClose = () => setIsOpen(false);

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Add Building</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.label}>University Name</div>
        <div className={classes.text}>{universityName}</div>
        <InputFieldWithLabel
          labelText="Building Name"
          placeholder="Enter Building Name"
          value={buildingToAdd}
          setFieldValue={(item) => setBuildingToAdd(item)}
          mTop="10px"
        />

        <ButtonsContainer
          cancelClicked={handleClose}
          submitClicked={() => onSubmit(buildingToAdd)}
          enable={!!isBuilding()}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddBuildingModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '10px',
    color: Colors.secondary_text,
  },
}));
