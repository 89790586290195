import Colors from 'theme';
import RoomIcon from 'assets/room-icon.png';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButton } from 'components';

interface Props {
  header: string;
  info: string;
  buttonText: string;
  onButtonClick: () => void;
  showIcon?: boolean;
  gridItem?: boolean;
}

const InfoBox = ({ header, info, buttonText, onButtonClick, showIcon = true, gridItem = false }: Props) => {
  const classes = useStyles({ gridItem });
  return (
    <div className={classes.assignmentsContainer}>
      {showIcon && <img src={RoomIcon} className={classes.img} />}
      <div className={classes.assignmentsHeader}>{header}</div>
      <div className={classes.assignmentsText}>{info}</div>
      <CustomButton
        title={buttonText}
        textColor={Colors.cyprus}
        buttonClicked={onButtonClick}
        borderRadius="34px"
        width="230px"
        height="48px"
        background={Colors.paleTurquoise}
        mTop="6px"
        mBottom={gridItem ? '10px' : '0px'}
      />
    </div>
  );
};

export default InfoBox;

const useStyles = makeStyles((theme) => ({
  assignmentsContainer: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    height: 'fit-content',
    border: `1px solid ${Colors.mystic}`,
    borderRadius: '10px',
    width: ({ gridItem }: { gridItem: boolean }) => (gridItem ? 'auto' : '360px'),
    padding: '25px 25px 30px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      width: 'auto',
    },
  },
  assignmentsHeader: {
    fontWeight: 700,
    fontSize: '24px',
    color: Colors.darkCerulean,
    margin: '6px 0px 16px',
  },
  assignmentsText: {
    fontSize: '16px',
    color: Colors.mortar,
    height: '58px',
    lineHeight: '140%',
    marginBottom: '8px',
  },
  img: {
    marginBottom: '18px',
    width: '80px',
    height: '80px',
  },
}));
