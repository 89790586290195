import { ItemModel, ItemModelProps, ItemModelSubType, ItemModelType, RoomScene } from '@roomie-engineering/room-viewer';
import config from 'config';
import { flatMap, map } from 'lodash-es';
import { Category, LayoutItem, RoomieCategories, SubCategory } from 'types';
import { PRODUCTION_ENV, STAGING_ENV, DEMO_ENV, PRODUCTION_APP, STAGING_APP, DEMO_APP } from './constants';

export const isBunk = (subType: string) => subType === ItemModelSubType.TwinXLBunk;

export const openEmail = (subject: string) => window.open(`mailto:support@roomie.com?subject=${subject}`, '_blank');

export const isValidEmail = (email: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

export const isPillowType = (name: string) => {
  return name.toLowerCase().includes('pillow');
};

export const getOrigin = () => {
  // TODO jim: this code and a lot of constants are duplicated between here and main FE app. we should merge them or create a common lib probably
  const origin = {
    [PRODUCTION_ENV]: PRODUCTION_APP,
    [STAGING_ENV]: STAGING_APP,
    [DEMO_ENV]: DEMO_APP,
  }[config.REACT_APP_ENVIRONMENT];

  if (!origin) {
    throw new Error(`invalid REACT_APP_ENVIRONMENT (${config.REACT_APP_ENVIRONMENT}) while trying to get origin`);
  }
  return origin;
};

export const isBedFrameType = (type: string): boolean => {
  return type == ItemModelType.Bed;
};

const getHeight = (item: ItemModel): number | undefined => {
  if (item) {
    const feature = item.getBedFeature();
    if (feature) return feature.getFrameHeight();
  }
};

export const isMattressType = (type: string): boolean => {
  return (
    type == ItemModelType.Mattress ||
    type == ItemModelType.MattressTopper ||
    type == ItemModelSubType.TwinXLRoomieFullXLUpgrade ||
    type == ItemModelSubType.TwinXLRoomieMidUpgrade
  );
};

export const isStyleType = (type: string): boolean => {
  return (
    type == ItemModelType.ShamPillow ||
    type == ItemModelType.Sheets ||
    type == ItemModelType.ThrowBlanket ||
    type == ItemModelType.AccentPillow ||
    type == ItemModelType.Cover ||
    type == ItemModelType.Riser ||
    type == ItemModelType.Headboard ||
    type == ItemModelType.DecorativePillow ||
    type == ItemModelType.Pillow
  );
};

export const layoutConfigToRoomItems = (layoutItems: LayoutItem[]): ItemModelProps[] => {
  return layoutItems.map((item: LayoutItem) => ({
    catalogId: item.catalogId,
    variantId: item.variantId,
    itemId: item.itemId,
    position: [item?.positionX ?? 0, item?.positionY ?? 0, item?.positionZ ?? 0],
    rotation: [item?.rotationX ?? 0, item?.rotationY ?? 0, item?.rotationZ ?? 0],
    assetUrl: item?.itemDetail?.url,
    height: item?.height,
    parentItemId: item?.parentItemId, // changed to assign parent id
    anchor: item.anchor,
    type: item?.itemDetail?.type,
    subType: item?.itemDetail?.subType,
    surfaceType: item?.itemDetail?.surfaceType,
    materials: item?.itemDetail?.materials ? JSON.parse(item?.itemDetail?.materials) : undefined,
  }));
};

export const roomSceneToLayoutItems = (roomScene: RoomScene | null) => {
  const layout =
    roomScene?.selectedRoom?.getAllItems().map((item) => {
      return {
        itemId: item?.itemId || '',
        catalogId: item.catalogId,
        variantId: item.variantId,
        deletable: item.deletable,
        moveable: item.moveable,
        height: getHeight(item),
        parentItemId: item?.parent?.props?.itemId || undefined,
        anchor: item.anchor ?? '',
        positionX: item.getPosition()._x,
        positionY: item.getPosition()._y,
        positionZ: item.getPosition()._z,
        rotationX: item.getRotation()._x,
        rotationY: item.getRotation()._y,
        rotationZ: item.getRotation()._z,
        children: [],
      };
    }) || [];
  return layout;
};

export const sortRooms = (a?: string, b?: string) => {
  if (!a || !b) {
    return 0;
  }
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const AInt = parseInt(a, 10);
  const BInt = parseInt(b, 10);
  if (isNaN(AInt) && isNaN(BInt)) {
    const aA = a.replace(reA, '');
    const bA = b.replace(reA, '');
    if (aA === bA) {
      const aN = parseInt(a.replace(reN, ''), 10);
      const bN = parseInt(b.replace(reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  } else if (isNaN(AInt)) {
    return 1;
  } else if (isNaN(BInt)) {
    return -1;
  } else {
    return AInt > BInt ? 1 : -1;
  }
};

export const myCategoriesHandler = (myCategories: RoomieCategories) => {
  const keys = Object.keys(myCategories);
  const categories: Category[] = keys.map((key) => {
    const category = myCategories[key];
    return {
      id: key,
      title: category?.category,
      thumbnailUrl: category?.thumbnail || 'https://i.ibb.co/Dk3gcxz/closet-Org.jpg',
    };
  });
  const subCategories: SubCategory[] = flatMap(keys, (key) => {
    const category = myCategories[key];
    return map(Object.keys(category?.subs), (secondaryKey) => {
      const subCategory = category?.subs[secondaryKey];
      return {
        id: secondaryKey,
        title: subCategory?.name,
        thumbnail: subCategory?.thumbnail || 'https://i.ibb.co/TYdzr2D/image-60.png',
        categoryId: key,
      };
    });
  });
  return { categories, subCategories };
};
