import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Zoom_out = (props: SvgIconProps) => {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.164 16.7667C20.399 14.0293 21.0626 10.3324 19.9218 6.98721C18.7797 3.64208 15.9928 1.12307 12.5507 0.32506C9.10823 -0.47459 5.49654 0.558284 2.99776 3.05713C0.498985 5.55598 -0.534097 9.1676 0.265689 12.6101C1.06397 16.0525 3.58284 18.8393 6.92784 19.9812C10.273 21.122 13.9701 20.4583 16.7073 18.2233L21.0133 22.5188C21.4158 22.8946 22.0448 22.8839 22.434 22.4934C22.8245 22.1043 22.8352 21.4752 22.4593 21.0727L18.164 16.7667ZM2.08594 10.3082C2.08727 7.58814 3.44399 5.04798 5.70309 3.53418C7.96205 2.01888 10.8276 1.72833 13.3437 2.75988C15.8612 3.79006 17.6992 6.00637 18.2468 8.67042C18.7944 11.3345 17.9802 14.0958 16.0744 16.0364L16.0305 16.0737L15.9931 16.1177H15.9918C14.4565 17.633 12.3842 18.4793 10.2266 18.47C8.07028 18.4606 6.00455 17.5984 4.4812 16.0711C2.95792 14.5438 2.10228 12.4754 2.09962 10.3178L2.08594 10.3082Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2721 9.28504H9.22501H7.48447C6.91941 9.28504 6.46094 9.74351 6.46094 10.3086C6.46094 10.8736 6.91941 11.3321 7.48447 11.3321H9.22501L11.2721 11.3321H13.0126C13.5777 11.3321 14.0348 10.8736 14.0348 10.3086C14.0348 9.74351 13.5777 9.28503 13.0113 9.28503L11.2721 9.28504Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default Zoom_out;
