import { createStyles, makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

export const AppBarStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      overflow: 'auto',
      height: '100%',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: Colors.icon_grey_light,
        border: '4px solid transparent',
        borderRadius: '8px',
        backgroundClip: 'padding-box',
      },
      '&::-webkit-scrollbar': {
        width: '16px',
      },
    },
    appBar: {
      flexGrow: 1,
      height: '70px',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.21)',
      backgroundColor: 'white',
    },
    toolBar: {
      justifyContent: 'space-between',
    },
    roomieIcon: {
      width: '100px',
      margin: '24px 0px',
    },
  }),
);
export const TextStyles = makeStyles(() =>
  createStyles({
    Typography_1: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '120%',
      color: Colors.plain_black,
    },
    Typography_2: {
      fontFamily: 'Cooper Lt BT',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '13px',
      color: Colors.list_item_black,
    },
    Typography_3: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '19px',
      color: Colors.list_item_black,
    },
    Typography_4: {
      fontFamily: 'Cooper Lt BT',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '25px',
      color: Colors.primary_text,
    },
    ButtonText_1: {
      color: Colors.hilighted_text_secondary,
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontSize: '15px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    Heading_1: {
      fontFamily: 'Cooper Lt BT',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '38px',
      color: Colors.plain_black,
    },
    AlertBarText_1: {
      fontFamily: 'Wigrum',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '29.2px',
      color: Colors.plain_white,
    },
    Icon_Text: {
      fontFamily: 'Wigrum',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: '8px',
      lineHeight: '8px',
      color: Colors.plain_white,
    },
  }),
);

export const CustomStyles = makeStyles(() =>
  createStyles({
    BreadCrumb: {
      fontSize: '10px',
      lineHeight: '10px',
      color: Colors.bold_text,
    },
  }),
);
