import { useEffect } from 'react';
import {
  Engine,
  Scene,
  Vector3,
  ItemModel,
  ItemModelProps,
  ItemModelSubType,
  ItemModelType,
  RoomModel,
  MovedEvent,
  RoomScene,
} from '@roomie-engineering/room-viewer';

interface Props {
  drawerOpen?: boolean;
  isSharedUser?: boolean;
  items: ItemModelProps[];
  buildingName: string;
  roomModelUrl: string;
  roomLoaded: boolean;
  setRoomSceneState: (roomSceneState: RoomScene | null) => void;
  setIsLoaded: (isLoaded: boolean) => void;
  onItemSelected: (item?: ItemModel) => void;
  onItemMoved?: (
    event: MovedEvent,
    item: ItemModel,
    parent?: ItemModel,
    anchor?: string,
    position?: Vector3,
    rotation?: Vector3,
  ) => Promise<void>;
  onMeasureTool?: (active: boolean, count?: number) => void;
  isAdminLayout?: boolean;
}

export const MATTRESS_ANCHOR = 'frame0_anchor0';
export const BUNK_ANCHOR = 'frame1_anchor0';

const populateRoom = (roomItems: ItemModelProps[], room: RoomModel): RoomModel => {
  let isBunk = false;
  let anchor = MATTRESS_ANCHOR;
  roomItems.forEach((item: ItemModelProps) => {
    if (item.type === ItemModelType.Bed) room.addItem(item);
    if (item.subType === ItemModelSubType.TwinXLBunk) isBunk = true;
  });

  roomItems.forEach((item: ItemModelProps) => {
    if (item.type === ItemModelType.Mattress) {
      item.anchor = anchor;
      room.addItem(item);
      if (anchor === BUNK_ANCHOR) anchor = MATTRESS_ANCHOR;
      else if (isBunk) anchor = BUNK_ANCHOR;
    }
  });
  anchor = MATTRESS_ANCHOR;
  roomItems.forEach((item: ItemModelProps) => {
    if (item.type === ItemModelType.MattressTopper) {
      item.anchor = anchor;
      if (anchor === BUNK_ANCHOR) anchor = MATTRESS_ANCHOR;
      else if (isBunk) anchor = BUNK_ANCHOR;
    }
    if (item.type !== ItemModelType.Bed && item.type !== ItemModelType.Mattress) room.addItem(item);
  });

  return room;
};

// TODO jm: this component has been duplicated and diverged from student frontend. bring them back in line
export const RoomViewer = ({
  drawerOpen = false,
  isSharedUser = false,
  isAdminLayout = false,
  roomLoaded = false,
  items,
  buildingName,
  roomModelUrl,
  setRoomSceneState,
  onItemMoved,
  onItemSelected,
  onMeasureTool,
  setIsLoaded,
}: Props) => {
  let canvas: HTMLCanvasElement;
  let engine: Engine;
  let scene: Scene;
  let roomScene: RoomScene;

  useEffect(() => {
    window.addEventListener('resize', resizeEventListener);
    return function cleanUp(): void {
      window.removeEventListener('resize', resizeEventListener);
      cleanupRoom();
    };
  }, []);

  useEffect(() => {
    if (roomLoaded) loadRoom();
  }, [roomLoaded]);

  const resizeEventListener = (): void => {
    if (engine) {
      engine.resize();
    }
  };

  const cleanupRoom = (): void => {
    if (engine) engine.dispose();
  };

  const loadRoom = (): void => {
    canvas = document.getElementById('renderCanvas') as HTMLCanvasElement;
    engine = new Engine(canvas, true);
    scene = new Scene(engine);
    engine.runRenderLoop(function () {
      scene.render();
    });
    roomScene = new RoomScene({
      canvas,
      scene,
      onItemSelected: onItemSelected,
      onItemMoved: onItemMoved,
      onMeasureTool: onMeasureTool,
      isDevMode: true,
    });
    const room = roomScene.addRoom({
      title: buildingName || '',
      assetUrl: roomModelUrl || '',
      setIsLoaded: setIsLoaded,
    });
    if (!isAdminLayout) roomScene.setSelectedRoom(populateRoom(items, room));
    else room.addItems(items);
    setRoomSceneState(roomScene);
  };

  return <canvas id="renderCanvas" style={{ left: drawerOpen && !isSharedUser ? '150px' : '0px' }} />;
};
