import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Measurement = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.51562 3.4375H2.34375C2.25781 3.4375 2.1875 3.50781 2.1875 3.59375V16.4062C2.1875 16.4922 2.25781 16.5625 2.34375 16.5625H3.51562C3.60156 16.5625 3.67188 16.4922 3.67188 16.4062V3.59375C3.67188 3.50781 3.60156 3.4375 3.51562 3.4375ZM17.6562 3.4375H16.4844C16.3984 3.4375 16.3281 3.50781 16.3281 3.59375V16.4062C16.3281 16.4922 16.3984 16.5625 16.4844 16.5625H17.6562C17.7422 16.5625 17.8125 16.4922 17.8125 16.4062V3.59375C17.8125 3.50781 17.7422 3.4375 17.6562 3.4375ZM15.3379 9.84961L12.8457 7.88281C12.8249 7.86641 12.7998 7.85621 12.7734 7.85339C12.747 7.85057 12.7204 7.85523 12.6966 7.86685C12.6727 7.87847 12.6526 7.89658 12.6386 7.91909C12.6245 7.9416 12.6171 7.96761 12.6172 7.99414V9.29688H7.38281V8.07031C7.38281 7.95313 7.24609 7.88672 7.1543 7.95898L4.66211 9.92773C4.64524 9.94076 4.63159 9.95749 4.6222 9.97662C4.6128 9.99575 4.60792 10.0168 4.60792 10.0381C4.60792 10.0594 4.6128 10.0804 4.6222 10.0996C4.63159 10.1187 4.64524 10.1354 4.66211 10.1484L7.15234 12.1172C7.24414 12.1895 7.38086 12.125 7.38086 12.0059V10.7031H12.6152V11.9297C12.6152 12.0469 12.752 12.1133 12.8437 12.041L15.334 10.0723C15.4082 10.0156 15.4082 9.90625 15.3379 9.84961V9.84961Z"
        fill={props.fill}
      />
    </SvgIcon>
  );
};

export default Measurement;
