import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { STATUS_OPTIONS } from 'utils';
import { RoomConfig } from 'types';
import ButtonsContainer from './buttonsContainer';

interface Props {
  item?: RoomConfig;
  status: string;
  onChangeStatus: (status: string) => void;
  setItem: (item?: RoomConfig) => void;
  onSubmit: (status: string) => void;
}

const UpdateStatusModal = ({ item, status, onChangeStatus, setItem, onSubmit }: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    setItem(undefined);
    onChangeStatus('Active');
  };

  return (
    <Dialog open={!!item} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Update Status of {item?.type}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <FormControl>
            <InputLabel id="select-status">Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => onChangeStatus(e.target.value as string)}
              displayEmpty
              labelId="select-status"
              className={classes.select}
            >
              {STATUS_OPTIONS.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <ButtonsContainer cancelClicked={handleClose} submitClicked={() => onSubmit(status)} />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateStatusModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  select: {
    marginBottom: '18px',
    width: '200px',
  },
}));
