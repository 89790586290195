import { useState, ReactNode } from 'react';
import { AuthContext } from './authContext';
import { SignInResponse } from 'types';
import {
  saveUserData,
  saveRoomInfo,
  deleteUserData,
  deleteRoomInfo,
  getUserData,
  getRoomInfo,
  deleteRoomConfig,
  deleteAuthTokens,
  saveAuthTokens,
  deleteTemplatesData,
  deleteUniversityId,
} from './localStorage';
import { getOrigin } from 'utils';

export interface UserInfo {
  email: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const authDataStored = getRoomInfo();
  const userDataStored = getUserData();
  const [roomInfo, setRoomInfo] = useState<SignInResponse>(authDataStored);
  const [userInfo, setUserInfo] = useState<UserInfo>(userDataStored);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const saveLoginInfo = (roomInfo: SignInResponse, infoToSave: UserInfo) => {
    saveRoomInfo(roomInfo);
    saveUserData(infoToSave);
  };

  const loginHandler = (email: string, roomInfo: SignInResponse) => {
    saveLoginInfo(roomInfo, { email });
    saveAuthTokens({
      roomieAccessToken: roomInfo.roomieAccessToken,
      roomieRefreshToken: roomInfo.roomieRefreshToken,
    });
    setRoomInfo({ ...roomInfo });
    setUserInfo({ email: email });
  };

  const goToLoginPage = () => {
    const origin = getOrigin();
    window.open(origin, '_self');
  };

  const logoutHandler = () => {
    setRoomInfo({
      code: 0,
      admin: false,
      reason: '',
      roomieAccessToken: '',
      roomieRefreshToken: '',
    });
    setUserInfo({ email: '' });
    deleteRoomConfig();
    deleteRoomInfo();
    deleteUserData();
    deleteAuthTokens();
    deleteTemplatesData();
    deleteUniversityId();
    goToLoginPage();
  };

  const setErrorExist = (isError: boolean) => {
    setError(isError);
  };

  const setErrMessage = (message: string) => {
    setErrorMessage(message);
  };

  const errorExists = () => {
    return error;
  };

  const errMessage = () => {
    return errorMessage;
  };

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        roomInfo: roomInfo,
        userInfo: userInfo,
        isError: errorExists,
        setIsError: setErrorExist,
        message: errMessage,
        setMessage: setErrMessage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
