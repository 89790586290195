import React from 'react';
import { styled, alpha } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FileUpload from '@material-ui/icons/CloudUpload';
import Divider from '@material-ui/core/Divider';
import { AuthContext } from '../../context/authContext';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AttachFile from '@material-ui/icons/AttachFile';
import RoomIcon from '@material-ui/icons/MeetingRoom';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import Routes from '../../routes';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import DownloadIcon from '@material-ui/icons/GetApp';
import { AxiosResponse } from 'axios';
import { Params } from 'api';
import { SUCCESS } from 'utils';
import { SignOutButton } from 'components/buttons';
import SearchIcon from '@material-ui/icons/Search';
import DeckOutlinedIcon from '@material-ui/icons/DeckOutlined';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ChecklistIcon from '@mui/icons-material/Checklist';

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(4),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface Props {
  logout: (
    params: Params | FormData,
    onCompleted?: ((res: AxiosResponse<any>) => void) | undefined,
    postParams?: boolean | undefined,
  ) => void;
}

const AdminMenu = ({ logout }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const authContext = React.useContext(AuthContext);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (route: string) => {
    handleClose();
    history.push(route);
  };

  const handleLogout = async () => {
    await logout({}, (res) => {
      if (res.data.reason === SUCCESS) {
        handleClose();
        authContext?.logout();
      }
    });
  };

  return (
    <div>
      {!authContext?.roomInfo.isRoomieEmployee && !authContext?.roomInfo?.isRoomieModeler ? (
        <SignOutButton onSignOut={async () => await handleLogout()} />
      ) : (
        <>
          <IconButton onClick={handleClick} disableRipple>
            <MenuIcon />
          </IconButton>
          <StyledMenu id="demo-customized-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
            {authContext?.roomInfo?.isRoomieEmployee && (
              <div>
                <MenuItem onClick={() => onClick(Routes.CHECKLIST)} disableRipple>
                  <ChecklistIcon />
                  Config Checklist
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.PRODUCTS)} disableRipple>
                  <DynamicFeedIcon />
                  Manage Products
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.STYLES_DASHBOARD)} disableRipple>
                  <FormatPaintIcon />
                  Config Styles
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.UPLOAD_CATALOG)} disableRipple>
                  <AttachFile />
                  Add Catalog
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.UPLOAD_ROOM)} disableRipple>
                  <FileUpload />
                  Upload Room
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.SEARCH_CATALOG)} disableRipple>
                  <SearchIcon />
                  Search Catalog
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.CATALOGS_UNIVERSITY_ASSOCIATION)} disableRipple>
                  <CompareArrowsIcon />
                  University Catalogs
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.UNIVERSITY_FURNITURE)} disableRipple>
                  <DeckOutlinedIcon />
                  University Furniture
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.ROOM_LINKS)} disableRipple>
                  <LinkIcon />
                  Download Room Links
                </MenuItem>
                <MenuItem onClick={() => onClick(Routes.TEMPLATE_LINKS)} disableRipple>
                  <DownloadIcon />
                  Download Template Links
                </MenuItem>
              </div>
            )}
            <MenuItem onClick={() => window.open(Routes.ROOM_VIEWER)} disableRipple>
              <RoomIcon />
              QA Viewer
            </MenuItem>
            <Divider />
            <MenuItem onClick={async () => await handleLogout()} disableRipple>
              <LogoutIcon />
              Sign out
            </MenuItem>
          </StyledMenu>
        </>
      )}
    </div>
  );
};

export default AdminMenu;
