import { createStyles, makeStyles } from '@material-ui/core';
import Colors from 'theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      overflow: 'visible',
      zIndex: 2,
      height: '100vh',
      width: 'calc(100% - 60px)',
      left: 60,
      background: Colors.lightWhite,
    },
    manageProductWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(1),
      marginTop: '64px',
    },
    productsContainer: {
      width: '86%',
      marginBottom: '10px',
    },
    heading: {
      marginTop: theme.spacing(2),
    },
    paragraph: {
      textAlign: 'justify',
    },
    buttons: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    downloadButton: {
      marginRight: '10px',
      background: Colors.light_blue_bg,
    },
    uploadButton: {
      marginLeft: '10px',
      background: Colors.light_blue_bg,
    },
    distributeHorizontally: {
      width: '80%',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexWrap: 'wrap',
    },
    distributeHorizontally100: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    cardStyle: {
      marginBottom: '10px',
    },
    materialWrapper: { width: '70%', border: '1px solid gray', marginLeft: '2%', marginRight: '2%' },
    geometriesWrapper: { width: '30%', border: '1px solid gray', marginLeft: '2%', marginRight: '2%' },
    mediasWrapper: { width: '100%', border: '1px solid gray', marginLeft: '2%', marginRight: '2%' },
    textField: { margin: '4px' },
    buttonStyle: { height: '30px' },
  }),
);

export default useStyles;
