const Colors = {
  primary_bg: '#84D1D1',
  primary_bg_hover: '#0fc7c7',
  primary_text: '#6A727B',
  secondary_text: '#545B64',
  grey_text: '#6B727B',
  disabled_text: '#c4c4c4',
  hilighted_text: '#09B6B6',
  hilighted_text_secondary: '#084B68',
  selected_border: '#084B68',
  border_grey: '#CCCCCC',
  darkCerulean: '#084A64',
  mortar: '#555555',
  bold_text: '#113649',
  hilighted_text_bg: '#09b6b612',
  hilighted_text_hover: '#059c9c',
  plain_white: '#FFFFFF',
  acadia: '#F6F6F6',
  plain_black: '#000000',
  plain_black_light: '#222222',
  list_item_black: '#113649',
  icon_bg_hover: '#d6d6d629',
  icon_grey_light: '#D8D8D8',
  icon_primary: '#6A727B',
  cypress2: '#081F32',
  button_bg_active: '#09B6B6',
  button_bg_active_light: '#0beaea',
  button_bg_opaque: '#ffffff80',
  button_bg_active_shadow: '#09b6b680',
  button_bg_active_shadow_light: '#09b6b612',
  button_bg_disabled: '#e2e2e2',
  button_shadow_black: '#2222225e',
  button_hover_bg_black: '#f5f5f5',
  button_primary_text: '#18CAA7',
  caution: '#CD2026',
  blue: '#005F85',
  caution_bg: '#cd20260a',
  disabled: '#dddddd',
  input_field_focus_bg: '#FFF3CF',
  input_field_border: '#0000003b',
  success_green: '#2E8540',
  heading_black: '#333333',
  drawer_bg_primary: '#E3F8F8',
  grey_bg: '#F3F3F3',
  light_blue_bg: '#A9E4E2',
  img_bg_light_blue: '#CFECEE',
  scrollBarColor: '#B5E6EA',
  rating_count_blue: '#38809F',
  dropdown_border: '#D2F3F3',
  chat_title_bg: '#A7E0E0',
  roomate_color: '#F1831D',
  timeStamp_grey: '#8C959F',
  gainsboroWhite: '#D9D9D9',
  message_black: '#24272B',
  message_title_black: '#121212',
  message_field_border_grey: '#D3D6D9',
  field_shadow: 'rgba(0, 0, 0, 0.08)',
  drawer_bg: '#EFECE5',
  boxShadow: 'rgba(94, 97, 115, 0.406168)',
  input_border: '#09B6B6',
  neptune: '#6CA7A6',
  bermuda: '#87d1d0',
  mabel: '#CDECEB',
  iceCold: '#ABDFDE',
  solidPink: '#79494A',
  paleTurquoise: '#B1F3F2',
  frenchPass: '#96DEDD',
  cyprus: '#091133',
  cyprus1: '#113649',
  viking: '#3CBAB8',
  gainsboro: '#E5E5E5',
  smoke: '#ECECEC',
  inputBoxBorder: 'rgb(227, 227, 227)',
  atomic: '#424D57',
  mystic: '#D8D9D8',
  whiteSmoke: '#f0f0f0',
  whiteSmoke2: '#f2f2f2',
  infoBoxShadow: 'rgb(0 0 0 / 20%) 0px 0px 8px',
  linen: '#FCF9F3',
  ivory: '#F6FFED',
  poreclain: '#e4e2e0',
  sulu: '#B7EB8F',
  snow: '#FFF1F0',
  pink: '#FFCCC7',
  darkGray: '#a7a7a7',
  greyShady: '#979797',
  drawer_background: '#F0EFEA',
  solitude: '#F7F8FA',
  drawer_shadow: 'rgba(0, 0, 0, 0.25)',
  overlay: 'rgba(0, 0, 0, 0.3)',
  appBarShadow: 'rgba(0, 0, 0, 0.21)',
  itemBackground: 'rgba(0, 0, 0, 0.04)',
  disabledText: 'rgba(17, 54, 73, 0.5)',
  shareModelShadow: '0px 2px 5px rgba(0, 0, 0, 0.16)',
  liveChatShadow: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  greyCarousel: '#D9D9D9',
  grey: '#7D7D7D',
  bigStone: '#323C47',
  lightRed: 'rgb(199, 15 ,15, 0.55)',
  lightWhite: '#ffffff',
};

export default Colors;
