import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const OnlineStatusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="4" cy="4" r="4" fill={props.fill ?? '#09B6B6'} />
    </SvgIcon>
  );
};

export default OnlineStatusIcon;
