import { Box, Chip, Collapse, IconButton, TextField } from '@material-ui/core';
import { Remove, Add, Delete } from '@material-ui/icons';
import useAxios, { ServerRoutes } from 'api';
import { Dispatch, SetStateAction, useState } from 'react';
import { Section } from 'types';
import { CATALOG_DELETED, DELETE } from 'utils';
import { Color } from '@material-ui/lab/Alert';

type ChecklistRowProps = {
  section: Section;
  handleSectionChangeHandler: (sectionId: string, fieldName: string, updatedValue: string) => void;
  deleteCatalogId: (sectionId: string, catalogId: string) => void;
  removeSection: (sectionId: string) => void;
  getSections: () => Promise<void>;
  addExpandedSections: (catalogId: string) => void;
  expandedSections: string[];
  showToast: (message: string, severity: Color) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  invalidCatalogIds: string[];
};
const ChecklistRow = ({
  section,
  handleSectionChangeHandler,
  deleteCatalogId,
  removeSection,
  getSections,
  addExpandedSections,
  expandedSections,
  showToast,
  setOpen,
  invalidCatalogIds,
}: ChecklistRowProps) => {
  const [catalogId, setCatalogId] = useState<string>('');

  const { callApi: deleteApi } = useAxios(
    `${ServerRoutes.createChecklist}/${section?.id}`,
    DELETE,
    false,
    '',
    {},
    'shopping',
  );

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement && section?.id) {
      if (section.catalogIds?.length > 2) {
        return false;
      }
      const inputElement = e.target as HTMLInputElement;
      handleSectionChangeHandler(section?.id, 'catalogIds', inputElement.value);
      setCatalogId('');
    }
  };

  const handleDeleteSection = async () => {
    if (section?.newSection && section?.id) {
      removeSection(section?.id);
    } else {
      await deleteApi({}, (res) => {
        if (res.status === 200) {
          getSections();
          showToast(CATALOG_DELETED, 'success');
          setOpen(true);
        }
      });
    }
  };

  const isExpandSection = (): boolean => {
    if (section?.id) {
      return !!expandedSections?.includes(section.id);
    } else {
      return false;
    }
  };

  const deprecateInvalidCatalogIdsSection = (): boolean => {
    if (!invalidCatalogIds || !invalidCatalogIds.length) {
      return false;
    }
    const { catalogIds } = section;
    return (
      catalogIds?.some((catalogId: string) => {
        return invalidCatalogIds.includes(catalogId);
      }) || false
    );
  };

  const isInvalidCatalogId = (catalogId: string) => {
    if (!invalidCatalogIds?.length || !catalogId) {
      return false;
    }
    if (invalidCatalogIds.includes(catalogId)) {
      return true;
    }
    return false;
  };

  return (
    <Box borderTop="1px solid #C0E7E8" borderBottom="1px solid #C0E7E8" borderColor="#C0E7E8" m="5px 10px">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" fontSize="md">
          {section?.sectionName}
        </Box>
        <Box>
          {deprecateInvalidCatalogIdsSection() ? (
            <Chip
              style={{
                borderColor: '#ff000078',
                color: '#ff000078',
              }}
              label="Invalid"
              variant="outlined"
              size="medium"
            />
          ) : null}
          <IconButton onClick={() => section?.id && addExpandedSections(section?.id)}>
            {isExpandSection() ? <Remove /> : <Add />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={isExpandSection()}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" justifyContent="start" flexDirection="column" minWidth="47%">
            <TextField
              label="Section Name"
              variant="outlined"
              fullWidth
              value={section.sectionName}
              onChange={(e) => section?.id && handleSectionChangeHandler(section.id, 'sectionName', e.target.value)}
              style={{ marginBottom: '8px', width: '90%' }}
              size="small"
            />
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {section?.catalogIds?.map((catalogId: string) => (
                <Chip
                  onDelete={() => section?.id && deleteCatalogId(section?.id, catalogId)}
                  key={catalogId}
                  style={{
                    margin: '8px',
                    maxWidth: '50%',
                    background: isInvalidCatalogId(catalogId) ? '#ff000078' : '',
                    color: isInvalidCatalogId(catalogId) ? '#fffff' : '',
                  }}
                  size="small"
                  label={catalogId}
                />
              ))}
            </Box>
            <TextField
              label="Catalog IDs"
              fullWidth
              variant="outlined"
              value={catalogId}
              onChange={(e) => setCatalogId(e.target.value)}
              onKeyDown={handleKeyUp}
              style={{
                marginBottom: '10px',
                width: '90%',
                marginTop: section?.catalogIds ? '0px' : '20px',
              }}
              size="small"
            />
          </Box>

          <Box display="flex" justifyContent="end" alignItems="center" minWidth="50%" style={{ cursor: 'pointer' }}>
            <span onClick={handleDeleteSection}>
              <Delete />
            </span>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
export default ChecklistRow;
