import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const PopChatIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3019 12.4362H12.96V1.65816V0H11.3019H0.52382V1.65816H10.1293L0 11.7874L1.17263 12.9601L11.3019 2.83082V12.4362Z"
        fill="#09B6B6"
      />
    </SvgIcon>
  );
};

export default PopChatIcon;
