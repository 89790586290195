import { AuthTokens, GuideView, SignInResponse } from 'types';
import { UserInfo } from './authProvider';

const AUTH_TOKENS = 'AUTH_TOKENS';
const ROOM_INFO_KEY = 'ROOM_INFO';
const USER_DATA_KEY = 'USER_DATA';
const ROOM_CONFIG = 'ROOM_CONFIG';
const TEMPLATES_DATA = 'TEMPLATES_DATA';
const UNIVERSITY_ID = 'UNIVERSITY_ID';
const SHOW_INSTRUCTIONS = 'SHOW_INSTRUCTIONS';
const SHOW_TOOLTIP = 'SHOW_TOOLTIP';
const ERROR = 'ERROR';

export const saveAuthTokens = (authTokens: AuthTokens): void => {
  localStorage.setItem(AUTH_TOKENS, JSON.stringify(authTokens));
};

export const getAuthTokens = (): AuthTokens => {
  const authTokens = JSON.parse(localStorage.getItem(AUTH_TOKENS) || '{}');
  return authTokens;
};

export const deleteAuthTokens = (): boolean => {
  localStorage.removeItem(AUTH_TOKENS);
  return true;
};

export const saveRoomInfo = (roomInfo: SignInResponse): void => {
  localStorage.setItem(ROOM_INFO_KEY, JSON.stringify(roomInfo));
};

export const getRoomInfo = (): SignInResponse => {
  const roomInfo = JSON.parse(localStorage.getItem(ROOM_INFO_KEY) || '{}');
  return roomInfo;
};

export const deleteRoomInfo = (): boolean => {
  localStorage.removeItem(ROOM_INFO_KEY);
  return true;
};

export const saveUserData = (userData: UserInfo): void => {
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
};

export const getUserData = (): UserInfo => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA_KEY) || '{}');
  return userData;
};

export const deleteUserData = (): boolean => {
  localStorage.removeItem(USER_DATA_KEY);
  return true;
};

export const saveRoomConfig = (roomConfig: GuideView): void => {
  localStorage.setItem(ROOM_CONFIG, JSON.stringify(roomConfig));
};

export const getRoomConfig = (): GuideView => {
  const roomConfig = JSON.parse(localStorage.getItem(ROOM_CONFIG) || '{}');
  return roomConfig;
};

export const deleteRoomConfig = (): boolean => {
  localStorage.removeItem(ROOM_CONFIG);
  return true;
};

export const saveUniversityId = (id: string): void => {
  localStorage.setItem(UNIVERSITY_ID, id);
};

export const getUniversityId = (): string => {
  const id = localStorage.getItem(UNIVERSITY_ID) || '';
  return id;
};

export const deleteUniversityId = (): boolean => {
  localStorage.removeItem(UNIVERSITY_ID);
  return true;
};

export const saveShowInstructions = (show: string): void => {
  localStorage.setItem(SHOW_INSTRUCTIONS, show);
};

export const getShowInstructions = (): string => {
  const show = localStorage.getItem(SHOW_INSTRUCTIONS) || 'true';
  return show;
};

export const deleteShowInstructions = (): boolean => {
  localStorage.removeItem(SHOW_INSTRUCTIONS);
  return true;
};

export const saveShowTooltip = (show: string): void => {
  localStorage.setItem(SHOW_TOOLTIP, show);
};

export const getShowTooltip = (): string => {
  const show = localStorage.getItem(SHOW_TOOLTIP) || 'true';
  return show;
};

export const deleteShowTooltip = (): boolean => {
  localStorage.removeItem(SHOW_TOOLTIP);
  return true;
};

export const saveError = (error: string): void => {
  localStorage.setItem(ERROR, error);
};

export const getError = (): string => {
  const error = localStorage.getItem(ERROR) || '';
  return error;
};

export const deleteError = (): boolean => {
  localStorage.removeItem(ERROR);
  return true;
};

export const saveTemplatesData = (templatesData: GuideView[]): void => {
  localStorage.setItem(TEMPLATES_DATA, JSON.stringify(templatesData));
};

export const getTemplatesData = (): GuideView[] => {
  const templatesData = JSON.parse(localStorage.getItem(TEMPLATES_DATA) || '{}');
  return templatesData;
};

export const deleteTemplatesData = (): boolean => {
  localStorage.removeItem(TEMPLATES_DATA);
  return true;
};
