import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputFieldWithLabel } from 'components/fields';
import ButtonsContainer from './buttonsContainer';
import { RoomConfig } from 'types';

interface Props {
  config?: RoomConfig;
  configName: string;
  onConfigNameChange: (name: string) => void;
  setConfig: (config?: RoomConfig) => void;
  onSubmit: (configName: string) => void;
}

const UpdateConfigNameModal = ({ config, configName, setConfig, onSubmit, onConfigNameChange }: Props) => {
  const classes = useStyles();

  const isConfigName = () => configName.split(' ').join('');

  const handleClose = () => {
    setConfig(undefined);
    onConfigNameChange('');
  };

  return (
    <Dialog open={!!config} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Update Config Name</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.label}>Roomie Template Name</div>
        <div className={classes.text}>{config?.type}</div>
        <InputFieldWithLabel
          labelText="Config Name"
          placeholder="Update Config Name"
          value={configName}
          setFieldValue={(item) => onConfigNameChange(item)}
          mTop="10px"
        />
        <ButtonsContainer
          cancelClicked={handleClose}
          submitClicked={() => onSubmit(configName)}
          enable={!!isConfigName()}
        />
      </DialogContent>
    </Dialog>
  );
};

export default UpdateConfigNameModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '10px',
    color: Colors.secondary_text,
  },
}));
