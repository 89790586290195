import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ButtonsContainer from './buttonsContainer';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { EditTableRow } from 'components/tables';
import { BuildingsAttributes } from 'types';
import { BUILDING_ATTRIBUTES_HEADERS } from 'utils';

interface Props {
  isEditingBuildingAttributes: boolean;
  onChangeIsEditingBuildingAttributes: (isEditingBuildingAttributes: boolean) => void;
  onSubmit: () => void;
  universityName: string;
  onChangeBuildingsAttributes: (buildingsAttributes: BuildingsAttributes[]) => void;
  buildingsAttributes: BuildingsAttributes[];
  handleEditBuilding: (buildingId: string, buildingName: string) => void;
}

const EditBuildingsModal = ({
  isEditingBuildingAttributes,
  onChangeIsEditingBuildingAttributes,
  onSubmit,
  universityName,
  buildingsAttributes,
  onChangeBuildingsAttributes,
  handleEditBuilding,
}: Props) => {
  const classes = useStyles();

  const handleClose = () => onChangeIsEditingBuildingAttributes(false);

  const updateData = (row: BuildingsAttributes) => {
    const temp = buildingsAttributes.map((item) => (item.buildingId === row.buildingId ? row : item));
    onChangeBuildingsAttributes(temp);
  };

  return (
    <Dialog
      open={isEditingBuildingAttributes}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      fullWidth={true}
    >
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>{universityName}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.tableWrapper}>
          <Table>
            <TableHead>
              <TableRow>
                {BUILDING_ATTRIBUTES_HEADERS.map((item) => (
                  <TableCell key={item}>{item}</TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {buildingsAttributes?.map((item, index) => (
                <EditTableRow key={index} row={item} handleEditBuilding={handleEditBuilding} updateData={updateData} />
              ))}
            </TableBody>
          </Table>
        </div>

        <ButtonsContainer
          cancelClicked={handleClose}
          submitClicked={async () => {
            await onSubmit();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditBuildingsModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '10px',
    color: Colors.secondary_text,
  },
  tableWrapper: {
    overflow: 'scroll',
    borderRadius: '8px',
    boxShadow: Colors.infoBoxShadow,
    minHeight: 'fit-content',
    maxHeight: '400px',
  },
}));
