import { useState, useEffect } from 'react';
import { ToolBar, CustomSmallButton, Snackbar, CircularProgress, SingleItem, UniversitySelect } from 'components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Cancel from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  TYPES,
  POST,
  SUCCESS,
  CATALOG_ITEM_UPLOAD_SUCCESS,
  CATALOG_UPLOAD_SUCCESS,
  ROOM_UPLOAD_SUCCESS,
  ALL_BUILDINGS,
  Messages,
} from 'utils';
import { UniversityBuilding, SingleItemProps, Toast } from 'types';
import useAxios, { ServerRoutes } from 'api';
import { Color } from '@material-ui/lab/Alert';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import Colors from 'theme';

interface Props {
  isRoom?: boolean;
}

const UploadPage = ({ isRoom = false }: Props) => {
  const classes = useStyles();
  const [file, setFile] = useState<any>();
  const [type, setType] = useState('');
  const [building, setBuilding] = useState('');
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [uploadType, setUploadType] = useState('');
  const [universityBuildings, setUniversityBuildings] = useState<UniversityBuilding[]>([]);
  const [failedItems, setFailedItems] = useState<SingleItemProps[]>([]);
  const history = useHistory();

  const {
    callApi: getUniversityBuildings,
    loading: buildingsLoading,
    error: buildingsError,
  } = useAxios(ServerRoutes.adminUniversityBuildings, POST, false);

  const {
    callApi: uploadCatalog,
    loading: uploadCatalogLoading,
    error: uploadCatalogError,
  } = useAxios(ServerRoutes.adminUploadCatalog, POST, false, 'multipart/form-data');

  const {
    callApi: uploadCatalogItem,
    loading: uploadCatalogItemLoading,
    error: uploadCatalogItemError,
  } = useAxios(ServerRoutes.adminUploadCatalogItem, POST, false, 'multipart/form-data');

  const {
    callApi: uploadRoom,
    loading: uploadRoomLoading,
    error: uploadRoomError,
  } = useAxios(ServerRoutes.adminUploadRoom, POST, false, 'multipart/form-data');

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    setType('');
    setFailedItems([]);
    setFile(undefined);
    setUniversityBuildings([]);
    setBuilding('');
  }, [history.location.pathname]);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
        setOpen(true);
      }
    };
    showError(logoutError);
    showError(uploadCatalogError);
    showError(uploadCatalogItemError);
    showError(buildingsError);
    showError(uploadRoomError);
  }, [uploadCatalogItemError, uploadCatalogError, buildingsError, uploadRoomError, logoutError]);

  const showToast = (message: string, severity: Color) => {
    setToast({
      severity: severity,
      message: message,
      autoHideDuration: 3000,
    });
  };

  const onTypeChange = (val: string) => {
    setType(val);
    if (isRoom) {
      setBuilding('');
      getUniversityBuildings({ universityId: val }, async (res) => {
        if (res.data.universityBuildings) {
          const buildings = res.data.universityBuildings.length > 1 ? [ALL_BUILDINGS] : [];
          setUniversityBuildings(buildings.concat(...res.data.universityBuildings));
        }
      });
    }
  };

  const onBuildingChange = (val: string) => {
    setBuilding(val);
  };

  const getType = () => {
    if (uploadType === 'Catalog') return 'catalog(s)';
    else if (uploadType === 'Catalog Item') return 'catalog item(s)';
    return 'room(s)';
  };

  const isDisabled = () => (isRoom ? !file || !type || !building : !file || !type);

  const checkResponse = (res: AxiosResponse, successMessage: string, warning: string, errorMessage: string) => {
    if (res?.data?.reason === SUCCESS && res?.data?.failed.length < 1) {
      showToast(successMessage, 'success');
    } else if (res?.data?.reason === SUCCESS && res?.data?.failed.length > 0) {
      setFailedItems(res?.data?.failed);
      showToast(warning, 'error');
    } else if (res?.data?.reason !== SUCCESS) {
      showToast(errorMessage, 'error');
    }
  };

  const onUpload = async () => {
    const formData = new FormData();
    formData.append('file', file[0]);
    setFailedItems([]);
    if (isRoom) {
      const id = building === 'All Buildings' ? '' : building;
      setUploadType('Room');
      formData.append('university-id', type);
      formData.append('building-id', id);
      await uploadRoom(formData, async (res) => {
        checkResponse(res, ROOM_UPLOAD_SUCCESS, Messages.ROOM_UPLOAD_WARNING, Messages.ROOM_UPLOAD_ERROR);
      });
    } else if (type === 'Catalog') {
      setUploadType('Catalog');
      await uploadCatalog(formData, async (res) => {
        checkResponse(res, CATALOG_UPLOAD_SUCCESS, Messages.CATALOG_UPLOAD_WARNING, Messages.CATALOG_UPLOAD_ERROR);
      });
    } else {
      await uploadCatalogItem(formData, async (res) => {
        setUploadType('Catalog Item');
        checkResponse(
          res,
          CATALOG_ITEM_UPLOAD_SUCCESS,
          Messages.CATALOG_ITEM_UPLOAD_WARNING,
          Messages.CATALOG_ITEM_UPLOAD_ERROR,
        );
      });
    }
    setOpen(true);
    setFile(null);
  };

  return (
    <div className={classes.root}>
      <CircularProgress
        isLoading={
          uploadCatalogItemLoading || uploadCatalogLoading || buildingsLoading || uploadRoomLoading || logoutLoading
        }
      />
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        autoHideDuration={toast?.autoHideDuration}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
      />
      <ToolBar logout={logout} />
      <div className={classes.container}>
        <div className={classes.heading}>{isRoom ? 'Upload Room' : 'Upload Catalog'}</div>
        {isRoom ? (
          <div className={classes.selectContainer}>
            <UniversitySelect university={type} onChange={onTypeChange} />
            {type && (
              <FormControl className={classes.formControl}>
                <InputLabel id="select-building">Building</InputLabel>
                <Select
                  labelId="select-building"
                  className={classes.select}
                  value={building}
                  onChange={(e) => onBuildingChange(e.target.value as string)}
                >
                  {universityBuildings.map((item) => (
                    <MenuItem key={item.roomie_university_building_id} value={item.roomie_university_building_id}>
                      {item.building_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        ) : (
          <FormControl className={classes.formControl}>
            <InputLabel id="select-type">Type</InputLabel>
            <Select
              labelId="select-type"
              className={classes.select}
              value={type}
              onChange={(e) => onTypeChange(e.target.value as string)}
            >
              {TYPES.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {file ? (
          <div className={classes.file}>
            {file[0].name}
            <IconButton className={classes.iconButton} onClick={() => setFile(undefined)}>
              <Cancel />
            </IconButton>
          </div>
        ) : (
          <IconButton className={classes.iconButton} component="label" color="primary">
            <AddCircleIcon className={classes.icon} />
            <input onChange={(e) => setFile(e.target.files)} accept=".csv" type="file" hidden />
          </IconButton>
        )}
        <CustomSmallButton disabled={isDisabled()} margin="20px 0px" title="Upload" onClick={onUpload} />
      </div>
      {failedItems.length > 0 && (
        <div className={classes.container}>
          <div className={classes.heading}>{`${failedItems.length} ${getType()} couldn't be uploaded.`}</div>
          {failedItems.map((item: SingleItemProps, index: number) => (
            <SingleItem key={index} item={item} type={uploadType} />
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadPage;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: Colors.plain_white,
      minHeight: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '30px',
      marginBottom: '9px',
    },
    heading: {
      fontFamily: 'Cooper Lt BT',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '25px',
      marginBottom: '15px',
    },
    iconButton: {
      padding: '3px',
      margin: '20px 0px',
    },
    icon: {
      width: '50px',
      height: '50px',
    },
    file: {
      margin: '15px',
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      width: '200px !important',
    },
    selectContainer: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    formControl: {
      minWidth: '120px',
      margin: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
