import { makeStyles } from '@material-ui/core/styles';
import { RoomScene } from '@roomie-engineering/room-viewer';
import Colors from '../../theme';

interface ButtonBarLeftBottomProps {
  roomScene: RoomScene | null;
}

const ButtonBarLeftBottom = ({}: ButtonBarLeftBottomProps): JSX.Element => {
  const classes = ButtonBarLeftBottomStyles();
  return (
    <div className={classes.LeftToolBottomColumn}>
      <div className={classes.version}>{RoomScene.VERSION}</div>
    </div>
  );
};

const ButtonBarLeftBottomStyles = makeStyles(() => ({
  LeftToolBottomColumn: {
    position: 'absolute',
    bottom: '10px',
    left: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'bottom',
  },
  LeftToolBottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  version: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.plain_black,
  },
}));

export default ButtonBarLeftBottom;
