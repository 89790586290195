import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as RoomieLogo } from 'assets/logo-header.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from './menu';
import { useHistory } from 'react-router-dom';
import Colors from 'theme';
import Routes from 'routes';
import { Params } from 'api';
import { AxiosResponse } from 'axios';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '80px',
    boxShadow: `none`,
    borderBottom: `1px solid ${Colors.mystic}`,
    backgroundColor: 'white',
    position: 'relative',
  },
  toolBar: {
    justifyContent: 'space-between',
    margin: '0px 2% 0px 7%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '20%',
    },
  },
  roomieIcon: {
    width: '100px',
    margin: '24px 0px',
    cursor: 'pointer',
  },
  university: {
    fontWeight: 700,
    fontSize: '22px',
    marginRight: '140px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '70px',
    },
  },
}));

interface Props {
  isUniversityAdmin?: boolean;
  university?: string;
  logout: (
    params: Params | FormData,
    onCompleted?: ((res: AxiosResponse<any>) => void) | undefined,
    postParams?: boolean | undefined,
  ) => void;
}

const ToolBar = ({ logout, isUniversityAdmin, university }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolBar}>
        <SvgIcon
          onClick={() => history.push(Routes.DEFAULT)}
          component={RoomieLogo}
          viewBox="0 0 251 59"
          classes={{ root: classes.roomieIcon }}
        />
        <div className={classes.university}>{isUniversityAdmin && university}</div>
        <Menu logout={logout} />
      </Toolbar>
    </AppBar>
  );
};

export default ToolBar;
