import { ReactElement, CSSProperties } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

const CustomIconButtonSecondary = ({ active = false, disabled = false, style = {}, ...props }: Props): JSX.Element => {
  const classes = useStyles({ active, disabled, ...props });

  return (
    <div className={classes.Container}>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => props.buttonPressed()}
        startIcon={props.icon}
        onMouseOver={() => props.setActiveValue(!/Mobi|Android/i.test(navigator.userAgent))}
        onMouseOut={() => props.setActiveValue(false)}
        classes={{ startIcon: classes.icon, label: classes.innerContent }}
        style={style}
      >
        {props.title}
      </Button>
    </div>
  );
};

interface Props {
  title: string;
  icon?: ReactElement;
  active?: boolean;
  disabled?: boolean;
  setActiveValue: (item: boolean) => void | null;
  buttonPressed: () => void | null;
  style?: CSSProperties;
}

const useStyles = makeStyles(() =>
  createStyles({
    Container: {
      margin: 'auto 0',
    },
    button: {
      width: 'auto',
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      color: (props: Props) => (props.disabled ? Colors.plain_white : Colors.primary_text),
      borderRadius: '25px',
      height: '47px',
      background: (props: Props) => (props.disabled ? Colors.disabled : Colors.plain_white),
      transition: 'background 0.5s, transform 0.8s',
      textTransform: 'none',
      pointerEvents: (props: Props) => (props.disabled ? 'none' : 'all'),
      '@media (min-width: 769px)': {
        '&:hover': {
          transform: 'scale(1.04)',
          boxShadow: ' 0px 0px 7px 0px ' + Colors.button_bg_active_shadow,
          background: Colors.button_bg_active,
          color: Colors.plain_white,
        },
      },
      '@media (max-width: 768px)': {
        transform: 'scale(0.9)',
        '&:active': {
          boxShadow: ' 0px 0px 7px 0px ' + Colors.button_bg_active_shadow,
          background: Colors.button_bg_active,
          color: Colors.plain_white,
        },
        '&:hover': {
          background: (props: Props) => (props.active ? Colors.button_bg_active : Colors.plain_white),
          color: (props: Props) => (props.active ? Colors.plain_white : Colors.hilighted_text),
          boxShadow: 'none',
        },
      },
    },

    icon: {
      width: '20px',
      height: '20px',
      marginBottom: '5px',
    },
    innerContent: {
      margin: 'auto 15px',
    },
  }),
);

export default CustomIconButtonSecondary;
