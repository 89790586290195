import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import { TextStyles } from 'styles/defaultStyles';
import clsx from 'clsx';
import { ARCHIVED, SYSTEM_MESSAGE, CLOSED, MONTHS } from 'utils';

interface Props {
  date: string;
  name?: string;
  message: string;
  roomateMessage: boolean;
  type: string;
}

const Message = ({ name = 'You', ...props }: Props): JSX.Element => {
  const classes = useStyles();
  const textStyles = TextStyles();

  const getDateString = (date: Date, showOnlyDate = false): string => {
    let dateString = MONTHS[date.getMonth()];
    dateString = dateString + ' ' + date.getDate() + ', ';
    dateString = dateString + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    if (!showOnlyDate) dateString = (props.roomateMessage ? name.split(' ')[0] : 'You') + ' . ' + dateString;
    return dateString;
  };

  const showMessage = (message: string) => {
    if (message.includes(ARCHIVED)) message = message.replace(ARCHIVED, CLOSED);
    return message;
  };

  const getInitials = (): string => {
    const fullName = name.split(' ');
    const initials = (fullName.shift() ?? 'R').charAt(0) + (fullName.pop() ?? 'M').charAt(0);
    return initials;
  };
  return (
    <>
      {props.type == SYSTEM_MESSAGE ? (
        <div className={classes.systemMessage}>
          <div className={classes.date}>{getDateString(new Date(props.date), true)}</div>
          <div>{showMessage(props.message)}</div>
        </div>
      ) : (
        <div className={clsx(classes.Container, { [classes.OwnMessageContainer]: !props.roomateMessage })}>
          {props.roomateMessage && <div className={clsx(classes.Icon, textStyles.Icon_Text)}>{getInitials()}</div>}
          <div className={clsx(classes.DateAndMessageContainer, { [classes.OwnMessage]: !props.roomateMessage })}>
            <div className={clsx(textStyles.Typography_3, classes.Date)}>{getDateString(new Date(props.date))}</div>
            <div className={clsx(textStyles.Typography_3, classes.Message)}>{props.message}</div>
          </div>
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '19px 24px 19px 6px',
  },
  systemMessage: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    fontSize: '12px',
    margin: '16px 0px 24px 0px',
    color: Colors.timeStamp_grey,
  },
  date: {
    fontSize: '10px',
  },
  Icon: {
    width: '23px',
    height: '23px',
    borderRadius: '100%',
    background: Colors.roomate_color,
    color: Colors.plain_white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  DateAndMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '87%',
  },
  OwnMessage: {
    width: 'fit-content',
    alignItems: 'flex-end',
    maxWidth: '80%',
  },
  Date: {
    fontSize: '10px',
    lineHeight: '10px',
    color: Colors.timeStamp_grey,
  },
  Message: {
    fontSize: '13px',
    lineHeight: '17px',
    color: Colors.message_black,
    marginTop: '4px',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
  OwnMessageContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    margin: '10px 24px 10px -10px',
  },
}));

export default Message;
