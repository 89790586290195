import { SvgIconProps } from '@material-ui/core/SvgIcon';

function HomeIcon(props: SvgIconProps): JSX.Element {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 11.7641C23.5 13.2104 22.7946 14.4841 21.2459 14.4841C18.7297 14.4841 19.8585 11.7251 18.1168 11.7251C16.8584 11.7251 15.9025 13.6348 15.9025 15.5123V22.1955C15.9025 23.6413 17.2634 23.9164 17.2634 24.7773V24.9146C17.2634 25.2936 16.9731 25.5 16.5536 25.5H11.2158C10.7642 25.5 10.5008 25.2648 10.5008 24.8863V24.7481C10.5008 24.0203 11.7318 23.6409 11.7318 22.1955L11.7181 14.1704C11.7181 12.8941 10.5 12.5885 10.5 11.7277V11.5903C10.5 11.3457 10.8889 11.1122 11.3301 10.8432L14.4097 8.92357C15.2293 8.31847 15.8921 8.80341 15.8921 9.45744V11.6796C17.0257 9.37075 18.4578 8.5 20.2474 8.5C22.1504 8.5 23.5 10.008 23.5 11.7637V11.7641Z"
        fill="#09B6B6"
      />
      <rect x="0.5" y="0.5" width="33" height="33" rx="16.5" stroke={props.stroke} />
    </svg>
  );
}

export default HomeIcon;
