import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CustomSelectCategory, InputFieldWithLabel } from 'components/fields';

import { useState } from 'react';
import { CatalogInfo, Category, UniversityCatalogProps, SubCategory } from 'types';
import ButtonsContainer from './buttonsContainer';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (catalog: CatalogInfo) => Promise<boolean>;
  catalog?: UniversityCatalogProps;
  categories?: Category[];
  subCategories?: SubCategory[];
  onChangeCatalog: (item?: UniversityCatalogProps) => void;
}

const initialValues = {
  id: '',
  thumbnail: '',
  itemName: '',
  category: '',
  subCategory: '',
  itemDescription: '',
};

const AddCatalogModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  catalog,
  categories,
  subCategories,
  onChangeCatalog,
}: Props) => {
  const [catalogInfo, setCatalogInfo] = useState<CatalogInfo>(initialValues);
  const [isError, setIsError] = useState(false);
  const label = catalog ? 'Edit Catalog' : ' Add Catalog';

  const classes = useStyles();

  const getSubCategories = (category: string) => subCategories?.filter((item) => item.categoryId === category);

  const getSubCategory = (category: string) => {
    const subs = getSubCategories(category);
    if (subs?.length) return subs[0].id;
    return '';
  };

  if (catalog && catalogInfo === initialValues) {
    setCatalogInfo({
      id: catalog.catalogId,
      thumbnail: catalog.thumbnail || '',
      itemName: catalog.itemName,
      category: catalog.category,
      subCategory: catalog.subcategory,
      itemDescription: catalog.itemDescription,
    });
  }

  const handleClose = () => {
    setIsOpen(false);
    setIsError(false);
    onChangeCatalog(undefined);
    setCatalogInfo(initialValues);
  };

  const handleSubmit = async () => {
    const error =
      !catalogInfo.itemName ||
      !catalogInfo?.itemDescription ||
      !catalogInfo.thumbnail ||
      !catalogInfo.category ||
      !catalogInfo.subCategory;
    if (error) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const success = await onSubmit(catalogInfo);
    if (success) {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>{label}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.label}></div>
        <div className={classes.text}></div>
        <form noValidate={false} autoComplete="on">
          <InputFieldWithLabel
            labelText="Item Name"
            placeholder="Enter Item Name"
            value={catalogInfo?.itemName}
            setFieldValue={(item: string) => setCatalogInfo({ ...catalogInfo, itemName: item })}
            isError={isError && !catalogInfo?.itemName}
            errorMsg="Item Name can't be empty"
            mTop="0px"
          />
          <InputFieldWithLabel
            labelText="Item Description"
            placeholder="Enter Item Description"
            value={catalogInfo?.itemDescription || ''}
            setFieldValue={(item: string) => setCatalogInfo({ ...catalogInfo, itemDescription: item })}
            isError={isError && !catalogInfo?.itemDescription}
            errorMsg="Item Description can't be empty"
            mTop="0px"
          />
          <InputFieldWithLabel
            labelText="Thumbnail"
            placeholder="Enter thumbnail"
            value={catalogInfo?.thumbnail}
            setFieldValue={(item: string) => setCatalogInfo({ ...catalogInfo, thumbnail: item })}
            isError={isError && !catalogInfo?.thumbnail}
            errorMsg="Thumbnail can't be empty"
            mTop="10px"
          />
          {!catalog && (
            <>
              <CustomSelectCategory
                options={categories}
                onChange={(category) =>
                  setCatalogInfo({ ...catalogInfo, category, subCategory: getSubCategory(category) })
                }
                label="Category"
                errorMsg={isError && !catalogInfo?.category ? "Category can't be empty" : ''}
                value={catalogInfo?.category}
              />
              {catalogInfo.category && (
                <CustomSelectCategory
                  options={getSubCategories(catalogInfo.category)}
                  onChange={(subCategory) => setCatalogInfo({ ...catalogInfo, subCategory })}
                  label="Sub Category"
                  errorMsg={isError && !catalogInfo?.subCategory ? "Sub Category can't be empty" : ''}
                  value={catalogInfo?.subCategory}
                />
              )}
            </>
          )}
        </form>
        <ButtonsContainer cancelClicked={handleClose} submitClicked={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddCatalogModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    margin: '20px 0px 10px',
    color: Colors.secondary_text,
  },
}));
