import { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthContext } from 'context/authContext';
import Routes from '.';

export interface ProtectedRouteProps extends RouteProps {
  path: string;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const authContext = useContext(AuthContext);

  const isEmployeeRoute = (path: string) =>
    path === Routes.UPLOAD_CATALOG ||
    path === Routes.UPLOAD_ROOM ||
    path === Routes.ROOM_LINKS ||
    path === Routes.TEMPLATE_LINKS ||
    path === Routes.SEARCH_CATALOG ||
    path === Routes.CATALOGS_UNIVERSITY_ASSOCIATION ||
    path === Routes.UNIVERSITY_FURNITURE;

  const isModelerRoute = (path: string) => path === Routes.ROOM_VIEWER || path === Routes.DEFAULT;

  if (authContext?.roomInfo?.roomieAccessToken) {
    if (
      (isEmployeeRoute(props.path) && !authContext?.roomInfo?.isRoomieEmployee) ||
      (authContext?.roomInfo?.isRoomieModeler && !isModelerRoute(props.path)) ||
      (!authContext?.roomInfo?.isRoomieEmployee &&
        !authContext?.roomInfo?.isRoomieModeler &&
        props.path === Routes.ROOM_VIEWER)
    ) {
      return <Redirect to={Routes.DEFAULT} />;
    } else {
      return <Route {...props} component={props.component} render={undefined} />;
    }
  } else {
    return <Redirect to={{ pathname: Routes.DEFAULT }} />;
  }
};

export default ProtectedRoute;
