import { Card, CardContent, FormControlLabel, List, Switch, TextField, Typography } from '@material-ui/core';
import useStyles from 'styles/ProductStyles';
import CombinationCard from './CombinationCard';
import { Catalog, Combination, Geometry, Material, Media } from 'types';

type ProductCardProps = {
  catalog: Catalog;
  handleCatalogFormChange: (catalogId: string, fieldToUpdate: string, updatedValue: any) => void;
  handleCombinationMaterialFormChange: (
    catalogId: string,
    combinationId: string,
    materialId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => void;
  handleCombinationGeometryFormChange: (
    catalogId: string,
    combinationId: string,
    geometryId: string,
    fieldToUpdate: string,
    updatedValue: string,
  ) => void;
  handleCombinationMediasFormChange: (
    catalogId: string,
    combinationId: string,
    mediasId: string,
    fieldToUpdate: string,
    updatedValue: string | number,
  ) => void;
  addNewMaterial: (catalogId: string, combinationId: string, material: Material) => void;
  addNewGeometries: (catalogId: string, combinationId: string, geometry: Geometry) => void;
  addNewMedias: (catalogId: string, combinationId: string, medias: Media) => void;
  removeAttribute: (catalogId: string, combinationId: string, attributeId: string, propertyName: string) => void;
  updateCombination: (catalogId: string, combinationId: string, fieldToUpdate: 'active', active: boolean) => void;
};

const ProductCard = ({
  catalog,
  handleCatalogFormChange,
  handleCombinationMaterialFormChange,
  handleCombinationGeometryFormChange,
  handleCombinationMediasFormChange,
  addNewMaterial,
  addNewGeometries,
  addNewMedias,
  removeAttribute,
  updateCombination,
}: ProductCardProps) => {
  const classes = useStyles();
  const handleInputChange = (fieldName: string, value: string): void => {
    handleCatalogFormChange(catalog.id, fieldName, value);
  };

  const handleSwitchChange = (event: any) => {
    const isActive = event.target.checked;
    console.log(isActive);
    handleCatalogFormChange(catalog.id, 'active', isActive);
  };
  return (
    <Card className={classes.cardStyle}>
      <CardContent>
        <Typography gutterBottom variant="button" style={{ fontWeight: 'bold' }}>
          {catalog?.blurb}
        </Typography>
        <Typography gutterBottom variant="caption" component="div">
          {catalog?.description && <div dangerouslySetInnerHTML={{ __html: catalog.description }} />}
        </Typography>
        <div className={classes.buttons}>
          <div className={classes.distributeHorizontally}>
            <TextField
              label="Type"
              onChange={(e) => handleInputChange('type', e.target.value)}
              variant="standard"
              defaultValue={catalog.type}
            />
            <TextField
              label="Surface Type"
              onChange={(e) => handleInputChange('surfaceType', e.target.value)}
              variant="standard"
              defaultValue={catalog.surfaceType}
            />
            <TextField
              label="Default Image"
              onChange={(e) => handleInputChange('defaultImage', e.target.value)}
              variant="standard"
              defaultValue={catalog.defaultImage}
            />
            <div>
              <FormControlLabel
                control={<Switch checked={catalog.active} onChange={(e) => handleSwitchChange(e)} />}
                label="Active"
              />
            </div>
          </div>
        </div>
        <Typography variant="button">Combinations</Typography>
        <List>
          {catalog?.combinations.map((combination: Combination, i: number) => {
            return (
              <CombinationCard
                combination={combination}
                i={i}
                catalogId={catalog.id}
                key={combination.vendorSku}
                handleCombinationMaterialFormChange={handleCombinationMaterialFormChange}
                handleCombinationGeometryFormChange={handleCombinationGeometryFormChange}
                handleCombinationMediasFormChange={handleCombinationMediasFormChange}
                addNewMaterial={addNewMaterial}
                addNewGeometries={addNewGeometries}
                addNewMedias={addNewMedias}
                removeAttribute={removeAttribute}
                updateCombination={updateCombination}
              />
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};
export default ProductCard;
