import { makeStyles } from '@material-ui/core/styles';
import { useContext, useState } from 'react';
import { HomeIcon, SettingsIcon } from 'components';
import clsx from 'clsx';
import { styled, alpha } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SignoutIcon from '@material-ui/icons/ExitToApp';
import Routes from 'routes';
import { Params } from 'api';
import { AxiosResponse } from 'axios';
import { SUCCESS } from 'utils';
import { useHistory } from 'react-router-dom';
import Colors from 'theme';
import { AuthContext } from 'context/authContext';

const StyledMenu = styled((props: MenuProps) => <Menu elevation={0} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginLeft: '40px',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

interface Props {
  logout: (
    params: Params | FormData,
    onCompleted?: ((res: AxiosResponse<any>) => void) | undefined,
    postParams?: boolean | undefined,
  ) => void;
}

const SideNavBar = ({ ...props }: Props): JSX.Element => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    await props.logout({}, (res) => {
      if (res.data.reason === SUCCESS) {
        handleClose();
        authContext?.logout();
      }
    });
  };

  return (
    <div className={classes.NavBar}>
      <div className={classes.Wrapper}>
        <div onClick={() => history.push(Routes.DEFAULT)} className={classes.HomeButton}>
          <HomeIcon
            stroke={window.location.pathname === Routes.DEFAULT ? Colors.button_bg_active : Colors.dropdown_border}
            className={classes.Icon}
          />
          <div className={classes.Text}>Home</div>
        </div>
      </div>
      <div className={classes.BottomContainer}>
        <div onClick={handleClick} className={clsx(classes.ButtonContainer)}>
          <SettingsIcon fill={open ? Colors.button_bg_active : Colors.primary_text} />
          <div className={classes.Text}>Settings</div>
        </div>
        <div className={classes.Bottom} />
      </div>
      <StyledMenu id="demo-customized-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem disableRipple onClick={async () => await logout()}>
          <SignoutIcon />
          Sign out
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  NavBar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100vh',
    width: '60px',
    position: 'absolute',
    background: Colors.plain_white,
    zIndex: theme.zIndex.drawer + 2,
    overflow: 'hidden',
    boxShadow: `0px 2px 8px ${Colors.boxShadow}`,
  },
  ButtonContainer: {
    width: '100%',
    height: 54,
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'all 0.5s',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      background: Colors.button_bg_active_shadow_light,
    },
  },
  HomeButton: {
    width: '100%',
    height: 62,
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'all 0.5s',
    alignItems: 'center',
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      background: Colors.button_bg_active_shadow_light,
    },
  },
  Icon: {
    transition: 'all 0.5s',
  },
  BottomContainer: {
    marginTop: 'auto',
  },
  Text: {
    fontFamily: 'Wigrum',
    fontSize: '9px',
    marginTop: '8px',
    color: Colors.primary_text,
  },
  Wrapper: {
    marginTop: '-12px',
  },
  Bottom: {
    marginBottom: '16px',
  },
}));
export default SideNavBar;
