import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  UniversitySelect,
  FurnitureRow,
  AddCatalogModal,
  CircularProgress,
  Snackbar,
  AddCatalogItemModal,
  ToolBar,
} from 'components';
import Colors from 'theme';
import { Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  CATALOG_ADDED_SUCCESS,
  CATALOG_HEADERS,
  CATALOG_ITEM_ADDED_SUCCESS,
  CATALOG_ITEM_UPDATED_SUCCESS,
  CATALOG_UPDATED_SUCCESS,
  GET,
  myCategoriesHandler,
  POST,
} from 'utils';
import useAxios, { ServerRoutes } from 'api';
import { AxiosError } from 'axios';
import {
  Toast,
  Category,
  SubCategory,
  CatalogInfo,
  UniversityCatalogProps,
  UniversityCatalogItemProps,
  AddCatalogItem,
} from 'types';

const LibraryCatalog = () => {
  const classes = useStyles();

  const [universityId, setUniversityId] = useState('');
  const [isAddingCatalog, setIsAddingCatalog] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [catalogData, setCatalogData] = useState<UniversityCatalogProps[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [isAddingCatalogItem, setIsAddingCatalogItem] = useState(false);
  const [catalog, setCatalog] = useState<UniversityCatalogProps>();
  const [openedCatalog, setOpenedCatalog] = useState('');
  const [selectedCatalogItem, setSelectedCatalogItem] = useState<UniversityCatalogItemProps>();

  const { callApi: logout, loading: logoutLoading, error: logoutError } = useAxios(ServerRoutes.logout, POST, false);

  const {
    callApi: loadUniversityCatalogs,
    loading: catalogsLoading,
    error: catalogsError,
  } = useAxios(ServerRoutes.adminLoadUniversityCatalogs, POST, false);

  const {
    callApi: loadCategories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useAxios(ServerRoutes.loadCategories, GET, false);

  const {
    callApi: saveCatalog,
    loading: saveCatalogLoading,
    error: saveCatalogError,
  } = useAxios(ServerRoutes.saveCatalog, POST, false);

  const {
    callApi: saveCatalogItem,
    loading: saveCatalogItemLoading,
    error: saveCatalogItemError,
  } = useAxios(ServerRoutes.saveCatalogItem, POST, false);

  useEffect(() => {
    const fetchCatagories = async () => {
      await loadCategories({}, (response) => {
        if (response?.data) {
          const { categories, subCategories } = myCategoriesHandler(response.data.roomieCategories);
          setCategories(categories);
          setSubCategories(subCategories);
        } else {
          setShowToast(true);
          setToast({
            severity: 'error',
            message: 'failed in loading categories',
            autoHideDuration: 3000,
          });
        }
      });
    };
    fetchCatagories();
  }, []);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
      }
    };
    showError(logoutError);
    showError(saveCatalogError);
    showError(catalogsError);
    showError(categoriesError);
    showError(saveCatalogItemError);
  }, [catalogsError, categoriesError, saveCatalogError, logoutError, saveCatalogItemError]);

  const onUniversityChange = async (value: string) => {
    setUniversityId(value);
    await loadUniversityCatalogs(
      {
        university_id: value,
      },
      (catalogsResp) => {
        if (catalogsResp.data?.catalogs) {
          setCatalogData(catalogsResp.data?.catalogs);
        }
      },
    );
  };

  const handleAddButtonClicked = () => {
    setIsAddingCatalog(true);
  };

  const onAddCatalog = async (info: CatalogInfo) => {
    let success = false;
    await saveCatalog({ ...info, universityId, catalogId: info.id || null }, async (res) => {
      if (res) {
        if (res?.data?.code === 1) {
          setShowToast(true);
          setToast({
            severity: 'error',
            message: res?.data?.reason,
            autoHideDuration: 3000,
          });
        } else {
          success = true;
          setShowToast(true);
          setToast({
            severity: 'success',
            message: info?.id ? CATALOG_UPDATED_SUCCESS : CATALOG_ADDED_SUCCESS,
            autoHideDuration: 3000,
          });
          await onUniversityChange(universityId);
        }
      }
    });
    return success;
  };

  const onAddCatalogItem = async (item: AddCatalogItem) => {
    let success = false;
    await saveCatalogItem(
      {
        ...item,
        catalogId: item?.catalogId || openedCatalog,
        catalogItemId: item?.id || null,
        itemVariantDefault: item.itemVariantDefault === 'True',
      },
      async (res) => {
        if (res) {
          if (res?.data?.code === 1) {
            setShowToast(true);
            setToast({
              severity: 'error',
              message: res?.data?.reason,
              autoHideDuration: 3000,
            });
          } else {
            success = true;
            setShowToast(true);
            setToast({
              severity: 'success',
              message: item?.id ? CATALOG_ITEM_UPDATED_SUCCESS : CATALOG_ITEM_ADDED_SUCCESS,
              autoHideDuration: 3000,
            });
            await onUniversityChange(universityId);
          }
        }
      },
    );
    return success;
  };

  return (
    <>
      <ToolBar logout={logout} />
      <div className={classes.root}>
        <Snackbar
          isOpen={showToast}
          setIsOpen={setShowToast}
          messageHeader={toast?.messageHeader}
          severity={toast?.severity || 'success'}
          message={toast?.message || ''}
          autoHideDuration={toast?.autoHideDuration}
        />
        <CircularProgress
          isLoading={
            catalogsLoading || categoriesLoading || logoutLoading || saveCatalogLoading || saveCatalogItemLoading
          }
        />
        <AddCatalogModal
          isOpen={isAddingCatalog}
          onSubmit={onAddCatalog}
          setIsOpen={setIsAddingCatalog}
          categories={categories}
          subCategories={subCategories}
          catalog={catalog}
          onChangeCatalog={setCatalog}
        />
        <AddCatalogItemModal
          isOpen={isAddingCatalogItem}
          onSubmit={onAddCatalogItem}
          setIsOpen={setIsAddingCatalogItem}
          item={selectedCatalogItem}
          setItem={setSelectedCatalogItem}
          catalogData={catalogData}
        />
        <div>
          <div className={classes.container}>
            <div className={classes.selectContainer}>
              <UniversitySelect university={universityId} onChange={onUniversityChange} />
            </div>
          </div>
          {catalogData.length !== 0 && (
            <div className={classes.addFurnitureIcon}>
              <AddCircleIcon className={classes.icon} color="primary" onClick={handleAddButtonClicked} />
            </div>
          )}
        </div>
        {catalogData.length !== 0 && (
          <div className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow>
                  {CATALOG_HEADERS.map((heading, index) => (
                    <TableCell
                      align={index === 1 ? 'right' : 'left'}
                      key={heading}
                      className={classes.tableHead}
                      style={{ paddingRight: index === 1 ? '112px' : '0px' }}
                    >
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {catalogData
                  .sort((a, b) => {
                    return a.itemName.toLowerCase().localeCompare(b.itemName.toLowerCase(), undefined, {
                      numeric: true,
                      sensitivity: 'base',
                    });
                  })
                  .map((row, index) => (
                    <FurnitureRow
                      row={row}
                      key={index}
                      setIsAddingCatalog={setIsAddingCatalog}
                      setCatalogData={setCatalog}
                      onOpenedCatalogChange={setOpenedCatalog}
                      setIsAddingCatalogItem={setIsAddingCatalogItem}
                      setSelectedCatalogItem={setSelectedCatalogItem}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default LibraryCatalog;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: Colors.plain_white,
      minHeight: '100vh',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      marginTop: '30px',
      marginBottom: '9px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '10%',
      },
    },
    heading: {
      fontFamily: 'Cooper Lt BT',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '25px',
      marginBottom: '15px',
    },
    sort: {
      marginLeft: '10px',
    },
    selectContainer: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    tableWrapper: {
      overflow: 'scroll',
      borderRadius: '8px',
      boxShadow: Colors.infoBoxShadow,

      width: '78%',
      height: 'fit-content',
      marginBottom: '50px',
      maxHeight: '65vh',
      display: 'flex',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      '& -webkit-scrollbar': {
        width: '0',
        background: 'transparent',
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        margin: '20px 0px 0px 10%',
      },
    },
    addFurnitureIcon: {
      width: '78%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
    },
    tableHead: {
      fontSize: '20px',
      fontWeight: 700,
      color: Colors.plain_black,
    },
    icon: {
      width: '50px',
      height: '50px',
      cursor: 'pointer',
      marginBottom: '10px',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
  }),
);
