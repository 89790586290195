import { makeStyles } from '@material-ui/core/styles';
import { useRef, useState, FormEvent } from 'react';
import Colors from 'theme';
import { SmileyIcon } from 'components';
import Picker, { IEmojiData } from 'emoji-picker-react';
import clsx from 'clsx';

interface Props {
  minimize: boolean;
  sendMessage: (newMessage: string) => void;
}

const InputBox = ({ sendMessage, minimize }: Props): JSX.Element => {
  const classes = useStyles();
  const inputField = useRef(null);
  const [showEmoji, setShowEmoji] = useState<boolean>(false);
  const handleSubmit = (event?: FormEvent<HTMLFormElement>) => {
    event && event.preventDefault();
    const form = inputField.current;
    if (form) {
      const message = (form['message'] as HTMLInputElement).value;
      sendMessage(message);
      (form['message'] as HTMLInputElement).value = '';
    }
  };

  const emojiSelected = (emoji: IEmojiData) => {
    const form = inputField.current;
    if (form) (form['message'] as HTMLInputElement).value = (form['message'] as HTMLInputElement).value + emoji.emoji;
  };
  return (
    <div className={clsx(classes.Container, { [classes.minimize]: minimize })}>
      <SmileyIcon className={classes.Smiley} onClick={() => setShowEmoji(!showEmoji)} />
      {showEmoji && (
        <Picker
          pickerStyle={{ position: 'absolute', bottom: '50px', right: '0px', height: '200px' }}
          onEmojiClick={(event, emojiObject) => emojiSelected(emojiObject)}
        />
      )}

      <form ref={inputField} onSubmit={handleSubmit}>
        <input
          className={classes.InputField}
          name={'message'}
          autoComplete={'off'}
          placeholder={'Send message ...'}
        ></input>
      </form>
      <button className={classes.SendBtn} onClick={() => handleSubmit()}>
        {'Send'}
      </button>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    border: '1px solid ' + Colors.message_field_border_grey,
    borderRadius: '4px',
    flexDirection: 'row',
    height: '47px',
    alignItems: 'center',
    padding: '0px 11px',
    width: '84%',
  },
  Smiley: {
    height: 16,
    width: 16,
    transition: 'all 0.5s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05)',
      outline: 'none',
    },
  },
  EmojiPicker: {
    position: 'absolute',
    bottom: '50px',
    right: '0px',
    height: '200px',
  },
  InputField: {
    border: 'none',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '14px',
    color: Colors.primary_text,
    height: '80%',
    marginLeft: '8px',
  },
  SendBtn: {
    background: 'none',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Wigrum',
    lineHeight: '16px',
    color: Colors.button_primary_text,
    boxShadow: 'none',
    border: 'none',
    transition: 'all 0.5s',
    height: 'fit-content',
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05)',
      fontWeigth: '600',
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:active': {
      background: Colors.button_bg_active_shadow,
    },
  },
  minimize: {
    display: 'none',
  },
}));

export default InputBox;
