import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Colors from 'theme';

interface Props {
  x: number;
  y: number;
  text: string;
  hidden: boolean;
}

const FloatingTooltip = (props: Props): JSX.Element => {
  const classes = FloatingTooltipStyles(props);

  return (
    <div className={classes.Container}>
      <Typography className={classes.text}>{props.text}</Typography>
    </div>
  );
};

const FloatingTooltipStyles = makeStyles(() => ({
  Container: {
    position: 'absolute',
    top: (props: Props) => Math.floor(props.y) - 15 + 'px', // our height is about 30px with one line of text
    left: (props: Props) => Math.floor(props.x) - 300 + 'px',
    width: '600px',
    display: (props: Props) => (props.hidden ? 'none' : 'block'),
    border: '10px solid white',
    borderRadius: '10px',
  },
  text: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.plain_black,
    backgroundColor: Colors.plain_white,
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
}));

export default FloatingTooltip;
