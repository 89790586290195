import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
  regex: RegExp;
  password: string;
  checkStatement: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    checkStatement: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
      color: (props: Props) => (props.regex.test(props.password) ? Colors.plain_black : Colors.secondary_text),
      marginLeft: '8px',
    },
    checkListItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '7px',
      marginLeft: '6px',
    },
  }),
);
const PasswordCheckListItem = (props: Props): JSX.Element => {
  const classes = useStyles(props);
  return (
    <div className={classes.checkListItemContainer}>
      {props.regex.test(props.password) ? (
        <CheckIcon style={{ fontSize: 14.5, marginBottom: 'auto', color: Colors.success_green }} />
      ) : (
        <FiberManualRecordIcon style={{ fontSize: 10.5, marginBottom: 'auto' }} color={'disabled'} />
      )}
      <Typography className={classes.checkStatement}>{props.checkStatement}</Typography>
    </div>
  );
};

export default PasswordCheckListItem;
