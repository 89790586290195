import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputFieldWithLabel } from 'components/fields';
import { SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { ConfigsType, EditRoomConfig } from 'types';
import ButtonsContainer from './buttonsContainer';
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  configInfo: ConfigsType;
  setConfigInfo: (value: SetStateAction<EditRoomConfig>) => void;
  saveConfig: () => void;
  closeDialog: () => void;
}

interface Flags {
  modelUrlError: boolean;
  typeError: boolean;
}

const EditConfigDialog = ({ isOpen, setIsOpen, configInfo, setConfigInfo, saveConfig, closeDialog }: Props) => {
  const classes = useStyles();

  const [flags, setFlags] = useState<Flags>({
    modelUrlError: false,
    typeError: false,
  });

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const setValueInConfigInfo = (key: string, value: string | number) => {
    setConfigInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClose = (setOpen: (open: boolean) => void) => {
    setFlags({
      modelUrlError: false,
      typeError: false,
    });
    setOpen(false);
  };

  const saveRoomConfig = () => {
    handleClose(setIsConfirmationModalOpen);
    handleClose(setIsOpen);
    saveConfig();
  };

  const openConfirmationDialog = () => {
    const isError = !configInfo.modelUrl || !configInfo.typeName;
    setValueInFlags('typeError', !configInfo.typeName);
    setValueInFlags('modelUrlError', !configInfo.modelUrl);
    if (isError) return;
    setIsConfirmationModalOpen(true);
  };

  const ConfirmationDialog = () => (
    <Dialog
      classes={{ paper: classes.confirmationPaper }}
      open={isConfirmationModalOpen}
      onClose={() => handleClose(setIsConfirmationModalOpen)}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogContent className={classes.confirmationContent}>
        <div className={clsx(classes.text, classes.center)}>Are you sure</div>
        <div>You are changing production data. Are you sure you want to update the room config?</div>
        <ButtonsContainer
          reverse
          cancelText="No"
          submitText="Yes"
          cancelClicked={() => handleClose(setIsConfirmationModalOpen)}
          submitClicked={saveRoomConfig}
        />
      </DialogContent>
    </Dialog>
  );

  return (
    <Dialog open={isOpen} onClose={closeDialog} classes={{ paper: classes.paper }} fullWidth={true}>
      {isConfirmationModalOpen && <ConfirmationDialog />}
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Edit Room Config</div>
          <CloseIcon className={classes.closeIcon} onClick={closeDialog} />
        </div>
        <InputFieldWithLabel
          labelText="Type Name"
          placeholder="Update Type Name"
          value={configInfo.typeName}
          setFieldValue={(item) => setValueInConfigInfo('typeName', item)}
          isError={flags.typeError}
          errorMsg="Type Name can't be empty"
          mTop="0px"
        />
        <InputFieldWithLabel
          labelText="Model AWS URL"
          placeholder="Update Model AWS URL"
          value={configInfo.modelUrl}
          setFieldValue={(item) => setValueInConfigInfo('modelUrl', item)}
          isError={flags.modelUrlError}
          errorMsg="Model AWS URL can't be empty"
          mTop="0px"
        />
        <InputFieldWithLabel
          labelText="360 Photo AWS URL"
          placeholder="Update 360 Photo AWS URL"
          value={configInfo.panoUrl}
          setFieldValue={(item) => setValueInConfigInfo('panoUrl', item)}
          mTop="0px"
        />
        <InputFieldWithLabel
          labelText="Type Description"
          placeholder="Update Type Description"
          value={configInfo.roomDescription}
          setFieldValue={(item) => setValueInConfigInfo('roomDescription', item)}
          mTop="0px"
        />
        <InputFieldWithLabel
          labelText="Type Friendy Name"
          placeholder="Update Type Friendly Name"
          value={configInfo.friendlyName}
          setFieldValue={(item) => setValueInConfigInfo('friendlyName', item)}
          mTop="0px"
        />
        <InputFieldWithLabel
          labelText="Type Config Name"
          placeholder="Update Type Config Name"
          value={configInfo.configName}
          setFieldValue={(item) => setValueInConfigInfo('configName', item)}
          mTop="0px"
        />
        <ButtonsContainer cancelClicked={closeDialog} submitClicked={openConfirmationDialog} />
      </DialogContent>
    </Dialog>
  );
};

export default EditConfigDialog;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  center: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  confirmationContent: {
    width: 'auto',
    padding: '20px',
  },
  confirmationPaper: {
    width: '300px',
    borderRadius: '20px',
  },
}));
