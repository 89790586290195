import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Colors from 'theme';

interface Props {
  textToDisplay: string;
  color?: string;
}

const OnScreenText = (props: Props): JSX.Element => {
  const classes = OnScreenTextStyles(props);

  return (
    <div className={classes.Container}>
      <Typography className={classes.text}>{props.textToDisplay}</Typography>
    </div>
  );
};

const OnScreenTextStyles = makeStyles(() => ({
  Container: {
    position: 'absolute',
    top: '30%',
    left: '38%',
    '@media (max-width: 660px)': {
      left: '4vw',
      width: '50vw',
    },
  },
  text: (props: Props) => ({
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: props.color || Colors.plain_white,
  }),
}));

export default OnScreenText;
