import { useEffect, useRef, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import Message from './message';
import TitleBar from './titleBar';
import InputBox from './inputBox';
import {
  addAvailabilityListener,
  addConnectedListener,
  addIncomingEventListener,
  addIncomingGreetingListener,
  getCustomerSDK,
  isValidEmail,
  startChat,
  Messages,
} from 'utils';
import InfoIcon from '@material-ui/icons/Info';
import { AuthContext } from 'context/authContext';
import { ProgressCircularIndicator, InputFieldWithLabel, CustomButton } from 'components';
import { IncomingEvent } from 'types';

interface Props {
  open: boolean;
  setOpen: (newVal: boolean) => void;
  setError: (error: boolean) => void;
  setMessage: (message: string) => void;
}

const LiveChatWidget = ({ open, setOpen, setMessage, setError }: Props): JSX.Element => {
  const authContext = useContext(AuthContext);
  const [minimize, setMinimize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatId, setChatId] = useState<string>('');
  const [messages, setMessages] = useState<IncomingEvent[]>([]);
  const [senderId, setSenderId] = useState<string>('');
  const MessageContainer = useRef(null);
  const [agentName, setAgentName] = useState<string>('');
  const [agentAvailable, setAgentAvailable] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [canChat, setCanChat] = useState(!!authContext?.roomInfo?.roomieAccessToken);
  const [customerSDK] = useState<any>(
    getCustomerSDK(authContext?.userInfo?.email || name, authContext?.userInfo?.email || email),
  );

  const agentRef = useRef(agentAvailable);

  useEffect(() => {
    if (customerSDK && connected && canChat)
      customerSDK.updateCustomer({
        name,
        email,
      });
  }, [canChat]);

  const deactivateChat = () => {
    customerSDK?.deactivateChat({ id: chatId });
  };

  const setAgentRef = (isAgentAvailable: boolean) => {
    setAgentAvailable(isAgentAvailable);
    agentRef.current = isAgentAvailable;
  };

  const sendMessage = (message: string) => {
    const event = {
      type: 'message',
      text: message,
      // ... other properties specific for the event's type
    };
    customerSDK
      .sendEvent({
        chatId: chatId,
        event,
      })
      .then((event: any) => {
        const oldEvents = messages;
        oldEvents.push(event);
        setMessages([...oldEvents]);
        setTimeout(() => adjustHeight(), 500);
      })
      .catch(() => {
        setMessage(Messages.CHAT_CLOSED);
        setError(true);
        setOpen(false);
      });
  };

  const getMessages = async (): Promise<IncomingEvent[]> => {
    const promise: Promise<IncomingEvent[]> = new Promise((resolve) => {
      setMessages((prev) => {
        resolve(prev);
        return prev;
      });
    });
    return promise;
  };

  const adjustHeight = () => {
    const myElement = MessageContainer.current;
    if (myElement) {
      (myElement as HTMLDivElement).scrollTop = (myElement as HTMLDivElement).scrollHeight;
    }
  };

  const receiveMessage = async (event: IncomingEvent) => {
    const oldEvents = await getMessages();
    oldEvents.push(event);
    setMessages([...oldEvents]);
    setTimeout(() => adjustHeight(), 500);
  };

  useEffect(() => {
    setLoading(true);
    addConnectedListener(customerSDK, setAgentRef, setSenderId, onConnected, onError, setMessage);
    addAvailabilityListener(customerSDK, setAgentRef);
    addIncomingGreetingListener(customerSDK);
    addIncomingEventListener(customerSDK, receiveMessage);
    return () => {
      customerSDK?.disconnect();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (chatId && connected && senderId && !authContext?.roomInfo?.roomieAccessToken) {
        deactivateChat();
        setOpen(false);
      }
    })();
  }, [authContext]);

  const onError = () => {
    setLoading(false);
    setOpen(false);
    setError(true);
  };

  const getChatHistory = async (chatId: string) => {
    if (agentRef.current) {
      const history = customerSDK.getChatHistory({ chatId });
      // let isDone = false,
      let oldEvents: IncomingEvent[] = [];
      // while (!isDone && history) {
      await history.next().then(async (result: any) => {
        const { threads } = result.value;
        const events = threads
          .map((thread: any) => thread.events || [])
          .reduce((acc: any, current: any) => acc.concat(current), []);
        oldEvents = [events[0]];
        // oldEvents = events.concat(events[0]);
        // if (result.done) isDone = true;
      });
      // }
      setLoading(false);
      setMessages([...oldEvents]);
      setTimeout(() => adjustHeight(), 100);
    } else {
      setLoading(false);
      setTimeout(() => adjustHeight(), 100);
    }
  };

  const onConnected = async () => {
    startChat(customerSDK, setChatId, setAgentName, onError, setMessage, getChatHistory);
  };

  useEffect(() => {
    if (open && !connected) {
      customerSDK?.connect();
      setConnected(true);
    }
    !open && connected && deactivateChat();
    open && agentAvailable && startChat(customerSDK, setChatId, setAgentName, onError, setMessage);
  }, [open, agentAvailable]);

  const onStartChat = () => {
    const nameVal = name.split(' ').join('');
    const emailVal = email.split(' ').join('');
    if (!emailVal || !nameVal) {
      setError(true);
      setMessage(Messages.FILL_FIELDS);
    } else if (!isValidEmail(emailVal)) {
      setError(true);
      setMessage(Messages.INVALID_EMAIL);
    } else {
      setName(nameVal);
      setEmail(emailVal);
      setCanChat(true);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.Container} style={{ height: minimize ? 'auto' : '450px' }}>
      {loading && <ProgressCircularIndicator absoluteCenter={false} top="220px" left="135px" />}
      <div className={classes.TitleBar}>
        <TitleBar
          open={!minimize}
          name={agentAvailable && !loading ? agentName : 'Live Chat'}
          online={true}
          minimize={() => {
            setMinimize(true);
          }}
          popChat={async () => {
            await setMinimize(false);
            adjustHeight();
          }}
          closeChat={() => {
            deactivateChat();
            setOpen(false);
          }}
        />
      </div>
      {!minimize && (
        <div className={classes.MessageContainer}>
          {connected && chatId && (
            <div>
              {!agentAvailable && (
                <div className={classes.infoContainer}>
                  <InfoIcon color="primary" />
                  Our agents are not available right now, but you can still send messages.
                </div>
              )}
              {!canChat && (
                <div className={classes.inputContainer}>
                  Welcome to our LiveChat! Please fill in the form below before starting the chat.
                  <InputFieldWithLabel
                    width="100%"
                    padding="9px 15px 10px"
                    background={Colors.plain_white}
                    mTop="0px"
                    height="42px"
                    labelText="Name:"
                    value={name}
                    setFieldValue={setName}
                  />
                  <InputFieldWithLabel
                    labelText="Email:"
                    width="100%"
                    padding="9px 15px 10px"
                    background={Colors.plain_white}
                    mTop="0px"
                    height="44px"
                    setFieldValue={setEmail}
                    type="email"
                    value={email}
                  />
                  <CustomButton title="Start the chat" width="100%" buttonClicked={onStartChat} mBottom="10px" />
                </div>
              )}
            </div>
          )}
          {canChat && (
            <div ref={MessageContainer} className={classes.TextsContainer}>
              {messages.map((message, key) => {
                return (
                  <Message
                    date={message.createdAt}
                    name={agentName ? agentName : ''}
                    type={message.type}
                    message={message.text}
                    key={key}
                    roomateMessage={!(message.authorId == senderId)}
                  />
                );
              })}
            </div>
          )}
          {canChat && (
            <div className={classes.MessageInputContainer}>
              <InputBox minimize={!open} sendMessage={sendMessage} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
const useStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.plain_white,
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    width: '317px',
    zIndex: 2,
  },
  TitleBar: {
    display: 'flex',
    background: Colors.chat_title_bg,
    borderRadius: '8px 8px 0px 0px',
    height: '50px',
  },
  MessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.plain_white,
    position: 'relative',
    height: '410px',
  },
  TextsContainer: {
    overflow: 'auto',
    height: '82%',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.scrollBarColor,
      border: '4px solid transparent',
      borderRadius: '8px',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar': {
      width: '12px',
    },
  },
  MessageInputContainer: {
    display: 'flex',
    height: '25%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    width: '50px',
    height: '50px',
    borderRadius: '30px',
    background: 'blue',
    color: 'white',
    border: '1px solid white',
  },
  inputContainer: {
    padding: '10px 15px',
    lineHeight: '150%',
    fontSize: '14px',
    margin: '20px 10px 0px',
    borderRadius: '4px',
    border: `1px solid ${Colors.inputBoxBorder}`,
    marginBottom: '10px',
  },
  minimize: {
    animation: '$minimizeEffect',
    animationDuration: '1s',
    animationFillMode: 'forwards',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px',
    lineHeight: '150%',
    color: Colors.atomic,
    fontSize: '14px',
    gap: '5px',
    width: '100%',
    boxShadow: Colors.infoBoxShadow,
  },
  '@keyframes minimizeEffect': {
    '0%': {
      height: '288px',
      width: '287px',
    },
    '25%': {
      bottom: '20px',
      right: '20px',
    },
    '100%': {
      bottom: '20px',
      right: '20px',
      height: '0px',
      width: '0px',
    },
  },
}));
export default LiveChatWidget;
