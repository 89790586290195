import { useState, ChangeEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import { HidePassIcon, ShowPassIcon } from 'components';
import PasswordCheckList from './passwordCheckList';
import Colors from 'theme';

interface PasswordFieldWithLabelProps {
  labelText: string;
  password: string;
  withChecks: boolean;
  setPassword?: (item: string) => void;
  setAllChecksPassed?: ((item: boolean) => void) | null;
  error?: boolean;
  borderRadius?: string;
  errorMsg?: string;
  autoFocus?: boolean;
}

const PasswordFieldWithLabel = ({
  error = false,
  errorMsg = '',
  autoFocus = false,
  ...props
}: PasswordFieldWithLabelProps): JSX.Element => {
  const classes = useStyles({
    error,
    errorMsg,
    ...props,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.setPassword && props.setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.TextFieldContainer}>
      <Typography variant="h4" className={classes.Label}>
        {props.labelText}{' '}
      </Typography>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(event)}
        endAdornment={
          <InputAdornment className={classes.icon} position="end">
            {showPassword ? (
              <ShowPassIcon onClick={handleClickShowPassword} />
            ) : (
              <HidePassIcon onClick={handleClickShowPassword} />
            )}
          </InputAdornment>
        }
        inputProps={{ className: classes.TextField }}
        classes={{ notchedOutline: classes.TextFieldNotched, adornedEnd: classes.width, root: classes.root }}
        required={true}
        autoFocus={autoFocus}
      />
      {error && (
        <Typography variant="h4" className={classes.Label}>
          {errorMsg}
        </Typography>
      )}
      {props.withChecks && <PasswordCheckList password={props.password} checkConditions={props.withChecks} />}
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    TextFieldContainer: {
      marginTop: '24px',
      '@media (max-width: 350px)': {
        width: '80vw',
      },
    },
    TextField: {
      padding: '10px 20px',
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      color: Colors.plain_black,
      zIndex: 1,
      '@media (max-width: 350px)': {
        width: 'inherit',
      },
      '&:focus': {
        boxShadow: ({ ...props }: PasswordFieldWithLabelProps) =>
          '0 0 0 21px ' + props.error ? Colors.input_field_focus_bg : Colors.dropdown_border + ' inset',
      },
      '&:focus ~ $TextFieldNotched': {
        background: ({ ...props }: PasswordFieldWithLabelProps) =>
          props.error ? Colors.input_field_focus_bg : Colors.dropdown_border,
        borderColor: ({ ...props }: PasswordFieldWithLabelProps) =>
          !props.error ? Colors.hilighted_text : Colors.caution,
      },
    },
    TextFieldNotched: {
      height: 'calc(100% - 2px)',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
      top: '-2.5px',
      background: ({ ...props }: PasswordFieldWithLabelProps) => (!props.error ? 'none' : Colors.input_field_focus_bg),
      borderColor: ({ ...props }: PasswordFieldWithLabelProps) =>
        !props.error ? Colors.input_field_border : Colors.caution,
    },
    Label: {
      fontFamily: 'Wigrum',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      color: ({ ...props }: PasswordFieldWithLabelProps) => (!props.error ? Colors.secondary_text : Colors.caution),
      height: 'fit-content',
      marginBottom: '6px',
      marginTop: ({ ...props }: PasswordFieldWithLabelProps) => (props.error ? 5 : 0),
    },
    width: {
      width: '311px',
      '@media (max-width: 350px)': {
        width: 'inherit',
      },
    },
    checkListContainer: {
      display: 'flex',
    },
    icon: {
      transition: 'all 0.4s',
      height: 'fit-content',
      cursor: 'pointer',
      zIndex: 1,
      marginLeft: '4px',
      marginRight: '8px',
      '&:hover': {
        background: Colors.icon_bg_hover,
        borderRadius: '20px',
        transform: 'scale(1.05)',
      },
    },
    root: {
      width: '311px',
      height: '47px',
      padding: '2px 2px',
      borderRadius: ({ ...props }: PasswordFieldWithLabelProps) => (props.borderRadius ? props.borderRadius : '4px'),
      '@media (max-width: 350px)': {
        paddingRight: '10px',
        width: 'inherit',
      },
    },
  }),
);
export default PasswordFieldWithLabel;
