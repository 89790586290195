import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SmileyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.7"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C3.58 16 0 12.42 0 8C0 3.58 3.58 0 8 0C12.42 0 16 3.58 16 8C16 12.42 12.42 16 8 16ZM10.5 8C11.33 8 12 7.33 12 6.5C12 5.67 11.33 5 10.5 5C9.67 5 9 5.67 9 6.5C9 7.33 9.67 8 10.5 8ZM5.5 8C6.33 8 7 7.33 7 6.5C7 5.67 6.33 5 5.5 5C4.67 5 4 5.67 4 6.5C4 7.33 4.67 8 5.5 8ZM8 13C9.82387 13 11.3738 11.7486 12 10H4C4.62622 11.7486 6.17613 13 8 13Z"
        fill="#B2B9C4"
      />
    </SvgIcon>
  );
};

export default SmileyIcon;
