import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Colors from 'theme';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  value: string;
  setValue: (value: string) => void;
  options: string[];
  title: string;
}

const RadioField = ({ value, setValue, options, title }: Props) => {
  const classes = useStyles();

  return (
    <RadioGroup className={classes.root} value={value} onChange={(e) => setValue(e.target.value)}>
      <div className={classes.title}>{title}</div>
      <div className={classes.flex}>
        {options.map((option) => (
          <div className={classes.flex} key={option}>
            <Radio className={classes.radio} value={option} disableRipple />
            <div className={classes.option}>{option}</div>
          </div>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioField;

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Wigrum',
    marginBottom: '16px',
  },
  title: {
    color: Colors.bold_text,
    fontSize: '14px',
  },
  radio: {
    marginLeft: '-10px',
    color: `${Colors.heading_black} !important`,
    background: 'none !important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  option: {
    marginLeft: '-3px',
    fontWeight: 500,
    fontSize: '15px',
    marginRight: '16px',
  },
}));
