import React, { useState, useEffect } from 'react';

interface LoadingIndicatorProps {
  isLoading: () => boolean;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ isLoading }) => {
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowIndicator(isLoading());
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading]);

  return (
    <>
      {showIndicator && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
            fontFamily: 'Wigrum',
            fontStyle: 'normal',
            fontWeight: 'bold',
            color: 'white',
            fontSize: 26,
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">LOADING...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingIndicator;
