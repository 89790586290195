import { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import useAxios, { ServerRoutes } from 'api';
import { GET } from 'utils';
import { University } from 'types';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Snackbar } from 'components';

interface Props {
  university: string;
  showLabel?: boolean;
  onChange: (university: string) => void;
  setUniversityName?: (name: string) => void;
  width?: string;
}

interface StyleProps {
  width?: string;
}

const UniversitySelect = ({ university, showLabel = true, onChange, setUniversityName, width }: Props) => {
  const classes = useStyles({ width });

  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { response: universitiesData, error: universitiesError } = useAxios(ServerRoutes.adminUniversities, GET, true);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setIsOpen(true);
        setMessage(error.message);
      }
    };
    showError(universitiesError);
  }, [universitiesError]);

  const handleChange = (val: string) => {
    onChange(val);
    if (setUniversityName) {
      const selectedUniversity = universitiesData?.data?.universities?.find((item: University) => item.org_id === val);
      if (selectedUniversity) setUniversityName(selectedUniversity?.org_name);
    }
  };

  return (
    <div>
      <Snackbar isOpen={isOpen} setIsOpen={setIsOpen} message={message} severity="error" />
      <FormControl className={classes.formControl}>
        {showLabel && <InputLabel id="select-university">University</InputLabel>}
        <Select
          displayEmpty
          labelId="select-university"
          className={classes.select}
          value={university}
          onChange={(e) => handleChange(e.target.value as string)}
          MenuProps={{
            classes: {
              list: classes.menuProps,
            },
          }}
        >
          {!showLabel && (
            <MenuItem disabled value="">
              Select School
            </MenuItem>
          )}
          {universitiesData?.data?.universities?.map((item: University) => (
            <MenuItem key={item.org_id} value={item.org_id}>
              {item.org_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default UniversitySelect;

const useStyles = makeStyles((theme) =>
  createStyles({
    select: {
      width: (props: StyleProps) => props.width || '200px !important',
      [theme.breakpoints.down('sm')]: {
        width: '200px !important',
      },
    },
    formControl: {
      minWidth: '120px',
      margin: '20px 0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuProps: {
      maxHeight: '400px',
      minWidth: '200px',
    },
  }),
);
