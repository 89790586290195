import { cleanEnv, str } from 'envalid';

const config = cleanEnv(process.env, {
  REACT_APP_API_BASE_URL: str({
    desc: 'Endpoint URL',
  }),
  REACT_APP_SHOPPING_URL: str({
    desc: 'Shopping Endpoint URL',
  }),
  REACT_APP_LIVE_CHAT_LICENSE_KEY: str({
    desc: 'Live chat license key',
  }),
  REACT_APP_LIVE_CHAT_CLIENT_ID: str({
    desc: 'Live chat client id',
  }),
  REACT_APP_FIREBASE_API_KEY: str({
    desc: 'Firebase api key',
  }),
  REACT_APP_FIREBASE_AUTH_DOMAIN: str({
    desc: 'Firebase auth domain',
  }),
  REACT_APP_FIREBASE_PROJECT_ID: str({
    desc: 'Firebase project id',
  }),
  REACT_APP_FIREBASE_STORAGE_BUCKET: str({
    desc: 'Firebase storage bucket',
  }),
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: str({
    desc: 'Firebase messaging sender id',
  }),
  REACT_APP_FIREBASE_APP_ID: str({
    desc: 'Firebase app id',
  }),
  REACT_APP_FIREBASE_MEASUREMENT_ID: str({
    desc: 'Firebase measurement id',
  }),
  REACT_APP_ENVIRONMENT: str({
    desc: 'App environment',
  }),
});

export default config;
