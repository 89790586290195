import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import { useState } from 'react';
import Typography from '@material-ui/core/Typography';

interface ItemProps {
  item: string;
  isOpen?: boolean;
  onClick: () => void;
}

interface Props {
  value: string;
  setValue: (value: string, label: string) => void;
  options: { label: string; value: string }[];
  textMargin?: string;
  height?: string;
  heightExpanded?: string;
  width?: string;
  labelText?: string;
  margin?: string;
  padding?: string;
  iconMargin?: string;
  optionalText?: string;
  backgroundColor?: string;
  borderRadius?: string;
  arrowColor?: string;
}

const CustomSelect = ({ value, setValue, options, ...props }: Props) => {
  const classes = useStyles({ value, setValue, options, ...props });
  const [expanded, setExpanded] = useState(false);
  const Item = ({ item, isOpen = false, onClick }: ItemProps) =>
    isOpen ? (
      <MenuItem
        className={isOpen ? classes.menuItem : ''}
        style={{ background: item === value ? Colors.dropdown_border : '' }}
        onClick={onClick}
      >
        {item}
        <IconButton className={classes.icon}>
          <KeyboardArrowDown className={classes.arrow} />
        </IconButton>
      </MenuItem>
    ) : (
      <MenuItem
        className={classes.item}
        style={{ background: item === value ? Colors.dropdown_border : '' }}
        onClick={onClick}
      >
        {item}
      </MenuItem>
    );

  return (
    <>
      <Typography variant="h4">
        <span className={classes.Label}>{props.labelText}</span>
        <span className={classes.optionalText}>{props.optionalText}</span>
      </Typography>
      <div onClick={() => setExpanded(!expanded)} className={expanded ? classes.customSelect1 : classes.customSelect}>
        {!expanded && (
          <div className={classes.wrapper}>
            <div
              className={classes.selectText}
              style={{
                color: value ? Colors.secondary_text : Colors.greyShady,
                fontStyle: value ? 'nomral' : 'italic',
              }}
            >
              {value || 'Select'}
            </div>
            <IconButton
              className={classes.icon}
              style={{
                marginTop: !expanded ? props.iconMargin || '9px' : '0px',
                color: value ? Colors.cyprus1 : Colors.disabledText,
              }}
              onClick={() => value && setExpanded(!expanded)}
            >
              <KeyboardArrowDown className={classes.arrow} />
            </IconButton>
          </div>
        )}
        {expanded && (
          <div className={classes.selectText} style={{ margin: 0 }}>
            {options.map((item, index) => (
              <div key={index}>
                {index === 0 && (
                  <Item
                    key={index}
                    isOpen
                    item="Select"
                    onClick={() => {
                      setValue('', '');
                      setExpanded(!expanded);
                    }}
                  />
                )}
                <Item
                  key={item.value}
                  item={item.label}
                  onClick={() => {
                    setValue(item.value, item.label);
                    setExpanded(!expanded);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomSelect;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  customSelect: {
    border: `1px solid ${Colors.border_grey}`,
    width: ({ ...props }: Props) => (props.width ? props.width : '180px'),
    height: ({ ...props }: Props) => (props.height ? props.height : '47px'),
    cursor: 'pointer',
    color: Colors.cyprus1,
    margin: ({ ...props }: Props) => (props.margin ? props.margin : '2px 15px 0px'),
    padding: ({ ...props }: Props) => (props.padding ? props.padding : '0px 5px'),
    borderRadius: ({ ...props }: Props) => (props.borderRadius ? props.borderRadius : '2px'),
    background: ({ ...props }: Props) => (props.backgroundColor ? props.backgroundColor : Colors.plain_white),
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
  selectText: {
    fontSize: '16px',
    color: Colors.cyprus1,
    margin: ({ ...props }: Props) => (props.textMargin ? props.textMargin : '8px 0px 0px 10px'),
    display: 'flex',
    flexDirection: 'column',
  },
  customSelect1: {
    border: `1px solid ${Colors.border_grey}`,
    borderRadius: ({ ...props }: Props) => (props.borderRadius ? props.borderRadius : '2px'),
    marginTop: '2px',
    background: ({ ...props }: Props) => (props.backgroundColor ? props.backgroundColor : Colors.plain_white),
    width: ({ ...props }: Props) => (props.width ? props.width : '180px'),
    zIndex: 10,
    margin: ({ ...props }: Props) => (props.margin ? props.margin : '2px 15px 0px'),
    height: ({ ...props }: Props) => (props.heightExpanded ? props.heightExpanded : '170px'),
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
      height: 'auto',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 5px 7px 15px',
    fontSize: '16px',
    fontStyle: 'italic',
    color: Colors.greyShady,
    '&:hover': {
      background: 'none',
    },
  },
  item: {
    padding: '10px 0px 7px 15px',
    fontSize: '16px',
    '&:hover': {
      background: Colors.dropdown_border,
    },
  },
  arrow: {
    color: ({ ...props }: Props) => (props.arrowColor ? props.arrowColor : Colors.bold_text),
  },
  icon: {
    padding: '3px',
    margin: '0px 5px 0px 0px',
  },
  Label: {
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '14px',
    color: Colors.secondary_text,
    marginTop: '11px',
    height: 'fit-content',
    marginBottom: '6px',
    transtion: 'all 0.8s',
  },
  optionalText: {
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    color: Colors.greyShady,
    marginTop: '11px',
    height: 'fit-content',
    marginBottom: '6px',
    transtion: 'all 0.8s',
  },
}));
