import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import Grid from '@material-ui/core/Grid';
import { Room, RoomConfig } from 'types';
import Collapse from '@material-ui/core/Collapse';
import { CircularProgress, CustomButton, ErrorIcon, SuccessIcon } from 'components';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Divider, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  isEmployee: boolean;
  row: RoomConfig;
  selectedConfig: string;
  onRoomToMoveChange: (item: Room) => void;
  showStatusModal: () => void;
  setConfigToAddRoom: () => void;
  onViewTemplate: () => void;
  showUpdateConfigNameModal: () => void;
  loadConfigRooms: (configId: string) => void;
  roomNumbers: Room[];
  loading: boolean;
}

const Row = ({
  isEmployee,
  roomNumbers,
  selectedConfig,
  loading,
  showStatusModal,
  showUpdateConfigNameModal,
  onRoomToMoveChange,
  row,
  onViewTemplate,
  setConfigToAddRoom,
  loadConfigRooms,
}: Props) => {
  const classes = useStyles();

  const isOpen = selectedConfig === row?.id;

  const isLoading = loading && isOpen;

  const onLoadRooms = async (configId: string) => {
    await loadConfigRooms(configId);
  };
  console.log(row);
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.bodyCell}>
          <div className={classes.flex}>
            {isOpen ? (
              <KeyboardArrowUpIcon className={classes.caret} onClick={() => onLoadRooms('')} />
            ) : (
              <KeyboardArrowDownIcon className={classes.caret} onClick={() => row?.id && onLoadRooms(row?.id)} />
            )}
            {isEmployee ? row.type : row?.roomieConfigName || 'Config Name not provided'}
          </div>
        </TableCell>
        <TableCell className={classes.bodyCell}>
          <Grid container justifyContent="flex-end" alignItems="center">
            {!isEmployee && (
              <IconButton className={classes.editIcon} onClick={showUpdateConfigNameModal}>
                <EditIcon color="primary" />
              </IconButton>
            )}
            <CustomButton
              title="View Template"
              width="180px"
              mTop="0px"
              mBottom="0px"
              buttonClicked={onViewTemplate}
              borderRadius="10px"
              height="40px"
              textColor={Colors.cyprus}
              background={Colors.paleTurquoise}
              border="none"
            />
            {isEmployee && (
              <div className={classes.statusContainer}>
                <div className={isEmployee ? classes.active : classes.status} onClick={showStatusModal}>
                  {row?.statusComments === 'Active' || row?.statusComments === 'Filler' || !row?.statusComments ? (
                    <SuccessIcon />
                  ) : (
                    <ErrorIcon />
                  )}
                  {row?.statusComments || 'Active'}
                </div>
              </div>
            )}
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.cell} colSpan={2}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <CircularProgress isLoading={isLoading} coverPage={false} color={Colors.button_bg_active} />
            {isEmployee && !isLoading && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.iconContainer}>
                  <AddCircleIcon className={classes.icon} color="primary" onClick={setConfigToAddRoom} />
                </div>
              </>
            )}
            {roomNumbers?.map((room) => (
              <div key={room.roomie_university_building_room_id}>
                <Divider className={classes.divider} />
                <div className={classes.row}>
                  <div className={classes.roomNumber}>{room.room_number}</div>
                  {isEmployee && (
                    <ArrowRightAltIcon className={classes.caret} onClick={() => onRoomToMoveChange(room)} />
                  )}
                </div>
              </div>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default Row;

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  cell: {
    padding: 0,
    marginBottom: '20px',
  },
  bodyCell: {
    fontFamily: 'Wigrum',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: Colors.bigStone,
    paddingTop: '20px',
  },
  status: {
    margin: '0px 10px 0px 30px',
    width: '95px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  active: {
    margin: '0px 0px 0px 30px',
    width: '160px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  divider: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 30px',
  },
  roomNumber: {
    marginLeft: '20px',
  },
  caret: {
    color: Colors.button_bg_active,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px',
  },
  icon: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  editIcon: {
    marginRight: '10px',
  },
}));
