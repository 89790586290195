import { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { RoomCatalogItemDTO, Toast } from 'types';
import { POST, SUCCESS, UNIVERSITY_CATALOGS_SAVE_SUCCESS } from 'utils';
import useAxios, { ServerRoutes } from 'api';
import { ToolBar, CircularProgress, Snackbar, CustomSmallButton, ListItems, UniversitySelect } from 'components';
import { AxiosError } from 'axios';
import Colors from 'theme';

const CatalogsUniversityAssociation = () => {
  const classes = useStyles();

  const [university, setUniversity] = useState('');
  const [selectedItems, setSelectedItems] = useState<RoomCatalogItemDTO[]>([]);
  const [availableItems, setAvailableItems] = useState<RoomCatalogItemDTO[]>([]);
  const [toast, setToast] = useState<Toast>();
  const [open, setOpen] = useState(false);

  const {
    response: universityCatalogsData,
    callApi: universityCatalogs,
    loading: universityCatalogsLoading,
    error: universityCatalogsError,
  } = useAxios(ServerRoutes.adminUniversityCatalogs, POST, false);

  const {
    callApi: saveUniversityCatalogs,
    loading: saveUniversityCatalogsLoading,
    error: saveUniversityCatalogsError,
  } = useAxios(ServerRoutes.adminSaveUniversityCatalogs, POST, false);

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
        setOpen(true);
      }
    };
    showError(universityCatalogsError);
    showError(saveUniversityCatalogsError);
    showError(logoutError);
  }, [universityCatalogsError, saveUniversityCatalogsError]);

  const onUniversityChange = async (value: string) => {
    setUniversity(value);
    await universityCatalogs({ universityId: value, type: 'AVAILABLE' }, (catalogsResp) => {
      if (catalogsResp.data?.catalogs) {
        setAvailableItems(catalogsResp.data?.catalogs);
      }
    });
    await universityCatalogs({ universityId: value, type: 'SELECTED' }, (catalogsResp) => {
      if (catalogsResp.data?.catalogs) {
        setSelectedItems(catalogsResp.data?.catalogs);
      }
    });
  };

  const onAddItem = (item: RoomCatalogItemDTO) => {
    setSelectedItems([item, ...selectedItems]);
    const items = availableItems.filter((availableItem) => availableItem.item_description !== item.item_description);
    setAvailableItems(items);
  };

  const onRemoveItem = (item: RoomCatalogItemDTO) => {
    setAvailableItems([item, ...availableItems]);
    const items = selectedItems.filter((selectedItem) => selectedItem.item_description !== item.item_description);
    setSelectedItems(items);
  };

  const isDisabled = () => {
    if (selectedItems.length !== universityCatalogsData?.data?.catalogs?.length) return false;
    const newItems = selectedItems;
    newItems.sort(function (a, b) {
      const nameA = a.item_name.toUpperCase();
      const nameB = b.item_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    const oldItems = universityCatalogsData?.data?.catalogs?.sort(function (
      a: RoomCatalogItemDTO,
      b: RoomCatalogItemDTO,
    ) {
      const nameA = a.item_name.toUpperCase();
      const nameB = b.item_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    let isSame = true;
    newItems.forEach((item, index) => {
      if (item.item_name !== oldItems[index].item_name) isSame = false;
    });
    return isSame;
  };

  const saveItems = () => {
    let catalogIds: string[] = [];
    selectedItems.map((item) => {
      catalogIds = [...catalogIds, item.catalog_id];
    });
    saveUniversityCatalogs({ universityId: university, catalogIds: catalogIds }, async (saveCatalogsResp) => {
      if (saveCatalogsResp.data.reason === SUCCESS) {
        await onUniversityChange(university);
        setToast({ severity: 'success', message: UNIVERSITY_CATALOGS_SAVE_SUCCESS, autoHideDuration: 4000 });
        setOpen(true);
      }
    });
  };

  return (
    <div className={classes.root}>
      <CircularProgress isLoading={universityCatalogsLoading || saveUniversityCatalogsLoading || logoutLoading} />
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        autoHideDuration={toast?.autoHideDuration}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
      />
      <ToolBar logout={logout} />
      <div className={classes.container}>
        <div className={classes.heading}>Catalogs University Association</div>
        <div className={classes.selectContainer}>
          <UniversitySelect university={university} onChange={onUniversityChange} />
        </div>
        {university && (
          <>
            <Grid container>
              <ListItems items={selectedItems} isSelectedItem onAddItem={onAddItem} onRemoveItem={onRemoveItem} />
              <ListItems items={availableItems} onAddItem={onAddItem} onRemoveItem={onRemoveItem} />
            </Grid>
            <CustomSmallButton disabled={isDisabled()} title="Save" margin="30px 0px" onClick={() => saveItems()} />
          </>
        )}
      </div>
    </div>
  );
};

export default CatalogsUniversityAssociation;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: Colors.plain_white,
      minHeight: '100%',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '30px',
      marginBottom: '9px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '10%',
      },
    },
    heading: {
      fontFamily: 'Cooper Lt BT',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '25px',
      marginBottom: '15px',
    },
    selectContainer: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  }),
);
