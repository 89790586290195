import { makeStyles } from '@material-ui/core/styles';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomButton, CustomSelect, CustomUploadButton } from 'components';
import {
  AssetTexture,
  ItemModelSubType,
  RoomScene,
  ItemModel,
  DistanceFormat,
  ItemModelProps,
  RoomModel,
  RoomHotspot,
} from '@roomie-engineering/room-viewer';

interface ButtonBarLeftProps {
  roomScene: RoomScene | null;
  selectedRoom: RoomModel | undefined;
  selectedHotspot: RoomHotspot | undefined;
  toggleBottomBar: () => void;
}

const ButtonBarLeft = ({
  roomScene,
  selectedRoom,
  selectedHotspot,
  toggleBottomBar,
}: ButtonBarLeftProps): JSX.Element => {
  function getArrayWithNumberBaseEnumItems<T>(numberEnum: T): Array<{ label: keyof T; value: T[keyof T] }> {
    const arrayWithEnumItems = [];
    for (const item in numberEnum) {
      if (Number.isNaN(Number(item))) {
        arrayWithEnumItems.push({
          label: numberEnum[numberEnum[item] as unknown as Extract<keyof T, string>] as unknown as keyof T,
          value: numberEnum[item],
        });
      }
    }
    return arrayWithEnumItems;
  }

  // ts enum runtime is a map of key to value and value to key
  // but we only want "KEY": 1, and not 1: "KEY"
  function getEnumLabelValuePairs<T>(enumeration: T) {
    const results = getArrayWithNumberBaseEnumItems(enumeration);
    // return results.map((value) => ({ label: enumeration[value], value }));
    return results;
  }

  const EMPTY_LIST = [
    {
      label: '',
      value: '',
    },
  ];

  const [hotspotList, setHotspotList] = useState(EMPTY_LIST);
  const updateHotspotList = (): void => {
    const room = selectedRoom;
    if (room) {
      if (room.asset.isLoaded()) {
        if (room.hasHotspots()) {
          const hotspots = room.getHotspots();
          const hotspotsList = [];
          for (let i = 0; i < hotspots.length; i++) {
            const hotspot = hotspots[i];
            hotspotsList.push({
              label: hotspot.name,
              value: hotspot.index.toString(),
            });
          }
          console.log(hotspotsList);
          setHotspotList(hotspotsList);
          setHotspotListIndex(room.getSelectedHotspot()?.name ?? ''); // USES LABEL TO MAKE SELECT COMPONENT WORK
        } else {
          setHotspotList(EMPTY_LIST);
          setHotspotListIndex('');
        }
      } else {
        room.asset.getLoadedObservable().add(() => {
          updateHotspotList();
        });
      }
    } else {
      setHotspotList(EMPTY_LIST);
      setHotspotListIndex('');
    }
  };

  const [hotspotListIndex, setHotspotListIndex] = useState('');
  const onHotspotIndexChange = (value: string, label: string): void => {
    if (!roomScene) return;
    const hotspot = Number.parseInt(value);
    roomScene.switchViewHotspot(hotspot);
    console.log('onHotspotIndexChange: ' + label + ' / ' + value);
  };

  function convertToFilename(str: string, ext: string, suffix = '') {
    // const filenameStr =
    //   str
    //     .trim()
    //     .split(/\s+/)
    //     .join('_')
    //     .replace(/\.\w+$/, '')
    //     .toLocaleLowerCase() + '.json';
    const filenameStr = str.trim().replace(/\.\w+$/, '') + suffix + '.' + ext;
    //console.log(str + ' -> ' + filenameStr);
    return filenameStr;
  }

  function downloadStringAsFile(str: string, filename: string) {
    const blob = new Blob([str], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  function readUploadedFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  const onUploadRoom = (evt: ChangeEvent<HTMLInputElement>): void => {
    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log('adding uploaded room: ' + files[i].name);
        const url = URL.createObjectURL(files[i]);
        roomScene?.addRoom({ title: files[i].name, assetUrl: url, unlit: false }, true);
      }
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';

    resetFocus();
  };

  const TEST_ROOMS = [
    {
      label: 'Surface Test',
      value: 'Surface',
    },
    {
      label: 'Sloped Ceiling Test',
      value: 'SlopedCeiling',
    },
    {
      label: 'Angled Walls Test',
      value: 'AngledWalls',
    },
    {
      label: 'Standard Double',
      value: 'StandardDouble',
    },
    {
      label: 'Platform',
      value: 'Platform',
    },
  ];
  const [testRoom, setTestRoom] = useState(TEST_ROOMS[0].label);
  const [selectedTestRoom, setSelectedTestRoom] = useState(TEST_ROOMS[0].label);
  const [selectedTestRoomValue, setSelectedTestRoomValue] = useState(TEST_ROOMS[0].value);

  const onTestRoomChange = (value: string, label: string): void => {
    setTestRoom(label);
    setSelectedTestRoom(label);
    setSelectedTestRoomValue(value);
    //console.log(label + ' -> ' + value);
  };

  // prettier-ignore
  const onTestRoomAdd = (): void => {
    if (!roomScene) return;

    console.log('adding test room: ' + selectedTestRoom + ' (' + selectedTestRoomValue + ')');
    if (selectedTestRoomValue === 'Surface') {
      const room = roomScene?.addRoom({title: selectedTestRoom, assetUrl:'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/rooms/room-standard-wesleyan-clark-type1-02.glb'}, true);
      room.addDefaultBedItem({ bedSubType: ItemModelSubType.TwinXL, mattressSubType: ItemModelSubType.TwinXL, position: [2.4949545110489746,0,-0.5025766640831986], rotation: [0,-0.008571428571428452,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/desk-standard-newoodcraft-570-oak-03-opt1.glb', position: [1.4162991070037094,0,-1.331501586858023], rotation: [0,6.301428571428572,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/chair-upholstered-newoodcraft-570-oak-02-opt1.glb', position: [1.5805445944177674,0,-0.9147840080448688], rotation: [0,3.0742857142857143,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/dresser-standard-newoodcraft-570-oak-03-opt1.glb', position: [0.5358087525016137,0,1.4604351044877397], rotation: [0,4.681428571428571,0]});
      room.addDefaultBedItem({bedSubType: ItemModelSubType.TwinXL, mattressSubType: ItemModelSubType.TwinXL, position: [-0.30359927587077135,0,-1.1921110288253165], rotation: [0,-1.5685714285714285,0]});
      const desk = room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/desk-standard-newoodcraft-570-oak-03-opt1.glb', position: [-0.8240606842151413,0,0.319670840033033], rotation: [0,3.1514285714285717,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/chair-upholstered-newoodcraft-570-oak-02-opt1.glb', position: [-1.0024660385307604,0,0.04563109896162887], rotation: [0,-0.03571428571428559,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/dresser-standard-newoodcraft-570-oak-03-opt1.glb', position: [-0.773403130003798,0,1.3193992396766383], rotation: [0,1.5742857142857145,0]});
      const floorshelf = room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-floor-demo1-00.glb', position: [1.483450172489792,0.030000000000000915,1.6830068916325864], rotation: [0,0,0]});
      room.addItem({surfaceType: 'Floor', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-floor-demo2-00.glb', position: [-2.6971894025273033,0.05999999999999961,1.5118800151335707], rotation: [0,3.11,0]});
      room.addItem({surfaceType: 'Wall', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/wallhanging-poster-pinkmaze-01.glb', position: [2.9774090373231257,1.509177266456784,-0.573613505399283], rotation: [0,-1.5692891700241183,0]});
      room.addItem({surfaceType: 'Wall', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/wallhanging-framedposter-demo1-00.glb', position: [0.7074266211945925,1.6536644979410235,0.6534011115301238], rotation: [0,3.1402182672370667,0]});
      room.addItem({surfaceType: 'Wall', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-wall-demo1-00.glb', position: [-2.9670848472446427,1.472603629764559,0.03435803036452807], rotation: [0,1.5700818997211106,0]});
      const wallshelf = room.addItem({surfaceType: 'Wall', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-wall-demo2-00.glb', position: [-1.8666819012899412,1.6389727130907517,1.8606619197617176], rotation: [0,3.14097114069111,0]});
      room.addItem({surfaceType: 'Platform', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/lights-desk-dutchbone-eclipse-01.glb', parentItemId: desk.itemId, position: [-0.3025916814804077,0.7656568288803101,0.03919310122728348], rotation: [0,0.7817567080871799,0]});
      room.addItem({surfaceType: 'Platform', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-plant-demo1-00.glb', parentItemId: wallshelf.itemId, position: [0.15396679937839508,-0.1180802583694458,0.14384785294532776], rotation: [0,0,0]});
      const tray = room.addItem({surfaceType: 'Platform', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-tray-demo1-00.glb', parentItemId: floorshelf.itemId, position: [0.08875110745429993,0.8604999780654907,0.015096098184585571], rotation: [0,0,0]});
      room.addItem({surfaceType: 'Platform', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-teapot-demo1-01.glb', parentItemId: tray.itemId, position: [0.07634223997592926,0.04238098859786987,-0.09131824970245361], rotation: [0,0,0]});
      room.addItem({surfaceType: 'Platform', assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-teacup-demo1-01.glb', parentItemId: tray.itemId, position: [-0.06253480166196823,0.029999971389770508,0.011437284760177135], rotation: [0,0,0]});
    } else if (selectedTestRoomValue === 'SlopedCeiling') {
      roomScene.addRoom({title: selectedTestRoom, assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/rooms/colgate-110broadstreet-304-00.glb'}, true);
    } else if (selectedTestRoomValue === 'AngledWalls') {
      roomScene.addRoom({title: selectedTestRoom, assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/rooms/colby-east-124-01.glb'}, true);
    } else if (selectedTestRoomValue === 'StandardDouble') {
      roomScene.addRoom({title: selectedTestRoom, assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/rooms/seattleu-campion-201-01.glb'}, true);
    } else if (selectedTestRoomValue === 'Platform') {
      roomScene.addRoom({title: selectedTestRoom, assetUrl: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/roomiepm_room.glb'}, true);
    }

    resetFocus();
  };

  const onSaveRoomLayout = (): void => {
    const room = roomScene?.selectedRoom;
    if (room) {
      const props = room.getProperties();
      const filename = convertToFilename(room.title, 'json');
      const json = JSON.stringify(props);
      // don't save if there are blobs (uploaded models)
      if (json.indexOf('blob:') < 0) downloadStringAsFile(json, filename);
      else console.log('cannot save room layout %s because it contains blobs', filename);
    }
  };

  const onLoadRoomLayout = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log('loading room layout: ' + files[i].name);
        const fileContents = await readUploadedFile(files[i]);
        const fileString = fileContents as string;
        //console.log(fileString);
        const props = JSON.parse(fileString);
        //console.log(props);
        roomScene?.addRoom(props, true);
      }
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';

    resetFocus();
  };

  const onUploadItem = (evt: ChangeEvent<HTMLInputElement>): void => {
    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log('adding uploaded item: ' + files[i].name);
        const url = URL.createObjectURL(files[i]);
        const name = files[i].name;
        // attempt to auto detect type/subtype from filename
        const type = ItemModel.findTypeFromFilename(name);
        let subtype;
        if (type) {
          subtype = ItemModel.findSubTypeFromFilename(name);
          if (!subtype) subtype = ItemModelSubType.Standard;
        }
        roomScene?.selectedRoom?.addItem(
          {
            title: name,
            catalogId: '0',
            type: type,
            subType: subtype,
            assetUrl: url,
            place: true,
            deletable: true,
          },
          true,
        );
      }
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';

    resetFocus();
  };

  const TEST_ITEMS = [
    {
      label: 'Desk',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/desk-standard-newoodcraft-570-oak-03-opt1.glb',
    },
    {
      label: 'Chair',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/chair-standard-newoodcraft-570-oak-03-opt1.glb',
    },
    {
      label: 'Chair Upholstered',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/chair-upholstered-newoodcraft-570-oak-02-opt1.glb',
    },
    {
      label: 'Dresser',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/dresser-standard-newoodcraft-570-oak-03-opt1.glb',
    },
    {
      label: 'Dresser Small',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/dresser-small-newoodcraft-570-oak-03-opt1.glb',
    },
    {
      label: 'Dresser Standup',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/common/dresser-standup-newoodcraft-570-oak-03-opt1.glb',
    },
    {
      label: 'Bed - TwinXL',
      value: 'BedTwinXL',
    },
    {
      label: 'Bed - TwinXLLoft',
      value: 'BedTwinXLLoft',
    },
    {
      label: 'Bed - TwinXLBunk',
      value: 'BedTwinXLBunk',
    },
    {
      label: 'Bed - FullXL',
      value: 'BedFullXL',
    },
    {
      label: 'Bed - Queen',
      value: 'BedQueen',
    },
    {
      label: '2 Seater Lounge',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/unh-lounge-2seater-grey-custom-00.glb',
    },
    {
      label: '3 Seater Lounge',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/unh-lounge-3seater-blue-custom-00.glb',
    },
    {
      label: 'Wardrobe',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/wakeforest-wardrobe-2door-2drawer-large-oak-00.glb',
    },
    {
      label: 'Bookcase',
      value: 'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-floor-demo1-00.glb',
    },
    {
      label: 'Corner Bookcase',
      value: 'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-floor-demo2-00.glb',
    },
    {
      label: 'Painting',
      value:
        'Wall|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/wallhanging-framedposter-demo1-00.glb',
    },
    {
      label: 'Poster',
      value:
        'Wall|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/wallhanging-poster-pinkmaze-01.glb',
    },
    {
      label: 'Tall Shelf',
      value: 'Wall|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-wall-demo1-00.glb',
    },
    {
      label: 'Small Shelf',
      value: 'Wall|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/shelf-wall-demo2-00.glb',
    },
    {
      label: 'Lamp',
      value:
        'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/lights-desk-dutchbone-eclipse-01.glb',
    },
    {
      label: 'Plant',
      value: 'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-plant-demo1-00.glb',
    },
    {
      label: 'Tray',
      value: 'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-tray-demo1-00.glb',
    },
    {
      label: 'Teapot',
      value: 'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-teapot-demo1-01.glb',
    },
    {
      label: 'Teacup',
      value: 'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/decor-teacup-demo1-01.glb',
    },
    {
      label: 'Storage Box',
      value:
        'Platform|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/underbed-storagebox-00-opt1.glb',
    },
    {
      label: 'Storage Cart',
      value: 'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ikea-shelf-00-opt1.glb',
    },
    {
      label: 'Rug - 2x3',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-02x03-00.glb',
    },
    {
      label: 'Rug - 2x7',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-02x07-00.glb',
    },
    {
      label: 'Rug - 2x10',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-02x10-00.glb',
    },
    {
      label: 'Rug - 3x5',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-03x05-00.glb',
    },
    {
      label: 'Rug - 6x9',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-06x09-00.glb',
    },
    {
      label: 'Rug - Round 6',
      value:
        'Floor|https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/items/ruggable-absida-rainbow-ma004-00x06-00.glb',
    },
  ];
  const [testItem, setTestItem] = useState(TEST_ITEMS[0].label);
  const [selectedTestItem, setSelectedTestItem] = useState(TEST_ITEMS[0].label);
  const [selectedTestItemValue, setSelectedTestItemValue] = useState(TEST_ITEMS[0].value);

  const onTestItemChange = (value: string, label: string): void => {
    setTestItem(label);
    setSelectedTestItem(label);
    setSelectedTestItemValue(value);
    //console.log(label + ' -> ' + value);
  };

  const onTestItemAdd = (): void => {
    console.log('adding test item: ' + selectedTestItem + ' (' + selectedTestItemValue + ')');
    if (selectedTestItemValue === 'BedTwinXL') {
      const item = roomScene?.selectedRoom?.addDefaultBedItem({
        bedSubType: ItemModelSubType.TwinXL,
        mattressSubType: ItemModelSubType.TwinXL,
        place: true,
      });
      roomScene?.selectedRoom?.setSelectedItem(item);
    } else if (selectedTestItemValue === 'BedTwinXLLoft') {
      const item = roomScene?.selectedRoom?.addDefaultBedItem({
        bedSubType: ItemModelSubType.TwinXLLoft,
        mattressSubType: ItemModelSubType.TwinXL,
        place: true,
      });
      roomScene?.selectedRoom?.setSelectedItem(item);
    } else if (selectedTestItemValue === 'BedTwinXLBunk') {
      const item = roomScene?.selectedRoom?.addDefaultBedItem({
        bedSubType: ItemModelSubType.TwinXLBunk,
        mattressSubType: ItemModelSubType.TwinXL,
        place: true,
      });
      roomScene?.selectedRoom?.setSelectedItem(item);
    } else if (selectedTestItemValue === 'BedFullXL') {
      const item = roomScene?.selectedRoom?.addDefaultBedItem({
        bedSubType: ItemModelSubType.FullXL,
        mattressSubType: ItemModelSubType.FullXL,
        place: true,
      });
      roomScene?.selectedRoom?.setSelectedItem(item);
    } else if (selectedTestItemValue === 'BedQueen') {
      const item = roomScene?.selectedRoom?.addDefaultBedItem({
        bedSubType: ItemModelSubType.Queen,
        mattressSubType: ItemModelSubType.Queen,
        place: true,
      });
      roomScene?.selectedRoom?.setSelectedItem(item);
    } else if (selectedTestItemValue.endsWith('.glb')) {
      const s = selectedTestItemValue.split('|');
      roomScene?.selectedRoom?.addItem(
        {
          catalogId: '0',
          surfaceType: s[0],
          assetUrl: s[1],
          place: true,
          deletable: true,
        },
        true,
      );
    }

    resetFocus();
  };

  const onSaveItemLayout = (): void => {
    const room = roomScene?.selectedRoom;
    if (room) {
      const item = room.selectedItem;
      let props;
      let filename;
      if (item) {
        props = item.getPropertiesWithDescendants();
        filename = convertToFilename(item.title, 'json', '-items');
      } else {
        props = room.getItemProperties();
        filename = convertToFilename(room.title, 'json', '-items');
      }
      const json = JSON.stringify(props);
      // don't save if there are blobs (uploaded models)
      if (json.indexOf('blob:') < 0) downloadStringAsFile(json, filename);
      else console.log('cannot save item layout %s because it contains blobs', filename);
    }
  };

  const onLoadItemLayout = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log('loading item layout: ' + files[i].name);
        const fileContents = await readUploadedFile(files[i]);
        const fileString = fileContents as string;
        //console.log(fileString);
        const props: ItemModelProps[] = JSON.parse(fileString) as ItemModelProps[];
        //console.log(props);
        roomScene?.selectedRoom?.setItems(props);
      }
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';

    resetFocus();
  };

  const onLoadItemLayoutAdditive = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        console.log('loading item layout additive: ' + files[i].name);
        const fileContents = await readUploadedFile(files[i]);
        const fileString = fileContents as string;
        //console.log(fileString);
        const props = JSON.parse(fileString);
        //console.log(props);
        roomScene?.selectedRoom?.setItems(props, true); // set additive
      }
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';

    resetFocus();
  };

  const resetFocus = (): void => {
    // defocus any button so hitting space to switch rooms doesn't click the button again (we really just should get rid of the space to switch rooms)
    roomScene?.canvas.focus();
  };

  const [distanceFormat, setDistanceFormat] = useState<DistanceFormat>(DistanceFormat.Feet);

  const onToggleDistanceFormat = (): void => {
    const existingDistanceFormat = roomScene?.distanceFormat;
    if (!existingDistanceFormat) return;
    let newDistanceFormat = existingDistanceFormat;
    if (existingDistanceFormat === DistanceFormat.Meters) newDistanceFormat = DistanceFormat.Feet;
    else if (existingDistanceFormat === DistanceFormat.Feet) newDistanceFormat = DistanceFormat.Meters;
    setDistanceFormat(newDistanceFormat);
    roomScene?.setDistanceFormat(newDistanceFormat);
  };

  const ASSET_TEXTURE = getEnumLabelValuePairs(AssetTexture);
  const [assetTexture, setAssetTexture] = useState(ASSET_TEXTURE[0].label);

  const onAssetTextureChange = (value: string, label: string): void => {
    setAssetTexture(AssetTexture[label as keyof typeof AssetTexture]);
    roomScene?.setAssetTexture(label as AssetTexture);
    //console.log(label + ' -> ' + value);
  };

  useEffect(() => {
    updateHotspotList();
  }, [selectedRoom]);

  useEffect(() => {
    setHotspotListIndex(selectedHotspot?.name ?? ''); // USES LABEL TO MAKE SELECT COMPONENT WORK
  }, [selectedHotspot]);

  const classes = UploadButtonStyles();
  return (
    <div className={classes.LeftToolColumn}>
      <div className={classes.LeftToolRow}>
        <CustomSelect
          options={ASSET_TEXTURE}
          value={assetTexture}
          setValue={(val, label) => onAssetTextureChange(val, label)}
          width="175px"
          height="50px"
          heightExpanded="140px"
          margin="0px 10px 0px 0px"
          textMargin="8px 0px 0px 20px"
          padding="0"
        />
        <CustomButton
          title={'Lights'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.selectedRoom?.toggleLights()}
        />
        <CustomButton
          title={'Post'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.togglePost()}
        />
        <CustomButton
          title={'Skybox'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleSkybox()}
        />
        <CustomButton
          title={'Color Spheres'}
          width="150px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleColorSpheres()}
        />
        <CustomButton
          title={'(D)ebug Inspector'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => {
            roomScene?.toggleDebug();
          }}
        />
      </div>
      <br />
      <div className={classes.LeftToolRow}>
        <CustomUploadButton
          title={'Upload Rooms'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          uploadType=".glb"
          onUpload={(event: ChangeEvent<HTMLInputElement>) => onUploadRoom(event)}
        />
        <CustomSelect
          options={TEST_ROOMS}
          value={testRoom}
          setValue={(val, label) => onTestRoomChange(val, label)}
          width="250px"
          height="50px"
          heightExpanded="275px"
          margin="0px 10px 0px 0px"
          textMargin="8px 0px 0px 20px"
          padding="0"
        />
        <CustomButton
          title={'Add Test Room'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={onTestRoomAdd}
        />
        <CustomUploadButton
          title={'Load Room Layout'}
          width="125px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          uploadType=".json"
          onUpload={(event: ChangeEvent<HTMLInputElement>) => onLoadRoomLayout(event)}
        />
        <CustomButton
          title={'Clear Rooms'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.clearRooms()}
        />
      </div>
      <div className={classes.LeftToolRow}>
        <CustomUploadButton
          title={'Upload Items'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          uploadType=".glb"
          onUpload={(event: ChangeEvent<HTMLInputElement>) => onUploadItem(event)}
        />
        <CustomSelect
          options={TEST_ITEMS}
          value={testItem}
          setValue={(val, label) => onTestItemChange(val, label)}
          width="250px"
          height="50px"
          heightExpanded="275px"
          margin="0px 10px 0px 0px"
          textMargin="8px 0px 0px 20px"
          padding="0"
        />
        <CustomButton
          title={'Add Test Item'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={onTestItemAdd}
        />
        <CustomUploadButton
          title={'Load Item Layout'}
          width="125px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          uploadType=".json"
          onUpload={(event: ChangeEvent<HTMLInputElement>) => onLoadItemLayout(event)}
        />
        <CustomUploadButton
          title={'Load Item Layout Additive'}
          width="150px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          uploadType=".json"
          onUpload={(event: ChangeEvent<HTMLInputElement>) => onLoadItemLayoutAdditive(event)}
        />
        <CustomButton
          title={'Clear Items'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.selectedRoom?.clearItems()}
        />
      </div>
      <br />
      <div className={classes.LeftToolRow}>
        <CustomButton
          title={'Previous Room'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleSelectedRoom(false)}
        />
        <CustomButton
          title={'(_) Next Room'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleSelectedRoom(true)}
        />
        <CustomButton
          title={'Save Room Layout'}
          width="125px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={onSaveRoomLayout}
        />
        <CustomButton
          title={'Save Item Layout'}
          width="125px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={onSaveItemLayout}
        />
        <CustomButton
          title={'Room (P)roperties'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.selectedRoom?.logProperties()}
        />
        <CustomButton
          title={'Show/Hide Measurements'}
          width="120px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.selectedRoom?.toggleWallMeasurementsVisualization()}
        />
        <CustomButton
          title={'Show/Hide (C)olliders'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.selectedRoom?.toggleCollidersVisible()}
        />
        <CustomButton
          title={'Delete Room'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => {
            roomScene?.selectedRoom?.remove();
            roomScene?.toggleSelectedRoom();
          }}
        />
      </div>
      <div className={classes.LeftToolRow}>
        <CustomButton
          title={roomScene?.getViewMode() + ' (V)iew'}
          width="150px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleViewMode()}
        />
        <CustomSelect
          options={hotspotList}
          value={hotspotListIndex}
          setValue={(val, label) => onHotspotIndexChange(val, label)}
          width="200px"
          height="50px"
          heightExpanded="250px"
          margin="0px 10px 0px 0px"
          textMargin="8px 0px 0px 20px"
          padding="0"
        />
        <CustomButton
          title={'Previous Hotspot'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleViewHotspot(false)}
        />
        <CustomButton
          title={'Next Hotspot'}
          width="100px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => roomScene?.toggleViewHotspot(true)}
        />
        <CustomButton
          title={'Display ' + distanceFormat.toString()}
          width="120px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => onToggleDistanceFormat()}
        />
        <CustomButton
          title={'Show/Hide Item Buttons'}
          width="130px"
          mTop="0"
          mBottom="10px"
          mLeft="0"
          mRight="10px"
          buttonClicked={() => toggleBottomBar()}
        />
      </div>
    </div>
  );
};

const UploadButtonStyles = makeStyles(() => ({
  LeftToolColumn: {
    position: 'absolute',
    top: '10px',
    left: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
  },
  LeftToolRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
}));

export default ButtonBarLeft;
