const SuccessIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.8827 6.77271H13.7835C13.5445 6.77271 13.3171 6.88755 13.1765 7.08442L9.49212 12.1938L7.82337 9.87817C7.68274 9.68364 7.45774 9.56646 7.21634 9.56646H6.11712C5.96477 9.56646 5.87571 9.73989 5.96477 9.86411L8.88509 13.9141C8.95408 14.0104 9.04502 14.0889 9.15038 14.143C9.25575 14.1971 9.37249 14.2253 9.49095 14.2253C9.6094 14.2253 9.72615 14.1971 9.83151 14.143C9.93687 14.0889 10.0278 14.0104 10.0968 13.9141L15.0327 7.07036C15.1242 6.94614 15.0351 6.77271 14.8827 6.77271Z"
      fill="#52C41A"
    />
    <path
      d="M10.5 0C4.70156 0 0 4.70156 0 10.5C0 16.2984 4.70156 21 10.5 21C16.2984 21 21 16.2984 21 10.5C21 4.70156 16.2984 0 10.5 0ZM10.5 19.2188C5.68594 19.2188 1.78125 15.3141 1.78125 10.5C1.78125 5.68594 5.68594 1.78125 10.5 1.78125C15.3141 1.78125 19.2188 5.68594 19.2188 10.5C19.2188 15.3141 15.3141 19.2188 10.5 19.2188Z"
      fill="#52C41A"
    />
  </svg>
);

export default SuccessIcon;
