import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthProvider from 'context/authProvider';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  TermsAndPolicy,
  Template,
  Home,
  UploadPage,
  StudentsAssignments,
  RoomLinks,
  TemplateLinks,
  RoomViewerContainer,
  CatalogsUniversityAssociation,
  SearchCatalog,
} from './pages';
import { LiveChatContainer } from './containers';
import Routes from './routes';
import ProtectedRoute from './routes/protectedRoute';
import Colors from './theme';
import SideNavBar from 'components/roomViewerComponents/sidebar';
import useAxios, { ServerRoutes } from 'api';
import { POST } from 'utils';
import { CircularProgress, Snackbar } from 'components';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import PhotoViewerContainer from 'components/PhotoViewerContainer';
import LibraryCatalog from 'pages/libraryCatalog';
import RoomStyles from 'pages/RoomStyles';
import Products from 'pages/Products';
import CreateStyle from 'pages/CreateStyle';
import StylesDashboard from 'pages/StylesDashboard';
import Checklist from 'pages/Checklist';

const App = (): JSX.Element => {
  const [isError, setIsError] = useState(false);

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setIsError(true);
      }
    };
    showError(logoutError);
  }, [logoutError]);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <LiveChatContainer>
          <Router>
            <Snackbar isOpen={isError} setIsOpen={setIsError} message={logoutError?.message || ''} severity="error" />
            <CircularProgress isLoading={logoutLoading} />
            <SideNavBar logout={logout} />
            <Switch>
              <Route path="/test-route">
                <div style={{ fontSize: 64 }}>Test Test Test Test Test</div>
              </Route>
              <ProtectedRoute path={Routes.STUDENTS_ASSIGNMENTS}>
                <StudentsAssignments />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.ROOM_VIEWER}>
                <RoomViewerContainer />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.PHOTO_VIEWER}>
                <PhotoViewerContainer />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.TERMS_AND_POLICY}>
                <TermsAndPolicy />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.TEMPLATE}>
                <Template />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.UPLOAD_CATALOG}>
                <UploadPage />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.UPLOAD_ROOM}>
                <UploadPage isRoom />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.UNIVERSITY_FURNITURE}>
                <LibraryCatalog />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.CATALOGS_UNIVERSITY_ASSOCIATION}>
                <CatalogsUniversityAssociation />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.SEARCH_CATALOG}>
                <SearchCatalog />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.ROOM_LINKS}>
                <RoomLinks />
              </ProtectedRoute>
              <ProtectedRoute path={Routes.TEMPLATE_LINKS}>
                <TemplateLinks />
              </ProtectedRoute>
              <Route path={Routes.CHECKLIST}>
                <Checklist />
              </Route>
              <Route path={Routes.PRODUCTS}>
                <Products />
              </Route>
              <Route path={Routes.STYLES_DASHBOARD}>
                <StylesDashboard />
              </Route>
              <Route path={Routes.CREATE_STYLE}>
                <CreateStyle />
              </Route>
              <Route path={Routes.ROOM_STYLES}>
                <RoomStyles />
              </Route>
              <Route path={Routes.ROOM_ASSIGNMENTS}>
                <Home index={1} />
              </Route>
              <Route path={Routes.ROOM_SETUP}>
                <Home index={2} />
              </Route>
              <Route path={Routes.DEFAULT}>
                <Home index={0} />
              </Route>
            </Switch>
          </Router>
        </LiveChatContainer>
      </AuthProvider>
    </ThemeProvider>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.hilighted_text,
    },
    secondary: {
      main: '#11cb5f',
    },
    success: {
      main: Colors.success_green,
    },
  },
});

export default App;
