import { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Routes from '../routes';
import {
  CircularProgress,
  Snackbar,
  ToolBar,
  UniversitySelect,
  BuildingsList,
  CustomButton,
  InfoBox,
  AddBuildingModal,
  UpdateStatusModal,
  AddTemplateModal,
  MoveRoomModal,
  AddRoomModal,
  Row,
  UpdateConfigNameModal,
  InputFieldWithLabel,
} from 'components';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { AuthContext } from '../context/authContext';
import {
  GuideView,
  Room,
  RoomConfig,
  SignInResponse,
  TemplateInfo,
  UniversityBuilding,
  BuildingsAttributes,
} from 'types';
import { uniqBy } from 'lodash-es';
import {
  getAuthTokens,
  saveAuthTokens,
  saveRoomConfig,
  saveTemplatesData,
  saveUniversityId,
} from '../context/localStorage';
import useAxios, { ServerRoutes } from 'api';
import Colors from 'theme';
import { AxiosError } from 'axios';
import { POST, SUCCESS, getOrigin, GUIDE_VIEW_HEADERS } from 'utils';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableHead from '@material-ui/core/TableHead';
import { IconButton, TableFooter } from '@material-ui/core';

interface StyleProps {
  loading: boolean;
}

interface Props {
  index: number;
}

const HomeContainer = ({ index }: Props): JSX.Element => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [universityId, setUniversityId] = useState('');
  const [universityName, setUniversityName] = useState('');
  const [buildingConfigs, setBuildingConfigs] = useState<RoomConfig[]>([]);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [isAddingTemplate, setIsAddingTemplate] = useState(false);
  const [allConfigs, setAllConfigs] = useState<GuideView[]>();
  const [isAddingBuilding, setIsAddingBuilding] = useState(false);
  const [configToUpdateStatus, setConfigToUpdateStatus] = useState<RoomConfig>();
  const [selectedBuilding, setSelectedBuilding] = useState<UniversityBuilding>();
  const [downloadReady, setDownloadReady] = useState(false);
  const [roomToMove, setRoomToMove] = useState<Room>();
  const [configToAddRoom, setConfigToAddRoom] = useState<RoomConfig>();
  const [configToUpdateName, setConfigToUpdateName] = useState<RoomConfig>();
  const [searchedValue, setSearchedValue] = useState('');
  const [configName, setConfigName] = useState('');
  const [isSearchError, setIsSearchError] = useState(false);
  const [roomNumbers, setRoomNumbers] = useState<Room[]>([]);
  const [selectedConfig, setSelectedConfig] = useState('');
  const [status, setStatus] = useState('Active');
  const [searchedConfigId, setSearchedConfigId] = useState('');
  const [buildingsAttributes, setBuildingsAttributes] = useState<BuildingsAttributes[]>([]);

  const csvLink = useRef<any>(null);

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  const {
    callApi: getGuideViewData,
    error: guideViewError,
    loading: guideViewLoading,
    response: guideViewRes,
  } = useAxios(ServerRoutes.guideViewData, POST, false);

  const {
    callApi: getBuildings,
    error: buildingsError,
    loading: buildingsLoading,
    response: buildingsRes,
  } = useAxios(ServerRoutes.adminUniversityBuildings, POST, false);

  const {
    callApi: updateRoomStatus,
    loading: updateStatusLoading,
    error: updateStatusError,
  } = useAxios(ServerRoutes.adminUpdateStatus, POST, false);

  const {
    callApi: updateConfigName,
    loading: updateConfigNameLoading,
    error: updateConfigNameError,
  } = useAxios(ServerRoutes.updateConfigName, POST, false);

  const { callApi: updateBuildingName, loading: updateBuildingNameLoading } = useAxios(
    ServerRoutes.updateBuildingName,
    POST,
    false,
  );

  const {
    callApi: addTemplate,
    loading: addTemplateLoading,
    error: addTemplateError,
  } = useAxios(ServerRoutes.addTemplate, POST, false);

  const {
    callApi: addRoom,
    error: addRoomError,
    loading: addRoomLoading,
  } = useAxios(ServerRoutes.addRoom, POST, false);

  const {
    callApi: moveRoom,
    error: moveRoomError,
    loading: moveRoomLoading,
  } = useAxios(ServerRoutes.moveRoom, POST, false);

  const { callApi: searchRoom, loading: searchRoomLoading } = useAxios(ServerRoutes.searchRoom, POST, false);
  const { callApi: getBuildingsAttributes, loading: attributesLoading } = useAxios(
    ServerRoutes.getBuildingsAttributes,
    POST,
    false,
  );

  const {
    callApi: addBuilding,
    error: addBuildingError,
    loading: addBuildingLoading,
  } = useAxios(ServerRoutes.addBuilding, POST, false);

  const {
    callApi: getAdminInfo,
    error: infoError,
    loading: infoLoading,
    response: adminData,
  } = useAxios(ServerRoutes.getAdminInfo, POST, false);

  const {
    response: configsResponse,
    callApi: getConfigsByBuilding,
    loading: configsLoading,
  } = useAxios(ServerRoutes.configsByBuilding, POST, false);

  const { callApi: loadRooms, loading: roomsLoading } = useAxios(ServerRoutes.loadRooms, POST, false);

  const classes = useStyles({ loading: configsLoading || buildingConfigs.length < 1 });

  useEffect(() => {
    let adminRes: SignInResponse | undefined;
    const fetchConfigs = async (id: string) => {
      saveUniversityId(id);
      await loadBuildings(id);
    };
    (async () => {
      if (!getAuthTokens().roomieAccessToken && !token) goToLoginPage();
      else if (token) {
        await saveAuthTokens({
          roomieAccessToken: token || getAuthTokens().roomieAccessToken,
          roomieRefreshToken: getAuthTokens().roomieRefreshToken || '',
        });
        await getAdminInfo({}, (res) => {
          if (res?.data?.reason === SUCCESS) {
            adminRes = res?.data;
            authContext?.login('', res?.data);
          }
        });
      }
      setTimeout(async () => {
        if (
          (authContext?.roomInfo.admin && !authContext?.roomInfo?.isRoomieModeler) ||
          (adminRes && adminRes?.admin && !adminRes?.isRoomieModeler)
        ) {
          if (
            (!authContext?.roomInfo.isRoomieEmployee && !authContext?.roomInfo?.isRoomieModeler && !adminRes) ||
            (!adminRes?.isRoomieEmployee && adminRes && !adminRes?.isRoomieModeler)
          ) {
            const id = adminRes ? adminRes?.orgId : authContext?.roomInfo?.orgId;
            setUniversityId(id || '');
            fetchConfigs(id || '');
          }
        } else if (!adminRes && !authContext?.roomInfo.admin) goToLoginPage();
      }, 100);
    })();
  }, []);

  useEffect(() => {
    if (csvLink && csvLink.current && downloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [downloadReady]);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setIsError(true);
        setMessage(error.message);
      }
    };
    showError(updateStatusError);
    showError(buildingsError);
    showError(addBuildingError);
    showError(logoutError);
    showError(infoError);
    showError(addRoomError);
    showError(guideViewError);
    showError(addTemplateError);
    showError(moveRoomError);
    showError(updateConfigNameError);
  }, [
    infoError,
    logoutError,
    guideViewError,
    addBuildingError,
    updateStatusError,
    buildingsError,
    addTemplateError,
    addRoomError,
    moveRoomError,
    updateConfigNameError,
  ]);

  const goToLoginPage = () => {
    const origin = getOrigin();
    window.open(origin, '_self');
  };

  const searchRoomNumber = async (event: React.FormEvent) => {
    event.preventDefault();
    await searchRoom(
      { universityId, buildingId: selectedBuilding?.roomie_university_building_id, roomNumber: searchedValue },
      async (res) => {
        if (res?.data?.rooms && res?.data?.rooms[0]) {
          const id = res?.data?.rooms[0].roomie_room_config_id;
          await loadConfigRooms(id);
          setSearchedConfigId(id);
        } else setIsSearchError(true);
      },
    );
  };

  const loadConfigRooms = async (configId: string) => {
    setRoomNumbers([]);
    setSelectedConfig(configId);
    setRoomNumbers([]);
    if (configId) {
      await loadRooms(
        {
          building_id: selectedBuilding?.roomie_university_building_id,
          university_id: universityId,
          roomie_room_config_id: configId,
        },
        (res) => {
          if (res?.data) {
            setRoomNumbers(res?.data?.rooms);
          }
        },
      );
    } else {
      setSelectedConfig('');
    }
  };

  const updateStatus = async (status: string) => {
    await updateRoomStatus({ status, configId: configToUpdateStatus?.id });
    const temp = buildingConfigs;
    temp.forEach((item) => {
      if (item.id === configToUpdateStatus?.id) item.statusComments = status;
    });
    setBuildingConfigs(temp);
    setConfigToUpdateStatus(undefined);
  };

  const onUpdateConfigName = async (configName: string) => {
    await updateConfigName({ roomConfigName: configName, configId: configToUpdateName?.id });
    const temp = buildingConfigs;
    temp.forEach((item) => {
      if (item?.id === configToUpdateName?.id) item.roomieConfigName = configName;
    });
    setBuildingConfigs(temp);
    setConfigToUpdateName(undefined);
  };

  const onUniversityChange = async (id: string) => {
    setAllConfigs(undefined);
    saveUniversityId(id);
    setUniversityId(id);
    await loadBuildings(id);
  };

  const loadBuildingsAttributes = async (id: string) => {
    await getBuildingsAttributes({ university_id: id }, (res) => {
      if (res) {
        setBuildingsAttributes(res?.data?.buildingAttributes);
      }
    });
  };

  const loadBuildings = async (university: string, isAdding = false) => {
    setSearchedConfigId('');
    setSearchedValue('');
    await getBuildings({ universityId: university }, async (res) => {
      if (res) {
        if (res?.data?.universityBuildings.length > 0) {
          const building = res?.data?.universityBuildings[0];
          isEmployee() && (await loadBuildingsAttributes(university));
          if (!isAdding) {
            setSelectedBuilding(res?.data?.universityBuildings[0]);
            onLoadConfigs(building);
          }
        } else {
          if (!isAdding) {
            setBuildingConfigs([]);
            setSelectedBuilding(undefined);
          }
        }
      }
    });
  };

  const onLoadConfigs = async (building: UniversityBuilding) => {
    await getConfigsByBuilding({ building_id: building.roomie_university_building_id }, (res) => {
      if (res) {
        setBuildingConfigs(res?.data?.roomConfigs);
      }
    });
  };

  const getUniversityData = async (id: string) => {
    let sortedData: GuideView[] = [];
    await getGuideViewData({ university_id: id }, (res) => {
      if (res.data.configs) {
        const data = res.data.configs;
        sortedData = data.sort((a: GuideView, b: GuideView) => {
          return (a.type.toLowerCase() + a.roomNumber.toLowerCase()).localeCompare(
            b.type.toLowerCase() + b.roomNumber.toLowerCase(),
            undefined,
            {
              numeric: true,
              sensitivity: 'base',
            },
          );
        });
        setAllConfigs(sortedData);
      }
    });
    return sortedData;
  };

  const onViewTemplate = async (config: RoomConfig) => {
    let data = allConfigs;
    if (!data) {
      data = await getUniversityData(universityId);
    }
    const sortedData = data.sort((a, b) => {
      return (a.buildingName.toLowerCase() + a.type.toLowerCase()).localeCompare(
        b.buildingName.toLowerCase() + b.type.toLowerCase(),
        undefined,
        {
          numeric: true,
          sensitivity: 'base',
        },
      );
    });
    const uniqueData = uniqBy(sortedData, (item) => item.type);
    const rConfig = uniqueData.find((item) => item?.roomieRoomConfigId === config?.id);
    saveTemplatesData(uniqueData);
    if (rConfig) {
      saveRoomConfig(rConfig);
      window.open(`${window.location.origin}${Routes.TEMPLATE}`, '_blank');
    }
  };

  const onDownload = async () => {
    await getUniversityData(universityId);
    setDownloadReady(true);
  };

  const onAddBuilding = async (building: string) => {
    const value = building.trim();
    await addBuilding({ universityId, buildingName: value }, async (res) => {
      if (res.data.code === 1) {
        setIsError(true);
        setMessage(res.data.reason);
      } else {
        await loadBuildings(universityId, true);
        setIsAddingBuilding(false);
      }
    });
  };

  const onAddTemplate = async (template: TemplateInfo) => {
    await addTemplate(
      {
        universityId,
        buildingId: selectedBuilding?.roomie_university_building_id,
        friendlyName: template.friendlyName,
        roomDescriptionBlurb: template.roomDescription,
        isSecondary: template.isSecondary,
        roomNumber: template.roomNumber,
        roomConfigName: template.configName,
        type: template.type,
      },
      async (res) => {
        if (res.data.code === 1) {
          setIsError(true);
          setMessage(res.data.reason);
        } else if (selectedBuilding) {
          await onLoadConfigs(selectedBuilding);
          setIsAddingTemplate(false);
        }
      },
    );
  };

  const onMoveRoom = async (configId: string) => {
    let response;
    await moveRoom(
      {
        configId,
        roomId: roomToMove?.roomie_university_building_room_id,
      },
      async (res) => {
        response = res;
        if (res.data.code === 1) {
          setIsError(true);
          setMessage(res.data.reason);
        } else await loadConfigRooms(roomToMove?.roomie_room_config_id || '');
      },
    );
    return response;
  };

  const onAddRoom = async (roomNumber: string) => {
    let response;
    await addRoom(
      {
        universityId,
        buildingId: configToAddRoom?.roomieUniversityBuildingId,
        configId: configToAddRoom?.id,
        roomNumber,
      },
      async (res) => {
        response = res;
        if (res.data.code === 1) {
          setIsError(true);
          setMessage(res.data.reason);
        } else await loadConfigRooms(configToAddRoom?.id || '');
      },
    );
    return response;
  };

  const getData = () =>
    buildingConfigs.filter((buildingConfig) =>
      searchedConfigId
        ? buildingConfig.id === searchedConfigId && isActive(buildingConfig?.statusComments)
        : isActive(buildingConfig?.statusComments),
    );

  const isActive = (status?: string) => {
    if (isEmployee()) return true;
    return status === 'Filler' || status === 'Active' || !status;
  };

  const getText = () => {
    if (index === 0) return 'Admin Dashboard';
    if (index === 1) return 'Assignments';
  };

  const isUniversityAdmin = () =>
    authContext?.roomInfo?.admin && !authContext?.roomInfo?.isRoomieEmployee && !authContext?.roomInfo.isRoomieModeler;

  const goToAssignments = (building?: UniversityBuilding) => {
    building &&
      history.push(
        `${Routes.STUDENTS_ASSIGNMENTS}?building=${building?.roomie_university_building_id}&name=${building?.building_name}`,
      );
  };

  const getFileName = () => `${universityName}_guide_view`;

  const getRoomNumbers = (item?: GuideView) => {
    const roomNumbers: string[] = [];
    if (item) {
      allConfigs?.forEach((config) => {
        if (item.type === config.type) {
          if (!roomNumbers.includes(config.roomNumber)) {
            roomNumbers.push(config.roomNumber);
          }
        }
      });
    }
    return roomNumbers.toString().replaceAll(',', ', ');
  };

  const getCsvData = () => {
    // const arr = [...new Map(getData().map((item) => [item['type'], item])).values()];
    const uniqueData = uniqBy(allConfigs, (item) => item.type);
    const sortedData = uniqueData.sort((a, b) => {
      return (a.buildingName.toLowerCase() + a.type.toLowerCase()).localeCompare(
        b.buildingName.toLowerCase() + b.type.toLowerCase(),
        undefined,
        {
          numeric: true,
          sensitivity: 'base',
        },
      );
    });
    const csvData = sortedData.map((item) => {
      return {
        type: item.type,
        friendly_name: item.friendlyName,
        building_name: item.buildingName,
        room_address: item.roomAddress,
        room_description_blurb: item.roomDescriptionBlurb,
        room_number: getRoomNumbers(item),
        is_secondary: item.isSecondary,
        roomie_model_url: item.roomieModelUrl,
        pano_room_config_url: item.panoUrl,
        room_config_name: item.roomieConfigName,
      };
    });
    return csvData;
  };

  const showStatusModal = (item: RoomConfig) => isEmployee() && setConfigToUpdateStatus(item);

  const isEmployee = () => {
    if (token) return adminData?.data?.isRoomieEmployee;
    return authContext?.roomInfo?.isRoomieEmployee;
  };

  const renderHeading = (index: number, heading: string) => {
    if (index === 0 && !isEmployee()) return 'Room Template Name';
    if (index === 1 && !isEmployee()) return '';
    return heading;
  };

  const onHandleChange = (value: string) => {
    if (!value) {
      setSearchedValue('');
      setSearchedConfigId('');
    }
    setIsSearchError(false);
    setSearchedValue(value);
  };

  const editBuildingName = async (buildingId: string, buildingName: string) => {
    await updateBuildingName({ universityId, buildingId, buildingName }, async (res) => {
      if (res?.data?.code === 0) {
        selectedBuilding && setSelectedBuilding({ ...selectedBuilding, building_name: buildingName });
        await loadBuildings(universityId, true);
      } else {
        setIsError(true);
        setMessage(res?.data?.reason);
      }
    });
  };

  const renderLibrary = () => (
    <div>
      <div className={classes.selectedBuilding}>{selectedBuilding?.building_name}</div>
      <div className={classes.input}>
        {buildingConfigs.length > 0 && (
          <form className={classes.searchForm} onSubmit={searchRoomNumber}>
            <InputFieldWithLabel
              value={searchedValue}
              onChange={(e) => onHandleChange(e.target.value)}
              placeholder="Search room"
              isError={isSearchError}
              errorMsg="Error: Room not found in this building"
            />
            <CustomButton title="Search" width="70px" height="42px" mBottom="1px" />
          </form>
        )}
      </div>
      {buildingsRes?.data?.universityBuildings.length > 0 && (
        <div className={classes.flex}>
          <BuildingsList
            isEmployee={isEmployee()}
            setIsOpen={setIsAddingBuilding}
            buildings={buildingsRes?.data?.universityBuildings}
            selectedBuilding={selectedBuilding}
            setSelectedBuilding={setSelectedBuilding}
            onBuildingChange={onLoadConfigs}
            univeristyName={universityName}
            updateBuildingName={editBuildingName}
            buildingsAttributes={buildingsAttributes}
            onChangeBuildingsAttributes={setBuildingsAttributes}
          />
          <div className={classes.tableWrapper}>
            {configsLoading ? (
              <div className={classes.loading}>
                <CircularProgress isLoading={configsLoading} coverPage={false} />
              </div>
            ) : (
              configsResponse?.data && (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {GUIDE_VIEW_HEADERS.map((heading, index) => (
                          <TableCell
                            align={index === 1 ? 'right' : 'left'}
                            key={heading}
                            className={classes.tableHead}
                            style={{ paddingRight: index === 1 ? '112px' : '0px' }}
                          >
                            {renderHeading(index, heading)}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {getData().map((row, index) => (
                        <Row
                          row={row}
                          key={index}
                          roomNumbers={roomNumbers}
                          loading={roomsLoading}
                          loadConfigRooms={loadConfigRooms}
                          setConfigToAddRoom={() => setConfigToAddRoom(row)}
                          onViewTemplate={() => onViewTemplate(row)}
                          isEmployee={isEmployee()}
                          selectedConfig={selectedConfig}
                          showStatusModal={() => {
                            showStatusModal(row);
                            setStatus(row?.statusComments || 'Active');
                          }}
                          showUpdateConfigNameModal={() => {
                            setConfigName(row?.roomieConfigName || '');
                            setConfigToUpdateName(row);
                          }}
                          onRoomToMoveChange={setRoomToMove}
                        />
                      ))}
                    </TableBody>

                    {isEmployee() && (
                      <TableFooter className={classes.stickyFooter}>
                        <TableCell colSpan={100}>
                          <div className={classes.center}>
                            <IconButton
                              aria-label="add template"
                              onClick={() => setIsAddingTemplate(true)}
                              style={{ pointerEvents: 'auto' }}
                            >
                              <AddCircleIcon color="primary" style={{ fontSize: 48 }} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableFooter>
                    )}
                  </Table>
                </>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Snackbar isOpen={isError} setIsOpen={setIsError} message={message} severity="error" />
      <CircularProgress
        isLoading={
          logoutLoading ||
          infoLoading ||
          attributesLoading ||
          updateBuildingNameLoading ||
          guideViewLoading ||
          addBuildingLoading ||
          searchRoomLoading ||
          buildingsLoading ||
          updateStatusLoading ||
          addTemplateLoading ||
          addRoomLoading ||
          moveRoomLoading ||
          updateConfigNameLoading ||
          updateBuildingNameLoading
        }
      />
      <AddRoomModal item={configToAddRoom} setItem={setConfigToAddRoom} onAddRoom={onAddRoom} />
      <MoveRoomModal
        roomToMove={roomToMove}
        onRoomToMoveChange={setRoomToMove}
        configs={buildingConfigs}
        onMoveRoom={onMoveRoom}
      />
      <UpdateStatusModal
        status={status}
        onChangeStatus={setStatus}
        item={configToUpdateStatus}
        setItem={setConfigToUpdateStatus}
        onSubmit={updateStatus}
      />
      <AddBuildingModal
        isOpen={isAddingBuilding}
        setIsOpen={setIsAddingBuilding}
        onSubmit={onAddBuilding}
        universityName={universityName}
      />
      <UpdateConfigNameModal
        configName={configName}
        onConfigNameChange={setConfigName}
        config={configToUpdateName}
        setConfig={setConfigToUpdateName}
        onSubmit={onUpdateConfigName}
      />
      <AddTemplateModal
        isOpen={isAddingTemplate}
        setIsOpen={setIsAddingTemplate}
        onSubmit={onAddTemplate}
        buildingName={selectedBuilding?.building_name || ''}
      />
      {authContext?.roomInfo?.roomieAccessToken && (
        <div className={classes.root}>
          <ToolBar
            logout={logout}
            isUniversityAdmin={isUniversityAdmin()}
            university={guideViewRes?.data?.universityName}
          />
          {isEmployee() || authContext?.roomInfo?.isRoomieModeler ? (
            <div className={classes.margin}>
              {authContext?.roomInfo?.isRoomieModeler && <div className={classes.adminText}>Dashboard</div>}
              {isEmployee() && (
                <div>
                  <div className={classes.selectContainer}>
                    <UniversitySelect
                      width="430px"
                      university={universityId}
                      onChange={onUniversityChange}
                      setUniversityName={setUniversityName}
                    />
                  </div>
                  <div className={classes.container}>
                    {configsResponse?.data && buildingsRes?.data?.universityBuildings.length > 0 && (
                      <div className={classes.wrapper}>
                        <div className={classes.buttonsContainer}>
                          <CustomButton
                            title="View Assignments"
                            height="40px"
                            width="200px"
                            mBottom="10px"
                            mTop="7px"
                            textColor={Colors.cyprus}
                            background={Colors.paleTurquoise}
                            buttonClicked={() => goToAssignments(selectedBuilding)}
                          />
                          {authContext?.roomInfo?.isRoomieEmployee && (
                            <CustomButton
                              title="Download GuideView"
                              buttonClicked={onDownload}
                              height="40px"
                              mTop="7px"
                              width="200px"
                              mLeft="30px"
                              textColor={Colors.cyprus}
                              background={Colors.paleTurquoise}
                              mBottom="10px"
                            />
                          )}
                          <CSVLink data={getCsvData()} filename={getFileName()} target="_blank" ref={csvLink} />
                        </div>
                      </div>
                    )}
                    {renderLibrary()}
                  </div>
                </div>
              )}
            </div>
          ) : (
            isUniversityAdmin() && (
              <div className={index === 2 ? classes.container : classes.adminContainer}>
                {!authContext?.roomInfo?.isRoomieEmployee && index !== 2 && (
                  <div className={classes.universityAdminText}>{getText()}</div>
                )}
                <div>
                  {index === 0 && (
                    <div className={classes.infoContainer}>
                      <InfoBox
                        header="Room Assignments"
                        info="View and Change Room Assignments Here!"
                        buttonText="View Buildings"
                        onButtonClick={() => history.push(Routes.ROOM_ASSIGNMENTS)}
                      />
                      <InfoBox
                        header="Room Setup"
                        info="View and edit a library of all room templates!"
                        buttonText="View/Setup Room"
                        onButtonClick={() => history.push(Routes.ROOM_SETUP)}
                      />
                    </div>
                  )}
                  {index === 1 && (
                    <div className={classes.grid}>
                      <Grid container spacing={3}>
                        {buildingsRes?.data?.universityBuildings?.map((item: UniversityBuilding) => (
                          <Grid item xs={12} sm={6} md={4} key={item.roomie_university_building_id}>
                            <InfoBox
                              header={item.building_name}
                              info="Students' list"
                              buttonText="View Assignments"
                              onButtonClick={() => goToAssignments(item)}
                              showIcon={false}
                              gridItem
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                  {index === 2 && <div className={classes.library}>{renderLibrary()}</div>}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default HomeContainer;

const useStyles = makeStyles((theme) => ({
  root: {
    background: Colors.plain_white,
    fontFamily: 'Wigrum',
    width: '100%',
    minHeight: '100%',
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  infoContainer: {
    display: 'flex',
    gap: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  adminContainer: {
    margin: '0px 0px 0px 7%',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
      textAlign: 'center',
    },
  },
  selectContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  tableWrapper: {
    overflow: 'scroll',
    borderRadius: (props: StyleProps) => (props.loading ? '0px' : '8px'),
    boxShadow: (props: StyleProps) => (props.loading ? 'none' : Colors.infoBoxShadow),
    width: '58%',
    height: 'fit-content',
    maxHeight: '65vh',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    '& -webkit-scrollbar': {
      width: '0',
      background: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: '20px 0px 0px 10%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '0px',
    },
  },
  margin: {
    marginTop: '20px',
  },
  library: {
    margin: '80px 0px 0px',
  },
  flex: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    marginTop: '15px',
    paddingBottom: '30px',
    marginLeft: '60px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginLeft: '0px',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  adminText: {
    fontSize: '24px',
    fontWeight: 700,
    color: Colors.plain_black,
    margin: '0px 0px 10px',
    textAlign: 'center',
  },
  tableHead: {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Wigrum',
    color: Colors.plain_black,
  },
  selectedBuilding: {
    fontSize: '20px',
    fontWeight: 700,
    color: Colors.plain_black,
    textAlign: 'center',
  },
  universityTitle: {
    color: Colors.cyprus,
    fontWeight: 700,
    fontSize: '32px',
    margin: '50px 0px 17px',
  },
  universityAdminText: {
    fontSize: '24px',
    fontWeight: 700,
    color: Colors.plain_black,
    marginTop: '32px',
    marginBottom: '25px',
  },
  buildingContainer: {
    display: 'flex',
    margin: '90px 33px 35px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buildingsText: {
    fontSize: '20px',
    fontWeight: 600,
  },
  grid: {
    width: '90%',
    paddingBottom: '40px',
    [theme.breakpoints.down('md')]: {
      width: '98%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: '0px 20px',
    },
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: '350px',
    background: Colors.button_hover_bg_black,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  icon: {
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  loading: {
    width: '100%',
    marginTop: '50px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    textAlign: 'center',
  },
  noDataText: {
    textAlign: 'center',
    fontSize: '24px',
    width: '100%',
    fontWeight: 700,
    color: Colors.plain_black,
    marginTop: '32px',
    marginBottom: '25px',
    lineHeight: '120%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      fontSize: '20px',
      padding: '20px',
    },
  },
  searchForm: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
  },
  searchButton: {
    marginTop: '21px',
    color: 'white',
    background: Colors.blue,
    marginLeft: '10px',
  },
  stickyFooter: {
    bottom: 0,
    position: 'sticky',
    width: '100%',
    pointerEvents: 'none',
    paddingBottom: 4,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
