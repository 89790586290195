import { createStyles, makeStyles } from '@material-ui/core';
import Colors from 'theme';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      zIndex: 2,
      height: '100vh',
      width: 'calc(100% - 60px)',
      left: 60,
      background: Colors.lightWhite,
      overflow: 'scroll',
    },
    createChecklistButton: {
      width: '120px',
      borderRadius: '10px',
      height: '40px',
      background: Colors.paleTurquoise,
      color: Colors.cyprus,
      marginBottom: '20px',
      marginRight: '10px',
    },
    updateCheklistButton: {
      borderRadius: '10px',
      background: Colors.paleTurquoise,
      color: Colors.cyprus,
      marginBottom: '20px',
      marginRight: '10px',
      position: 'fixed',
      bottom: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '250px',
      height: '40px',
    },
    label: {
      fontFamily: 'Wigrum',
      color: Colors.bold_text,
      fontSize: '30px',
      textAlign: 'center',
      marginTop: '10px',
    },
  }),
);

export default useStyles;
