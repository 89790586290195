import * as CustomerSDK from '@livechat/customer-sdk';
import { IncomingEvent } from 'types';
import config from '../config';
import { Messages } from './errors';

export const getCustomerSDK = (name: string, email: string): any => {
  const customerSDK = CustomerSDK.init({
    licenseId: parseInt(config.REACT_APP_LIVE_CHAT_LICENSE_KEY ?? ''),
    clientId: config.REACT_APP_LIVE_CHAT_CLIENT_ID ?? 0,
    autoConnect: false,
    customerDataProvider: () => {
      return {
        name: name,
        email: email,
      };
    },
  });
  return customerSDK;
};

export const addConnectedListener = (
  customerSDK: any,
  setAgentAvailable: (availability: boolean) => void,
  setSenderId: (id: string) => void,
  onConnected: () => void,
  onError: () => void,
  setMessage: (message: string) => void,
): void => {
  customerSDK.on('connected', (payload: { customer: any; availability: any; greeting: any }) => {
    const { availability } = payload;
    setAgentAvailable(availability == 'online');
    onConnected();
    customerSDK
      .getCustomer()
      .then((customer: any) => {
        setSenderId(customer.id);
      })
      .catch((error: any) => {
        onError();
        setMessage(error.toString());
      });
  });
  customerSDK.on('disconnected', () => {
    onError();
    setMessage(Messages.CHAT_ERROR);
  });
};

export const addAvailabilityListener = (customerSDK: any, setAgentAvailable: (availability: boolean) => void): void => {
  customerSDK.on('availability_updated', (availability: any) => {
    setAgentAvailable(availability.availability == 'online');
  });
};
export const addIncomingGreetingListener = (customerSDK: any): void => {
  customerSDK.on('incoming_greeting', (payload: { text: any; agent: any }) => {
    const { text } = payload;
    console.log(text);
  });
};

export const addIncomingEventListener = (customerSDK: any, receiveMessage: (event: IncomingEvent) => void): void => {
  customerSDK.on('incoming_event', (payload: { chat: any; event: IncomingEvent }) => {
    const { event } = payload;
    receiveMessage(event);
  });
};

export const startChat = (
  customerSDK: any,
  setChatId: (id: string) => void,
  setAgentName: (name: string) => void,
  onError: () => void,
  setMessage: (message: string) => void,
  onAlreadyActiveChat?: (chatId: string) => void,
): void => {
  customerSDK
    .listChats()
    .then(async (chatsSummary: any) => {
      if (chatsSummary.chatsSummary.length < 1) {
        await customerSDK
          .startChat({
            continuous: true,
            chat: {
              thread: {
                events: [],
              },
            },
          })
          .then((res: any) => {
            const chatId = res.chat.id;
            setChatId(chatId);
            activateChat(customerSDK, chatId, onError, setMessage, onAlreadyActiveChat);
            getChat(customerSDK, chatId, setAgentName, onError, setMessage);
          })
          .catch((error: any) => {
            if (error != Messages.CHAT_LIMIT && error.toString() !== Messages.GROUP_OFFLINE_ERROR) {
              onError();
              setMessage(error.toString());
            }
          });
      } else {
        const chatId = chatsSummary.chatsSummary[0].id;
        setChatId(chatId);
        activateChat(customerSDK, chatId, onError, setMessage, onAlreadyActiveChat);
        getChat(customerSDK, chatId, setAgentName, onError, setMessage);
      }
    })
    .catch((error: any) => {
      if (error.toString() !== Messages.GROUP_OFFLINE_ERROR) {
        onError();
        setMessage(error.toString());
      }
    });
};

export const activateChat = (
  customerSDK: any,
  chatId: string,
  onError: () => void,
  setMessage: (message: string) => void,
  onAlreadyActiveChat?: (chatId: string) => void,
) => {
  customerSDK
    .resumeChat({
      continuous: true,
      chat: {
        id: chatId,
        thread: {
          events: [],
        },
      },
    })
    .then(() => {
      onAlreadyActiveChat && onAlreadyActiveChat(chatId);
    })
    .catch((error: any) => {
      if (error != Messages.CHAT_ACTIVE && error.toString() !== Messages.GROUP_OFFLINE_ERROR) {
        onError();
        setMessage(error.toString());
      } else {
        onAlreadyActiveChat && onAlreadyActiveChat(chatId);
      }
    });
};
export const getChat = (
  customerSDK: any,
  chatId: string,
  setAgentName: (name: string) => void,
  onError: () => void,
  setMessage: (message: string) => void,
): void => {
  customerSDK
    .getChat({
      chatId: chatId,
    })
    .then((chat: { id: any; access: any; users: any; properties: any; thread: any }) => {
      const { users } = chat;
      let isSet = false,
        agent = '';
      users.forEach((user: any) => {
        if (user.type == 'agent' && user.present) {
          setAgentName(user.name);
          isSet = true;
        } else agent = user.name;
      });
      if (!isSet) setAgentName(agent);
    })
    .catch((error: any) => {
      onError();
      setMessage(error.toString());
    });
};

export const getAuthToken = (customerSDK: any): Token => {
  return customerSDK.auth.getToken().then((token: Token) => {
    return token;
  });
};

interface Token {
  accessToken: string;
  entityId: string;
  expiresIn: number;
  tokenType: string;
  creationDate: number;
}
