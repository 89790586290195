import { CustomButton } from '.';
import CopyIcon from 'assets/copyIcon.png';
import Colors from 'theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    width: '18px',
    height: '6px',
    margin: '0px 5px 0px 0px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface Props {
  isCopied: boolean;
  onClick: () => void;
}

const CopyButton = ({ isCopied, onClick }: Props) => {
  const classes = useStyles();

  return (
    <CustomButton
      title={
        isCopied ? (
          <div>Copied!</div>
        ) : (
          <div className={classes.flex}>
            <img className={classes.icon} src={CopyIcon} />
            <div>Copy Link</div>
          </div>
        )
      }
      padding="0px"
      mTop="0px"
      background={isCopied ? Colors.viking : Colors.plain_white}
      solid={isCopied ? true : false}
      fontSize="14px"
      borderRadius="28px"
      height="34px"
      border={isCopied ? 'none' : `1px solid ${Colors.frenchPass}`}
      textColor={isCopied ? Colors.plain_white : Colors.viking}
      width="118px"
      buttonClicked={() => onClick()}
    />
  );
};

export default CopyButton;
