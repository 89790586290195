import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

const useStyles = makeStyles(() => ({
  button: {
    margin: (props: StyleProps) => props.margin || '23px 30px 0px 0px',
    width: '103px',
    fontFamily: 'Wigrum',
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'none',
    color: Colors.hilighted_text_secondary,
    borderRadius: '4px',
    height: '45px',
    background: `${Colors.light_blue_bg} !important`,
    transition: 'transform 0.5s',
    boxShadow: 'none',
    '&:hover': {
      transform: 'scale(1.04)',
      background: 'none',
    },
    '@media (max-width: 768px)': {
      marginRight: '0px',
    },
  },
}));

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  margin?: string;
}

interface StyleProps {
  margin?: string;
}

const CustomSmallButton = ({ title, onClick, disabled, margin }: Props): JSX.Element => {
  const classes = useStyles({ margin });
  return (
    <Button variant="contained" disabled={disabled} onClick={onClick} className={classes.button}>
      {title}
    </Button>
  );
};

export default CustomSmallButton;
