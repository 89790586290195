import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CustomSelectFilter, InputFieldWithLabel } from 'components/fields';
import { useState } from 'react';
import { AddCatalogItem, UniversityCatalogItemProps, UniversityCatalogProps } from 'types';
import ButtonsContainer from './buttonsContainer';
import { ITEM_VARIANT_DEFAULT, TAGS_SUB_TYPES, TAGS_SURFACE_TYPES, TAGS_TYPES } from 'utils';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (catalogItem: AddCatalogItem) => Promise<boolean>;
  item?: UniversityCatalogItemProps;
  setItem: (item?: UniversityCatalogItemProps) => void;
  catalogData: UniversityCatalogProps[];
}

const initialValues = {
  itemVariantName: '',
  id: '',
  catalogId: '',
  modelUrl: '',
  itemVariantDescription: '',
  type: '',
  subType: '',
  surfaceType: '',
  thumbnail: '',
  itemVariantDefault: 'False',
};

const AddCatalogItemModal = ({ isOpen, setIsOpen, onSubmit, item, setItem }: Props) => {
  const classes = useStyles();
  const [catalogItemInfo, setCatalogItemInfo] = useState<AddCatalogItem>(initialValues);
  const [isError, setIsError] = useState(false);
  const label = item ? 'Edit Catalog Item' : 'Add Catalog Item';

  if (item && catalogItemInfo === initialValues) {
    const tags = item?.descriptorTags;
    setCatalogItemInfo({
      itemVariantName: item.itemVariantName,
      catalogId: item.catalogId,
      id: item.catalogItemId,
      modelUrl: tags.url || '',
      itemVariantDescription: item.itemVariantDescription || '',
      type: tags.type || '',
      subType: tags.subType || '',
      surfaceType: tags.surfaceType || '',
      thumbnail: item?.thumbnail || '',
      itemVariantDefault: item.itemVariantDefault === true ? 'True' : 'False',
    });
  }

  const handleClose = () => {
    setIsOpen(false);
    setCatalogItemInfo(initialValues);
    setIsError(false);
    setItem(undefined);
  };

  const handleSubmit = async () => {
    const error =
      !catalogItemInfo.itemVariantName ||
      !catalogItemInfo.itemVariantDescription ||
      !catalogItemInfo.thumbnail ||
      !catalogItemInfo.modelUrl ||
      !catalogItemInfo.type ||
      !catalogItemInfo.surfaceType;

    if (error) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const success = await onSubmit(catalogItemInfo);
    if (success) {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>{label}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.label}></div>
        <div className={classes.text}></div>
        <form noValidate={false} autoComplete="on">
          <InputFieldWithLabel
            labelText="Item Variant Name"
            placeholder="Enter Item Variant Name"
            value={catalogItemInfo?.itemVariantName}
            setFieldValue={(item: string) => setCatalogItemInfo({ ...catalogItemInfo, itemVariantName: item })}
            isError={isError && !catalogItemInfo.itemVariantName}
            errorMsg="Item Variant Name can't be empty"
            mTop="10px"
          />
          <InputFieldWithLabel
            labelText="Thumbnail"
            placeholder="Enter Thumbnail"
            value={catalogItemInfo?.thumbnail}
            setFieldValue={(item: string) => setCatalogItemInfo({ ...catalogItemInfo, thumbnail: item })}
            isError={isError && !catalogItemInfo.thumbnail}
            errorMsg="Thumbnail can't be empty"
            mTop="10px"
          />
          <InputFieldWithLabel
            labelText="Item Variant Description"
            placeholder="Enter Item Variant Description"
            value={catalogItemInfo?.itemVariantDescription}
            setFieldValue={(item: string) => setCatalogItemInfo({ ...catalogItemInfo, itemVariantDescription: item })}
            isError={isError && !catalogItemInfo.itemVariantDescription}
            errorMsg="Item Variant Description can't be empty"
            mTop="10px"
          />

          <CustomSelectFilter
            options={TAGS_TYPES}
            onChange={(type) => setCatalogItemInfo({ ...catalogItemInfo, type })}
            label="Type"
            isError={isError && !catalogItemInfo?.type}
            errorMsg="Type can't be empty"
            value={catalogItemInfo?.type}
            width="300px"
          />
          <CustomSelectFilter
            options={TAGS_SUB_TYPES}
            onChange={(subType) => setCatalogItemInfo({ ...catalogItemInfo, subType })}
            label="SubType"
            isError={false}
            value={catalogItemInfo?.subType}
            width="300px"
          />
          <CustomSelectFilter
            options={TAGS_SURFACE_TYPES}
            onChange={(surfaceType) => setCatalogItemInfo({ ...catalogItemInfo, surfaceType })}
            label="Surface Type"
            isError={isError && !catalogItemInfo?.surfaceType}
            errorMsg="Surface Type Can't be empty"
            value={catalogItemInfo?.surfaceType}
            width="300px"
          />
          <CustomSelectFilter
            options={ITEM_VARIANT_DEFAULT}
            onChange={(itemVariantDefault) => setCatalogItemInfo({ ...catalogItemInfo, itemVariantDefault })}
            label="Item Variant default"
            value={catalogItemInfo?.itemVariantDefault}
            width="300px"
          />

          <InputFieldWithLabel
            labelText="Model Url"
            placeholder="Enter Model Url"
            value={catalogItemInfo?.modelUrl}
            setFieldValue={(item: string) => setCatalogItemInfo({ ...catalogItemInfo, modelUrl: item })}
            isError={isError && !catalogItemInfo.modelUrl}
            errorMsg="Model Url can't be empty"
            mTop="0px"
          />
        </form>
        <ButtonsContainer cancelClicked={handleClose} submitClicked={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddCatalogItemModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    margin: '20px 0px 10px',
    color: Colors.secondary_text,
  },
  diamensions: {
    display: 'flex',
  },
  diamensionRow: {
    marginRight: '10px',
  },
}));
