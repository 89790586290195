import { Collapse, TableCell, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import { IconButton, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { UniversityCatalogItemProps, UniversityCatalogProps } from 'types';
import { DEFAULTCATALOG_ITEM_IMAGE, DEFAULT_CATALOG_IMAGE } from 'utils';

interface Props {
  row: UniversityCatalogProps;
  setCatalogData: (catalogData?: UniversityCatalogProps) => void;
  setIsAddingCatalog: (isAddingCatalog: boolean) => void;
  setIsAddingCatalogItem: (isAddingCatalog: boolean) => void;
  onOpenedCatalogChange: (catalogId: string) => void;
  setSelectedCatalogItem: (catalogItem?: UniversityCatalogItemProps) => void;
}

const FurnitureRow = ({
  row,
  setCatalogData,
  setIsAddingCatalog,
  setIsAddingCatalogItem,
  setSelectedCatalogItem,
  onOpenedCatalogChange,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onAddCatalogItem = (catalogId: string) => {
    setIsAddingCatalogItem(true);
    onOpenedCatalogChange(catalogId);
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.bodyCell}>
          <div className={classes.flex}>
            {open ? (
              <KeyboardArrowUpIcon className={classes.caret} onClick={() => setOpen(!open)} />
            ) : (
              <KeyboardArrowDownIcon className={classes.caret} onClick={() => setOpen(!open)} />
            )}
            <img src={row.thumbnail || DEFAULT_CATALOG_IMAGE} className={classes.image} alt="" loading="lazy" />
            {row.itemName}
          </div>
        </TableCell>
        <TableCell className={classes.bodyCell}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <IconButton
              className={classes.editIcon}
              onClick={() => {
                setCatalogData(row);
                setIsAddingCatalog(true);
              }}
            >
              <EditIcon color="primary" />
            </IconButton>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.cell} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider className={classes.divider} />
            <div className={classes.iconContainer}>
              <AddCircleIcon className={classes.icon} color="primary" onClick={() => onAddCatalogItem(row.catalogId)} />
            </div>
            {row.catalogItems
              .sort((a, b) => {
                return a.itemVariantName.toLowerCase().localeCompare(b.itemVariantName.toLowerCase(), undefined, {
                  numeric: true,
                  sensitivity: 'base',
                });
              })
              .map((item: UniversityCatalogItemProps, index: number) => (
                <div key={index}>
                  <Divider className={classes.divider} />
                  <div className={classes.row}>
                    <div className={classes.roomNumber}>
                      <div className={classes.RowLeftSie}>
                        <img
                          src={item.thumbnail || DEFAULTCATALOG_ITEM_IMAGE}
                          className={classes.image}
                          alt=""
                          loading="lazy"
                        />
                        <div className={classes.title}>{item.itemVariantName}</div>
                      </div>
                    </div>

                    <EditIcon
                      className={classes.caret}
                      onClick={() => {
                        setSelectedCatalogItem(item);
                        setIsAddingCatalogItem(true);
                      }}
                    />
                  </div>
                </div>
              ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FurnitureRow;

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  best: {
    width: '1000px',
    backgroundColor: 'blue',
  },
  cell: {
    padding: 0,
    marginBottom: '20px',
  },
  bodyCell: {
    fontFamily: 'Wigrum',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: Colors.bigStone,
    paddingTop: '20px',
  },
  status: {
    margin: '0px 10px 0px 30px',
    width: '95px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  active: {
    margin: '0px 0px 0px 30px',
    width: '160px',
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  divider: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 30px',
    alignItems: 'center',
  },
  roomNumber: {
    marginLeft: '20px',
  },
  caret: {
    color: Colors.button_bg_active,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px',
  },
  icon: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  editIcon: {
    marginRight: '10px',
  },
  RowLeftSie: {
    display: 'flex',
  },
  image: {
    width: '40px',
    height: '40px',
  },
  title: {
    alignSelf: 'center',
    marginLeft: '10px',
  },
}));
