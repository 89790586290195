import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Pointer from 'assets/pointer.png';
import { saveShowInstructions, getShowInstructions } from 'context/localStorage';
import PhotoViewerIcon from 'assets/photoViewerIcon.png';
import PhotoViewerContainer from 'components/PhotoViewerContainer';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  buildingName: string;
  url?: string;
}

const PhotoViewerDialog = ({ isOpen, setIsOpen, buildingName, url }: Props) => {
  const classes = useStyles();
  const show = getShowInstructions();
  const [showInstructions, setShowInstructions] = useState(show === 'true' && !!url ? true : false);

  const handleClose = (setOpen: (open: boolean) => void) => {
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose(setIsOpen)}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
      fullWidth={true}
    >
      <Dialog
        open={showInstructions}
        onClose={() => {
          saveShowInstructions('false');
          handleClose(setShowInstructions);
        }}
        maxWidth="xl"
      >
        <DialogContent className={classes.instructionsContent}>
          <div className={classes.column}>
            <img src={Pointer} className={classes.pointer} />
            <div className={classes.building}>Click and drag to explore the space</div>
          </div>
        </DialogContent>
      </Dialog>
      <DialogContent className={classes.content} style={{ height: url ? 'auto' : '87vh' }}>
        <div className={classes.flex}>
          <div className={classes.building}>{`360° View: ${buildingName}`}</div>
          <CloseIcon className={classes.closeIcon} onClick={() => handleClose(setIsOpen)} />
        </div>
        <div className={classes.modal} style={{ cursor: url ? 'pointer' : 'default', height: url ? 'auto' : '90%' }}>
          {url ? (
            <PhotoViewerContainer url={url} />
          ) : (
            <div className={classes.dialog}>
              <div className={classes.wrapper}>
                <DialogContent className={classes.comingSoonContent}>
                  360° view
                  <img src={PhotoViewerIcon} className={classes.photoViewerIcon} />
                  Coming Soon
                </DialogContent>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PhotoViewerDialog;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 52px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  wrapper: {
    padding: '47px 0px 63px',
  },
  photoViewerIcon: {
    width: '81px',
    height: '60px',
    margin: '14px 0px 20px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  building: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  instructionsContent: {
    width: '494px',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  dialog: {
    boxShadow: `0px 8px 34px ${Colors.drawer_shadow} !important`,
    borderRadius: '8px !important',
    width: '494px',
    background: Colors.plain_white,
  },
  comingSoonContent: {
    scrollbarWidth: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    color: Colors.bold_text,
    fontWeight: 700,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 0px',
  },
  pointer: {
    height: '92px',
    width: '73px',
    marginBottom: '37px',
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.smoke,
  },
}));
