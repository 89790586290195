import { CustomButton } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

interface StyleProps {
  reverse?: boolean;
  mTop?: string;
}

interface Props {
  cancelText?: string;
  submitText?: string;
  reverse?: boolean;
  showSubmit?: boolean;
  mTop?: string;
  enable?: boolean;
  cancelClicked: () => void;
  submitClicked?: () => void;
}

const ButtonsContainer = ({
  cancelClicked,
  submitClicked,
  enable = true,
  cancelText = 'Cancel',
  submitText = 'Submit',
  reverse = false,
  showSubmit = true,
  mTop = '50px',
}: Props) => {
  const classes = useStyles({ reverse, mTop });

  return (
    <div className={classes.buttonsContainer}>
      <CustomButton
        title={cancelText}
        padding="0px"
        mTop="0px"
        background="none"
        solid={false}
        fontSize="14px"
        borderRadius="6px"
        border={`1px solid ${Colors.frenchPass}`}
        height="34px"
        textColor={Colors.cyprus}
        width="118px"
        buttonClicked={cancelClicked}
      />
      {showSubmit && (
        <CustomButton
          title={submitText}
          padding="0px"
          mTop="0px"
          background={Colors.paleTurquoise}
          fontSize="14px"
          borderRadius="6px"
          height="34px"
          enable={enable}
          textColor={Colors.cyprus}
          width="118px"
          buttonClicked={submitClicked}
        />
      )}
    </div>
  );
};

export default ButtonsContainer;

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: (props: StyleProps) => (props.reverse ? 'row-reverse' : 'row'),
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: (props: StyleProps) => props.mTop,
  },
}));
