import styled from 'styled-components';
import { useState, ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ReactComponent as RoomieLogo } from 'assets/logo-header.svg';
import { TermsAndPolicy as TermsAndPolicyText } from 'utils';
import Colors from 'theme';

const TABS = {
  terms_of_service: 0,
  privacy_policy: 1,
};
interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TermsAndPolicyContainer = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolBar}>
          <SvgIcon component={RoomieLogo} viewBox="0 0 251 59" classes={{ root: classes.roomieIcon }} />
        </Toolbar>
      </AppBar>
      <VerticalTabs />
    </div>
  );
};
const VerticalTabs = (): JSX.Element => {
  const classes = useStyles();
  const [value, setValue] = useState(getTab());

  return (
    <div className={classes.tabsContainer}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={(_, value) => setValue(value)}
        aria-label="Vertical tabs as example"
        className={classes.tabs}
        TabIndicatorProps={{ style: { visibility: 'hidden' } }}
      >
        <Tab
          label={TermsAndPolicyText.TermsOfServiceHeading}
          style={getStyle(value === 0)}
          {...a11yProps(0)}
          className={classes.tab}
        />
        <Tab
          label={TermsAndPolicyText.PrivacyPolicyHeading}
          style={getStyle(value === 1)}
          {...a11yProps(1)}
          className={classes.tab}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TextHeading>{TermsAndPolicyText.TermsOfServiceHeading}</TextHeading>
        {TermsAndPolicyText.TermsOfServiceText}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TextHeading>{TermsAndPolicyText.PrivacyPolicyHeading}</TextHeading>
        {TermsAndPolicyText.TermsOfServiceText}
      </TabPanel>
    </div>
  );
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && (
        <div>
          <Typography className={classes.textBody}>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function getStyle(isActive: boolean) {
  return isActive ? tabStyle.active_tab : tabStyle.default_tab;
}
function getTab() {
  if (window.location.hash === '#privacy_policy') {
    return TABS.privacy_policy;
  }
  return TABS.terms_of_service;
}

const tabStyle = {
  default_tab: {
    color: Colors.primary_text,
    opacity: 1,
  },
  active_tab: {
    color: Colors.hilighted_text_secondary,
  },
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'auto',
  },
  appBar: {
    flexGrow: 1,
    height: '70px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.21)',
    backgroundColor: 'white',
  },
  toolBar: {
    justifyContent: 'space-between',
  },
  roomieIcon: {
    width: '100px',
    margin: '24px 0px',
  },
  tabsContainer: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    margin: '20px',
    paddingRight: '17vw',
    paddingLeft: '3vw',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0',
      margin: '0',
    },
  },
  tabs: {
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeigh: '26px',
    color: Colors.primary_text,
    width: '25vw',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      flexDirection: 'row',
    },
  },
  tabPanel: {
    width: '60vw',
    paddingLeft: '5vw',
    height: '88vh',
    overflow: 'auto',
    paddingRight: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '92vw',
      height: '79vh',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: Colors.icon_grey_light,
      border: '4px solid transparent',
      borderRadius: '8px',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar': {
      width: '16px',
    },
  },
  tab: {
    transition: '0.3s',
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
    textTransform: 'none',
    color: Colors.primary_text,
    '&:hover': {
      transform: 'scale(1.05)',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textBody: {
    fontFamily: 'Wigrum',
    fontSize: '16px',
    FontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0px',
    textAlign: 'left',
    whiteSpace: 'pre-line',
    color: Colors.primary_text,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'justify',
    },
  },
}));
const TextHeading = styled.span`
  font-family: Wigrum;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: ${Colors.heading_black};
  margin-top: 13px;
  margin-bottom: 18px;
  display: block;
  @media (max-width: 959px) {
    text-align: center;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;

export default TermsAndPolicyContainer;
