import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Colors from 'theme';

interface Props {
  textToDisplay: string;
}

const OnScreenText = (props: Props): JSX.Element => {
  const classes = OnScreenTextStyles();

  return (
    <div className={classes.Container}>
      <Typography className={classes.text}>{props.textToDisplay}</Typography>
    </div>
  );
};

const OnScreenTextStyles = makeStyles(() => ({
  Container: {
    position: 'absolute',
    top: '30%',
    left: '0',
    right: '0',
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '61vw',
  },
  text: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.cyprus1,
  },
}));

export default OnScreenText;
