import { SvgIconProps } from '@material-ui/core/SvgIcon';

const StyleIcon = (props: SvgIconProps) => {
  return (
    <svg fill={props.fill} width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82097 32.1184C4.77911 32.4877 4.48771 32.7791 4.11845 32.821L0.707602 33.2076C-0.235867 33.3146 -0.235868 34.6854 0.707601 34.7924L4.11844 35.179C4.48771 35.2209 4.77911 35.5123 4.82097 35.8816L5.20765 39.2924C5.3146 40.2359 6.68539 40.2359 6.79235 39.2924L7.17903 35.8816C7.22089 35.5123 7.51229 35.2209 7.88155 35.179L11.2924 34.7924C12.2359 34.6854 12.2359 33.3146 11.2924 33.2076L7.88156 32.821C7.51229 32.7791 7.22089 32.4877 7.17903 32.1184L6.79235 28.7076C6.6854 27.7641 5.31461 27.7641 5.20765 28.7076L4.82097 32.1184Z"
        fill="#09B6B6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.821 4.11844C32.7791 4.48771 32.4877 4.77911 32.1184 4.82097L28.7076 5.20765C27.7641 5.3146 27.7641 6.68539 28.7076 6.79235L32.1184 7.17903C32.4877 7.22089 32.7791 7.51229 32.821 7.88155L33.2076 11.2924C33.3146 12.2359 34.6854 12.2359 34.7924 11.2924L35.179 7.88156C35.2209 7.51229 35.5123 7.22089 35.8816 7.17903L39.2924 6.79235C40.2359 6.6854 40.2359 5.31461 39.2924 5.20765L35.8816 4.82097C35.5123 4.77911 35.2209 4.48771 35.179 4.11845L34.7924 0.707602C34.6854 -0.235867 33.3146 -0.235868 33.2076 0.707601L32.821 4.11844Z"
        fill="#09B6B6"
      />
      <path
        d="M30.25 14L28.5 14V11.75C28.5 11.3344 28.1656 11 27.75 11L9.25 11C8.83437 11 8.5 11.3344 8.5 11.75V20.25C8.5 20.6656 8.83437 21 9.25 21H27.75C28.1656 21 28.5 20.6656 28.5 20.25V16H29.5V22.25H18.5312C17.8406 22.25 17.2812 22.8094 17.2812 23.5V27.75H15.9062C15.7688 27.75 15.6562 27.8625 15.6562 28L15.6562 35.125C15.6562 35.1437 15.6594 35.1656 15.6625 35.1844C15.6594 35.2469 15.6562 35.3125 15.6562 35.375C15.6562 36.825 16.8313 38 18.2812 38C19.7312 38 20.9062 36.825 20.9062 35.375C20.9062 35.3094 20.9031 35.2469 20.9 35.1844C20.9031 35.1656 20.9062 35.1469 20.9062 35.125V28C20.9062 27.8625 20.7937 27.75 20.6562 27.75H19.2812V24.25H30.25C30.9406 24.25 31.5 23.6906 31.5 23V15.25C31.5 14.5594 30.9406 14 30.25 14ZM26.5 19H10.5V13H26.5V19ZM18.9062 35.375C18.9062 35.7188 18.625 36 18.2812 36C17.9375 36 17.6562 35.7188 17.6562 35.375V29.75H18.9062V35.375Z"
        fill="#09B6B6"
      />
    </svg>
  );
};

export default StyleIcon;
