import React, { Fragment, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAxios, { ServerRoutes } from 'api';
import { GET } from 'utils';
import { AutocompleteStyleItem } from 'types';

interface Option {
  id: string;
  name: string;
}

interface AsyncAutocompleteProps {
  addStyleItem: any;
  resetRoom: () => void;
  setIsEnableCatalogList?: React.Dispatch<React.SetStateAction<boolean>>;
  setStyle?: React.Dispatch<React.SetStateAction<any>>;
}

export default function AsyncAutocomplete({
  addStyleItem,
  resetRoom,
  setIsEnableCatalogList,
  setStyle,
}: AsyncAutocompleteProps) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [styles, setStyles] = useState<any>(null);
  const [styleIds, setStyleIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const loading = open && options.length === 0;

  const { callApi: loadStyleItemCatalogs } = useAxios(ServerRoutes.styleItemsCatalogs, GET, false, '', {}, 'shopping');

  useEffect(() => {
    loadStyleItemCatalogsAndUpdateState({});
  }, [loading]);

  const loadStyleItemCatalogsAndUpdateState = async (queryParams: any) => {
    await loadStyleItemCatalogs(queryParams, (styles) => {
      if (styles?.data) {
        const filteredStyles: any = styles?.data?.filter((style: any) => style.id && style.styleName);
        setStyles(filteredStyles);
        setOptions(
          filteredStyles
            .filter((style: any) => style.styleName !== undefined)
            .map((style: any) => ({ id: style.id, name: style.styleName as string })),
        );
      }
    });
  };

  const handleSearch = async (value: string) => {
    if (value.trim() === '') {
      loadStyleItemCatalogsAndUpdateState({});
    } else {
      loadStyleItemCatalogsAndUpdateState({ query: value });
    }
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const applyStyleItems = (value: Option) => {
    if (!styles) {
      return null;
    }
    if (styleIds?.length > 0) {
      resetRoom();
      setStyleIds([]);
    }
    const { id } = value;
    setStyleIds((prevIds: any) => [...prevIds, id]);
    const selectedStyle: any | undefined = styles?.find((style: any) => style.id === id);
    if (!selectedStyle) {
      return;
    }
    selectedStyle.styleItems.forEach((item: AutocompleteStyleItem) => {
      addStyleItem(selectedStyle, item);
    });
  };

  const handleClear = () => {
    setStyleIds([]);
    setIsEnableCatalogList && setIsEnableCatalogList(false);
  };
  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: '100%', margin: '10px 0px' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={(e, value) => {
        if (!value) {
          handleClear();
          resetRoom();
          setStyle && setStyle(null);
          return null;
        }
        applyStyleItems(value);
        setIsEnableCatalogList && setIsEnableCatalogList(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add Style"
          variant="outlined"
          value={searchTerm || ''}
          onChange={(e) => {
            handleSearch(e.target.value);
            setSearchTerm(e.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
