import { Button, Chip, FormControl, TextField, makeStyles } from '@material-ui/core';
import useAxios, { ServerRoutes } from 'api';
import React, { useState } from 'react';
import { GET } from 'utils';

export default function MultiValuesInput({ setCatalogs }: any) {
  const classes = useStyles();
  const [catalogIds, setCatalogIds] = useState<string[] | []>([]);
  const [catalogInput, setCatalogInput] = useState('');

  const { callApi: fetchCatalogsApi } = useAxios(ServerRoutes.productCatalogs, GET, false, '', {}, 'shopping');

  const formatCatalogIds = (ids: string[]) => {
    return ids.join(',');
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ') {
      setCatalogIds((oldState) => [...oldState, (e.target as HTMLInputElement).value]);
      setCatalogInput('');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCatalogInput(e.target.value);
  };

  const handleDelete = (item: string, index: number) => {
    const arr = [...catalogIds];
    arr.splice(index, 1);
    setCatalogIds(arr);
  };

  const fetchCatalogs = async () => {
    if (!catalogIds) {
      return;
    }
    await fetchCatalogsApi({ productCatalogIds: formatCatalogIds(catalogIds) }, async (res) => {
      setCatalogIds([]);
      if (res?.data) setCatalogs(res?.data);
    });
  };

  return (
    <FormControl classes={{ root: classes.formControlRoot }}>
      <div className={'container'}>
        {catalogIds.map((item, index) => (
          <Chip key={item} size="small" onDelete={() => handleDelete(item, index)} label={item} />
        ))}
      </div>
      <TextField label="Catalog IDs" value={catalogInput} onChange={handleChange} onKeyDown={handleKeyUp} />
      <Button variant="contained" color="primary" disabled={catalogIds.length == 0} onClick={fetchCatalogs}>
        Fetch Catalogs
      </Button>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '80%',
    flexWrap: 'wrap',
    flexDirection: 'row',
    border: '2px solid lightgray',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    margin: theme.spacing(2),
    '&> div.container': {
      gap: '6px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    '& > div.container > span': {
      backgroundColor: 'gray',
      padding: '1px 3px',
      borderRadius: theme.spacing(2),
    },
    '& > div.MuiTextField-root': {
      width: '100%',
    },
  },
}));
