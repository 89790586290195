import firebase from 'firebase/app';
import 'firebase/auth';
import { CustomButton } from '.';
import Colors from 'theme';

interface Props {
  onSignOut: () => void;
}

const SignOutButton = ({ onSignOut }: Props): JSX.Element => {
  const signOut = async () => {
    await firebase.auth().signOut();
    onSignOut();
  };
  return (
    <CustomButton
      title="Log out"
      textColor={Colors.cyprus}
      buttonClicked={signOut}
      borderRadius="34px"
      width="138px"
      height="48px"
      background={Colors.paleTurquoise}
      mTop="6px"
    />
  );
};

export default SignOutButton;
