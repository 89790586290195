const CloseIcon = () => (
  <svg width="12" height="12" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.86858 5.5L9.97014 0.610938C10.0389 0.529688 9.98108 0.40625 9.87483 0.40625H8.62796C8.55452 0.40625 8.48421 0.439062 8.43577 0.495312L5.05296 4.52813L1.67014 0.495312C1.62327 0.439062 1.55296 0.40625 1.47796 0.40625H0.231082C0.124832 0.40625 0.0670197 0.529688 0.13577 0.610938L4.23733 5.5L0.13577 10.3891C0.120369 10.4072 0.110489 10.4293 0.107302 10.4529C0.104115 10.4764 0.107755 10.5004 0.117791 10.522C0.127826 10.5435 0.143835 10.5617 0.163917 10.5745C0.183998 10.5872 0.207309 10.5939 0.231082 10.5938H1.47796C1.55139 10.5938 1.62171 10.5609 1.67014 10.5047L5.05296 6.47188L8.43577 10.5047C8.48264 10.5609 8.55296 10.5938 8.62796 10.5938H9.87483C9.98108 10.5938 10.0389 10.4703 9.97014 10.3891L5.86858 5.5Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

export default CloseIcon;
