import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import { Color } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';
import { CloseIcon, ErrorIcon, SuccessIcon } from 'components';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];
  message: string;
  messageHeader?: string;
  severity: Color;
  autoHideDuration?: number;
}

const Toast = ({
  isOpen,
  setIsOpen,
  vertical = 'top',
  horizontal = 'center',
  message,
  messageHeader = 'Error',
  autoHideDuration = 3000,
  severity,
}: Props): JSX.Element => {
  const classes = useStyles({ severity, isOpen, setIsOpen, message });

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical, horizontal }}
      onClose={handleClose}
    >
      <div className={classes.toast}>
        <div className={classes.container}>
          <div className={classes.flex}>
            {severity === 'error' ? <ErrorIcon /> : <SuccessIcon />}
            <div className={classes.text}> {severity === 'error' ? messageHeader : message}</div>
          </div>
          <div className={classes.close} onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
        {severity === 'error' && <div className={classes.error}>{message}</div>}
      </div>
    </Snackbar>
  );
};

export default Toast;

const useStyles = makeStyles(() => ({
  toast: {
    width: '395px',
    background: ({ ...props }: Props) => (props.severity === 'error' ? Colors.snow : Colors.ivory),
    border: ({ ...props }: Props) => `1px solid ${props.severity === 'error' ? Colors.pink : Colors.sulu}`,
    borderRadius: '2px',
    padding: '11px 18px',
    fontSize: '16px',
    color: ({ ...props }: Props) => (props.severity === 'error' ? Colors.cyprus : Colors.bold_text),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
  },
  close: {
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.09)',
    },
  },
  error: {
    fontSize: '14px',
    margin: '8px 12px 4px 33px',
    color: Colors.plain_black,
    lineHeight: '20px',
  },
  text: {
    marginLeft: '12px',
    lineHeight: '20px',
  },
}));
