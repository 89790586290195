import { useState } from 'react';
import { CustomButton } from 'components';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { RoomConfig, University, UniversityBuilding } from 'types';
import Colors from 'theme';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { sortRooms } from 'utils';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    border: `1px solid ${Colors.mystic}`,
    borderRadius: '10px',
    width: 'max-content',
    marginTop: '30px',
    padding: '40px 35px',
    [theme.breakpoints.down('sm')]: {
      margin: '30px auto 0px',
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
    },
  },
  form: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  label: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '20px',
  },
  label1: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '20px',
    marginRight: '200px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
    },
  },
  select: {
    width: '200px !important',
  },
  icon: {
    padding: '3px',
    margin: '0px 5px 0px 0px',
  },
  formControl: {
    margin: theme.spacing(4),
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      margin: '32px 0px',
    },
  },
  universitySelect: {
    margin: theme.spacing(4),
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '32px 0px',
    },
  },
  buttonsContainer: {
    marginTop: '10px',
  },
  customSelect: {
    border: `1px solid ${Colors.mystic}`,
    width: '290px',
    height: '50px',
    cursor: 'pointer',
    borderRadius: '6px',
  },
  selectText: {
    fontSize: '15px',
    color: Colors.cyprus1,
    margin: '17px 0px 0px 10px',
    display: 'flex',
    flexDirection: 'column',
  },
  customSelect1: {
    border: `1px solid ${Colors.mystic}`,
    width: '290px',
    height: '185px',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    borderRadius: '6px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomButton: {
    margin: '20px 0px 0px 70px',
    [theme.breakpoints.down('sm')]: {
      margin: '0px',
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px 7px 10px',
    fontSize: '14px',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
  item: {
    padding: '10px 0px 7px 10px',
    overflowX: 'scroll',
    fontSize: '15px',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
  },
}));

interface ItemProps {
  value?: string;
  valueToShow?: string;
  isOpen?: boolean;
  onClick: () => void;
}

interface Props {
  buildings: UniversityBuilding[];
  configs: RoomConfig[];
  currentConfig: RoomConfig | undefined;
  buildingName: string;
  onChangeConfig: (id?: string) => void;
  onChangeBuildingName: (buidling: string, id: string) => void;
  onSelectConfig: () => void;
  onUniversityChange: (id: string, name: string) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  universityName: string;
  isEmployee: boolean;
  universities: University[];
}

const ConfigForm = ({
  buildings,
  configs,
  currentConfig,
  onChangeConfig,
  onSelectConfig,
  onChangeBuildingName,
  buildingName,
  onUniversityChange,
  setOpen,
  universityName,
  open,
  universities,
  isEmployee = false,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [showUniversities, setShowUniversities] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const getFontSize = () => (universityName.length > 36 ? '13px' : '15px');

  const onUniversitySelect = (id: string, name: string) => {
    setExpanded1(false);
    setExpanded2(false);
    onUniversityChange(id, name);
    setShowUniversities(!showUniversities);
  };

  const UniversitySelect = () => (
    <FormControl className={classes.universitySelect}>
      <div className={classes.label1}>University</div>
      <div
        onClick={() => setShowUniversities(!showUniversities)}
        className={showUniversities ? classes.customSelect1 : classes.customSelect}
      >
        {!showUniversities && (
          <div className={classes.flex}>
            <div style={{ fontSize: getFontSize() }} className={classes.selectText}>
              {universityName || 'Select'}
            </div>
            <IconButton
              className={classes.icon}
              style={{ marginTop: !showUniversities ? '9px' : '0px', color: Colors.plain_black }}
              onClick={() => setShowUniversities(!showUniversities)}
            >
              <KeyboardArrowDown />
            </IconButton>
          </div>
        )}
        {showUniversities && (
          <div className={classes.selectText} style={{ margin: 0 }}>
            {universities.map((university, index) =>
              index === 0 ? (
                <Item
                  key={university.org_id}
                  isOpen
                  value={university.org_name}
                  onClick={() => {
                    onUniversitySelect(university?.org_id, university.org_name);
                  }}
                />
              ) : (
                <Item
                  key={university.org_id}
                  value={university.org_name}
                  onClick={() => {
                    onUniversitySelect(university?.org_id, university.org_name);
                  }}
                />
              ),
            )}
          </div>
        )}
      </div>
    </FormControl>
  );

  const Item = ({ value, isOpen = false, onClick, valueToShow = '' }: ItemProps) =>
    isOpen ? (
      <MenuItem className={isOpen ? classes.menuItem : ''} onClick={onClick}>
        {valueToShow ? valueToShow : value}
        <IconButton className={classes.icon}>
          <KeyboardArrowDown />
        </IconButton>
      </MenuItem>
    ) : (
      <MenuItem className={classes.item} onClick={onClick}>
        {valueToShow ? valueToShow : value}
      </MenuItem>
    );

  return (
    <>
      <div className={classes.selectContainer}>
        {isEmployee && <UniversitySelect />}
        <div className={classes.form}>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Building</div>
            <div
              onClick={() => universityName && setExpanded1(!expanded1)}
              className={expanded1 ? classes.customSelect1 : classes.customSelect}
            >
              {!expanded1 && (
                <div className={classes.flex}>
                  <div
                    className={classes.selectText}
                    style={{
                      color: universityName ? Colors.cyprus1 : Colors.disabledText,
                    }}
                  >
                    {buildingName || 'Select'}
                  </div>
                  <IconButton
                    className={classes.icon}
                    style={{
                      marginTop: !expanded1 ? '9px' : '0px',
                      color: universityName ? Colors.cyprus1 : Colors.disabledText,
                    }}
                    onClick={() => universityName && setExpanded1(!expanded1)}
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                </div>
              )}
              {expanded1 && (
                <div className={classes.selectText} style={{ margin: 0 }}>
                  {buildings.map((building, index) =>
                    index === 0 ? (
                      <Item
                        key={building?.roomie_university_building_id}
                        isOpen
                        value={building?.building_name}
                        onClick={() => {
                          setExpanded2(false);
                          onChangeBuildingName(building?.building_name, building?.roomie_university_building_id);
                          setExpanded1(!expanded1);
                        }}
                      />
                    ) : (
                      <Item
                        key={building?.roomie_university_building_id}
                        value={building?.building_name}
                        onClick={() => {
                          setExpanded2(false);
                          onChangeBuildingName(building?.building_name, building?.roomie_university_building_id);
                          setExpanded1(!expanded1);
                        }}
                      />
                    ),
                  )}
                </div>
              )}
            </div>
            {!expanded1 && (
              <div className={classes.buttonsContainer}>
                <CustomButton
                  enable={!!currentConfig}
                  title="View/Set up Room"
                  width="230px"
                  buttonClicked={onSelectConfig}
                  borderRadius="34px"
                  textColor={Colors.cyprus}
                  background={Colors.paleTurquoise}
                  solid={!!currentConfig}
                  border="none"
                />
              </div>
            )}
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className={classes.label}>Room Type</div>
            <div
              onClick={() => buildingName && configs.length > 0 && setExpanded2(!expanded2)}
              className={expanded2 ? classes.customSelect1 : classes.customSelect}
            >
              {!expanded2 && (
                <div className={classes.flex}>
                  <div
                    className={classes.selectText}
                    style={{ color: buildingName && configs.length > 0 ? Colors.cyprus1 : Colors.disabledText }}
                  >
                    {currentConfig?.type || currentConfig?.id || 'Select'}
                  </div>
                  <IconButton
                    className={classes.icon}
                    style={{
                      marginTop: !expanded2 ? '9px' : '0px',
                      color: buildingName && configs.length > 0 ? Colors.cyprus1 : Colors.disabledText,
                    }}
                    onClick={() => buildingName && configs.length > 0 && setExpanded2(!expanded2)}
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                </div>
              )}
              {expanded2 && (
                <div className={classes.selectText} style={{ margin: 0 }}>
                  {configs
                    .sort((a, b) => sortRooms(a.type || a.id, b.type || b.id))
                    .map((config, index) =>
                      index === 0 ? (
                        <Item
                          key={config?.id}
                          isOpen
                          valueToShow={config?.type || config?.id}
                          value={currentConfig ? currentConfig?.id : ''}
                          onClick={() => {
                            onChangeConfig(config?.id);
                            setExpanded2(!expanded2);
                          }}
                        />
                      ) : (
                        <Item
                          key={config?.id}
                          valueToShow={config?.type || config?.id}
                          value={currentConfig ? currentConfig?.id : ''}
                          onClick={() => {
                            onChangeConfig(config?.id);
                            setExpanded2(!expanded2);
                          }}
                        />
                      ),
                    )}
                </div>
              )}
            </div>
          </FormControl>
        </div>
      </div>
      <div className={classes.bottomButton}>
        <CustomButton
          enable={!!currentConfig}
          textColor={Colors.viking}
          border={!!currentConfig ? `1px solid ${Colors.frenchPass}` : 'none'}
          solid={false}
          padding="0px"
          width="230px"
          mBottom="30px"
          borderRadius="34px"
          title="Units with this Room Layout"
          buttonClicked={() => setOpen(!open)}
        />
      </div>
    </>
  );
};

export default ConfigForm;
