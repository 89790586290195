import { Box, Button } from '@material-ui/core';
import useAxios, { ServerRoutes } from 'api';
import ChecklistRow from 'components/forms/ChecklistRow';
import { useEffect, useState } from 'react';
import useStyles from 'styles/ChecklistStyles';
import { Section, Toast } from 'types';
import { CATALOG_DELETED, GET, Messages, POST, SECTION_ADDED, SECTION_REMOVED } from 'utils';
import { v4 } from 'uuid';
import { Color } from '@material-ui/lab/Alert';
import { Snackbar } from 'components';
import { AxiosError } from 'axios';

const Checklist = () => {
  const classes = useStyles();
  const [sections, setSections] = useState<Section[] | []>([]);
  const [toast, setToast] = useState<Toast>();
  const [open, setOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState<string[] | []>([]);
  const [invalidCatalogIds, setInvalidCatalogIds] = useState<string[]>([]);

  const addExpandedSections = (sectionId: string) => {
    setExpandedSections((prevExpandedSections: string[]) => {
      const isExpanded = prevExpandedSections.includes(sectionId);
      if (isExpanded) {
        return prevExpandedSections.filter((id) => id !== sectionId);
      } else {
        return [...prevExpandedSections, sectionId];
      }
    });
  };
  const { callApi: getSectiontApi, error: getApiError } = useAxios(
    ServerRoutes.createChecklist,
    GET,
    false,
    '',
    {},
    'shopping',
  );

  const { callApi: createSectionApi, error: createApiError } = useAxios(
    ServerRoutes.createChecklist,
    POST,
    false,
    '',
    {},
    'shopping',
  );

  useEffect(() => {
    getSections();
  }, []);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 1000,
        });
        setOpen(true);
      }
    };
    showError(createApiError);
    showError(getApiError);
  }, [createApiError, getApiError]);

  const showToast = (message: string, severity: Color) => {
    setToast({
      severity: severity,
      message: message,
      autoHideDuration: 3000,
    });
  };

  const getIdArraySortedByPosition = (objArray: any) => {
    const sortedArray = objArray.sort((a: any, b: any) => a.position - b.position);
    const idArray = sortedArray.map((obj: any) => obj.catalogId);

    return idArray;
  };

  const sortSectionsByPosition = (sections: Section[]): Section[] => {
    return sections
      .sort((a: Section, b: Section) => {
        const positionA = a?.position ?? 0;
        const positionB = b?.position ?? 0;
        return positionA - positionB;
      })
      .map((section: Section) => ({
        ...section,
        catalogIds: getIdArraySortedByPosition(section.catalogIds),
      }));
  };

  const getSections = async () => {
    await getSectiontApi({}, (res) => {
      if (res?.data) {
        const sortedSections: Section[] = sortSectionsByPosition(res.data);
        setSections(sortedSections);
      }
    });
  };

  const addNewSection = () => {
    const newSection = {
      id: v4(),
      sectionName: '',
      catalogIds: [],
      newSection: true,
    };
    setSections((preState) => [...preState, newSection]);
    showToast(SECTION_ADDED, 'success');
    setOpen(true);
  };

  const handleSectionChangeHandler = (sectionId: string, fieldName: string, updatedValue: string) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        if (fieldName === 'catalogIds') {
          return {
            ...section,
            catalogIds: [...section.catalogIds, updatedValue],
          };
        } else {
          return {
            ...section,
            [fieldName]: updatedValue,
          };
        }
      }
      return section;
    });

    setSections(updatedSections);
  };

  const validateSections = () => {
    for (const section of sections) {
      if (section.sectionName.trim() === '' || section.catalogIds.length === 0) {
        showToast(Messages.SECTION_INFO, 'error');
        setOpen(true);
        return false;
      }
    }
    return true;
  };

  const generateObjectsFromArray = (idsArray: any) => {
    return idsArray.map((id: string, index: number) => ({
      catalogId: id,
      position: index + 1,
    }));
  };

  const createChecklist = async () => {
    if (!validateSections()) {
      return false;
    }
    const updatedSections = sections.map((section: Section, index: number) => {
      if (section?.newSection) {
        const { id, newSection, ...rest } = section;
        console.log({ id, newSection, rest });
        section = {
          ...rest,
          catalogIds: generateObjectsFromArray(rest?.catalogIds),
          position: index + 1,
        };
      } else {
        section = {
          ...section,
          catalogIds: generateObjectsFromArray(section?.catalogIds),
          position: index + 1,
        };
      }

      return section;
    });

    createSectionApi({ sections: updatedSections }, (res) => {
      if (res?.data?.invalidCatalogIds?.length > 0) {
        const { invalidCatalogIds, message } = res?.data;
        if (invalidCatalogIds?.length > 0) {
          setInvalidCatalogIds(invalidCatalogIds);
        }
        if (message) {
          showToast(message, 'error');
          setOpen(true);
        }
      } else {
        setSections([]);
        showToast(SECTION_ADDED, 'success');
        setOpen(true);
        getSections();
      }
    });
  };

  const deleteCatalogId = (sectionId: string, catalogId: string) => {
    const updatedSections = sections.map((section: Section) => {
      if (section.id === sectionId) {
        const updatedCatalogIds = section.catalogIds.filter((item: string) => {
          return item !== catalogId;
        });
        return {
          ...section,
          catalogIds: updatedCatalogIds,
        };
      } else {
        return section;
      }
    });
    setSections(updatedSections);
    showToast(CATALOG_DELETED, 'success');
    setOpen(true);
  };

  const removeSection = (sectionId: string) => {
    const updatedSections = sections.filter((section: Section) => {
      return section.id !== sectionId;
    });
    setSections(updatedSections);
    showToast(SECTION_REMOVED, 'success');
    setOpen(true);
  };

  return (
    <Box className={classes.root} fontSize="sm">
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        autoHideDuration={toast?.autoHideDuration}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
      />
      <Box className={classes.label}>Manage Checklist</Box>
      {sections?.length > 0 &&
        sections?.map((section: Section) => (
          <ChecklistRow
            key={section?.id}
            section={section}
            handleSectionChangeHandler={handleSectionChangeHandler}
            deleteCatalogId={deleteCatalogId}
            removeSection={removeSection}
            getSections={getSections}
            addExpandedSections={addExpandedSections}
            expandedSections={expandedSections}
            showToast={showToast}
            setOpen={setOpen}
            invalidCatalogIds={invalidCatalogIds}
          />
        ))}
      <Box display="flex" justifyContent="flex-end" mt={10}>
        <Button variant="contained" className={classes.createChecklistButton} onClick={addNewSection}>
          + Add
        </Button>
      </Box>
      <Button
        variant="contained"
        className={classes.updateCheklistButton}
        onClick={createChecklist}
        disabled={!sections?.length}
      >
        Update
      </Button>
    </Box>
  );
};
export default Checklist;
