import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Cancel from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { RoomCatalogItemDTO } from 'types';
import Colors from 'theme';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { InputFieldWithLabel } from 'components';

interface Props {
  isSelectedItem?: boolean;
  onAddItem: (item: RoomCatalogItemDTO) => void;
  onRemoveItem: (item: RoomCatalogItemDTO) => void;
  items: RoomCatalogItemDTO[];
}

const ListItems = ({ isSelectedItem = false, onAddItem, onRemoveItem, items }: Props) => {
  const classes = useStyles();
  const [searchedValue, setSearchedValue] = useState('');

  const onSearch = (value: string) => {
    setSearchedValue(value);
  };

  return (
    <Grid item xs={12} sm={6}>
      <List className={classes.list}>
        <div className={classes.itemsHeading}>{isSelectedItem ? 'Selected Items' : 'Available Items'}</div>
        <div className={classes.input}>
          <InputFieldWithLabel
            value={searchedValue}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Search Item"
          />
        </div>
        {items
          .filter((item) => item.item_name.toLowerCase().includes(searchedValue.toLowerCase()))
          .map((item, index) =>
            isSelectedItem ? (
              <ListItem
                key={index}
                className={classes.selectedItem}
                style={{ background: index % 2 === 0 ? Colors.iceCold : Colors.mabel }}
              >
                <div>{item.item_name}</div>
                <IconButton className={classes.iconButton} onClick={() => onRemoveItem(item)}>
                  <Cancel className={classes.cancel} />
                </IconButton>
              </ListItem>
            ) : (
              <ListItem
                button
                onClick={() => onAddItem(item)}
                key={index}
                className={classes.listItem}
                style={{ background: index % 2 === 0 ? Colors.iceCold : Colors.mabel }}
              >
                {item.item_name}
              </ListItem>
            ),
          )}
      </List>
    </Grid>
  );
};

export default ListItems;

const useStyles = makeStyles((theme) =>
  createStyles({
    itemsHeading: {
      fontFamily: 'Cooper Lt BT',
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '25px',
      margin: '15px 0px',
    },
    iconButton: {
      padding: '3px',
    },
    cancel: {
      color: Colors.hilighted_text_secondary,
    },
    input: {
      marginBottom: '30px',
    },
    list: {
      border: `1px solid ${Colors.plain_black}`,
      width: '60%',
      height: (window.outerHeight * 50) / 100,
      paddingBottom: '0px',
      margin: '20px auto',
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
      overflowY: 'scroll',
      textAlign: 'center',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
    },
    listItem: {
      padding: '13px',
      color: Colors.solidPink,
      fontWeight: 500,
      '&:hover': {
        backgroundColor: `${Colors.neptune} !important`,
      },
    },
    selectedItem: {
      padding: '7px 10px',
      color: Colors.solidPink,
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);
