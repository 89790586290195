import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const MinimizeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="3" viewBox="0 0 14 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0.674H14V2.354H0V0.674Z" fill="#09B6B6" />
    </SvgIcon>
  );
};

export default MinimizeIcon;
