import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Colors from 'theme';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { EditBuildingNameModal, EditBuildingsModal, CircularProgress, Snackbar } from 'components';
import { useState } from 'react';
import { BuildingsAttributes, UniversityBuilding } from 'types';
import useAxios, { ServerRoutes } from 'api';
import { POST } from 'utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '300px',
      height: 'fit-content',
      maxHeight: '65vh',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      overflow: 'scroll',
      margin: '0px 3%',
      borderRadius: '8px',
      overflowX: 'hidden',
      boxShadow: Colors.infoBoxShadow,
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '300px',
      height: '50px',
      '&:hover': {
        transform: 'scale(1.04)',
        boxShadow: `${Colors.infoBoxShadow} 0px 0px 8px`,
        background: `${Colors.paleTurquoise} !important`,
      },
    },
    heading: {
      width: '300px',
      height: '50px',
      fontSize: '20px',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'space-between',
    },
    list: {
      padding: '0px',
    },
    listText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: '200px',
    },
    flex: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    stickyFooter: {
      bottom: 0,
      position: 'sticky',
      pointerEvents: 'none',
      paddingBottom: 4,
    },

    caret: {
      color: Colors.button_bg_active,
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.07)',
      },
    },
  }),
);

interface Props {
  buildings?: UniversityBuilding[];
  isEmployee: boolean;
  width?: string;
  selectedBuilding?: UniversityBuilding;
  setIsOpen: (open: boolean) => void;
  setSelectedBuilding: (selectedBuilding: UniversityBuilding) => void;
  onBuildingChange: (building: UniversityBuilding) => void;
  updateBuildingName: (buildingId: string, buildingName: string) => void;
  univeristyName: string;
  buildingsAttributes: BuildingsAttributes[];
  onChangeBuildingsAttributes: (buildingsAttributes: BuildingsAttributes[]) => void;
}

export default function SelectedListItem({
  buildings,
  selectedBuilding,
  setSelectedBuilding,
  width,
  setIsOpen,
  isEmployee,
  onBuildingChange,
  univeristyName,
  buildingsAttributes,
  updateBuildingName,
  onChangeBuildingsAttributes,
}: Props) {
  const classes = useStyles({ width });
  const [isEditingBuildingAttributes, setIsEditingBuildingAttributes] = useState(false);
  const [isEditingBuildingName, setIsEditingBuildingName] = useState(false);
  const [buildingName, setBuildingName] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [open, setOpen] = useState(false);

  const { callApi: saveBuildingsAttributes, loading: saveAttributesLoading } = useAxios(
    ServerRoutes.saveBuildingsAttributes,
    POST,
    false,
  );

  const handleSubmitBuildings = async () => {
    await saveBuildingsAttributes({ buildingAttributes: buildingsAttributes }, (res) => {
      if (res?.data?.code === 0) {
        setOpen(true);
      }
    });
  };

  const handleListItemClick = (building: UniversityBuilding) => {
    if (building.roomie_university_building_id !== selectedBuilding?.roomie_university_building_id) {
      setSelectedBuilding(building);
      onBuildingChange(building);
    }
  };

  return (
    <>
      <CircularProgress isLoading={saveAttributesLoading} />
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        message="Buildings Attributes updated successfully."
        severity="success"
      />
      <EditBuildingsModal
        isEditingBuildingAttributes={isEditingBuildingAttributes}
        onChangeIsEditingBuildingAttributes={setIsEditingBuildingAttributes}
        universityName={univeristyName}
        onSubmit={handleSubmitBuildings}
        buildingsAttributes={buildingsAttributes}
        onChangeBuildingsAttributes={onChangeBuildingsAttributes}
        handleEditBuilding={(buildingId, buildingName) => {
          setBuildingName(buildingName);
          setBuildingId(buildingId);
          setIsEditingBuildingName(true);
        }}
      />
      <EditBuildingNameModal
        isOpen={isEditingBuildingName}
        setIsOpen={setIsEditingBuildingName}
        buildingName={buildingName}
        buildingId={buildingId}
        onBuildingNameChange={setBuildingName}
        onSubmit={updateBuildingName}
        universityName={univeristyName}
      />

      <div className={classes.root}>
        <List component="nav" aria-label="secondary mailbox folder" className={classes.list}>
          <ListItem className={classes.heading}>
            <div className={classes.listText}>Residential Buildings</div>
            {isEmployee && (
              <div>
                <Tooltip title="Edit Buildings">
                  <EditIcon
                    className={classes.caret}
                    onClick={() => {
                      setIsEditingBuildingAttributes(true);
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </ListItem>
          {buildings?.map((building) => (
            <ListItem
              key={building?.roomie_university_building_id}
              className={classes.listItem}
              style={{
                background:
                  selectedBuilding?.roomie_university_building_id === building?.roomie_university_building_id
                    ? Colors.paleTurquoise
                    : Colors.plain_white,
              }}
              button
              onClick={() => handleListItemClick(building)}
            >
              <div className={classes.listText}>{building?.building_name}</div>
            </ListItem>
          ))}
        </List>
        {isEmployee && (
          <div className={clsx(classes.stickyFooter, classes.flex)}>
            <IconButton aria-label="add building" onClick={() => setIsOpen(true)} style={{ pointerEvents: 'auto' }}>
              <AddCircleIcon color="primary" fontSize="large" />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
}
