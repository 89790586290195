import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputFieldWithLabel } from 'components/fields';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useEffect, useState } from 'react';
import { TemplateInfo } from 'types';
import ButtonsContainer from './buttonsContainer';

const CheckBox = withStyles({
  root: {
    color: Colors.primary_text,
    '&$checked': {
      color: Colors.button_bg_active,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckboxContainer = styled.div`
  border-radius: 5px;
  background: ${Colors.plain_white};
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0px 40px;
`;

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (template: TemplateInfo) => void;
  buildingName: string;
}

interface Flags {
  roomNumberError: boolean;
  roomDescriptionError: boolean;
  configNameError: boolean;
  friendlyNameError: boolean;
  typeError: boolean;
}

const initialValues = {
  type: '',
  roomNumber: '',
  configName: '',
  roomDescription: '',
  friendlyName: '',
  isSecondary: false,
};

const AddTemplateModal = ({ isOpen, setIsOpen, onSubmit, buildingName }: Props) => {
  const classes = useStyles();
  const [templateInfo, setTemplateInfo] = useState<TemplateInfo>(initialValues);

  const [flags, setFlags] = useState<Flags>({
    typeError: false,
    roomNumberError: false,
    roomDescriptionError: false,
    configNameError: false,
    friendlyNameError: false,
  });

  useEffect(() => {
    if (!isOpen) {
      setTemplateInfo(initialValues);
      setFlags({
        typeError: false,
        roomNumberError: false,
        roomDescriptionError: false,
        configNameError: false,
        friendlyNameError: false,
      });
    }
  }, [isOpen]);

  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    const isError =
      !templateInfo.type ||
      !templateInfo.configName ||
      !templateInfo.friendlyName ||
      !templateInfo.roomDescription ||
      !templateInfo.roomNumber;
    setValueInFlags('typeError', !templateInfo.type);
    setValueInFlags('roomNumberError', !templateInfo.roomNumber);
    setValueInFlags('roomDescriptionError', !templateInfo.roomDescription);
    setValueInFlags('configNameError', !templateInfo.configName);
    setValueInFlags('friendlyNameError', !templateInfo.friendlyName);
    if (isError) return;
    await onSubmit(templateInfo);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Add Template</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div className={classes.label}>Building Name</div>
        <div className={classes.text}>{buildingName}</div>
        <form noValidate={false} autoComplete="on">
          <InputFieldWithLabel
            labelText="Type"
            placeholder="Enter Type Name"
            value={templateInfo?.type}
            setFieldValue={(item) => setTemplateInfo({ ...templateInfo, type: item })}
            isError={flags.typeError}
            errorMsg="Type can't be empty"
            mTop="10px"
          />
          <InputFieldWithLabel
            labelText="Config Name"
            placeholder="Enter Config Name"
            value={templateInfo?.configName}
            setFieldValue={(item) => setTemplateInfo({ ...templateInfo, configName: item })}
            isError={flags.configNameError}
            errorMsg="Config Name can't be empty"
            mTop="0px"
          />
          <InputFieldWithLabel
            labelText="Room Number"
            placeholder="Enter Room Number"
            value={templateInfo?.roomNumber}
            setFieldValue={(item) => setTemplateInfo({ ...templateInfo, roomNumber: item })}
            isError={flags.roomNumberError}
            errorMsg="Room Number can't be empty"
            mTop="0px"
          />
          <InputFieldWithLabel
            labelText="Room Description"
            placeholder="Emter Room Description"
            value={templateInfo?.roomDescription}
            setFieldValue={(item) => setTemplateInfo({ ...templateInfo, roomDescription: item })}
            isError={flags.roomDescriptionError}
            errorMsg="Room Description can't be empty"
            mTop="0px"
          />
          <InputFieldWithLabel
            labelText="Friendy Name"
            placeholder="Enter Friendly Name"
            value={templateInfo?.friendlyName}
            setFieldValue={(item) => setTemplateInfo({ ...templateInfo, friendlyName: item })}
            isError={flags.friendlyNameError}
            errorMsg="Friendly Name can't be empty"
            mTop="0px"
          />
          <CheckboxContainer>
            <FormControlLabel
              label="Is Secondary"
              checked={!!templateInfo?.isSecondary}
              control={<CheckBox />}
              className="label"
              onChange={() => setTemplateInfo({ ...templateInfo, isSecondary: !templateInfo?.isSecondary })}
            />
          </CheckboxContainer>
        </form>
        <ButtonsContainer cancelClicked={handleClose} submitClicked={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

export default AddTemplateModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    margin: '20px 0px 10px',
    color: Colors.secondary_text,
  },
}));
