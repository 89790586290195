import { SvgIconProps } from '@material-ui/core/SvgIcon';

const Zoom_in = (props: SvgIconProps) => {
  return (
    <svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2226 16.7667C20.4576 14.0293 21.1212 10.3325 19.9804 6.98723C18.8383 3.64209 16.0514 1.12309 12.6093 0.325075C9.16683 -0.474575 5.55513 0.5583 3.05636 3.05715C0.557579 5.556 -0.475504 9.16762 0.324282 12.6101C1.12257 16.0526 3.64144 18.8393 6.98644 19.9812C10.3316 21.122 14.0287 20.4583 16.7659 18.2234L21.0719 22.5188C21.4744 22.8946 22.1034 22.8839 22.4926 22.4935C22.8831 22.1043 22.8938 21.4752 22.5179 21.0728L18.2226 16.7667ZM2.14453 10.3083C2.14586 7.58815 3.50258 5.04799 5.76168 3.53419C8.02065 2.01889 10.8862 1.72834 13.4023 2.75989C15.9198 3.79007 17.7578 6.00639 18.3054 8.67043C18.853 11.3345 18.0388 14.0958 16.133 16.0364L16.0891 16.0738L16.0517 16.1177H16.0504C14.5151 17.633 12.4428 18.4793 10.2852 18.47C8.12887 18.4606 6.06315 17.5984 4.53979 16.0711C3.01651 14.5438 2.16087 12.4754 2.15821 10.3178L2.14453 10.3083Z"
        fill={props.fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0739 9.28506H11.3307V7.54451C11.3307 6.97945 10.8722 6.52098 10.3071 6.52098C9.74208 6.52098 9.28361 6.97945 9.28361 7.54451V9.28506H7.54306C6.978 9.28506 6.51953 9.74353 6.51953 10.3086C6.51953 10.8736 6.978 11.3321 7.54306 11.3321H9.28361V13.0727C9.28361 13.6377 9.74208 14.0949 10.3071 14.0949C10.8722 14.0949 11.3307 13.6377 11.3307 13.0713V11.3321H13.0712C13.6363 11.3321 14.0934 10.8736 14.0934 10.3086C14.0934 9.74353 13.6363 9.28505 13.0699 9.28505L13.0739 9.28506Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default Zoom_in;
