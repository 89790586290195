import { makeStyles } from '@material-ui/core/styles';
import {
  BedViewMode,
  ItemBedFeature,
  ItemBedFeatureSlot,
  ViewMode,
  ItemModel,
  ItemModelSubType,
  ItemModelType,
  ItemMattressFeature,
  ItemMattressFeatureSlot,
  RoomScene,
  SurfaceType,
  Vector3,
} from '@roomie-engineering/room-viewer';
import { CancelIcon, CustomButton, CustomIconButtonSecondary, CustomSelect, CustomUploadButton } from 'components';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Colors from 'theme';

interface Flags {
  clearMeasurementHovered: boolean;
}

interface ButtonBarBottomProps {
  roomScene: RoomScene | null;
  viewMode: ViewMode;
  selectedItem: ItemModel | undefined;
  showMeasuremet?: boolean;
}

const ButtonBarBottom = ({
  roomScene,
  viewMode,
  selectedItem,
  showMeasuremet = false,
}: ButtonBarBottomProps): JSX.Element => {
  const BOTTOM = '65vh';
  const TOP = '25vh';

  const buttonBarBottomRef = useRef<HTMLDivElement | null>(null);
  const [buttonBarBottomPosition, setButtonBarBottomPosition] = useState(BOTTOM);

  const toggleButtonBarBottomPosition = () => {
    if (buttonBarBottomPosition === BOTTOM) setButtonBarBottomPosition(TOP);
    else setButtonBarBottomPosition(BOTTOM);
  };

  function getArrayWithNumberBaseEnumItems<T>(numberEnum: T): Array<{ label: keyof T; value: T[keyof T] }> {
    const arrayWithEnumItems = [];
    for (const item in numberEnum) {
      if (Number.isNaN(Number(item))) {
        arrayWithEnumItems.push({
          label: numberEnum[numberEnum[item] as unknown as Extract<keyof T, string>] as unknown as keyof T,
          value: numberEnum[item],
        });
      }
    }
    return arrayWithEnumItems;
  }

  const EMPTY_LIST = [
    {
      label: '',
      value: '',
    },
  ];

  const getOptionValueFromLabel = (label: string, list: Array<{ label: string; value: string }>): string => {
    for (let i = 0; i < list.length; i++) if (list[i].label === label) return list[i].value;
    return label;
  };

  const getOptionLabelFromValue = (value: string, list: Array<{ label: string; value: string }>): string => {
    for (let i = 0; i < list.length; i++) if (list[i].value === value) return list[i].label;
    return value;
  };

  function getArrayWithStrings(strings: string[]): Array<{ label: string; value: string }> {
    const arrayWithStrings = [];
    for (let i = 0; i < strings.length; i++) {
      arrayWithStrings.push({
        label: strings[i],
        value: strings[i],
      });
    }
    return arrayWithStrings;
  }

  // ts enum runtime is a map of key to value and value to key
  // but we only want "KEY": 1, and not 1: "KEY"
  function getEnumLabelValuePairs<T>(enumeration: T) {
    const results = getArrayWithNumberBaseEnumItems(enumeration);
    // return results.map((value) => ({ label: enumeration[value], value }));
    return results;
  }

  const TYPE = getEnumLabelValuePairs(ItemModelType);
  const [type, setType] = useState(TYPE[0].label);
  //console.log(JSON.stringify(TYPE, null, 2));
  const onTypeChange = (value: string, label: string): void => {
    setType(ItemModelType[label as keyof typeof ItemModelType]);
    selectedItem?.setType(label as ItemModelType);
    //console.log(label + ' -> ' + value);
  };

  const SUB_TYPE = getEnumLabelValuePairs(ItemModelSubType);
  const [subType, setSubType] = useState(SUB_TYPE[0].label);
  //console.log(JSON.stringify(TYPE, null, 2));
  const onSubTypeChange = (value: string, label: string): void => {
    setSubType(ItemModelSubType[label as keyof typeof ItemModelSubType]);
    selectedItem?.setSubType(label as ItemModelSubType);
    //console.log(label + ' -> ' + value);
  };

  const SURFACE_TYPE = getEnumLabelValuePairs(SurfaceType);
  const [surfaceType, setSurfaceType] = useState(SURFACE_TYPE[0].label);
  //console.log(JSON.stringify(SURFACE_TYPE, null, 2));
  const onSurfaceTypeChange = (value: string, label: string): void => {
    setSurfaceType(SurfaceType[label as keyof typeof SurfaceType]);
    selectedItem?.setSurfaceType(label as SurfaceType);
    selectedItem?.setParentAndAnchor();
    selectedItem?.place();
    //console.log(label + ' -> ' + value);
  };

  const [flags, setFlags] = useState<Flags>({
    clearMeasurementHovered: false,
  });
  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const clearMeasurementPressed = () => {
    setValueInFlags('clearMeasurementHovered', false);
    roomScene?.measureTool.deactivate();
    roomScene?.surfaceMeasureTool.deactivate();
  };

  const zoomIn = (turntable?: boolean) => {
    if (roomScene?.getViewMode() === ViewMode.Room) {
      const bed = selectedItem?.getBedFeature();
      if (bed) {
        roomScene?.switchViewToBed(bed, turntable);
        setButtonBarBottomPosition(BOTTOM); // move the menu to the top
      } else if (selectedItem) roomScene?.switchViewToItem(selectedItem, turntable);
    }
  };

  const zoomOut = () => {
    if (roomScene?.getViewMode() === ViewMode.BelowBed) setButtonBarBottomPosition(BOTTOM); // move the menu to the bottom

    if (roomScene?.getViewMode() !== ViewMode.Room) {
      roomScene?.switchViewToRoom();
    }
  };

  const getItem = (): ItemModel | undefined => {
    if (selectedItem) return selectedItem;
    else return roomScene?.cameraItem;
  };

  // if we are in bed view camera modes return the camera item (the bed) otherwise check if the selected item has a bed feature and return if it does
  const getBedItem = (): ItemModel | undefined => {
    if (roomScene?.getViewMode() === ViewMode.Bed || roomScene?.getViewMode() === ViewMode.BelowBed)
      return roomScene?.cameraItem;
    else if (selectedItem?.getBedFeature()) return selectedItem;
  };

  const switchToBed = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) {
      roomScene?.switchViewToBed(bed, roomScene?.camera.isTurntableOn()); // pass in current turntable setting since these are used in both zoom in and zoom in turntable
      setButtonBarBottomPosition(BOTTOM); // move the menu to the bottom
    }
  };

  const resetBed = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) {
      bed.clearSlotItems(ItemBedFeatureSlot.Riser);
      bed.clearSlotItems(ItemBedFeatureSlot.Skirt);
      bed.removeAllMattressesAndMattressToppers();
      bed.addDefaultItemToSlot(ItemBedFeatureSlot.Mattress);
      refreshMaterial();
    }
  };

  const [showBedSetupButtons, setShowBedSetupButtons] = useState(false);

  // TODO once we switch to the new UI and complete EN-75 we need to update this to build it from enums vs hardcoding
  const BED_ITEM_TYPE_SUBTYPE = [
    {
      label: 'Riser - Standard',
      value: 'Riser|Standard',
    },
    {
      label: 'Mattress - TwinXL',
      value: 'Mattress|TwinXL',
    },
    {
      label: 'Mattress - FullXL',
      value: 'Mattress|FullXL',
    },
    {
      label: 'Mattress - Queen',
      value: 'Mattress|Queen',
    },
    {
      label: 'MattressTopper - TwinXL2InchUpgrade',
      value: 'MattressTopper|TwinXL2InchUpgrade',
    },
    {
      label: 'MattressTopper - TwinXL3InchUpgrade',
      value: 'MattressTopper|TwinXL3InchUpgrade',
    },
    {
      label: 'MattressTopper - TwinXL4InchUpgrade',
      value: 'MattressTopper|TwinXL4InchUpgrade',
    },
    {
      label: 'MattressTopper - TwinXLRoomieMidUpgrade',
      value: 'MattressTopper|TwinXLRoomieMidUpgrade',
    },
    {
      label: 'MattressTopper - TwinXLRoomieFullXLUpgrade',
      value: 'MattressTopper|TwinXLRoomieFullXLUpgrade',
    },
    {
      label: 'Skirt - TwinXL',
      value: 'Skirt|TwinXL',
    },
    {
      label: 'Skirt - FullXL',
      value: 'Skirt|FullXL',
    },
    {
      label: 'Skirt - Queen',
      value: 'Skirt|Queen',
    },
    {
      label: 'Sheets - TwinXL',
      value: 'Sheets|TwinXL',
    },
    {
      label: 'Sheets - FullXL',
      value: 'Sheets|FullXL',
    },
    {
      label: 'Sheets - Queen',
      value: 'Sheets|Queen',
    },
    {
      label: 'Sheets - TwinXL2InchUpgrade',
      value: 'Sheets|TwinXL2InchUpgrade',
    },
    {
      label: 'Sheets - TwinXL3InchUpgrade',
      value: 'Sheets|TwinXL3InchUpgrade',
    },
    {
      label: 'Sheets - TwinXL4InchUpgrade',
      value: 'Sheets|TwinXL4InchUpgrade',
    },
    {
      label: 'Sheets - TwinXLRoomieMidUpgrade',
      value: 'Sheets|TwinXLRoomieMidUpgrade',
    },
    {
      label: 'Sheets - TwinXLRoomieFullXLUpgrade',
      value: 'Sheets|TwinXLRoomieFullXLUpgrade',
    },
    {
      label: 'Cover - TwinXL',
      value: 'Cover|TwinXL',
    },
    {
      label: 'Cover - FullXL',
      value: 'Cover|FullXL',
    },
    {
      label: 'Cover - Queen',
      value: 'Cover|Queen',
    },
    {
      label: 'Cover - TwinXLRoomieMidUpgrade',
      value: 'Cover|TwinXLRoomieMidUpgrade',
    },
    {
      label: 'Cover - TwinXLRoomieFullXLUpgrade',
      value: 'Cover|TwinXLRoomieFullXLUpgrade',
    },
    {
      label: 'Pillow - Standard',
      value: 'Pillow|Standard',
    },
    {
      label: 'ShamPillow - Standard',
      value: 'ShamPillow|Standard',
    },
    {
      label: 'DecorativePillow - Standard',
      value: 'DecorativePillow|Standard',
    },
    {
      label: 'AccentPillow - Standard',
      value: 'AccentPillow|Standard',
    },
    {
      label: 'ThrowBlanket - TwinXL',
      value: 'ThrowBlanket|TwinXL',
    },
    {
      label: 'ThrowBlanket - FullXL',
      value: 'ThrowBlanket|FullXL',
    },
    {
      label: 'ThrowBlanket - Queen',
      value: 'ThrowBlanket|Queen',
    },
    {
      label: 'ThrowBlanket - TwinXLRoomieMidUpgrade',
      value: 'ThrowBlanket|TwinXLRoomieMidUpgrade',
    },
    {
      label: 'ThrowBlanket - TwinXLRoomieFullXLUpgrade',
      value: 'ThrowBlanket|TwinXLRoomieFullXLUpgrade',
    },
    // {
    //   label: 'Caddie - Standard',
    //   value: 'Caddie|Standard',
    // },
    {
      label: 'Headboard - TwinXL',
      value: 'Headboard|TwinXL',
    },
    {
      label: 'Headboard - FullXL',
      value: 'Headboard|FullXL',
    },
    {
      label: 'Headboard - Queen',
      value: 'Headboard|Queen',
    },
  ];
  const [bedItemTypeSubType, setBedItemTypeSubType] = useState('');
  const [selectedBedItemTypeSubTypeValue, setSelectedBedItemTypeSubTypeValue] = useState(
    BED_ITEM_TYPE_SUBTYPE[0].value,
  );

  const onBedItemTypeSubTypeChange = (value: string, label: string): void => {
    setBedItemTypeSubType(label);
    setSelectedBedItemTypeSubTypeValue(value);
    //console.log(label + ' -> ' + value);
  };

  const getBedItemTypeSubType = (): { type: ItemModelType; subType: ItemModelSubType } => {
    const s = selectedBedItemTypeSubTypeValue.split('|');
    const type = s[0] as ItemModelType;
    const subType = s[1] as ItemModelSubType;
    return { type, subType };
  };

  const addDefaultBedItem = () => {
    const bedItem = getBedItem();
    if (!bedItem) return;

    const typeSubType = getBedItemTypeSubType();
    if (typeSubType.type && typeSubType.subType) {
      // add item
      const item = roomScene?.selectedRoom?.addDefaultItem({
        type: typeSubType.type,
        subType: typeSubType.subType,
      });
      if (item) addBedItem(item);
      refreshMaterial();
    }
  };

  const onUploadBedItem = (evt: ChangeEvent<HTMLInputElement>): void => {
    const bedItem = getBedItem();
    if (!bedItem) return;

    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        const name = files[i].name;
        // use the selected type/subtype as default
        const typeSubType = getBedItemTypeSubType();
        if (!typeSubType.type || !typeSubType.subType || files.length > 1) {
          // if there are multiple files attempt to auto detect type/subtype from filename
          const foundType = ItemModel.findTypeFromFilename(name);
          if (foundType) {
            let foundSubType = ItemModel.findSubTypeFromFilename(name);
            if (!foundSubType) foundSubType = ItemModel.getDefaultItemModelSubType(foundType);
            typeSubType.type = foundType;
            typeSubType.subType = foundSubType;
          }
        }
        // add item
        const item = roomScene?.selectedRoom?.addItem(
          {
            title: name,
            catalogId: '0',
            type: typeSubType.type,
            subType: typeSubType.subType,
            assetUrl: url,
            deletable: true,
          },
          false,
        );
        if (item) addBedItem(item);
      }
      refreshMaterial();
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';
  };

  const addBedItem = (item: ItemModel) => {
    const bedItem = getBedItem();
    if (!bedItem) return;

    item.asset.getLoadedObservable().add(() => {
      const type = item.type;
      if (type === ItemModelType.Riser) {
        bedItem?.getBedFeature()?.addSlotItem(ItemBedFeatureSlot.Riser, item);
      } else if (type === ItemModelType.Mattress) {
        const bed = bedItem?.getBedFeature();
        if (bed) {
          bed.removeMattressAndMattressTopper();
          bed.setSlotItem(ItemBedFeatureSlot.Mattress, item);
        }
      } else if (type === ItemModelType.MattressTopper) {
        const bed = bedItem?.getBedFeature();
        if (bed) {
          // see if there is a mattress and either reset it or add a default one if there isn't
          const mattress = bed.getSlotItem(ItemBedFeatureSlot.Mattress);
          if (mattress) mattress.getMattressFeature()?.clearAllSlotItems();
          else bed.addDefaultItemToSlot(ItemBedFeatureSlot.Mattress);
          // replace item
          bed.replaceSlotItem(ItemBedFeatureSlot.MattressTopper, item);
        }
      } else if (type === ItemModelType.Skirt) {
        bedItem?.getBedFeature()?.replaceSlotItem(ItemBedFeatureSlot.Skirt, item);
      } else if (type === ItemModelType.Sheets) {
        bedItem?.getBedFeature()?.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.Sheets, item);
      } else if (type === ItemModelType.Cover) {
        bedItem?.getBedFeature()?.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.Cover, item);
      } else if (type === ItemModelType.Pillow) {
        const mattress = bedItem?.getBedFeature()?.getMattressFeature();
        if (mattress) {
          // if max count is reached reset
          const count = mattress.getSlotCount(ItemMattressFeatureSlot.Pillow);
          if (count === mattress.getSlotCountMax(ItemMattressFeatureSlot.Pillow))
            mattress.clearSlotItems(ItemMattressFeatureSlot.Pillow);
          // add the new item
          mattress.addSlotItem(ItemMattressFeatureSlot.Pillow, item);
        }
      } else if (type === ItemModelType.ShamPillow) {
        const mattress = bedItem?.getBedFeature()?.getMattressFeature();
        if (mattress) {
          // if max count is reached reset
          const count = mattress.getSlotCount(ItemMattressFeatureSlot.ShamPillow);
          if (count === mattress.getSlotCountMax(ItemMattressFeatureSlot.ShamPillow))
            mattress.clearSlotItems(ItemMattressFeatureSlot.ShamPillow);
          // add the new item
          mattress.addSlotItem(ItemMattressFeatureSlot.ShamPillow, item);
        }
      } else if (type === ItemModelType.DecorativePillow) {
        const mattress = bedItem?.getBedFeature()?.getMattressFeature();
        if (mattress) {
          // if max count is reached reset
          const count = mattress.getSlotCount(ItemMattressFeatureSlot.DecorativePillow);
          if (count === mattress.getSlotCountMax(ItemMattressFeatureSlot.DecorativePillow))
            mattress.clearSlotItems(ItemMattressFeatureSlot.DecorativePillow);
          // add the new item
          mattress.addSlotItem(ItemMattressFeatureSlot.DecorativePillow, item);
        }
      } else if (type === ItemModelType.AccentPillow) {
        const mattress = bedItem?.getBedFeature()?.getMattressFeature();
        if (mattress) {
          // if max count is reached reset
          const count = mattress.getSlotCount(ItemMattressFeatureSlot.AccentPillow);
          if (count === mattress.getSlotCountMax(ItemMattressFeatureSlot.AccentPillow))
            mattress.clearSlotItems(ItemMattressFeatureSlot.AccentPillow);
          // add the new item
          mattress.addSlotItem(ItemMattressFeatureSlot.AccentPillow, item);
        }
      } else if (type === ItemModelType.ThrowBlanket) {
        bedItem?.getBedFeature()?.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.ThrowBlanket, item);
      } else if (type === ItemModelType.Headboard) {
        bedItem?.getBedFeature()?.getMattressFeature()?.replaceSlotItem(ItemMattressFeatureSlot.Headboard, item);
      }
      refreshMaterial();
    });
  };

  const removeBedItem = () => {
    const bedItem = getBedItem();
    if (!bedItem) return;

    const typeSubType = getBedItemTypeSubType();
    if (typeSubType.type && typeSubType.subType) {
      const type = typeSubType.type;
      if (type === ItemModelType.Riser) {
        bedItem?.getBedFeature()?.clearSlotItems(ItemBedFeatureSlot.Riser);
      } else if (type === ItemModelType.Mattress) {
        bedItem?.getBedFeature()?.removeMattressAndMattressTopper();
      } else if (type === ItemModelType.MattressTopper) {
        bedItem?.getBedFeature()?.clearSlotItem(ItemBedFeatureSlot.MattressTopper);
      } else if (type === ItemModelType.Skirt) {
        bedItem?.getBedFeature()?.clearSlotItem(ItemBedFeatureSlot.Skirt);
      } else if (type === ItemModelType.Sheets) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItem(ItemMattressFeatureSlot.Sheets);
      } else if (type === ItemModelType.Cover) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItem(ItemMattressFeatureSlot.Cover);
      } else if (type === ItemModelType.Pillow) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.Pillow);
      } else if (type === ItemModelType.ShamPillow) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.ShamPillow);
      } else if (type === ItemModelType.DecorativePillow) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.DecorativePillow);
      } else if (type === ItemModelType.AccentPillow) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.AccentPillow);
      } else if (type === ItemModelType.ThrowBlanket) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.ThrowBlanket);
      } else if (type === ItemModelType.Headboard) {
        bedItem?.getBedFeature()?.getMattressFeature()?.clearSlotItems(ItemMattressFeatureSlot.Headboard);
      }
      refreshMaterial();
    }
  };

  const clearBedItems = () => {
    getBedItem()?.getBedFeature()?.removeAllMattressesAndMattressToppers();
    refreshMaterial();
  };

  const addDefaultRisers = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) {
      // one for each leg
      bed.addDefaultItemToSlot(ItemBedFeatureSlot.Riser);
      bed.addDefaultItemToSlot(ItemBedFeatureSlot.Riser);
      bed.addDefaultItemToSlot(ItemBedFeatureSlot.Riser);
      bed.addDefaultItemToSlot(ItemBedFeatureSlot.Riser);
    }
  };

  const removeRisers = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) bed.clearSlotItems(ItemBedFeatureSlot.Riser);
  };

  const removeTopperOrMattress = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) {
      for (let f = 0; f < bed.getFrameCount(); f++) {
        if (bed.getTopperMattressFeature(f)) bed.clearSlotItem(ItemBedFeatureSlot.MattressTopper, f);
        else bed.clearSlotItem(ItemBedFeatureSlot.Mattress, f);
      }
    }
  };

  const addDefaultBeddingItem = (slot: ItemMattressFeatureSlot) => {
    const mattress = getBedItem()?.getBedFeature()?.getMattressFeature();
    if (mattress) mattress.addDefaultItemToSlot(slot);
  };

  const removeBeddingItem = (slot: ItemMattressFeatureSlot) => {
    const mattress = getBedItem()?.getBedFeature()?.getMattressFeature();
    if (mattress) mattress.clearSlotItem(slot, mattress.getSlotCount(slot) - 1);
  };

  const removePillows = () => {
    const mattress = getBedItem()?.getBedFeature()?.getMattressFeature();
    if (mattress) {
      mattress.clearSlotItems(ItemMattressFeatureSlot.AccentPillow);
      mattress.clearSlotItems(ItemMattressFeatureSlot.DecorativePillow);
      mattress.clearSlotItems(ItemMattressFeatureSlot.ShamPillow);
      mattress.clearSlotItems(ItemMattressFeatureSlot.Pillow);
    }
  };

  const switchToBelowBed = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) {
      roomScene?.switchViewToBelowBed(bed, BedViewMode.Auto, roomScene?.camera.isTurntableOn()); // pass in current turntable setting since these are used in both zoom in and zoom in turntable
      roomScene?.selectedRoom?.setSelectedItem(); // deselect the bed
      setButtonBarBottomPosition(TOP); // move the menu to the top
    }
  };

  const switchBelowBedSide = () => {
    const bed = getBedItem()?.getBedFeature();
    if (bed) roomScene?.switchBedViewSide();
  };

  // TODO this is only necessary if we add slots for below bed items attached to the frame in some way (just use normal upload/add test item for below bed testing)
  // const onUploadBelowBedItems = (evt: ChangeEvent<HTMLInputElement>): void => {
  //   const files = evt.target.files;
  //   if (files && files.length > 0) {
  //     for (let i = 0; i < files.length; i++) {
  //       const url = URL.createObjectURL(files[i]);
  //       const name = files[i].name;
  //       // attempt to auto detect type/subtype from filename
  //       const type = ItemModel.findTypeFromFilename(name);
  //       let subtype;
  //       if (type) {
  //         subtype = ItemModel.findSubTypeFromFilename(name);
  //         if (!subtype) subtype = ItemModelSubType.Standard;
  //       }
  //       roomScene?.selectedRoom?.addItem(
  //         {
  //           title: name,
  //           catalogId: '0',
  //           type: type,
  //           subType: subtype,
  //           surfaceType: 'Floor',
  //           url: url,
  //           deletable: true,
  //           place: true,
  //         },
  //         true,
  //       );
  //     }
  //   }
  //   // reset the value so uploading the same item twice works
  //   evt.target.value = '';
  // };

  // if we have a bed feature and a bed item type is selected find the item for that
  const getMaterialItem = (): ItemModel | undefined => {
    const bedFeature = getItem()?.getBedFeature();
    if (bedFeature) {
      const typeSubType = getBedItemTypeSubType();
      if (typeSubType.type) {
        if (ItemBedFeature.isBedType(typeSubType.type)) {
          const slot = ItemBedFeature.getDefaultSlot(typeSubType.type);
          if (slot !== undefined) return bedFeature.getSlotItem(slot);
        } else if (ItemMattressFeature.isMattressType(typeSubType.type)) {
          const slot = ItemMattressFeature.getDefaultSlot(typeSubType.type);
          if (slot !== undefined) return bedFeature.getMattressFeature()?.getSlotItem(slot);
        }
      }
    }
    return getItem();
  };

  const [itemVariantList, setItemVariantList] = useState(EMPTY_LIST);
  const updateItemVariantList = (): void => {
    const materialItem = getMaterialItem();
    if (materialItem) {
      if (materialItem.asset.isLoaded()) {
        const variantAvailable = materialItem.asset.getContainerVariants();
        setItemVariantList(variantAvailable ? getArrayWithStrings(variantAvailable) : EMPTY_LIST);
        const variants = materialItem.asset.variants;
        const variant = variants && variants.length > 0 ? variants[0] : '';
        setItemVariant(variant);
      } else {
        materialItem.asset.getLoadedObservable().add(() => {
          updateItemVariantList();
        });
      }
    } else {
      setItemVariantList(EMPTY_LIST);
      setItemVariant('');
    }
  };

  const [itemVariant, setItemVariant] = useState('');
  const onItemVariantChange = (value: string, label: string): void => {
    setItemVariant(label);
    const materialItem = getMaterialItem();
    materialItem?.asset.selectVariants([label]);
    //console.log(label + ' -> ' + value);
  };

  const [materialNameList, setMaterialNameList] = useState(EMPTY_LIST);
  const updateMaterialNameList = (): void => {
    const materialItem = getMaterialItem();
    if (materialItem) {
      if (materialItem.asset.isLoaded()) {
        //console.log('updateMaterialNameList');
        const existingMaterialName = getOptionValueFromLabel(materialName, materialNameList); // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
        const materialNames = materialItem.asset.getContainerMaterialNames(); // filter out variants but we need to wait until loaded to know the variants???  should we be waiting here???  maybe same issue waiting to get bed ui
        const materialNamesModified = [];
        if (materialNames) {
          for (let i = 0; i < materialNames.length; i++) {
            const materialFound = materialItem.getMaterial(materialNames[i]);
            materialNamesModified.push({
              label: materialFound ? materialNames[i] + ' (' + materialFound.title + ')' : materialNames[i],
              value: materialNames[i],
            });
          }
        }
        const materialNamesNew = materialNamesModified.length > 0 ? materialNamesModified : EMPTY_LIST;
        setMaterialNameList(materialNamesNew);
        const materialNameDefault = materialItem.asset.getContainerMaterialDefault();
        const materialNameNew = materialNames?.includes(existingMaterialName)
          ? existingMaterialName
          : materialNameDefault
          ? materialNameDefault.name
          : '';
        setMaterialName(getOptionLabelFromValue(materialNameNew, materialNamesNew)); // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
      } else {
        materialItem.asset.getLoadedObservable().add(() => {
          updateMaterialNameList();
        });
      }
    } else {
      setMaterialNameList(EMPTY_LIST);
      setMaterialName('');
    }
  };

  const [materialName, setMaterialName] = useState('');
  const onMaterialNameChange = (value: string, label: string): void => {
    setMaterialName(label);
    //console.log('onMaterialNameChange: ' + label + ' / ' + value);
  };

  const [materialVariantList, setMaterialVariantList] = useState(EMPTY_LIST);
  const updateMaterialVariantList = (): void => {
    const material = getMaterialItem()?.getMaterial(getOptionValueFromLabel(materialName, materialNameList)); // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
    if (material) {
      if (material.asset.isLoaded()) {
        //console.log('updateMaterialVariantList');
        const variantsAvailable = material.asset.getContainerVariants();
        setMaterialVariantList(variantsAvailable ? getArrayWithStrings(variantsAvailable) : EMPTY_LIST);
        const variants = material?.asset.variants;
        const variant = variants && variants.length > 0 ? variants[0] : '';
        setMaterialVariant(variant);
      } else {
        material.asset.getLoadedObservable().add(() => {
          updateMaterialVariantList();
        });
      }
    } else {
      setMaterialVariantList(EMPTY_LIST);
      setMaterialVariant('');
    }
  };

  const [materialVariant, setMaterialVariant] = useState('');
  const onMaterialVariantChange = (value: string, label: string): void => {
    setMaterialVariant(label);
    getMaterialItem()?.getMaterial(getOptionValueFromLabel(materialName, materialNameList))?.updateVariant(label); // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
    //console.log('onMaterialVariantChange: ' + label + ' / ' + value);
  };

  const refreshMaterial = (): void => {
    updateItemVariantList();
    updateMaterialNameList();
  };

  const onUploadMaterial = (evt: ChangeEvent<HTMLInputElement>): void => {
    const item2 = getMaterialItem();
    if (!item2) return;

    const files = evt.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = URL.createObjectURL(files[i]);
        const name = files[i].name;

        item2.setMaterial({
          title: name,
          name: getOptionValueFromLabel(materialName, materialNameList), // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
          assetUrl: url,
          assetVariant: materialVariant.length > 0 ? materialVariant : undefined,
        });
      }

      updateMaterialNameList();
    }
    // reset the value so uploading the same item twice works
    evt.target.value = '';
  };

  const TEST_MATERIALS = [
    {
      label: '4" Pattern',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-pattern-4inch-00.glb',
    },
    {
      label: '8" Pattern',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-pattern-8inch-00.glb',
    },
    {
      label: '12" Pattern',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-pattern-12inch-00.glb',
    },
    {
      label: 'Cloth',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-fabric-cloth.glb',
    },
    {
      label: 'Grid',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-fabric-grid.glb',
    },
    {
      label: 'Flannel',
      value: 'https://roomie-web.s3.us-west-1.amazonaws.com/web/dev/models/qa/materials/material-fabric-flannel.glb',
    },
  ];
  const [testMaterial, setTestMaterial] = useState(TEST_MATERIALS[0].label);
  const [selectedTestMaterial, setSelectedTestMaterial] = useState(TEST_MATERIALS[0].label);
  const [selectedTestMaterialValue, setSelectedTestMaterialValue] = useState(TEST_MATERIALS[0].value);

  const onTestMaterialChange = (value: string, label: string): void => {
    setTestMaterial(label);
    setSelectedTestMaterial(label);
    setSelectedTestMaterialValue(value);
    //console.log(label + ' -> ' + value);
  };

  const onTestMaterialAdd = (): void => {
    const item2 = getMaterialItem();
    if (!item2) return;

    console.log('adding test material: ' + selectedTestMaterial + ' (' + selectedTestMaterialValue + ')');

    item2.setMaterial({
      name: getOptionValueFromLabel(materialName, materialNameList), // MATERIAL NAME USES LABEL TO MAKE SELECT COMPONENT WORK
      assetUrl: selectedTestMaterialValue,
      assetVariant: materialVariant.length > 0 ? materialVariant : undefined,
    });

    updateItemVariantList();
    updateMaterialNameList();
  };

  const clearMaterials = (): void => {
    getMaterialItem()?.clearMaterials();
    updateMaterialNameList();
  };

  // const adjustSkirt = (up: boolean, instant?: boolean) => {
  //   const bed = getBedItem()?.getBedFeature();
  //   if (bed) {
  //     const skirt = bed.getSlotItem(ItemBedFeatureSlot.Skirt);
  //     if (skirt) {
  //       const animationGroup = skirt.getAnimationGroup();
  //       if (animationGroup) {
  //         // find the ratio between the size of the skirt and the space below it
  //         const size = skirt.modelBounds.size.y;
  //         const space = skirt.transformLocalToRoom(Vector3.Zero()).y;
  //         const ratio = space / size;

  //         // find the animation length and remove ease in/out time at start/end of the animation (assumed to be 1/8 at either end so 1/4 total)
  //         const animLength = animationGroup.to - animationGroup.from;
  //         const animLengthAdjusted = animLength - animLength * 0.1;

  //         // find the animation frame for up and down (up is at the end and down is at the beginning)
  //         // if the ratio is 1 or more (space is bigger than the skirt) show the entire skirt
  //         // otherwise adjust by the ratio using the part of the animation folding the skirt (minus ease in/out)
  //         const animUp = animLength;
  //         const animDown = ratio >= 1.0 ? 0 : animLength - animLengthAdjusted * ratio;

  //         // trigger the animation
  //         //animationGroup.loopAnimation = false;
  //         if (instant) {
  //           if (up) animationGroup.start(false, 1.0, animUp, animUp);
  //           else animationGroup.start(false, 1.0, animDown, animDown);
  //         } else {
  //           if (up) animationGroup.start(false, 1.0, animDown, animUp);
  //           else animationGroup.start(false, 1.0, animUp, animDown);
  //         }

  //         console.log(
  //           'size=%s, space=%s, ratio=%s, animName=%s animLength=%s, animUp=%s, animDown=%s, from=%s, to=%s',
  //           size,
  //           space,
  //           ratio,
  //           animationGroup.name,
  //           animLength,
  //           animUp,
  //           animDown,
  //           animationGroup.from,
  //           animationGroup.to,
  //         );
  //         console.log(animationGroup);
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (buttonBarBottomRef.current) {
      buttonBarBottomRef.current.style.marginTop = buttonBarBottomPosition;
    }
  }, [buttonBarBottomPosition]);

  useEffect(() => {
    const type = selectedItem?.getType() ?? TYPE[0].label;
    setType(ItemModelType[type as keyof typeof ItemModelType]);
    const subType = selectedItem?.getSubType() ?? SUB_TYPE[0].label;
    setSubType(ItemModelSubType[subType as keyof typeof ItemModelSubType]);
    const surfaceType = selectedItem?.getSurfaceType() ?? SURFACE_TYPE[0].label;
    setSurfaceType(SurfaceType[surfaceType as keyof typeof SurfaceType]);
    //console.log('type=' + type + ', ' + 'subType=' + subType + ', ' + 'surfaceType=' + surfaceType + '');
  }, [getItem()]);

  useEffect(() => {
    refreshMaterial();
  }, [getMaterialItem()]);

  useEffect(() => {
    updateMaterialVariantList();
  }, [materialName, materialNameList]);

  const classes = ButtonBarBottomStyles();
  return (
    <div ref={buttonBarBottomRef} className={classes.ButtonBarBottom}>
      <div className={classes.BottomBarBottomRow}>
        {showMeasuremet && (
          <div className={classes.clearMeasurementContainer}>
            <CustomIconButtonSecondary
              title="Clear measurement"
              buttonPressed={() => clearMeasurementPressed()}
              setActiveValue={(item) => setValueInFlags('clearMeasurementHovered', item)}
              active={flags.clearMeasurementHovered}
              icon={<CancelIcon fill={flags.clearMeasurementHovered ? Colors.plain_white : Colors.hilighted_text} />}
            />
          </div>
        )}
      </div>
      <br />
      <div className={classes.BottomBarBottomRow}>
        {getItem() && (
          <div>
            <CustomButton
              title={'Move Top/Bottom'}
              width="100px"
              height="40px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => toggleButtonBarBottomPosition()}
            />
            <CustomButton
              title={'Reset Origin'}
              width="100px"
              height="40px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => getItem()?.resetOrigin(new Vector3(0, 0.02, 0))}
            />
          </div>
        )}
        {getItem() && <div className={classes.title}>{getItem()?.title}</div>}
      </div>
      <div className={classes.BottomBarBottomRow}>
        {getBedItem() && viewMode !== ViewMode.BelowBed && (
          <div>
            <div className={classes.BottomBarBottomRow}>
              <CustomButton
                title={'Raise #1'}
                width="70px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(true)}
              />
              <CustomButton
                title={'Lower #1'}
                width="70px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(false)}
              />
              {getBedItem()?.getSubType() === ItemModelSubType.TwinXLBunk && (
                <div>
                  <CustomButton
                    title={'Raise #2'}
                    width="70px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(true, 1)}
                  />
                  <CustomButton
                    title={'Lower #2'}
                    width="70px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(false, 1)}
                  />
                </div>
              )}
              <CustomButton
                title={'Raise/Lower Skirt'}
                width="110px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => getBedItem()?.getBedFeature()?.toggleSkirt()}
              />
              {/* <CustomButton
                title={'Test Raise Skirt'}
                width="75px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => adjustSkirt(true, false)}
              />
              <CustomButton
                title={'Test Lower Skirt'}
                width="75px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => adjustSkirt(false, false)}
              /> */}
              <CustomButton
                title={'Reset Bed'}
                width="75px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => resetBed()}
              />
              <CustomButton
                title={'Show/Hide Bed Setup Buttons'}
                width="150px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => setShowBedSetupButtons(!showBedSetupButtons)}
              />
              <CustomSelect
                options={BED_ITEM_TYPE_SUBTYPE}
                value={bedItemTypeSubType}
                setValue={(val, label) => onBedItemTypeSubTypeChange(val, label)}
                width="400px"
                height="50px"
                heightExpanded="250px"
                margin="0px 10px 0px 0px"
                textMargin="8px 0px 0px 20px"
                padding="0"
              />
              <CustomUploadButton
                title={'Upload Bed Item'}
                width="100px"
                height="48px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                uploadType=".glb"
                onUpload={(event: ChangeEvent<HTMLInputElement>) => onUploadBedItem(event)}
              />
              <CustomButton
                title={'Add Default Bed Item'}
                width="125px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => addDefaultBedItem()}
              />
              <CustomButton
                title={'Remove Bed Item'}
                width="105px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => removeBedItem()}
              />
              <CustomButton
                title={'Clear Bed Items'}
                width="110px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => clearBedItems()}
              />
            </div>
            {showBedSetupButtons && (
              <div>
                <div className={classes.BottomBarBottomRow}>
                  <CustomButton
                    title={'Add Default Risers'}
                    width="130px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => addDefaultRisers()}
                  />
                  <CustomButton
                    title={'Remove Risers'}
                    width="80px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeRisers()}
                  />
                  <CustomButton
                    title={'Add/Upgrade Default Mattress'}
                    width="155px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.addUpgradeAllMattressesUsingDefaultItems()}
                  />
                  <CustomButton
                    title={'Remove Topper/Mattress'}
                    width="135px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeTopperOrMattress()}
                  />
                  <CustomButton
                    title={'Add Default Skirt'}
                    width="130px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.addDefaultItemToSlot(ItemBedFeatureSlot.Skirt)}
                  />
                  <CustomButton
                    title={'Remove Skirt'}
                    width="80px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.clearSlotItem(ItemBedFeatureSlot.Skirt)}
                  />
                </div>
                <div className={classes.BottomBarBottomRow}>
                  <CustomButton
                    title={'Add Default Bedding'}
                    width="125px"
                    height="48px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.addBeddingUsingDefaultItems()}
                  />
                  <CustomButton
                    title={'Add Default Extra Bedding'}
                    width="140px"
                    height="48px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.addExtraBeddingUsingDefaultItems()}
                  />
                  <CustomButton
                    title={'Remove Bedding'}
                    width="90px"
                    height="48px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.removeBedding()}
                  />
                  <CustomButton
                    title={'+P'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => addDefaultBeddingItem(ItemMattressFeatureSlot.Pillow)}
                  />
                  <CustomButton
                    title={'-P'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeBeddingItem(ItemMattressFeatureSlot.Pillow)}
                  />
                  <CustomButton
                    title={'+SP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => addDefaultBeddingItem(ItemMattressFeatureSlot.ShamPillow)}
                  />
                  <CustomButton
                    title={'-SP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeBeddingItem(ItemMattressFeatureSlot.ShamPillow)}
                  />
                  <CustomButton
                    title={'+DP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => addDefaultBeddingItem(ItemMattressFeatureSlot.DecorativePillow)}
                  />
                  <CustomButton
                    title={'-DP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeBeddingItem(ItemMattressFeatureSlot.DecorativePillow)}
                  />
                  <CustomButton
                    title={'+AP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => addDefaultBeddingItem(ItemMattressFeatureSlot.AccentPillow)}
                  />
                  <CustomButton
                    title={'-AP'}
                    width="10px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removeBeddingItem(ItemMattressFeatureSlot.AccentPillow)}
                  />
                  <CustomButton
                    title={'Show/Hide Pillow Rays'}
                    width="115px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => getBedItem()?.getBedFeature()?.getMattressFeature()?.togglePillowRays()}
                  />
                  <CustomButton
                    title={'Remove Pillows'}
                    width="75px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => removePillows()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {getBedItem() && viewMode === ViewMode.BelowBed && (
          <div>
            <CustomButton
              title={'Raise #1'}
              width="70px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(true)}
            />
            <CustomButton
              title={'Lower #1'}
              width="70px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(false)}
            />
            {getBedItem()?.getSubType() === ItemModelSubType.TwinXLBunk && (
              <div>
                <CustomButton
                  title={'Raise #2'}
                  width="70px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(true, 1)}
                />
                <CustomButton
                  title={'Lower #2'}
                  width="70px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => getBedItem()?.getBedFeature()?.adjustFrameHeight(false, 1)}
                />
              </div>
            )}
            <CustomButton
              title={'Raise/Lower Skirt'}
              width="110px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => getBedItem()?.getBedFeature()?.toggleSkirt()}
            />
            {/* <CustomUploadButton
              title={'Upload Items'}
              width="160px"
              height="48px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              uploadType=".glb"
              onUpload={(event: ChangeEvent<HTMLInputElement>) => onUploadBelowBedItems(event)}
            /> */}
          </div>
        )}
      </div>
      {viewMode !== ViewMode.Room && (
        <div className={classes.BottomBarBottomRow}>
          <CustomButton
            title={'(Z)oom Out To Room'}
            width="125px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            buttonClicked={() => zoomOut()}
          />
          {viewMode === ViewMode.Bed && (
            <CustomButton
              title={'Switch To Below Bed'}
              width="150px"
              mTop="0"
              mBottom="10px"
              mLeft="0"
              mRight="10px"
              buttonClicked={() => switchToBelowBed()}
            />
          )}
          {viewMode === ViewMode.BelowBed && (
            <div>
              <CustomButton
                title={'Switch Sides'}
                width="150px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => switchBelowBedSide()}
              />
              <CustomButton
                title={'Switch To Bed'}
                width="150px"
                mTop="0"
                mBottom="10px"
                mLeft="0"
                mRight="10px"
                buttonClicked={() => switchToBed()}
              />
            </div>
          )}
        </div>
      )}
      {viewMode === ViewMode.Person && (
        <div className={classes.BottomBarBottomRow}>
          <CustomButton
            title={'Turn Left'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            //buttonClicked={() => roomScene?.turnPersonView(-RoomScene.DEFAULT_PERSON_VIEW_TURN_ANGLE)}
            mouseDown={() => roomScene?.turnPersonViewAtSpeed(-RoomScene.PERSON_VIEW_TURN_SPEED_DEFAULT)}
            mouseUp={() => roomScene?.turnPersonViewAtSpeed(0)}
          />
          <CustomButton
            title={'Walk'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            //buttonClicked={() => roomScene?.stepPersonView(RoomScene.DEFAULT_PERSON_VIEW_STEP_DISTANCE)}
            mouseDown={() => roomScene?.stepPersonViewAtSpeed(RoomScene.PERSON_VIEW_STEP_SPEED_DEFAULT)}
            mouseUp={() => roomScene?.stepPersonViewAtSpeed(0)}
          />
          <CustomButton
            title={'Turn Right'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            //buttonClicked={() => roomScene?.turnPersonView(RoomScene.DEFAULT_PERSON_VIEW_TURN_ANGLE)}
            mouseDown={() => roomScene?.turnPersonViewAtSpeed(RoomScene.PERSON_VIEW_TURN_SPEED_DEFAULT)}
            mouseUp={() => roomScene?.turnPersonViewAtSpeed(0)}
          />
          <CustomButton
            title={'Walk Back'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            //buttonClicked={() => roomScene?.stepPersonView(-RoomScene.DEFAULT_PERSON_VIEW_STEP_DISTANCE)}
            mouseDown={() => roomScene?.stepPersonViewAtSpeed(-RoomScene.PERSON_VIEW_STEP_SPEED_DEFAULT)}
            mouseUp={() => roomScene?.stepPersonViewAtSpeed(0)}
          />
          <CustomButton
            title={'Turn Around'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            buttonClicked={() => roomScene?.turnPersonView(180)}
          />
          <CustomButton
            title={'Recenter'}
            width="90px"
            mTop="0"
            mBottom="10px"
            mLeft="0"
            mRight="10px"
            buttonClicked={() => roomScene?.movePersonView()}
          />
        </div>
      )}
      {selectedItem && (
        <div>
          {(viewMode === ViewMode.Room || viewMode === ViewMode.Person || viewMode === ViewMode.BelowBed) && (
            <div>
              <div className={classes.BottomBarBottomRow}>
                <CustomSelect
                  options={TYPE}
                  value={type}
                  setValue={(val, label) => onTypeChange(val, label)}
                  width="175px"
                  height="50px"
                  heightExpanded="140px"
                  margin="0px 10px 0px 0px"
                  textMargin="8px 0px 0px 20px"
                  padding="0"
                />
                <CustomSelect
                  options={SUB_TYPE}
                  value={subType}
                  setValue={(val, label) => onSubTypeChange(val, label)}
                  width="275px"
                  height="50px"
                  heightExpanded="140px"
                  margin="0px 10px 0px 0px"
                  textMargin="8px 0px 0px 20px"
                  padding="0"
                />
                <CustomSelect
                  options={SURFACE_TYPE}
                  value={surfaceType}
                  setValue={(val, label) => onSurfaceTypeChange(val, label)}
                  width="125px"
                  height="50px"
                  heightExpanded="140px"
                  margin="0px 10px 0px 0px"
                  textMargin="8px 0px 0px 20px"
                  padding="0"
                />
                <CustomButton
                  title={'Focus'}
                  width="110px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.focus()}
                />
                <CustomButton
                  title={'(<) Rotate Left'}
                  width="125px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.rotateLeft()}
                />
                <CustomButton
                  title={'(>) Rotate Right'}
                  width="125px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.rotateRight()}
                />
                {viewMode === ViewMode.Room && (
                  <div>
                    <CustomButton
                      title={'(Z)oom In'}
                      width="100px"
                      mTop="0"
                      mBottom="10px"
                      mLeft="0"
                      mRight="10px"
                      buttonClicked={() => zoomIn()}
                    />
                    <CustomButton
                      title={'Zoom In Turntable'}
                      width="100px"
                      mTop="0"
                      mBottom="10px"
                      mLeft="0"
                      mRight="10px"
                      buttonClicked={() => zoomIn(true)}
                    />
                  </div>
                )}
                <CustomButton
                  title={'(B)ounding Box'}
                  width="100px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.toggleBoundsVisualization()}
                />
                <CustomButton
                  title={'Item (P)roperties'}
                  width="100px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.logProperties()}
                />
                <CustomButton
                  title={'Delete'}
                  width="100px"
                  mTop="0"
                  mBottom="10px"
                  mLeft="0"
                  mRight="10px"
                  buttonClicked={() => selectedItem.remove()}
                />
              </div>
              {getMaterialItem() !== undefined && getMaterialItem()?.asset.variantsAvailable && (
                <div className={classes.BottomBarBottomRow}>
                  <CustomSelect
                    options={itemVariantList}
                    value={itemVariant}
                    setValue={(val, label) => onItemVariantChange(val, label)}
                    width="350px"
                    height="50px"
                    heightExpanded="140px"
                    margin="0px 10px 0px 0px"
                    textMargin="8px 0px 0px 20px"
                    padding="0"
                  />
                </div>
              )}
              {getMaterialItem() !== undefined && !getMaterialItem()?.asset.variantsAvailable && (
                <div className={classes.BottomBarBottomRow}>
                  <CustomSelect
                    options={materialNameList}
                    value={materialName}
                    setValue={(val, label) => onMaterialNameChange(val, label)}
                    width="450px"
                    height="50px"
                    heightExpanded="140px"
                    margin="0px 10px 0px 0px"
                    textMargin="8px 0px 0px 20px"
                    padding="0"
                  />
                  <CustomUploadButton
                    title={'Upload Material'}
                    width="100px"
                    height="48px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    uploadType=".glb"
                    onUpload={(event: ChangeEvent<HTMLInputElement>) => onUploadMaterial(event)}
                  />
                  <CustomSelect
                    options={TEST_MATERIALS}
                    value={testMaterial}
                    setValue={(val, label) => onTestMaterialChange(val, label)}
                    width="150px"
                    height="50px"
                    heightExpanded="140px"
                    margin="0px 10px 0px 0px"
                    textMargin="8px 0px 0px 20px"
                    padding="0"
                  />
                  <CustomButton
                    title={'Add Test Material'}
                    width="100px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={onTestMaterialAdd}
                  />
                  <CustomSelect
                    options={materialVariantList}
                    value={materialVariant}
                    setValue={(val, label) => onMaterialVariantChange(val, label)}
                    width="350px"
                    height="50px"
                    heightExpanded="140px"
                    margin="0px 10px 0px 0px"
                    textMargin="8px 0px 0px 20px"
                    padding="0"
                  />
                  <CustomButton
                    title={'Clear Materials'}
                    width="100px"
                    mTop="0"
                    mBottom="10px"
                    mLeft="0"
                    mRight="10px"
                    buttonClicked={() => clearMaterials()}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ButtonBarBottomStyles = makeStyles(() => ({
  ButtonBarBottom: {
    marginTop: '65vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    '@media (max-width: 1024px)': {
      position: 'absolute',
      bottom: '76px',
      //left: '32%',
      //right: '36%',
    },
  },
  BottomBarBottomRow: {
    position: 'relative',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  clearMeasurementContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  title: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.plain_black,
    backgroundColor: Colors.plain_white,
    textAlign: 'center',
    whiteSpace: 'pre-line',
    border: '5px solid white',
    borderRadius: '5px',
    marginBottom: '10px',
    //color: Colors.plain_white,
    //textShadow: '1px 1px #000000',
  },
  label: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.plain_black,
    textAlign: 'center',
    marginTop: '10px',
  },
}));

export default ButtonBarBottom;
