import { SvgIconProps } from '@material-ui/core/SvgIcon';

const ThreeD = (props: SvgIconProps) => {
  return (
    <svg width={19} height={13} viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.71063 12.192C5.88663 12.192 7.58263 10.672 7.58263 8.496C7.58263 6.608 6.30263 5.152 4.39863 5.008L6.91063 2.464V0.799999H0.766625V2.624H4.51063L2.27063 4.976V6.368L3.71063 6.48C4.84663 6.56 5.63063 7.376 5.63063 8.416C5.63063 9.568 4.78263 10.352 3.74263 10.352C2.76663 10.352 2.06263 9.712 1.85463 8.816L0.078625 9.376C0.478625 11.056 1.72663 12.192 3.71063 12.192ZM9.70563 12H12.5536C16.2656 12 18.7616 9.744 18.7616 6.4C18.7616 3.104 16.4256 0.799999 12.5536 0.799999H9.70563V12ZM11.6576 10.176V2.624H12.6976C15.2096 2.624 16.7776 4.016 16.7776 6.4C16.7776 8.784 15.1776 10.176 12.6976 10.176H11.6576Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default ThreeD;
