import { createStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import useAxios, { ServerRoutes } from 'api';
import Colors from 'theme';
import { Toast, UniversityBuilding } from 'types';
import { POST, ALL_BUILDINGS, TEMPLATE_LINKS_HEADERS, getOrigin } from 'utils';
import { CircularProgress, UniversitySelect, CustomButton, InputFieldWithLabel, Snackbar, ToolBar } from 'components';
import { AxiosError } from 'axios';
import { CSVLink } from 'react-csv';
import Routes from 'routes';

interface TemplateLink {
  building_name: string | null;
  type: string;
  token: string;
  url: string;
}

const TemplateLinks = () => {
  const classes = useStyles();
  const smallScreen = useMediaQuery('@media (max-width: 768px)');

  const [university, setUniversity] = useState('');
  const [universityName, setUniversityName] = useState('');
  const [universityBuildings, setUniversityBuildings] = useState<UniversityBuilding[]>([]);
  const [building, setBuilding] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [searchedValue, setSearchedValue] = useState('');
  const [data, setData] = useState<TemplateLink[]>([]);
  const [isCopied, setIsCopied] = useState(false);
  const [bDownloadReady, setDownloadReady] = useState(false);

  const csvLink = useRef<any>(null);

  const {
    callApi: getUniversityBuildings,
    loading: buildingsLoading,
    error: buildingsError,
  } = useAxios(ServerRoutes.adminUniversityBuildings, POST, false);

  const {
    callApi: getTemplateLinks,
    loading: templateLinksLoading,
    error: templateLinksError,
  } = useAxios(ServerRoutes.getTemplateLinks, POST, false);

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
        setOpen(true);
      }
    };
    showError(templateLinksError);
    showError(buildingsError);
    showError(logoutError);
  }, [buildingsError, templateLinksError, logoutError]);

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  useEffect(() => {
    if (isCopied) {
      setOpen(true);
      setToast({
        severity: 'success',
        message: 'Template Link Copied',
        autoHideDuration: 1500,
      });
      setIsCopied(false);
    }
  }, [isCopied]);

  const onChange = (value: string) => {
    setUniversity(value);
    setData([]);
    getUniversityBuildings({ universityId: value }, async (res) => {
      if (res.data.universityBuildings) {
        const buildings = res.data.universityBuildings.length > 1 ? [ALL_BUILDINGS] : [];
        setUniversityBuildings(buildings.concat(...res.data.universityBuildings));
      }
    });
  };

  const onBuildingChange = async (value: string) => {
    setBuilding(value);
    if (value !== 'All Buildings') {
      const selectedBuilding = universityBuildings.find((item) => item.roomie_university_building_id === value);
      setBuildingName(selectedBuilding?.building_name || '');
    }
    await getTemplateLinks(
      { university_id: university, building_id: value === 'All Buildings' ? null : value },
      async (res) => {
        if (res?.data?.configs) {
          setData(res?.data?.configs);
        }
      },
    );
  };

  const onDownload = () => {
    setDownloadReady(true);
  };

  const copy = (token: string) => {
    const origin = getOrigin();
    const url = `${origin}${Routes.TEMPLATE}?id=${token}`;
    const textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if (navigator) navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  const getData = () => {
    const csvData = filterData().map((item) => {
      return {
        Building_Name: item.building_name || buildingName,
        Type: item.type,
        Token: item.token,
        Url: `${window.location.origin}/template?id=${item.token}`,
      };
    });
    return csvData;
  };

  const filterData = () => {
    if (!searchedValue) return data;
    return data?.filter(
      (item) =>
        item?.building_name?.toLowerCase().includes(searchedValue.toLowerCase()) ||
        item?.type?.toLowerCase().includes(searchedValue.toLowerCase()),
    );
  };

  const getFileName = () => {
    let filename = universityName;
    if (building !== 'All Buildings') {
      filename = filename + '_' + buildingName;
    }
    filename = filename + '_template_links';
    return filename;
  };

  return (
    <>
      <CircularProgress isLoading={buildingsLoading || templateLinksLoading || logoutLoading} />
      <Snackbar
        isOpen={open}
        setIsOpen={setOpen}
        autoHideDuration={toast?.autoHideDuration}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
      />
      <div className={classes.container}>
        <ToolBar logout={logout} />
        <div className={classes.heading}>Template Links</div>
        <div className={classes.selectContainer}>
          <UniversitySelect university={university} onChange={onChange} setUniversityName={setUniversityName} />
          {university && (
            <FormControl className={classes.formControl}>
              <InputLabel id="select-building">Building</InputLabel>
              <Select
                labelId="select-building"
                className={classes.select}
                value={building}
                onChange={(e) => onBuildingChange(e.target.value as string)}
              >
                {universityBuildings.map((item) => (
                  <MenuItem key={item.roomie_university_building_id} value={item.roomie_university_building_id}>
                    {item.building_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        {data.length > 0 && (
          <div className={classes.buttonStyle}>
            <CustomButton title="Download" buttonClicked={onDownload} height="40px" width="180px" mBottom="10px" />
            <CSVLink data={getData()} filename={getFileName()} target="_blank" ref={csvLink} />
            <InputFieldWithLabel
              placeholder="Search"
              width={smallScreen ? '240px' : '30vh'}
              background="none"
              value={searchedValue}
              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (event.target) setSearchedValue(event.target.value);
              }}
              searchIcon={true}
            />
          </div>
        )}
        {data?.length > 0 && (
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headCell}>
                  {TEMPLATE_LINKS_HEADERS.map((heading) => (
                    <TableCell key={heading} className={classes.headerColor}>
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterData()?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.bodyCell}>{row?.building_name || buildingName}</TableCell>
                    <TableCell className={classes.bodyCellSecondary}>{row?.type}</TableCell>
                    <TableCell className={classes.bodyCell}>{row?.token}</TableCell>
                    <TableCell className={classes.bodyCellSecondary}>
                      <span onClick={() => copy(row?.token || '')} className={classes.iconCell}>
                        Template Link
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </>
  );
};

export default TemplateLinks;

const useStyles = makeStyles(() =>
  createStyles({
    buttonStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconCell: {
      color: `${Colors.rating_count_blue} !important`,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    selectContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '@media (max-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    container: {
      width: '100%',
      backgroundColor: Colors.plain_white,
      minHeight: '100%',
      fontFamily: 'Wigrum',
    },
    formControl: {
      minWidth: '120px',
      margin: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    select: {
      width: '200px !important',
    },
    heading: {
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '25px',
      paddingTop: '25px',
      textAlign: 'center',
    },
    tableWrapper: {
      overflow: 'scroll',
      marginTop: '30px',
      maxHeight: '60vh',
      display: 'flex',
      justifyContent: 'center',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      '& -webkit-scrollbar': {
        width: '0',
        background: 'transparent',
      },
    },
    headCell: {
      backgroundColor: Colors.whiteSmoke,
      margin: 0,
      padding: 0,
      height: '26px',
    },
    headerColor: {
      color: Colors.grey,
      fontFamily: 'Wigrum',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '10px',
    },
    table: {
      width: '60%',
      '@media (max-width: 1000px)': {
        width: '80%',
      },
      '@media (max-width: 760px)': {
        marginLeft: '10%',
      },
    },
    bodyCell: {
      fontFamily: 'Wigrum',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '14px',
      color: Colors.bigStone,
      paddingTop: '10px',
      paddingBottom: '34px',
    },
    bodyCellSecondary: {
      fontFamily: 'Wigrum',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '14px',
      color: Colors.grey,
      paddingTop: '10px',
      paddingBottom: '34px',
    },
  }),
);
