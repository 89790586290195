import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 200,
      position: 'absolute',
      left: (props: CircularProps) => (props.absoluteCenter ? 'calc(50vw - 20px)' : props.left ? props.left : '58vw'),
      top: (props: CircularProps) => (props.top ? props.top : 'calc(50vh - 20px)'),
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    backdrop: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: '#00000042',
      zIndex: 10,
    },
  }),
);
interface CircularProps {
  absoluteCenter?: boolean;
  left?: string;
  top?: string;
}

export default function CircularIndeterminate(props: CircularProps): JSX.Element {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.backdrop} />

      <div className={classes.root}>
        <CircularProgress />
      </div>
    </>
  );
}
