import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Colors from 'theme';

interface Props {
  buildingName: string;
  rooms: string[];
}

export const sortRooms = (a: string, b: string) => {
  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;
  const AInt = parseInt(a, 10);
  const BInt = parseInt(b, 10);
  if (isNaN(AInt) && isNaN(BInt)) {
    const aA = a.replace(reA, '');
    const bA = b.replace(reA, '');
    if (aA === bA) {
      const aN = parseInt(a.replace(reN, ''), 10);
      const bN = parseInt(b.replace(reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  } else if (isNaN(AInt)) {
    return 1;
  } else if (isNaN(BInt)) {
    return -1;
  } else {
    return AInt > BInt ? 1 : -1;
  }
};

const BuildingCard = ({ buildingName, rooms }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.container}>
          <div className={classes.title}>{`${buildingName} / Suite`}</div>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          <div className={classes.roomsText}>Unit(s) with this layout:</div>
          {rooms?.sort(sortRooms).map(
            (room, index) =>
              room != '"' && (
                <ListItem key={index} className={classes.item}>
                  {room}
                </ListItem>
              ),
          )}
        </List>
      </Card>
    </div>
  );
};

export default BuildingCard;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    list: {
      padding: '10px 20px',
      marginBottom: '20px',
    },
    card: {
      justifyContent: 'center',
      margin: '5px 20px',
      borderRadius: '5px',
      boxShadow: 'none',
      width: '280px',
      border: `1px solid ${Colors.button_bg_disabled}`,
    },
    divider: {
      marginTop: '10px',
    },
    item: {
      fontSize: '18px',
      justifyContent: 'center',
      color: Colors.cyprus1,
    },
    roomsText: {
      fontSize: '18px',
      margin: '17px 0px',
      color: Colors.cyprus1,
      textAlign: 'center',
      fontWeight: 500,
      lineHeight: '15px',
    },
    title: {
      fontSize: '18px',
      margin: '20px 0px 10px',
      fontWeight: 500,
      lineHeight: '18px',
      color: Colors.cyprus1,
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
  }),
);
