import { createContext } from 'react';
import { SignInResponse } from 'types';

type ContextType = {
  roomInfo: SignInResponse;
  userInfo: { email: string };
  login: (email: string, roomInfo: SignInResponse) => void;
  logout: () => void;
  isError: () => boolean;
  setIsError: (invalidSession: boolean) => void;
  message: () => string;
  setMessage: (message: string) => void;
};

export const AuthContext = createContext<ContextType | undefined>(undefined);
