import { createStyles, makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

const drawerWidth = '320px';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      position: 'fixed',
      zIndex: 2,
      height: '100vh',
      width: 'calc(283px - 77px)',
      left: 50,
      background: Colors.drawer_bg,
    },
    buttonContainer: {
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
    },
    roomsList: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      maxHeight: '130px',
      overflowY: 'scroll',
    },
    menuButton: {
      borderRadius: '0',
      display: 'flex',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      zIndex: 0,
      height: 'fit-content',
      marginTop: '43vh',
      transform: 'rotate3d(1, 100, 1, 180deg) !important',
      transition: 'transform 0.8s',
    },
    header: {
      fontSize: '23px',
      fontWeight: 700,
      color: Colors.cyprus1,
      margin: '10px 0px 15px 3px',
    },
    sectionTitle: {
      fontSize: '16px',
      fontWeight: 700,
      color: Colors.cyprus1,
      margin: '10px 0px 15px 3px',
    },
    mattressHeader: {
      fontWeight: 'bold',
      fontSize: '20px',
      margin: '0px 0px 10px 10px',
    },
    close: {
      transform: 'translateX(-82%) !important',
      background: 'transparent',
    },
    open: {
      transform: 'translateX(0px)',
    },
    buttons: {
      marginLeft: '20px',
    },
    drawer: {
      width: drawerWidth,
      minWidth: '200px',
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      minWidth: '230px',
      zIndex: 1,
      marginLeft: '60px',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      '@media (max-width: 768px)': {
        width: 'calc(' + drawerWidth + ' - 77px)',
      },
      boxShadow: `0px 2px 8px ${Colors.boxShadow}`,
    },
    mattressContainer: {
      marginTop: '20px',
    },
    flex: {
      display: 'flex',
      transition: 'transform 0.4s',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
    buttonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
    },
    backIcon: {
      width: '15px',
      height: '15px',
      cursor: 'pointer',
      color: Colors.cyprus1,
    },
    closeIcon: {
      width: '18px',
      height: '18px',
      color: Colors.button_bg_active,
    },
    iconButton: {
      marginRight: '88px',
      padding: '3px',
    },
    infoText: {
      marginLeft: '88px',
    },
    backText: {
      cursor: 'pointer',
      color: Colors.cyprus1,
      fontSize: '12px',
    },
    roomItems: {
      margin: '16px 0px 26px',
      color: Colors.cyprus1,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    topContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',
    },
    dropDown: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '46rem',
      paddingTop: '16px',
      paddingLeft: '10px',
      filter: Colors.liveChatShadow,
      paddingRight: '10px',
      margin: '5px 10px 0px auto',
      transition: 'transform 8s',
    },
    selectText: {
      fontSize: '17px',
      color: Colors.cyprus1,
      marginRight: '10px',
    },
    selectTop: {
      display: 'flex',
      cursor: 'pointer',
      justifyContent: 'center',
      marginTop: '13px',
    },
    closed: {
      transform: 'translateX(0px) !important',
      background: Colors.plain_white,
      border: `1px solid ${Colors.mystic}`,
      width: '230px',
      height: '48px',
      cursor: 'pointer',
      borderRadius: '0px 0px 5px 5px',
      transition: 'transform 0.4s',
      '&:hover': {
        transform: 'scale(1.04) !important',
      },
    },
    opened: {
      transform: 'translateX(0px)',
      background: Colors.plain_white,
      border: `1px solid ${Colors.mystic}`,
      width: '230px',
      borderRadius: '0px 0px 5px 5px',
    },
    catalog: {
      fontSize: '16px',
      color: Colors.cyprus1,
      lineHeight: '21px',
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '16px',
      lineHeight: '15px',
      color: Colors.cyprus1,
    },
    ButtonBarBottom: {
      position: 'absolute',
      alignItems: 'center',
      height: 'auto',
      width: 'auto',
      bottom: '20px',
      left: '32%',
      right: '36%',
      marginLeft: '70px',
    },
    tooltip: {
      display: 'flex',
      width: '300px',
      borderRadius: '8px',
      padding: '12px',
      transition: 'transform 8s',
      position: 'fixed',
      margin: '5% auto',
      left: 0,
      right: 0,
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Wigrum',
      fontSize: '14px',
      lineHeight: '21px',
      background: Colors.plain_white,
    },
    arrowKeys: {
      width: '25px',
      height: '55px',
      marginRight: '12px',
    },
    buildingName: {
      position: 'absolute',
      fontFamily: 'Wigrum',
      zIndex: 1,
      color: Colors.cyprus1,
      fontWeight: 'bold',
      fontSize: '18px',
      left: '40px',
    },
    infoRoot: {
      marginBottom: '40px',
    },
    guestViewInfo: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: Colors.plain_white,
      width: '100%',
      height: '44px',
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'Wigrum',
      color: Colors.bold_text,
    },
    roomConfiguration: {
      width: '90%',
      padding: '16px 12px',
      marginTop: '16px',
      background: Colors.button_hover_bg_black,
    },
    configurationText: {
      color: Colors.cyprus1,
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '16px',
      fontFamily: 'Wigrum',
    },
    label: {
      fontFamily: 'Wigrum',
      color: Colors.bold_text,
      fontSize: '14px',
    },
    photoButton: {
      position: 'absolute',
      left: '360px',
      zIndex: 10,
      top: '20px',
      cursor: 'pointer',
      filter: Colors.liveChatShadow,
    },
    createStyleButton: {
      position: 'absolute',
      right: '80px',
      zIndex: 10,
      top: '20px',
      cursor: 'pointer',
      filter: Colors.liveChatShadow,
    },
    addStyleButton: {
      position: 'absolute',
      right: '256px',
      zIndex: 10,
      top: '10px',
      cursor: 'pointer',
      minWidth: 120,
    },
    stylesButton: {
      cursor: 'pointer',
      filter: Colors.liveChatShadow,
    },
    typeText: {
      position: 'absolute',
      textAlign: 'center',
      zIndex: 10,
      top: '30px',
      left: '50%',
      color: Colors.bold_text,
      fontSize: '18px',
      fontWeight: 700,
    },
    bottomButtons: {
      position: 'absolute',
      right: '100px',
      zIndex: 10,
      display: 'flex',
      gap: '20px',
      bottom: '0px',
      cursor: 'pointer',
      filter: Colors.liveChatShadow,
    },
    select: {
      width: '220px !important',
    },
    formControl: {
      minWidth: '120px',
      margin: '10px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    stylesNavButton: {
      margin: '10px 0px',
    },
    saveBtn: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px',
    },
    styleConfigDrawer: {
      width: drawerWidth,
      minWidth: '400px',
      zIndex: 1,
      marginLeft: '60px',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '&::-webkit-scrollbar-thumb': {
        display: 'none',
      },
      '@media (max-width: 768px)': {
        width: 'calc(' + drawerWidth + ' - 77px)',
      },
      boxShadow: `0px 2px 8px ${Colors.boxShadow}`,
    },
    styleItemList: {
      maxHeight: '280px',
      overflow: 'auto',
    },
    styleActionButton: {
      display: 'flex',
      padding: '12px',
      gap: '0px 12px',
    },
    deleteStyle: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '0px 12px',
    },
    selectStyle: {
      top: '110px',
      right: '74px',
      cursor: 'pointer',
      zIndex: 10,
      position: 'absolute',
      minWidth: '120px',
      background: 'white',
      padding: '12px',
      border: '1px solid gray',
      borderRadius: '14px',
      alignItems: 'center',
      width: '250px',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    selectStylesLabel: {
      position: 'absolute',
      textAlign: 'center',
      zIndex: 10,
      top: '80px',
      right: '101px',
      color: Colors.bold_text,
      fontSize: '18px',
      fontWeight: 700,
    },
    appliedStyleWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    tab: {
      padding: '6px 5px',
    },
  }),
);

export default useStyles;
