import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Colors from 'theme';
import { ReactElement } from 'react';

interface CustomIconButtonProps {
  icon: ReactElement;
  active?: boolean;
  disabled?: boolean;
  setActiveValue: (item: boolean) => void | null;
  buttonPressed: () => void | null;
  bordered?: boolean;
  badgeText?: string;
}

const CustomIconButton = ({
  active = false,
  disabled = false,
  bordered = false,
  badgeText = '',
  ...props
}: CustomIconButtonProps): JSX.Element => {
  const classes = CustomIconButtonStyles({ active, disabled, bordered, badgeText, ...props });
  return (
    <div
      className={classes.Container}
      onMouseOver={() => props.setActiveValue(!/Mobi|Android/i.test(navigator.userAgent))}
      onMouseOut={() => props.setActiveValue(false)}
      onClick={() => props.buttonPressed()}
    >
      <IconButton classes={{ root: classes.button }} color="primary" component={'span'}>
        {props.icon}
      </IconButton>
      {!!badgeText && (
        <div className={classes.badge}>
          <h1 className={classes.fontFamily}>{badgeText}</h1>
        </div>
      )}
    </div>
  );
};

const CustomIconButtonStyles = makeStyles(() => ({
  Container: {
    zIndex: 0,
    cursor: 'pointer',
    display: 'flex',
    '&:hover $button': {
      border: (props: CustomIconButtonProps) => (props.bordered ? '2px solid ' + Colors.button_bg_active : 'none'),
      background: (props: CustomIconButtonProps) => (props.bordered ? Colors.plain_white : Colors.button_bg_active),
      transform: 'scale(1.05)',
      cursor: 'pointer',
      boxShadow: ' 0px 0px 7px 0px ' + Colors.button_bg_active_shadow,
      width: (props: CustomIconButtonProps) => (props.bordered ? '22px' : '26px'),
      height: (props: CustomIconButtonProps) => (props.bordered ? '22px' : '26px'),
    },
    '&:hover $badge': {
      background: Colors.plain_white,
      color: Colors.hilighted_text,
      border: '1px solid ' + Colors.button_bg_active,
      height: '22px',
      width: '22px',
      boxShadow: ' 0px 0px 7px 0px ' + Colors.button_bg_active_shadow,
    },
    '@media (max-width: 768px)': {
      margin: '5px 0',
      marginLeft: (props: CustomIconButtonProps) => (!!props.badgeText ? '25px' : '0'),

      '&:hover $button': {
        width: (props: CustomIconButtonProps) => (props.bordered ? '14px' : '16px'),
        height: (props: CustomIconButtonProps) => (props.bordered ? '14px' : '16px'),

        background: (props: CustomIconButtonProps) =>
          props.bordered
            ? props.active
              ? Colors.plain_white
              : Colors.button_bg_opaque
            : props.active
            ? Colors.button_bg_active
            : Colors.plain_white,

        border: (props: CustomIconButtonProps) =>
          props.bordered ? (props.active ? '2px solid ' + Colors.button_bg_active : 'none') : 'none',
        transform: 'none',
        boxShadow: 'none',
      },
      '&:hover $badge': {
        background: Colors.button_bg_active,
        color: Colors.plain_white,
        border: 'none',
        boxShadow: 'none',
      },
    },
  },
  button: {
    background: (props: CustomIconButtonProps) =>
      props.bordered
        ? props.active
          ? Colors.plain_white
          : Colors.button_bg_opaque
        : props.active
        ? Colors.button_bg_active
        : Colors.plain_white,

    border: (props: CustomIconButtonProps) =>
      props.bordered ? (props.active ? '2px solid ' + Colors.button_bg_active : 'none') : 'none',
    transition: 'background 0.5s, transform 0.2s',
    width: (props: CustomIconButtonProps) => (props.bordered ? (props.active ? '22px' : '26px') : '26px'),
    height: (props: CustomIconButtonProps) => (props.bordered ? (props.active ? '22px' : '26px') : '26px'),
    '@media (max-width: 768px)': {
      width: (props: CustomIconButtonProps) => (props.bordered ? (props.active ? '12px' : '16px') : '16px'),
      height: (props: CustomIconButtonProps) => (props.bordered ? (props.active ? '12px' : '16px') : '16px'),
    },
  },
  badge: {
    background: Colors.button_bg_active,
    width: '24px',
    height: '24px',
    borderRadius: '30px',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '-8%',
    color: Colors.plain_white,
    transition: 'background 0.5s',
  },
  fontFamily: {
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '17px',
    color: 'inherit',
    marginTop: '2px',
  },
}));

export default CustomIconButton;
