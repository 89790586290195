import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useState } from 'react';
import { Room, RoomConfig } from 'types';
import ButtonsContainer from './buttonsContainer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

interface Props {
  roomToMove?: Room;
  onRoomToMoveChange: (selectedItem?: Room) => void;
  configs: RoomConfig[];
  onMoveRoom: (configId: string) => Promise<any>;
}

const MoveRoomModal = ({ roomToMove, onRoomToMoveChange, configs, onMoveRoom }: Props) => {
  const classes = useStyles();

  const [type, setType] = useState('');

  const closeModal = () => {
    onRoomToMoveChange(undefined);
    setType('');
  };

  const moveRoom = async () => {
    const res = await onMoveRoom(type);
    if (res?.data?.code === 0) {
      onRoomToMoveChange(undefined);
      setType('');
    }
  };

  const data = configs?.filter((item) => item?.id !== roomToMove?.roomie_room_config_id);

  return (
    <Dialog open={!!roomToMove} onClose={closeModal} classes={{ paper: classes.paper }} fullWidth={true}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Move Room</div>
          <CloseIcon className={classes.closeIcon} onClick={closeModal} />
        </div>
        <div className={classes.iconContainer}>
          <div className={classes.text}>
            {data?.length > 0
              ? `Select type to move room number ${roomToMove?.room_number}`
              : 'There is no other type in this building to move this room to'}
          </div>
        </div>
        {data?.length > 0 && (
          <div className={classes.selectContainer}>
            <FormControl>
              <InputLabel id="select-type">Type</InputLabel>
              <Select
                labelId="select-type"
                className={classes.select}
                value={type}
                onChange={(e) => setType(e.target.value as string)}
                MenuProps={{
                  classes: {
                    list: classes.menuProps,
                  },
                }}
              >
                {data?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <ButtonsContainer
          cancelClicked={closeModal}
          cancelText="Close"
          mTop="80px"
          enable={!!type}
          submitClicked={moveRoom}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MoveRoomModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  menuProps: {
    maxHeight: '350px',
    minWidth: '200px',
  },
  select: {
    width: '200px !important',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '30px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    width: '400px',
    lineHeight: '120%',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  icon: {
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    margin: '15px 0px',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  selectContainer: {
    display: 'flex',
    margin: '30px 0px',
    justifyContent: 'center',
    gap: '20px',
  },
}));
