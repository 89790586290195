import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Dialog from '@material-ui/core/Dialog';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { CircularProgress, CustomButton, CustomSelect, InputFieldWithLabel, Snackbar, ToolBar } from 'components';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import { AuthContext } from 'context/authContext';
import { Toast, UniversityBuilding } from 'types';
import { ASSIGNMENTS_HEADERS, POST, SUCCESS, Messages } from 'utils';
import useAxios, { ServerRoutes } from 'api';
import { AxiosError } from 'axios';
import { getUniversityId } from 'context/localStorage';

interface StudentAssignment {
  building_name: string;
  roomie_user_room_id: string;
  roomie_university_building_id: string;
  room_number: string;
  last_name: string;
  first_name: string;
  email: string;
  removeButton?: boolean;
}
const StudentsAssignments = () => {
  const authContext = useContext(AuthContext);

  const classes = useStyles();
  const smallScreen = useMediaQuery('@media (max-width: 768px)');

  const [showToast, setShowToast] = useState(false);
  const [toast, setToast] = useState<Toast>();
  const [building, setBuilding] = useState('');
  const [buildings, setBuildings] = useState<UniversityBuilding[]>([]);
  const [searchedValue, setSearchedValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [rowToDelete, setRowToDelete] = useState<StudentAssignment>();
  const [data, setData] = useState<StudentAssignment[]>([]);

  const params = new URLSearchParams(window.location.search);
  const id = params.get('building');
  const name = params.get('name');

  const {
    callApi: getAssignments,
    loading: assignmentsLoading,
    error: assignmentsError,
  } = useAxios(ServerRoutes.studentsAssigments, POST, false);

  const {
    response: buildingsRes,
    callApi: loadBuildings,
    loading: buildingsLoading,
    error: buildingsError,
  } = useAxios(ServerRoutes.adminUniversityBuildings, POST, false);

  const {
    callApi: removeStudent,
    loading: removeLoading,
    error: removeError,
  } = useAxios(ServerRoutes.removeStudent, POST, false);

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    (async () => {
      await loadBuildings({ universityId: getUniversityId() || authContext?.roomInfo?.orgId }, (loadBuildingsResp) => {
        if (loadBuildingsResp?.data?.reason === SUCCESS) {
          setBuildings(loadBuildingsResp?.data?.universityBuildings);
        }
        if (loadBuildingsResp.data.code === 1) {
          setToast({
            severity: 'error',
            message: Messages.BUILDINGS_ERROR,
            autoHideDuration: 3000,
          });
        }
      });
      if (id && name) {
        setBuilding(name);
        await setAssignments(id);
      }
    })();
  }, []);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
      }
    };
    showError(buildingsError);
    showError(assignmentsError);
    showError(removeError);
    showError(logoutError);
  }, [assignmentsError, buildingsError, logoutError]);

  const setAssignments = async (buildingId: string) => {
    await getAssignments(
      { university_id: getUniversityId() || authContext?.roomInfo?.orgId, building_id: buildingId || '' },
      (assignmentsResp) => {
        if (assignmentsResp?.data?.studentList) {
          const temp = assignmentsResp?.data?.studentList?.map((item: StudentAssignment) => {
            return {
              building_name: item?.building_name || '',
              roomie_user_room_id: item?.roomie_user_room_id || '',
              roomie_university_building_id: item?.roomie_university_building_id || '',
              room_number: item?.room_number || '',
              last_name: item?.last_name || '',
              first_name: item?.first_name || '',
              email: item?.email || '',
              removeButton: false,
            };
          });
          setData(temp || []);
        }
      },
      true,
    );
  };

  const removeFromRoom = (rowId: string) => {
    if (data) {
      const row = data.find((item) => item?.roomie_user_room_id === rowId);
      if (row) {
        setRowToDelete(row);
        setOpenModal(true);
      }
    }
  };

  const getData = () => {
    if (!searchedValue) return data;
    return data.filter(
      (item) =>
        item?.email.toLowerCase().includes(searchedValue.toLowerCase()) ||
        item?.first_name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        item?.last_name.toLowerCase().includes(searchedValue.toLowerCase()) ||
        (item?.first_name.toLowerCase() + ' ' + item?.last_name.toLowerCase()).includes(searchedValue.toLowerCase()),
    );
  };

  const onConfirm = async () => {
    if (rowToDelete) {
      await removeStudent({ roomie_user_room_id: rowToDelete?.roomie_user_room_id }, (res) => {
        if (res?.data?.reason === SUCCESS) {
          const temp = data.map((rowSelected) => {
            if (rowSelected?.roomie_user_room_id === rowToDelete?.roomie_user_room_id) {
              rowSelected.building_name = '--';
              rowSelected.room_number = '--';
              rowSelected.removeButton = true;
            }
            return rowSelected;
          });
          setData(temp);
        }
      });
    }
    setOpenModal(false);
  };

  const changeBuilding = async (val: string, label: string) => {
    setBuilding(label);
    if (val) await setAssignments(val);
  };

  const DialogBody = () => (
    <div>
      <div className={classes.close}>
        <CloseIcon className={classes.closeIcon} onClick={() => setOpenModal(false)} />
      </div>
      <div className={classes.modalContainer}>
        <div className={classes.modalText}>
          Are you sure you want to remove {rowToDelete?.first_name} {rowToDelete?.last_name} from room{' '}
          {rowToDelete?.room_number}?
        </div>
        <div className={classes.modalButtons}>
          <CustomButton
            title="Cancel"
            borderRadius="34px"
            textColor={Colors.cyprus}
            background={Colors.whiteSmoke}
            hoverColor={Colors.whiteSmoke}
            fontSize="15px"
            fontWeight="700"
            mTop="0px"
            width="138px"
            border="0px"
            height="38px"
            buttonClicked={() => setOpenModal(false)}
          />
          <CustomButton
            title="Yes, remove"
            borderRadius="34px"
            textColor={Colors.cyprus}
            background={Colors.paleTurquoise}
            fontSize="15px"
            fontWeight="700"
            mTop="0px"
            width="138px"
            height="38px"
            buttonClicked={onConfirm}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <CircularProgress isLoading={buildingsLoading || assignmentsLoading || removeLoading || logoutLoading} />
      <Snackbar
        isOpen={showToast}
        setIsOpen={setShowToast}
        messageHeader={toast?.messageHeader}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
        autoHideDuration={toast?.autoHideDuration}
      />
      <div className={classes.container}>
        <ToolBar logout={logout} />
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="md">
          <DialogBody />
        </Dialog>
        <div className={classes.body}>
          <div className={classes.heading}>{buildingsRes?.data?.universityName}</div>
          <CustomSelect
            options={buildings?.map((item) => {
              return { label: item?.building_name, value: item?.roomie_university_building_id };
            })}
            value={building}
            setValue={(val, label) => changeBuilding(val, label)}
            width="228px"
            height="34px"
            margin="0px"
            iconMargin="2px"
            textMargin="3px 0px 0px 8px"
            backgroundColor={Colors.whiteSmoke}
            borderRadius="8px"
            arrowColor={Colors.viking}
          />
          <InputFieldWithLabel
            placeholder="Search"
            width={smallScreen ? '240px' : '60%'}
            background="none"
            value={searchedValue}
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (event.target) setSearchedValue(event.target.value);
            }}
            searchIcon={true}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.headCell}>
                  {ASSIGNMENTS_HEADERS.map((heading) => (
                    <TableCell key={heading} className={classes.headerColor}>
                      {heading}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {getData().map((row, index) => (
                  <TableRow key={index} style={{ backgroundColor: row?.removeButton ? Colors.smoke : '' }}>
                    <TableCell className={classes.bodyCell}>{row?.building_name}</TableCell>
                    <TableCell className={classes.bodyCellSecondary}>
                      {row?.room_number}
                      <br />
                      {/* {row.roomType} */}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>{row?.first_name + ' ' + row?.last_name}</TableCell>
                    <TableCell className={classes.bodyCellSecondary}>
                      {row?.email}
                      {/* <div style={{ marginTop: '6px' }}>{row.phone}</div> */}
                    </TableCell>
                    <TableCell>
                      {!row?.removeButton && (
                        <CustomButton
                          title="Remove from room"
                          solid={false}
                          borderRadius="34px"
                          textColor={Colors.viking}
                          border="solid 1px"
                          fontSize="12px"
                          fontWeight="700"
                          mTop="-5px"
                          width="170px"
                          height="38px"
                          buttonClicked={() => {
                            removeFromRoom(row?.roomie_user_room_id);
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentsAssignments;

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '100%',
    fontFamily: 'Wigrum',
    backgroundColor: Colors.plain_white,
  },
  body: {
    padding: '30px 7%',
    wdith: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '20%',
    },
  },
  heading: {
    fontFamily: 'Wigrum',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '64px',
  },
  headCell: {
    backgroundColor: Colors.whiteSmoke,
    margin: 0,
    padding: 0,
    height: '26px',
  },
  headerColor: {
    color: Colors.grey,
    fontFamily: 'Wigrum',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '10px',
  },
  table: {
    width: '60%',
    marginTop: '40px',
    '@media (max-width: 1100px)': {
      width: '100%',
    },
  },

  bodyCell: {
    fontFamily: 'Wigrum',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '14px',
    color: Colors.bigStone,
    paddingTop: '10px',
    paddingBottom: '34px',
  },

  bodyCellSecondary: {
    fontFamily: 'Wigrum',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    color: Colors.grey,
    paddingTop: '10px',
    paddingBottom: '34px',
  },
  tableWrapper: {
    overflowX: 'scroll',
    display: 'flex',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    '& -webkit-scrollbar': {
      width: '0',
      background: 'transparent',
    },
  },
  modalContainer: {
    padding: '55px 200px 90px',
    '@media (max-width: 800px)': {
      padding: '55px 100px 90px',
    },
    '@media (max-width: 560px)': {
      padding: '50px 50px 85px',
    },
    '@media (max-width: 460px)': {
      padding: '40px 30px 75px',
    },
    '@media (max-width: 410px)': {
      padding: '30px 15px 65px',
    },
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '46px',
  },
  modalText: {
    fontFamily: 'Wigrum',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    width: '290px',
    textAlign: 'center',
    color: Colors.secondary_text,
  },
  closeIcon: {
    width: '20px',
    height: '20px',
    color: Colors.button_bg_active,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.09)',
    },
  },
  close: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '42px',
    paddingTop: '20px',
  },
}));
