import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';
import { BuildingsAttributes } from 'types';
import EditIcon from '@material-ui/icons/Edit';
import Colors from 'theme';

interface Props {
  row: BuildingsAttributes;
  updateData: (row: BuildingsAttributes) => void;
  handleEditBuilding: (buildingId: string, buildingName: string) => void;
}

const EditTableRow = ({ row, updateData, handleEditBuilding }: Props) => {
  const classes = useStyles();

  const handleChange = (name: string, value: boolean) => {
    row.studentYears[name] = value;
    updateData(row);
  };

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.flex}>
        {row.buildingName}
        <div>
          <EditIcon className={classes.caret} onClick={() => handleEditBuilding(row.buildingId, row.buildingName)} />
        </div>
      </TableCell>
      <TableCell>
        <Checkbox
          checked={row.studentYears.yearOne}
          name="yearOne"
          onChange={(event) => handleChange(event.target.name, event.target.checked)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={row.studentYears.yearTwo}
          name="yearTwo"
          onChange={(event) => handleChange(event.target.name, event.target.checked)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={row.studentYears.yearThree}
          name="yearThree"
          onChange={(event) => handleChange(event.target.name, event.target.checked)}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={row.studentYears.yearFour}
          name="yearFour"
          onChange={(event) => handleChange(event.target.name, event.target.checked)}
        />
      </TableCell>
    </TableRow>
  );
};

export default EditTableRow;

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  caret: {
    color: Colors.button_bg_active,
    width: '19px',
    height: '15px',
    marginTop: '3px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
}));
