import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Colors from 'theme';
import { ReactElement } from 'react';

interface SemiCircularButtonPairProps {
  iconL: ReactElement;
  iconR: ReactElement;
  activeL?: boolean;
  activeR?: boolean;
  disabledL?: boolean;
  disabledR?: boolean;
  setActiveValueL: (item: boolean) => void | null;
  setActiveValueR: (item: boolean) => void | null;
  buttonPressedL: () => void | null;
  buttonPressedR: () => void | null;
}

const SemiCircularButtonPair = ({
  activeL = false,
  activeR = false,
  disabledL = false,
  disabledR = false,
  ...props
}: SemiCircularButtonPairProps): JSX.Element => {
  const classes = SemiCircularButtonPairStyles({ activeL, activeR, disabledL, disabledR, ...props });
  return (
    <div className={classes.Container}>
      <div
        onClick={() => props.buttonPressedL()}
        onMouseOver={() => props.setActiveValueL(!/Mobi|Android/i.test(navigator.userAgent))}
        onMouseOut={() => props.setActiveValueL(false)}
      >
        <IconButton classes={{ root: [classes.buttonL, classes.button].join(' ') }} color="primary" component={'div'}>
          {props.iconL}
        </IconButton>
      </div>
      <div
        onClick={() => props.buttonPressedR()}
        onMouseOver={() => props.setActiveValueR(!/Mobi|Android/i.test(navigator.userAgent))}
        onMouseOut={() => props.setActiveValueR(false)}
      >
        <IconButton classes={{ root: [classes.buttonR, classes.button].join(' ') }} color="primary" component={'div'}>
          {props.iconR}
        </IconButton>
      </div>
    </div>
  );
};

const SemiCircularButtonPairStyles = makeStyles(() => ({
  Container: {
    display: 'flex',
    flexDirection: 'row',
    width: '102px',
    justifyContent: 'space-between',
    margin: '0px 10px',
    '@media (max-width: 768px)': {
      margin: '5px 0',
      width: '82px',
    },
  },
  button: {
    transition: 'all 0.3s',
    width: 'calc(50px - 24px)',
    height: 'calc(50px - 24px)',
    '&:hover': {
      background: Colors.button_bg_active,
      transform: 'scale(1.05)',
      cursor: 'pointer',
      boxShadow: ' 0px 0px 7px 0px ' + Colors.button_bg_active_shadow,
    },
    '@media (max-width: 768px)': {
      width: 'calc(50px - 34px)',
      height: 'calc(50px - 34px)',
      '&:hover': {
        transform: 'none',
        cursor: 'normal',
        boxShadow: 'none',
      },
    },
  },
  buttonL: {
    background: (props: SemiCircularButtonPairProps) => (props.activeL ? Colors.button_bg_active : Colors.plain_white),
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    '@media (max-width: 768px)': {
      '&:hover': {
        background: (props: SemiCircularButtonPairProps) =>
          props.activeL ? Colors.button_bg_active : Colors.plain_white,
      },
    },
  },
  buttonR: {
    background: (props: SemiCircularButtonPairProps) => (props.activeR ? Colors.button_bg_active : Colors.plain_white),
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
    '@media (max-width: 768px)': {
      '&:hover': {
        background: (props: SemiCircularButtonPairProps) =>
          props.activeR ? Colors.button_bg_active : Colors.plain_white,
      },
    },
  },
}));

export default SemiCircularButtonPair;
