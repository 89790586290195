import { IconButton, createStyles, makeStyles } from '@material-ui/core';
import useAxios, { ServerRoutes } from 'api';
import Colors from 'theme';
import { DELETE } from 'utils';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfigStyle } from 'types';

export default function AppliedStyles({
  style,
  addStyleItem,
  getAssociatedStyles,
  setActiveMode,
  resetRoom,
  loadLayout,
}: {
  style: ConfigStyle;
  addStyleItem: any;
  getAssociatedStyles: () => Promise<void>;
  setActiveMode: any;
  resetRoom: (disableMessage?: boolean) => void;
  loadLayout: () => Promise<void>;
}) {
  const classes = useStyles();
  const { callApi: removeStyleByConfigId } = useAxios(
    `${ServerRoutes.removeStyleByConfigId}?configStyleId=${style?.id}`,
    DELETE,
    false,
  );
  const onAddStyleItem = () => {
    resetRoom(true);
    style?.configStyleItems?.forEach((item: any) => addStyleItem(style, item));
    setActiveMode('UpdateStyleAssociation');
  };

  const onRemoveStyle = async () => {
    if (style?.id) {
      await removeStyleByConfigId({}, (resp) => {
        if (resp?.status === 200) {
          resetRoom(true);
          getAssociatedStyles();
          loadLayout();
          setActiveMode('TemplateView');
        }
      });
    }
  };
  return (
    <div className={`${classes.root}`} onClick={onAddStyleItem}>
      <div className={classes.flex}>
        <img src={style.styleThumbnail} className={classes.thumbnail} />
        <div className={classes.catalog}>
          <div title={style.styleId} className={classes.ellipsis}>
            <span className={classes.styleInfo}>id : </span>
            {style.styleId}
          </div>
          <div title={style?.styleName} className={classes.ellipsis}>
            <span className={classes.styleInfo}>Name : </span>
            {style?.styleName}
          </div>
        </div>
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onRemoveStyle();
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: 'auto',
      marginBottom: '30px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      cursor: 'pointer',
      transition: 'transform 0.4s',
      '&:hover': {
        transform: 'scale(1.04)',
      },
    },
    catalog: {
      fontSize: '16px',
      color: Colors.cyprus1,
      lineHeight: '21px',
    },
    thumbnail: {
      width: '40px',
      height: '40px',
      marginRight: '20px',
    },
    styleInfo: {
      fontWeight: 'bold',
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '190px',
    },
  }),
);
