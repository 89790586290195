import React, { useEffect, useState } from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import Colors from 'theme';
import { Button as ButtonMUI } from '@material-ui/core';
import Routes from 'routes';
import { useHistory } from 'react-router-dom';
import { CircularProgress, InputFieldWithLabel, StyleIcon } from 'components';
import useAxios, { ServerRoutes } from 'api';
import { GET, POST } from 'utils';
import { StyleCatalogs } from 'types';

const useStyles = makeStyles(() => ({
  root: {
    background: Colors.plain_white,
    fontFamily: 'Wigrum',
    display: 'flex',
    height: '100%',
    marginLeft: '60px',
  },
  configStyleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 'auto',
    gap: 20,
  },
  createNewStyleWrapper: {
    width: '350px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: Colors.drawer_shadow,
    margin: 'auto',
  },
  styles: {
    width: '450px',
    border: '1px solid gray',
    padding: '16px',
    margin: 'auto',
    borderRadius: ' 25px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: Colors.cyprus1,
  },
  list: {
    margin: '10px 0px',
    maxHeight: 'calc(100vh - 83px)',
    overflow: 'auto',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
    marginTop: '10px',
    alignItems: 'baseline',
    width: '6%',
    marginLeft: '5px',
  },
  flex: {
    display: 'flex',
    transition: 'transform 0.4s',
    '&:hover': {
      transform: 'scale(1.04)',
    },
  },
  backIcon: {
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    color: Colors.cyprus1,
  },
  backText: {
    cursor: 'pointer',
    color: Colors.cyprus1,
    fontSize: '12px',
  },
  createStyleButton: {
    width: '60%',
    borderRadius: '10px',
    height: '55px',
    background: Colors.paleTurquoise,
    color: Colors.cyprus,
  },
}));

type QueryParams = {
  query?: string;
};

const StylesDashboard = () => {
  const [searchStyleTerm, setSearchStyleTerm] = useState('');
  const [styles, setStyles] = useState<StyleCatalogs[] | null>(null);
  const history = useHistory();
  const classes = useStyles();
  const [styleId] = useState<string | null>(null);

  const { callApi: loadStyleItemCatalogs, loading: loadStyleItemCatalogsLoading } = useAxios(
    ServerRoutes.styleItemsCatalogs,
    GET,
    false,
    '',
    {},
    'shopping',
  );

  const { callApi: deleteStyle, loading: deleteStyleLoading } = useAxios(
    `${ServerRoutes.deleteStyle}/${styleId}/remove`,
    POST,
    false,
  );

  const removeSelectedStyle = async () => {
    await deleteStyle({}, (deleteResponse) => {
      if (deleteResponse) {
        fetchStyles();
      }
    });
  };

  useEffect(() => {
    if (styleId) {
      removeSelectedStyle();
    }
  }, [styleId]);

  const loadStyleItemCatalogsAndUpdateState: any = async (queryParams: QueryParams) => {
    await loadStyleItemCatalogs(queryParams, (styles) => {
      if (styles?.data) {
        setStyles(styles.data);
      }
    });
  };

  const handleSearch = async (value: string) => {
    if (value.trim() === '') {
      await loadStyleItemCatalogsAndUpdateState({});
    } else {
      setSearchStyleTerm(value);
      if (value.length > 3) {
        await loadStyleItemCatalogsAndUpdateState({ query: value });
      }
    }
  };

  useEffect(() => {
    fetchStyles();
  }, []);

  const fetchStyles = async () => {
    await loadStyleItemCatalogs({}, (styles) => {
      if (styles.data) {
        setStyles(styles.data);
      }
    });
  };

  return (
    <div className={classes.root}>
      {/* <div className={classes.topContainer}>
        <div className={classes.flex} onClick={() => history.push(Routes.DEFAULT)}>
          <ArrowBackIos className={classes.backIcon} />
          <div className={classes.backText}>Back to Home</div>
        </div>
      </div> */}
      <div className={classes.configStyleWrapper}>
        <div className={classes.createNewStyleWrapper}>
          <ButtonMUI
            variant="contained"
            onClick={() => {
              history.push(Routes.CREATE_STYLE);
            }}
            className={classes.createStyleButton}
            startIcon={<StyleIcon fill={Colors.paleTurquoise} height={25} width={25} />}
          >
            Add New Style
          </ButtonMUI>
        </div>
        <div className={classes.styles}>
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Select Style To Update
          </span>
          <InputFieldWithLabel
            placeholder="Search Styles to Update"
            value={searchStyleTerm}
            onChange={(e) => {
              setSearchStyleTerm(e.target.value);
              handleSearch(e.target.value);
            }}
            width="100%"
          />
          <CircularProgress isLoading={loadStyleItemCatalogsLoading || deleteStyleLoading} coverPage={false} />
          {!loadStyleItemCatalogsLoading && !deleteStyleLoading && (
            <>
              {styles && styles?.length > 0 ? (
                <div className={classes.list}>
                  {styles &&
                    styles?.map((style: StyleCatalogs, index: number) => (
                      <ListItem
                        onClick={() => {
                          history.push({
                            pathname: Routes.CREATE_STYLE,
                            search: `?styleId=${style?.id}`,
                          });
                        }}
                        className={classes.item}
                        button
                        key={index}
                      >
                        {style.styleName || 'No Name Provided'}
                        {/* <CustomButton
                          title="Remove"
                          borderRadius="6px"
                          solid={false}
                          width="100px"
                          buttonClicked={(e) => {
                            e.stopPropagation();
                            setStyleId(style?.id);
                          }}
                          mTop="0px"
                          mBottom="5px"
                          textColor={Colors.lightRed}
                          border={`1px solid ${Colors.lightRed}`}
                          background={Colors.lightWhite}
                          fontWeight="500"
                        /> */}
                      </ListItem>
                    ))}
                </div>
              ) : (
                <h2 style={{ textAlign: 'center' }}>styles Not Found!</h2>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StylesDashboard;
