import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Colors from 'theme';

const CustomButton = ({
  title,
  enable = true,
  solid = true,
  buttonClicked,
  mouseDown,
  mouseUp,
  icon = '',
  containerWidth,
  width,
  height,
  mTop,
  mBottom,
  mLeft,
  mRight,
  background,
  borderRadius,
  border,
  textColor,
  fontSize,
  fontWeight,
  padding,
  hoverColor,
  hoverTextColor,
}: Props): JSX.Element => {
  const classes = useStyles({
    containerWidth,
    width,
    height,
    mTop,
    mBottom,
    mLeft,
    mRight,
    fontSize,
    background,
    borderRadius,
    border,
    textColor,
    hoverColor,
    hoverTextColor,
    padding,
    fontWeight,
    enable,
  });
  return (
    <Button
      className={solid ? classes.customButtonSolid : classes.customButtonNonSolid}
      variant="contained"
      disableElevation
      disabled={!enable}
      onClick={(e: any) => buttonClicked && buttonClicked(e)}
      onMouseDown={() => mouseDown && mouseDown()}
      onMouseUp={() => mouseUp && mouseUp()}
      type="submit"
      startIcon={
        !!icon && (
          <Icon>
            <img className={classes.icon} src={icon} />
          </Icon>
        )
      }
    >
      {title}
    </Button>
  );
};

interface Props {
  title: string | JSX.Element;
  enable?: boolean;
  solid?: boolean;
  icon?: string;
  hoverColor?: string;
  buttonClicked?: (e: any) => void;
  mouseDown?: () => void;
  mouseUp?: () => void;
  disable?: boolean;
  containerWidth?: string;
  width?: string;
  height?: string;
  mTop?: string;
  mBottom?: string;
  mLeft?: string;
  mRight?: string;
  background?: string;
  borderRadius?: string;
  border?: string;
  textColor?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string;
  hoverTextColor?: string;
}

interface StyleProps {
  containerWidth?: string;
  height?: string;
  width?: string;
  mTop?: string;
  mBottom?: string;
  mLeft?: string;
  mRight?: string;
  fontSize?: string;
  background?: string;
  borderRadius?: string;
  border?: string;
  fontWeight?: string;
  textColor?: string;
  enable?: boolean;
  padding?: string;
  hoverColor?: string;
  hoverTextColor?: string;
}

const useStyles = makeStyles((theme) => ({
  customButtonSolid: {
    marginTop: (props: StyleProps) => props.mTop || '23px',
    marginBottom: (props: StyleProps) => props.mBottom || '0px',
    marginLeft: (props: StyleProps) => props.mLeft || '0px',
    marginRight: (props: StyleProps) => props.mRight || '0px',
    width: (props: StyleProps) => props.width || props.containerWidth || '311px',
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontWeight: 'bold',
    border: (props: StyleProps) => props.border || 'none',
    fontSize: (props: StyleProps) => props.fontSize || '15px',
    lineHeight: '16px',
    textAlign: 'center',
    padding: (props: StyleProps) => props.padding || '6px 16px',
    color: (props: StyleProps) => props.textColor || Colors.hilighted_text_secondary,
    borderRadius: (props: StyleProps) => props.borderRadius || '4px',
    height: (props: StyleProps) => props.height || '50px',
    background: (props: StyleProps) =>
      !props.enable ? `${Colors.whiteSmoke} !important` : props.background || Colors.light_blue_bg,
    textTransform: 'none',
    transition: 'transform 0.5s',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px !important',
    },
    '&:hover': {
      background: (props: StyleProps) => props.hoverColor || Colors.primary_bg_hover,
      color: (props: StyleProps) => props.hoverTextColor || '',
      transform: 'scale(1.04)',
    },
    '&:disabled': {
      background: `${Colors.poreclain} !important`,
      color: Colors.secondary_text,
    },
    '@media (max-width: 350px)': {
      width: '250px !important',
    },
  },
  icon: {
    marginBottom: '5px',
  },
  customButtonNonSolid: {
    marginTop: (props: StyleProps) => props.mTop || '23px',
    marginBottom: (props: StyleProps) => props.mBottom || '0px',
    textTransform: 'none',
    width: (props: StyleProps) => props.width || props.containerWidth || '311px',
    fontFamily: 'Wigrum',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: (props: StyleProps) => props.fontSize || '15px',
    lineHeight: '22px',
    textAlign: 'center',
    padding: (props: StyleProps) => props.padding || '6px 16px',
    color: (props: StyleProps) => props.textColor || Colors.hilighted_text_secondary,
    borderRadius: (props: StyleProps) => props.borderRadius || '4px',
    height: (props: StyleProps) => props.height || '50px',
    background: (props: StyleProps) =>
      !props.enable ? `${Colors.whiteSmoke} !important` : props.background || Colors.plain_white,
    border: (props: StyleProps) => props.border || 'solid 2px',
    borderColor: Colors.light_blue_bg,
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(1.04)',
      background: (props: StyleProps) => props.hoverColor || 'none',
      color: (props: StyleProps) => props.hoverTextColor || '',
    },
    '@media (max-width: 350px)': {
      width: '250px !important',
    },
  },
}));

export default CustomButton;
