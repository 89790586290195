const ErrorIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5637 6.79358C14.5637 6.69045 14.4794 6.60608 14.3762 6.60608L12.8294 6.61311L10.4997 9.39046L8.17233 6.61545L6.62311 6.60842C6.51998 6.60842 6.43561 6.69045 6.43561 6.79592C6.43561 6.84045 6.45201 6.88264 6.48014 6.9178L9.52936 10.5506L6.48014 14.1811C6.45182 14.2154 6.4361 14.2584 6.43561 14.303C6.43561 14.4061 6.51998 14.4905 6.62311 14.4905L8.17233 14.4834L10.4997 11.7061L12.827 14.4811L14.3739 14.4881C14.477 14.4881 14.5614 14.4061 14.5614 14.3006C14.5614 14.2561 14.545 14.2139 14.5169 14.1787L11.4723 10.5483L14.5215 6.91545C14.5497 6.88264 14.5637 6.83811 14.5637 6.79358Z"
      fill="#FF4D4F"
    />
    <path
      d="M10.5 0C4.70156 0 0 4.70156 0 10.5C0 16.2984 4.70156 21 10.5 21C16.2984 21 21 16.2984 21 10.5C21 4.70156 16.2984 0 10.5 0ZM10.5 19.2188C5.68594 19.2188 1.78125 15.3141 1.78125 10.5C1.78125 5.68594 5.68594 1.78125 10.5 1.78125C15.3141 1.78125 19.2188 5.68594 19.2188 10.5C19.2188 15.3141 15.3141 19.2188 10.5 19.2188Z"
      fill="#FF4D4F"
    />
  </svg>
);

export default ErrorIcon;
