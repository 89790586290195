import { SvgIconProps } from '@material-ui/core/SvgIcon';

const TwoD = (props: SvgIconProps) => {
  return (
    <svg width={19} height={12} viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.430625 12H7.23063V10.176H2.71863L5.21463 7.424C6.33463 6.192 7.08663 5.168 7.08663 3.744C7.08663 1.984 5.59863 0.608 3.83863 0.608C1.95063 0.608 0.782625 1.76 0.398625 3.28L2.25463 3.872C2.39863 3.056 2.87863 2.448 3.74263 2.448C4.51063 2.448 5.13463 3.072 5.13463 3.84C5.13463 4.656 4.62263 5.312 3.64663 6.448L0.430625 10.208V12ZM9.70563 12H12.5536C16.2656 12 18.7616 9.744 18.7616 6.4C18.7616 3.104 16.4256 0.799999 12.5536 0.799999H9.70563V12ZM11.6576 10.176V2.624H12.6976C15.2096 2.624 16.7776 4.016 16.7776 6.4C16.7776 8.784 15.1776 10.176 12.6976 10.176H11.6576Z"
        fill={props.fill}
      />
    </svg>
  );
};

export default TwoD;
