import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { InputFieldWithLabel } from 'components';
import { RoomConfig } from 'types';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import ButtonsContainer from './buttonsContainer';
import { useState } from 'react';

interface Props {
  item?: RoomConfig;
  setItem: (item?: RoomConfig) => void;
  onAddRoom: (roomNumber: string) => Promise<any>;
}

const AddRoomModal = ({ item, setItem, onAddRoom }: Props) => {
  const classes = useStyles();

  const [roomNumber, setRoomNumber] = useState('');

  const addRoom = async () => {
    const res = await onAddRoom(roomNumber);
    if (res?.data?.code === 0) {
      handleClose();
    }
  };

  const handleClose = () => {
    setItem(undefined);
    setRoomNumber('');
  };

  return (
    <Dialog classes={{ paper: classes.paper }} open={!!item} onClose={handleClose} maxWidth="lg">
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>Add Room</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>
        <div>Please enter the room number which you want to add.</div>
        <InputFieldWithLabel
          labelText="Room Number"
          placeholder="Enter Room Number"
          value={roomNumber}
          setFieldValue={(item) => setRoomNumber(item)}
          mTop="0px"
          width="200px"
        />
        <ButtonsContainer cancelClicked={handleClose} submitClicked={addRoom} submitText="Add" />
      </DialogContent>
    </Dialog>
  );
};

export default AddRoomModal;

const useStyles = makeStyles((theme) => ({
  addRoomContent: {
    width: 'auto',
    padding: '20px',
  },
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  paper: {
    borderRadius: '12px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  center: {
    textAlign: 'center',
    marginBottom: '20px',
  },
}));
