import { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { POST, Messages } from 'utils';
import { RoomCatalogItemDTO } from 'types';
import { ToolBar, InputFieldWithLabel, CustomSmallButton, SingleItem, Snackbar, CircularProgress } from 'components';
import useAxios, { ServerRoutes } from 'api';
import { AxiosError } from 'axios';
import Colors from 'theme';

const SearchCatalog = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [results, setResults] = useState<RoomCatalogItemDTO[]>([]);
  const classes = useStyles();

  const {
    callApi: searchCatalogs,
    loading: seachCatalogsLoading,
    error: searchCatalogsError,
  } = useAxios(ServerRoutes.adminSearchCatalog, POST, false);

  const { callApi: logout, error: logoutError, loading: logoutLoading } = useAxios(ServerRoutes.logout, POST, false);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        if (error?.response?.status && error?.response?.status === 400) setMessage(Messages.SEARCH_CATALOG_EMPTY);
        else setMessage(error.message);
        setIsError(true);
      }
    };
    showError(searchCatalogsError);
    showError(logoutError);
  }, [searchCatalogsError, logoutError]);

  const getCatalogs = () => {
    setResults([]);
    searchCatalogs(
      {
        catalogId: id,
        catalogName: name,
      },
      (catalogsResp) => {
        if (catalogsResp.data?.catalogs.length > 0) {
          setResults(catalogsResp.data?.catalogs);
        } else {
          setIsError(true);
          setMessage(Messages.SEARCH_CATALOG_EMPTY);
        }
      },
    );
  };

  return (
    <div className={classes.root}>
      <CircularProgress isLoading={seachCatalogsLoading || logoutLoading} />
      <Snackbar isOpen={isError} setIsOpen={setIsError} severity="error" message={message} />
      <ToolBar logout={logout} />
      <div className={classes.heading}>Search Catalog</div>
      <div className={classes.column}>
        <InputFieldWithLabel value={id} onChange={(e) => setId(e.target.value)} labelText={'Catalog Id'} />
        <InputFieldWithLabel value={name} onChange={(e) => setName(e.target.value)} labelText={'Catalog Name'} />
        <CustomSmallButton disabled={!name && !id} title="Search" margin="30px 0px" onClick={() => getCatalogs()} />
      </div>
      {results.length > 0 && (
        <div className={classes.container}>
          <div className={classes.heading}>{`${results.length} catalog(s) found.`}</div>
          {results.map((item) => (
            <SingleItem key={item.item_description} type="Search" item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchCatalog;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: Colors.plain_white,
      minHeight: '100vh',
    },
    heading: {
      fontFamily: 'Cooper Lt BT',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '25px',
      textAlign: 'center',
      marginTop: '30px',
      marginBottom: '9px',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '10%',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '10%',
      },
    },
  }),
);
