import { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import Colors from '../../theme';
import clsx from 'clsx';
import { SingleItemProps } from 'types';

interface Props {
  item: SingleItemProps;
  type: string;
}

const SingleItem = ({ item, type }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const getDate = (date: number | undefined) => {
    if (date) return new Date(date).toLocaleDateString();
    return '';
  };

  const Row = ({ title, value }: { title: string; value: string | number }) => (
    <div className={classes.row}>
      <b className={classes.title}>{title}:</b>
      {value}
    </div>
  );

  const Items = () => {
    if (type == 'Catalog')
      return (
        <div>
          <Row title="Category Id" value={item?.category ?? ''} />
          <Row title="Sub Category Id" value={item?.subcategory ?? ''} />
          <Row title="Name" value={item?.itemName ?? ''} />
          <Row title="Description" value={item?.item_description ?? ''} />
          <Row title="Thumbnail" value={item?.thumbnail ?? ''} />
        </div>
      );
    else if (type === 'Catalog Item')
      return (
        <div>
          <Row title="Catalog Id" value={item?.catalogId ?? ''} />
          <Row title="Variant Name" value={item?.itemVariantName ?? ''} />
          <Row title="Variant Description" value={item?.itemVariantDescription ?? ''} />
          <Row title="Variant Default" value={item?.itemVariantDefault ? 'True' : 'False'} />
          <Row title="Type" value={item?.type ?? ''} />
          <Row title="Sub Type" value={item?.subType ?? ''} />
          <Row title="Surface Type" value={item?.surfaceType ?? ''} />
          <Row title="Url" value={item?.url ?? ''} />
        </div>
      );
    else if (type === 'Search')
      return (
        <div>
          <Row title="Id" value={item?.catalog_id ?? ''} />
          <Row title="Name" value={item?.item_name ?? ''} />
          <Row title="Description" value={item?.item_description ?? ''} />
          <Row title="Created On" value={getDate(item?.created_on)} />
          <Row title="Active" value={item?.active ? 'True' : 'False'} />
          <Row title="Category Id" value={item?.category ?? ''} />
          <Row title="Sub Category Id" value={item?.subcategory ?? ''} />
          <Row title="Room Item" value={item?.room_item ? 'True' : 'False'} />
          <Row title="Thumbnail" value={item?.thumbnail ?? ''} />
        </div>
      );
    else
      return (
        <div>
          <Row title="Room Address" value={item?.roomAddress ?? ''} />
          <Row title="Room Description Blurb" value={item?.roomDescriptionBlurb ?? ''} />
          <Row title="Room Number" value={item?.roomNumber ?? ''} />
          <Row title="Suite" value={item?.suite ? 'True' : 'False'} />
          <Row title="Type" value={item?.type ?? ''} />
          <Row title="Roomie Model Url" value={item?.roomieModelUrl ?? ''} />
        </div>
      );
  };

  return (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        <div className={classes.name}>
          {item?.itemName || item?.itemVariantName || item.item_name || item?.type || "'No Name Found'"}
        </div>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreIcon />
        </IconButton>
      </div>
      {expanded && <Items />}
    </Card>
  );
};

export default SingleItem;

const useStyles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    row: {
      display: 'flex',
      margin: '0px 0px 6px 6px',
      wordBreak: 'break-word',
    },
    card: {
      width: '500px',
      background: Colors.drawer_bg,
      margin: '10px 20px',
      padding: '10px',
      wordWrap: 'break-word',
      [theme.breakpoints.down('xs')]: {
        width: '90%',
      },
    },
    expand: {
      padding: '5px',
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    name: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '15px',
      color: Colors.plain_black_light,
      marginLeft: '6px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
      marginRight: '10px',
      wordBreak: 'normal',
    },
  }),
);
