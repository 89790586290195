import { makeStyles } from '@material-ui/core/styles';
import { RoomScene } from '@roomie-engineering/room-viewer';
import { useState } from 'react';
import {
  CustomIconButton,
  SemiCircularButtonPair,
  Measurement,
  CancelIcon,
  Zoom_in,
  Zoom_out,
  ThreeD,
  TwoD,
} from 'components';
import Colors from 'theme';

interface Flags {
  measurementActive: boolean;
  measurementHovered: boolean;
  surfaceMeasurementActive: boolean;
  surfaceMeasurementHovered: boolean;
  zoomInPressed: boolean;
  zoomInHovered: boolean;
  zoomOutPressed: boolean;
  zoomOutHovered: boolean;
  twoDActive: boolean;
  twoDHovered: boolean;
  threeDActive: boolean;
  threeDHovered: boolean;
}

interface ButtonBarTopProps {
  roomScene: RoomScene | null;
  activateMeasurement: boolean;
  filename: string;
}
const ButtonBarTop = ({ roomScene, ...props }: ButtonBarTopProps): JSX.Element => {
  const [flags, setFlags] = useState<Flags>({
    measurementActive: props.activateMeasurement,
    measurementHovered: false,
    surfaceMeasurementActive: props.activateMeasurement,
    surfaceMeasurementHovered: false,
    zoomInPressed: false,
    zoomInHovered: false,
    zoomOutPressed: false,
    zoomOutHovered: false,
    twoDActive: false,
    twoDHovered: false,
    threeDActive: true,
    threeDHovered: false,
  });
  const setValueInFlags = (key: string, value: boolean) => {
    setFlags((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const measurementPressed = () => {
    setValueInFlags('measurementActive', true);
    roomScene?.surfaceMeasureTool.deactivate();
    roomScene?.measureTool.activate();
  };
  const surfaceMeasurementPressed = () => {
    setValueInFlags('measurementActive', true);
    roomScene?.measureTool.deactivate();
    roomScene?.surfaceMeasureTool.activate();
  };
  const zoomInPressed = () => {
    roomScene?.camera.zoom(-1);
  };
  const zoomOutPressed = () => {
    roomScene?.camera.zoom(1);
  };
  const twoDPressed = () => {
    setValueInFlags('threeDActive', false);
    setValueInFlags('twoDActive', true);
    roomScene?.switchRoomViewTo2D();
  };
  const threeDPressed = () => {
    setValueInFlags('threeDActive', true);
    setValueInFlags('twoDActive', false);
    roomScene?.switchRoomViewTo3D();
  };

  const classes = ButtonBarTopStyles();
  return (
    <div className={classes.ButtonBarTop}>
      <div className={classes.title}>{props.filename}</div>
      <CustomIconButton
        buttonPressed={() => measurementPressed()}
        setActiveValue={(item) => setValueInFlags('measurementHovered', item)}
        active={props.activateMeasurement ?? flags.measurementActive ?? flags.measurementHovered}
        icon={
          <Measurement
            fill={
              (props.activateMeasurement ?? flags.measurementActive ? true : flags.measurementHovered)
                ? Colors.plain_white
                : Colors.icon_primary
            }
          />
        }
      />

      <CustomIconButton
        buttonPressed={() => surfaceMeasurementPressed()}
        setActiveValue={(item) => setValueInFlags('surfaceMeasurementHovered', item)}
        active={props.activateMeasurement ?? flags.surfaceMeasurementActive ?? flags.surfaceMeasurementHovered}
        icon={
          <CancelIcon
            fill={
              (props.activateMeasurement ?? flags.surfaceMeasurementActive ? true : flags.surfaceMeasurementHovered)
                ? Colors.plain_white
                : Colors.icon_primary
            }
          />
        }
      />

      <SemiCircularButtonPair
        buttonPressedL={() => zoomInPressed()}
        setActiveValueL={(item) => setValueInFlags('zoomInHovered', item)}
        buttonPressedR={() => zoomOutPressed()}
        setActiveValueR={(item) => setValueInFlags('zoomOutHovered', item)}
        iconR={
          <Zoom_out
            fill={(flags.zoomOutPressed ? true : flags.zoomOutHovered) ? Colors.plain_white : Colors.icon_primary}
          />
        }
        activeR={flags.zoomOutPressed ?? flags.zoomOutHovered}
        iconL={
          <Zoom_in
            fill={(flags.zoomInPressed ? true : flags.zoomInHovered) ? Colors.plain_white : Colors.icon_primary}
          />
        }
        activeL={flags.zoomInPressed ?? flags.zoomInHovered}
      />
      <SemiCircularButtonPair
        buttonPressedL={() => twoDPressed()}
        setActiveValueL={(item) => setValueInFlags('twoDHovered', item)}
        buttonPressedR={() => threeDPressed()}
        setActiveValueR={(item) => setValueInFlags('threeDHovered', item)}
        iconR={
          <ThreeD fill={(flags.threeDActive ? true : flags.threeDHovered) ? Colors.plain_white : Colors.icon_primary} />
        }
        activeR={flags.threeDActive ?? flags.threeDHovered}
        iconL={<TwoD fill={(flags.twoDActive ? true : flags.twoDHovered) ? Colors.plain_white : Colors.icon_primary} />}
        activeL={flags.twoDActive ?? flags.twoDHovered}
      />
    </div>
  );
};

const ButtonBarTopStyles = makeStyles(() => ({
  ButtonBarTop: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
  title: {
    fontFamily: 'Wigrum',
    fontWeight: 700,
    fontSize: '16px',
    color: Colors.plain_black,
    marginTop: '15px',
    marginLeft: '20px',
    marginRight: '20px',
  },
}));

export default ButtonBarTop;
