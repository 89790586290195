import { makeStyles } from '@material-ui/core/styles';
import { RoomScene } from '@roomie-engineering/room-viewer';
import { useState } from 'react';
import { CustomIconButton, Measurement } from 'components';
import Colors from 'theme';

interface RightMeasurementButtonProps {
  roomScene: RoomScene | null;
  activateMeasurement: boolean;
}

const RightMeasurementButton = ({ roomScene, activateMeasurement }: RightMeasurementButtonProps): JSX.Element => {
  const [measurementActive, setMeasurementActive] = useState<boolean>(activateMeasurement ?? false);
  const [measurementHovered, setMeasurementHovered] = useState<boolean>(false);

  const measurementPressed = () => {
    setMeasurementActive(true);
    roomScene?.measureTool.activate();
  };

  const classes = RightMeasurementButtonStyles();

  return (
    <div className={classes.RightMeasurementButton}>
      <CustomIconButton
        buttonPressed={() => measurementPressed()}
        setActiveValue={(item) => setMeasurementHovered(item)}
        active={activateMeasurement ?? measurementActive ?? measurementHovered}
        icon={
          <Measurement
            fill={
              (activateMeasurement ?? measurementActive ? true : measurementHovered)
                ? Colors.plain_white
                : Colors.icon_primary
            }
          />
        }
      />
    </div>
  );
};

const RightMeasurementButtonStyles = makeStyles(() => ({
  RightMeasurementButton: {
    position: 'absolute',
    top: '20px',
    right: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
  },
}));

export default RightMeasurementButton;
