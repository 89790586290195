/* eslint-disable no-use-before-define */

import { useEffect, useState, useRef, useContext } from 'react';
import 'styles/RoomPlanner.css';
import { useHistory } from 'react-router-dom';
import { getRoomConfig, getRoomInfo, getShowTooltip, saveRoomConfig, saveShowTooltip } from 'context/localStorage';
import { CircularProgress, CustomButton, InputFieldWithLabel, RadioField } from 'components';
import Drawer from '@material-ui/core/Drawer';
import Routes from 'routes';
import useStyles from 'styles/templateStyles';
import {
  layoutConfigToRoomItems,
  isBedFrameType,
  GET,
  POST,
  isPillowType,
  isBunk,
  MATTRESS_ANCHOR,
  BUNK_ANCHOR,
  roomSceneToLayoutItems,
  STATUS_OPTIONS,
  ROOM_CONFIGURATIONS,
  STYLE_ADDED_SUCCESS,
  ROOM_RESET_SUCCESS,
  Messages,
  isStyleType,
  STYLE_DELETE_SUCCESS,
  SELECT_ITEMS,
} from 'utils';
import { LayoutItem, Toast, GuideView, ConfigsType, StyleItem } from 'types';
import useAxios, { ServerRoutes, ShoppingServiceRoute } from 'api';
import { AxiosError } from 'axios';
import Colors from 'theme';
import UpDownKeys from 'assets/upDownKeys.png';
import { ItemModel, ItemModelSubType, ItemModelType, RoomScene } from '@roomie-engineering/room-viewer';
import { AuthContext } from 'context/authContext';
import { RoomViewer } from 'components/RoomViewer';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { v4 } from 'uuid';
import { Style } from 'types';
import { Snackbar } from 'components';
import StyleItems from 'components/styleComponents/styleItems';
// import AsyncAutocomplete from 'components/autocomplete/AsyncAutocomplete';
import { isArray } from 'lodash-es';

interface ButtonProps {
  background?: string;
  border?: string;
  title: string;
  onClick: () => void;
  solid?: boolean;
  width?: string;
  borderRadius?: string;
  enable?: boolean;
  hoverColor?: string;
  hoverTextColor?: string;
}
interface StyleDetails {
  id: string;
  styleName: string;
  templateId: string;
  active: boolean;
  styleItems: StyleItem[];
}

const initialConfigs = {
  bedConfig: '',
  standardBed: '',
  loftBed: '',
  bunkBed: '',
  studentCount: '',
};
interface Props {
  styles?: Style[];
  fetchStyles: () => void;
}
const SHOW_BED_TOGGLE_CONFIGURATIONS = false;
const CURRENT_STYLE_INITIAL = {
  styleName: '',
  templateId: '',
  active: true,
  styleItems: [],
  id: '',
};

const RoomStyleTemplate = ({ styles, fetchStyles }: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [style, setStyle] = useState(null);
  const [searchItem, setSearchItem] = useState('');
  const [roomSceneState, setRoomSceneState] = useState<RoomScene | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeMode, setActiveMode] = useState<'CreateNewStyle' | 'StylesListView' | 'UpdateStyle'>('StylesListView');
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false);
  const [status, setStatus] = useState('Active');
  const [toast, setToast] = useState<Toast>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isBedItem, setIsBed] = useState(false);
  const [catalogs, setCatalogs] = useState<any[]>([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState<any[]>([]);
  const [roomConfigs, setRoomConfigs] = useState<ConfigsType>(initialConfigs);
  const [currentStyle, setCurrentStyle] = useState<StyleDetails>(CURRENT_STYLE_INITIAL);
  const [styleItemIds, setStyleItemIds] = useState<any>([]);
  const [isError, setIsError] = useState(false);
  const rConfig = getRoomConfig();
  const roomInfo = getRoomInfo();
  const isStyleItemsExist = styles && styles?.length > 0 && styles.some((style: Style) => style?.styleName);

  const roomSceneStateRef = useRef(roomSceneState);
  const listRef = useRef<HTMLDivElement>(null);

  const setRoomSceneStateRef = (roomScene: RoomScene | null) => {
    if (roomScene) {
      setRoomSceneState(roomScene);
      roomSceneStateRef.current = roomScene;
    }
  };

  const setValueInRoomConfigs = (key: string, value: string | number) => {
    setRoomConfigs((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const params = new URLSearchParams(window.location.search);
  const isLocked = params.get('isLocked');

  const {
    callApi: loadLayout,
    response: loadLayoutRes,
    loading: loadLayoutLoading,
    error: loadLayoutError,
  } = useAxios(ServerRoutes.adminLoadLayout, GET, false);

  const {
    callApi: loadRooms,
    loading: roomsLoading,
    error: roomsError,
  } = useAxios(ServerRoutes.adminRoomsByConfig, POST, false);

  const {
    callApi: createNewStyle,
    loading: saveStyleLoading,
    error: saveStyleError,
  } = useAxios(ServerRoutes.stylesApi, POST, false);

  const {
    callApi: loadStylesCatalogs,
    loading: catalogsLoading,
    error: catalogsError,
  } = useAxios(ShoppingServiceRoute.getStyleCatalog, GET, false, '', {}, 'shopping');

  const {
    callApi: deleteStyle,
    loading: deleteStyleLoading,
    error: deleteStyleError,
  } = useAxios(`${ServerRoutes.deleteStyle}/${currentStyle?.id}/remove`, POST, false);

  const {
    callApi: removeItemApi,
    loading: removeItemLoading,
    error: removeItemError,
  } = useAxios(ServerRoutes.removeItem, POST, false);

  async function removeItem(itemIdOrItemIds: any, userRoomLayoutId: string) {
    if (!isArray(itemIdOrItemIds)) {
      return await removeItemApi({
        roomie_user_room_layout_id: userRoomLayoutId,
        roomie_user_room_item_id: itemIdOrItemIds,
      });
    }

    return Promise.all(
      itemIdOrItemIds.map((itemId) =>
        removeItemApi({
          roomie_user_room_layout_id: userRoomLayoutId,
          roomie_user_room_item_id: itemId,
        }),
      ),
    );
  }

  useEffect(() => {
    if (!rConfig.roomieRoomConfigId) history.push(Routes.DEFAULT);
    const fetchCatalogs = async () => {
      await loadStylesCatalogs({}, (catalogsResp) => {
        if (catalogsResp.data) {
          setCatalogs(catalogsResp.data);
        }
      });
    };
    fetchCatalogs();
  }, []);

  useEffect(() => {
    if (!rConfig.roomieRoomConfigId) history.push(Routes.DEFAULT);
    const loadItems = async () => {
      await loadStylesLayout(rConfig);
      window.addEventListener('keydown', keyPressEventListener);
    };
    if (rConfig.roomieRoomConfigId) {
      loadItems();
    }
    return function cleanUp() {
      window.removeEventListener('keydown', keyPressEventListener);
    };
  }, []);

  useEffect(() => {
    const loadHeights = async () => {
      if (roomSceneState) await applyHeight(roomSceneState, loadLayoutRes?.data.templateItems);
    };
    if (isLoaded) setTimeout(() => loadHeights(), 1000);
  }, [isLoaded]);

  useEffect(() => {
    const showError = (error: AxiosError | undefined) => {
      if (error) {
        setShowToast(true);
        setToast({
          severity: 'error',
          message: error.message,
          autoHideDuration: 3000,
        });
      }
    };
    showError(loadLayoutError);
    showError(roomsError);
    showError(saveStyleError);
    showError(catalogsError);
    showError(deleteStyleError);
    showError(removeItemError);
  }, [loadLayoutError, roomsError, saveStyleError, catalogsError, deleteStyleError]);

  useEffect(() => {
    setFilteredCatalogs((prevState: any) => {
      console.log({ prevState });
      return catalogs;
    });
  }, [catalogs]);

  const hideTooltip = () => {
    saveShowTooltip('false');
    setShowTooltip(false);
  };

  const keyPressEventListener = (evt: KeyboardEvent) => {
    if (roomSceneStateRef.current) {
      const selectedItem = roomSceneStateRef?.current?.selectedRoom?.selectedItem;
      if (selectedItem) {
        const bedFeature = selectedItem.getBedFeature();
        if (bedFeature) {
          if (evt.key == 'ArrowUp') {
            bedFeature.adjustFrameHeight(true);
            hideTooltip();
          } else if (evt.key == 'ArrowDown') {
            bedFeature.adjustFrameHeight(false);
            hideTooltip();
          }
        }
      }
    }
  };

  const loadStylesLayout = async (currentConfig: GuideView, changingTemplate = false) => {
    await loadLayout({ id: currentConfig.roomieRoomConfigId, token: '' }, async (loadLayoutResp) => {
      if (loadLayoutResp && loadLayoutResp.data.code === 0) {
        const data = loadLayoutResp?.data;
        roomInfo.admin && (await loadRooms({ roomie_room_config_id: data?.roomieRoomConfigId }));
        if (roomSceneState && changingTemplate) {
          saveRoomConfig(currentConfig);
          const room = roomSceneState.addRoom({
            title: data?.building_name || '',
            assetUrl: data?.roomie_model_url,
            isLocked: isLocked === 'true' && !roomInfo.admin,
            setIsLoaded: setIsLoaded,
          });
          room.addItems(layoutConfigToRoomItems(data?.template));
          roomSceneState.setSelectedRoom(room);
        }
      }
    });
  };

  const removeElement = async () => {
    const selectedItem = roomSceneState?.selectedRoom?.selectedItem;
    if (selectedItem) {
      if (selectedItem.type === ItemModelType.Bed) {
        const children = selectedItem?.getChildren() || [];
        for (let i = 0; i < children?.length; i++) roomSceneState?.selectedRoom?.removeItem(children[i], false);
      }
      roomSceneState?.selectedRoom?.removeItem(selectedItem, false);
      console.log('1111', selectedItem, loadLayoutRes?.data?.roomieRoomConfigId);

      removeItem(selectedItem?.itemId, loadLayoutRes?.data?.roomieRoomConfigId);
      setIsError(false);
    }
  };

  const notBed = () => {
    setIsBed(false);
    setShowTooltip(false);
  };
  const scrollToProduct = (productId: string) => {
    console.log({ productId });
    console.log('2222', `#item-${productId}`);
    console.log({ listRef });
    // const listItem = listRef?.current?.querySelector(`#item-${productId}`);
    const listItem = document.querySelector(`#item-${productId}`);
    console.log({ listItem });
    if (listItem) {
      listItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const onItemSelected = (item?: ItemModel, catalogs?: any) => {
    console.log({ item });
    console.log({ filteredCatalogs, catalogs });
    if (item) {
      if (item?.props?.catalogId) {
        scrollToProduct(item?.props?.catalogId);
      }
      setIsItemSelected(true);
      if (item.getBedFeature()) {
        setIsBed(true);
        if (getShowTooltip() === 'true') {
          setShowTooltip(true);
        }
      } else {
        notBed();
      }
    } else {
      console.log('onItemSelected False');
      setIsItemSelected(false);
    }
  };

  const applyHeight = async (scene: RoomScene, template: LayoutItem[]) => {
    if (scene) {
      const beds = scene.selectedRoom?.getAllItems().filter((item) => isBedFrameType(item.type || ''));
      beds?.forEach((item) => {
        const bedItem = template.filter(
          (resItem: LayoutItem) =>
            (resItem.parentItemId === item.itemId || resItem.itemId === item.itemId) &&
            isBedFrameType(resItem?.itemDetail?.type || ''),
        );
        if (bedItem) {
          renderBedWithHeight(bedItem[0].height ?? -1, item);
        }
      });
    }
  };

  const renderBedWithHeight = async (height: number, bed: ItemModel) => {
    if (height > -1) {
      const feature = bed.getBedFeature();
      feature?.setFrameHeight(height);
    }
    setIsLoaded(false);
  };

  const CustomTooltip = () => (
    <div className={classes.tooltip}>
      <img src={UpDownKeys} className={classes.arrowKeys} />
      Select bed then tap the up/down keys to customize bed height
    </div>
  );

  const handleSearch = (value: any) => {
    if (!value) setFilteredCatalogs(catalogs);
    else {
      const catalog = catalogs.filter((item) => item.itemName.toLowerCase().includes(value.toLowerCase()));
      setFilteredCatalogs(catalog);
    }
    setSearchItem(value);
  };

  const resetRoom = () => {
    if (loadLayoutRes && roomSceneState) {
      setIsError(false);
      setIsLoaded(false);
      const room = roomSceneState.addRoom({
        title: loadLayoutRes?.data?.building_name || '',
        assetUrl: loadLayoutRes?.data?.roomie_model_url,
        isLocked: isLocked === 'true' && !roomInfo.admin,
        setIsLoaded: setIsLoaded,
      });
      room.addItems(layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems));
      roomSceneState.setSelectedRoom(room);
      setToast({
        severity: 'success',
        message: ROOM_RESET_SUCCESS,
        autoHideDuration: 3000,
      });
      setShowToast(true);
    }
  };

  const Button = ({
    background = 'none',
    border = 'none',
    solid = false,
    title,
    borderRadius = '34px',
    onClick,
    width = '230px',
    enable = true,
    hoverColor = '',
    hoverTextColor = '',
  }: ButtonProps) => (
    <CustomButton
      title={title}
      width={width}
      height="48px"
      borderRadius={borderRadius}
      mTop="0px"
      mBottom="21px"
      buttonClicked={onClick}
      textColor={Colors.cyprus}
      border={border}
      solid={solid}
      background={background}
      enable={enable}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
    />
  );

  const applyStyleChange = (event: any) => {
    resetRoom();
    setActiveMode('UpdateStyle');
    setIsDrawerVisible(!isDrawerVisible);
    const styleId = event.target.value;
    setStyle(styleId);
    const selectedStyle: any = styles?.find((style) => style.id === styleId) || {};

    if (!selectedStyle) {
      return;
    }

    setCurrentStyle({
      styleName: selectedStyle['styleName'],
      templateId: selectedStyle['templateId'],
      active: selectedStyle['active'],
      styleItems: selectedStyle['styleItems'],
      id: selectedStyle['id'],
    });
    if (selectedStyle !== undefined) {
      addStyleItems(undefined, selectedStyle?.styleItems);
    }
  };

  const getBeds = () => {
    const beds = roomSceneState?.selectedRoom?.getAllItems().filter((item) => item.type === ItemModelType.Bed) || [];
    return beds;
  };

  const getBedsCount = () => {
    const beds = getBeds();
    let count = 0;
    for (let i = 0; i < beds?.length; i++) {
      count++;
      if (beds[i].subType === ItemModelSubType.TwinXLBunk) count++;
    }
    return count;
  };

  const addStyleItems = async (catalog?: any, styleItems?: StyleItem[] | undefined) => {
    console.log({ catalog, styleItems });
    if (styleItems) {
      styleItems &&
        styleItems?.map((styleItem) => {
          const itemData = {
            itemId: v4(),
            catalogId: styleItem.catalogId,
            variantId: styleItem.variantId,
            assetUrl: styleItem?.itemDetail?.url,
            deletable: styleItem?.deletable,
            moveable: true,
            parentItemId: styleItem?.itemId,
            anchor: styleItem?.anchor,
            type: styleItem?.itemDetail?.type || '',
            subType: styleItem?.itemDetail?.subType || '',
            surfaceType: styleItem?.itemDetail?.surfaceType || '',
            position: [styleItem?.positionX, styleItem?.positionY, styleItem?.positionZ],
            rotation: [styleItem?.rotationX, styleItem?.rotationY, styleItem?.rotationZ],
          };
          setStyleItemIds((prevIds: any) => [...prevIds, itemData.itemId]);
          roomSceneState?.selectedRoom?.addItem(itemData, false);
        });
      return;
    }
    if (!catalog) {
      return null;
    }
    const tags = catalog['descriptorTags'] || catalog['descriptorTag'];
    console.log({ tags });
    const materials: any = tags?.materials;
    if (isStyleType(tags?.type || '')) {
      if (!isBedItem) {
        console.log('111');

        setShowToast(true);
        setToast({
          severity: 'error',
          message: Messages.SELECT_BED,
          autoHideDuration: 3000,
        });
      } else {
        const selectedItem = roomSceneState?.selectedRoom?.selectedItem;
        const children = selectedItem?.getChildren() || [];
        const bunkItem = isBunk(selectedItem?.subType || '');
        const j = bunkItem ? 2 : 1;
        for (let i = 0; i < j; i++) {
          if (children?.length > 0) roomSceneState?.selectedRoom?.removeItem(children[i], false);
          const itemData: any = {
            itemId: v4(),
            catalogId: catalog.catalogId,
            variantId: catalog.catalogItemId,
            assetUrl: tags?.url || '',
            deletable: true,
            moveable: true,
            parentItemId: selectedItem?.itemId,
            anchor: i === 0 ? MATTRESS_ANCHOR : BUNK_ANCHOR,
            type: tags?.type || '',
            subType: tags?.subType || '',
            surfaceType: tags?.surfaceType || '',
            materials: materials,
          };
          roomSceneState?.selectedRoom?.addItem(itemData, false);
        }
      }
    } else {
      const count = getBedsCount();
      if (
        ((count === roomConfigs.studentCount || count > +roomConfigs.studentCount) &&
          tags?.type === ItemModelType.Bed &&
          SHOW_BED_TOGGLE_CONFIGURATIONS) ||
        (count === Number(roomConfigs.studentCount) - 1 &&
          tags?.subType === ItemModelSubType.TwinXLBunk &&
          SHOW_BED_TOGGLE_CONFIGURATIONS)
      ) {
        setIsError(true);
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.BED_COUNT_HEADER,
          message: Messages.BED_COUNT,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXLBunk &&
        roomConfigs.bunkBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.BUNK_NOT_ALLOWED,
          message: Messages.BUNK_NOT_ALLOWED_HEADER,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXLLoft &&
        roomConfigs.loftBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.LOFT_NOT_ALLOWED_HEADER,
          message: Messages.LOFT_NOT_ALLOWED,
          autoHideDuration: 3000,
        });
      } else if (
        tags?.subType === ItemModelSubType.TwinXL &&
        tags?.type === ItemModelType.Bed &&
        roomConfigs.standardBed !== 'Yes' &&
        SHOW_BED_TOGGLE_CONFIGURATIONS
      ) {
        setShowToast(true);
        setToast({
          severity: 'error',
          messageHeader: Messages.STANDARD_NOT_ALLOWED_HEADER,
          message: Messages.STANDARD_NOT_ALLOWED,
          autoHideDuration: 3000,
        });
      } else {
        setIsError(false);
        const itemProps = {
          itemId: v4(),
          variantId: catalog.catalogItemId,
          catalogId: catalog.catalogId,
          assetUrl: tags?.url,
          deletable: true,
          moveable: true,
          type: tags?.type || '',
          subType: tags?.subType || '',
          surfaceType: tags?.surfaceType || '',
          materials: materials,
          place: true,
        };
        setStyleItemIds((prevIds: any) => [...prevIds, itemProps.itemId]);
        roomSceneState?.selectedRoom?.addItem(itemProps, true);
      }
    }
  };

  const saveStyleHandler = async () => {
    const layout = roomSceneToLayoutItems(roomSceneState);
    const filteredLayout = layout.filter((item: any) => styleItemIds.includes(item.itemId));
    const styleItems = filteredLayout?.map((styleItem: any) => ({
      ...styleItem,
      active: status === 'Active',
    }));
    if (styleItems?.length === 0) {
      setToast({
        severity: 'error',
        message: SELECT_ITEMS,
        autoHideDuration: 3000,
      });
      setShowToast(true);
      return;
    }
    if (styleItems?.length > 0) {
      const createStylePayload = {
        ...currentStyle,
        active: status === 'Active',
        styleItems: [...styleItems],
        templateId: rConfig.roomieRoomConfigId,
      };

      await createNewStyle(createStylePayload, async (saveRoomResp) => {
        if (saveRoomResp.data.code == 0) {
          await loadLayout({ id: loadLayoutRes?.data?.roomieRoomConfigId, token: '' });
          if (activeMode === 'CreateNewStyle') {
            setIsDrawerVisible(false);
            setActiveMode('StylesListView');
          }
          setToast({
            severity: 'success',
            message: STYLE_ADDED_SUCCESS,
            autoHideDuration: 3000,
          });
          setShowToast(true);
          resetRoom();
          fetchStyles();
        }
      });
    }
  };

  const deleteStyleHandler = () => {
    deleteStyle({}, async (deleteResponse) => {
      console.log({ deleteResponse });
      setActiveMode('StylesListView');
      setIsDrawerVisible(false);
      setStyle(null);
      fetchStyles();
      resetRoom();
      setToast({
        severity: 'success',
        message: STYLE_DELETE_SUCCESS,
        autoHideDuration: 3000,
      });
      setShowToast(true);
    });
  };
  return (
    <>
      <CircularProgress
        isLoading={
          loadLayoutLoading ||
          roomsLoading ||
          saveStyleLoading ||
          catalogsLoading ||
          deleteStyleLoading ||
          removeItemLoading
        }
      />
      <Snackbar
        isOpen={showToast}
        setIsOpen={setShowToast}
        messageHeader={toast?.messageHeader}
        severity={toast?.severity || 'success'}
        message={toast?.message || ''}
        autoHideDuration={toast?.autoHideDuration}
      />
      <Drawer
        variant="persistent"
        anchor="left"
        open={isDrawerVisible}
        classes={{
          paper: classes.styleConfigDrawer,
        }}
      >
        <div className={classes.list}>
          <div className={classes.topContainer}>
            {activeMode === 'UpdateStyle' && (
              <>
                <div
                  className={classes.flex}
                  onClick={() => {
                    setActiveMode('StylesListView');
                    setIsDrawerVisible(false);
                    setStyle(null);
                    fetchStyles();
                    resetRoom();
                  }}
                >
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Styles List</div>
                </div>
              </>
            )}
            {activeMode === 'CreateNewStyle' && (
              <>
                <div
                  className={classes.flex}
                  onClick={() => {
                    setActiveMode('StylesListView');
                    setIsDrawerVisible(false);
                    resetRoom();
                    fetchStyles();
                  }}
                >
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Styles List</div>
                </div>
              </>
            )}
            {activeMode === 'StylesListView' && (
              <>
                <div className={classes.flex} onClick={() => history.push(Routes.TEMPLATE)}>
                  <ArrowBackIos className={classes.backIcon} />
                  <div className={classes.backText}>Back to Template</div>
                </div>
              </>
            )}
          </div>
          <div>
            {/* <AsyncAutocomplete resetRoom={resetRoom} addStyleItems={addStyleItems} /> */}
            {(activeMode === 'CreateNewStyle' || activeMode === 'UpdateStyle') && (
              <>
                <InputFieldWithLabel
                  placeholder="Style Name"
                  value={currentStyle.styleName}
                  onChange={(e) => {
                    setCurrentStyle({ ...currentStyle, styleName: e.target.value });
                  }}
                  width="100%"
                />
              </>
            )}

            {(activeMode === 'CreateNewStyle' || activeMode === 'UpdateStyle') && (
              <FormControl className={classes.formControl}>
                <InputLabel id="select-status">Status</InputLabel>
                <Select
                  value={status}
                  disabled={!authContext?.roomInfo?.isRoomieEmployee}
                  onChange={(e) => setStatus(e.target.value as string)}
                  displayEmpty
                  labelId="select-status"
                  className={classes.select}
                >
                  {STATUS_OPTIONS.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(activeMode === 'CreateNewStyle' || activeMode === 'UpdateStyle') && (
              <InputFieldWithLabel
                value={searchItem}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search Items"
                width="100%"
              />
            )}
          </div>
          {SHOW_BED_TOGGLE_CONFIGURATIONS && (
            <div className={classes.roomConfiguration}>
              <div className={classes.configurationText}>Room Configuration</div>
              {ROOM_CONFIGURATIONS.map((item) => (
                <RadioField
                  key={item.key}
                  title={item.title}
                  value={roomConfigs[item.key] as string}
                  setValue={(value) => setValueInRoomConfigs(item.key, value)}
                  options={item.options}
                />
              ))}
              <div className={classes.label}>Student count:</div>
              <InputFieldWithLabel
                value={roomConfigs['studentCount']}
                onChange={(e) => {
                  setIsError(false);
                  setValueInRoomConfigs('studentCount', Number(e.target.value));
                }}
                placeholder="Enter number"
                italicPlaceholder
                isError={isError}
                padding="10px 11px"
                width="140px"
                mTop="8px"
                type="number"
                background={Colors.plain_white}
                borderRadius="2px"
              />
            </div>
          )}
          {(activeMode === 'CreateNewStyle' || activeMode === 'UpdateStyle') && (
            <>
              <div className={classes.roomItems}>Style Items</div>
              <div className={classes.styleItemList}>
                {filteredCatalogs.map(
                  (catalog) =>
                    catalog.active &&
                    !isPillowType(catalog.itemName) && (
                      <StyleItems
                        key={catalog.id}
                        catalog={catalog || []}
                        addStyleItems={addStyleItems}
                        listRef={listRef}
                      />
                    ),
                )}
              </div>
            </>
          )}
        </div>
        {(activeMode === 'CreateNewStyle' || activeMode === 'UpdateStyle') && (
          <>
            <div className={classes.styleActionButton}>
              <CustomButton
                title="Remove Item"
                width="230px"
                solid={false}
                height="48px"
                borderRadius="34px"
                mTop="0px"
                enable={!!isItemSelected}
                buttonClicked={removeElement}
                textColor={Colors.cyprus}
                border={isItemSelected ? `1px solid ${Colors.frenchPass}` : 'none'}
              />
              <Button
                title={activeMode === 'CreateNewStyle' ? 'Create Style' : 'Update'}
                solid
                onClick={saveStyleHandler}
                background={Colors.paleTurquoise}
                enable={!!currentStyle.styleName}
              />
            </div>
            {activeMode === 'UpdateStyle' && (
              <div className={classes.deleteStyle}>
                <Button
                  width="90%"
                  hoverColor={Colors.lightRed}
                  hoverTextColor={Colors.lightWhite}
                  solid
                  title="Delete Style"
                  border={`1px solid ${Colors.frenchPass}`}
                  onClick={deleteStyleHandler}
                />
              </div>
            )}
          </>
        )}
      </Drawer>
      {!!loadLayoutRes?.data?.templateItems && (
        <RoomViewer
          isSharedUser={false}
          drawerOpen={true}
          onItemSelected={(ItemModel: any) => {
            console.log({ catalogs });
            onItemSelected(ItemModel, catalogs);
          }}
          setIsLoaded={setIsLoaded}
          roomLoaded={true}
          setRoomSceneState={setRoomSceneStateRef}
          buildingName={loadLayoutRes?.data?.roomieConfigName}
          roomModelUrl={loadLayoutRes?.data?.roomie_model_url}
          items={layoutConfigToRoomItems(loadLayoutRes?.data?.templateItems)}
        />
      )}
      {showTooltip && roomInfo.admin && <CustomTooltip />}
      {activeMode !== 'CreateNewStyle' && activeMode !== 'UpdateStyle' && isStyleItemsExist && (
        <div className={classes.selectStylesLabel}>Select Style</div>
      )}
      {activeMode !== 'CreateNewStyle' && activeMode !== 'UpdateStyle' && isStyleItemsExist && (
        <>
          <FormControl className={classes.selectStyle}>
            {styles && (
              <Select title="Select Style To View" value={style} style={{ width: '90%' }} onChange={applyStyleChange}>
                {styles.map((style) => (
                  <MenuItem key={style.id} value={style.id}>
                    {style.styleName}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </>
      )}

      {activeMode !== 'CreateNewStyle' && activeMode !== `UpdateStyle` && (
        <>
          <div className={classes.createStyleButton}>
            <Button
              title="Add New Style"
              borderRadius="6px"
              solid
              onClick={() => {
                setIsDrawerVisible(!isDrawerVisible);
                setActiveMode('CreateNewStyle');
                setCurrentStyle(CURRENT_STYLE_INITIAL);
              }}
              background={Colors.paleTurquoise}
              width="150px"
            />
          </div>
        </>
      )}

      {authContext?.roomInfo?.isRoomieEmployee && <div className={classes.typeText}>{loadLayoutRes?.data?.type}</div>}
    </>
  );
};

export default RoomStyleTemplate;
