import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Colors from 'theme';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ButtonsContainer from './buttonsContainer';

import { InputFieldWithLabel } from 'components/fields';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSubmit: (buildingId: string, buildingName: string) => void;
  universityName: string;
  buildingName: string;
  buildingId: string;
  onBuildingNameChange: (item: string) => void;
}

const EditBuildingNameModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  universityName,
  buildingName,
  buildingId,
  onBuildingNameChange,
}: Props) => {
  const classes = useStyles();

  const handleClose = () => setIsOpen(false);

  return (
    <Dialog open={isOpen} onClose={handleClose} classes={{ paper: classes.paper }}>
      <DialogContent className={classes.content}>
        <div className={classes.flex}>
          <div className={classes.text}>{universityName}</div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <InputFieldWithLabel
          labelText="Building Name"
          placeholder="Update Building Name"
          value={buildingName}
          setFieldValue={(item) => onBuildingNameChange(item)}
          mTop="10px"
        />

        <ButtonsContainer
          cancelClicked={handleClose}
          submitClicked={() => {
            onSubmit(buildingId, buildingName);
            handleClose();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditBuildingNameModal;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0px 36px 30px !important',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  paper: {
    borderRadius: '12px',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '30px 0px 18px',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '18px',
    color: Colors.bold_text,
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    color: Colors.icon_primary,
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.06)',
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '16px',
    marginBottom: '10px',
    color: Colors.secondary_text,
  },
  tableWrapper: {
    overflow: 'scroll',
    borderRadius: '8px',
    boxShadow: Colors.infoBoxShadow,
    height: '400px',
  },
}));
