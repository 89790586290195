const ServerErrors = {
  INVALID_SESSION: 'Invalid Session',
  INVALID_CREDENTIALS: 'Invalid Credentials',
  FIREBASE_USER_EXISTS: 'Firebase User Exists',
  USER_EXISTS: 'User Exists',
  EXPIRED_ACCESS_TOKEN: 'Expired Access Token',
  REFRESH_TOKEN_EXPIRED: 'Refresh Token expired',
  INVALID_REFRESH_TOKEN: 'Invalid Refresh Token',
};

const Messages = {
  SESSION_EXPIRED: 'Your session was expired. Please login again.',
  DUPLICATE_USER: 'User already exists.',
  TRY_AGAIN: 'Something went wrong. Please try again later.',
  NO_ACCOUNT: "User doesn't exist.",
  WRONG_INPUT: 'Please enter correct url and try again.',
  BUILDINGS_ERROR: 'Problem in loading university buildings.',
  LIMIT_REACHED: "This item's limit has been reached.",
  CATEGORIES_ERROR: 'Problem in loading categories.',
  CATALOGS_ERROR: 'Problem in loading catalogs.',
  DEFAULT_CATALOGS_ERROR: 'Problem in loading default catalogs.',
  NO_ROOM: "Room can't be displayed at the moment.",
  ROOM_ITEMS_ERROR: 'Problem in loading room items.',
  UNAVAILABLE: 'We are sorry that the selected item is currently unavailable.',
  NOT_IN_CATALOGS: "The selected item's info can't be displayed as it's not in the list of standard furnishings.",
  CHAT_CLOSED: 'Chat was closed. Please start chat again.',
  NO_BIGGER_BED: "Selected item doesn't have a bigger bed.",
  NO_BIGGER_MATTRESS: "Bigger Bed isn't available at the moment.",
  VARIANTS_ERROR: 'Problem in fetching variants.',
  NO_BED: 'No bed present. Please add a bed first.',
  NOT_BED: 'Selected item is not a bed.',
  NO_PRODUCTS: "Products aren't available at the moment.",
  NO_BUNK: "Bunk bed can't be swapped with the selected bed.",
  REMOVE_BUNK: 'Please swap it with a bunk bed that is already in the room.',
  REMOVE_BEDS: 'Please remove all beds to add a bunk bed.',
  SELECT_BED: 'Please select a bed to add a mattress.',
  ADD_ITEM_ERROR: 'Problem in adding item.',
  SWAP_ITEM_ERROR: 'There is no item to swap.',
  BED_COUNT: 'If you wish to add more beds to the room, please change max student count.',
  BED_COUNT_HEADER: 'Max beds reached',
  BUNK_NOT_ALLOWED: 'If you wish to add a bunk bed to the room, please allow it in the Room Configuration.',
  REMOVE_BUNKS: 'Please remove the bunk bed(s) or allow bunk bed in the Room Configuration to save.',
  BUNK_NOT_ALLOWED_HEADER: 'Bunk bed not allowed',
  LOFT_NOT_ALLOWED: 'If you wish to add a loft bed to the room, please allow it in the Room Configuration.',
  REMOVE_LOFT: 'Please remove the loft bed(s) or allow loft bed in the Room Configuration to save.',
  LOFT_NOT_ALLOWED_HEADER: 'Loft bed not allowed',
  STANDARD_NOT_ALLOWED: 'If you wish to add a standard bed to the room, please allow it in the Room Configuration.',
  REMOVE_STANDARD: 'Please remove the standard bed(s) or allow standard bed in the Room Configuration to save.',
  STANDARD_NOT_ALLOWED_HEADER: 'Standard bed not allowed',
  BED_COUNT_ERROR: 'Number of beds must match the max student count. Please remove or add bed(s) accordingly.',
  BED_COUNT_ERROR_HEADER: 'Bed count error',
  STUDENT_COUNT: 'Student count must be between 0 and 10',
  STUDENT_COUNT_HEADER: 'Student count error',
  MATTRESS_ERROR: 'Each bed must have a mattress.',
  ROOM_CONFIG: 'Please fill all the fields of Room Configuration before saving.',
  NO_ROOM_ITEMS: "There aren't any items in your room.",
  CHAT_ACTIVE: 'Error: Chat is already active',
  INVALID_EMAIL: 'Please enter a valid email',
  FILL_FIELDS: 'Please fill both fields before starting the chat',
  GROUP_OFFLINE_ERROR: 'Error: Groups offline',
  GROUP_OFFLINE: 'Our customer support is offline right now. Please email us at support@roomie.com or try again later.',
  CHAT_LIMIT: 'Error: Customer chat limit reached',
  CHAT_ERROR: "Connection with live chat couldn't be established. Please try again or contact us at brett@roomie.co.",
  CATALOG_UPLOAD_ERROR: "Catalog(s) weren't uploaded. Please check your csv file and try again.",
  CATALOG_ITEM_UPLOAD_ERROR: "Catalog Item(s) weren't uploaded. Please check your csv file and try again.",
  ROOM_UPLOAD_ERROR: "Room(s) weren't uploaded. Please check your csv file and try again.",
  SEARCH_CATALOG_EMPTY: 'No catalogs exist corresponding to the name and id you mentioned.',
  CATALOG_UPLOAD_WARNING: "Some catalogs weren't uploaded. Please take a look at the items below.",
  PRODUCTS_UPLOAD_ERROR: 'A problem occurred while updating products.',
  PRODUCTS_SYNC_ERROR: 'A problem occurred while syncing products.',
  CATALOG_ITEM_UPLOAD_WARNING: "Some catalog items weren't uploaded. Please take a look at the items below.",
  ROOM_UPLOAD_WARNING: "Some rooms weren't uploaded. Please take a look at the items below.",
  NO_TYPE_URL:
    "You don't have a room associated with your account, so please enter a url containing a type of room model in the address bar and then sign in.",
  INVALID_INVITE_ID: 'Invalid invite id. Please provide a valid one.',
  NO_INVITE: 'Please enter the invite url which was shared with you and then sign in.',
  INVALID_PIN: 'Invalid pin. Please enter the correct one.',
  INVALID_REQUEST_BODY: 'Invalid Request Body',
  NOT_AUTHORIZED: 'Not Authorized For This Action',
  TIMEOUT: 'Pin was expired. Please refresh the page and try again',
  INVALID_OTP: 'OTP is not valid',
  SECTION_INFO: 'Section Info invalid',
};

export { ServerErrors, Messages };
